<template>
    <div>
        <top-view></top-view>
        <div class="backTop">
            <!-- <div>
                <p>走进唐诚</p>
                <span class="english">ENTERING TANG CHENG</span>
                <button @click="gotoAboutus">了解更多</button>
            </div> -->
        </div>
        <div class="wenhua">
            <p><img src="../../assets/image/left.png">&nbsp;集团文化&nbsp;<img src="../../assets/image/right.png"></p>
            <span>GROUP CULTURE</span>
            <ul>
                <li>
                    <p>企业愿景</p>
                    <span>携手亿企辉煌</span>
                    <span>缔造百年唐诚</span>
                </li>
                <li>
                    <p>企业使命</p><span>客户因唐诚而卓越</span>
                    <span>员工因唐诚而幸福</span>
                </li>
                <li>
                    <p>价值观</p><span>诚实守信 激情敬业</span>
                    <span>拥抱变化 同创共荣</span>
                </li>
                <li>
                    <p>从业准则</p><span>用专业的会计税务服务</span>
                    <span>为客户创造价值</span>
                </li>
            </ul>
        </div>
        <div class="licheng">
            <p><img src="../../assets/image/left.png">&nbsp;发展历程&nbsp;<img src="../../assets/image/right.png"></p>
            <span>DEVELOPMENT HISTORY</span>
            <ul>
                <li v-for="(item, index) in lichengList" :key="item.mileageId">
                    <div v-if="index % 2 == 0">
                        <img :src="imgurl + item.mileageImg" alt="">
                        <div>
                            <div class="qiu"></div>
                            <div class="year">{{ item.mileageYear }}</div>
                            <p>【{{ item.mileageTitle }}】</p>
                            <div style="margin-top: 10px;width: 518px;height: 2px;background-color: #F0F0F0;margin-left: 12px;">
                                <div style="width: 30%;border: 1px solid #DADADA;"></div>
                            </div>
                            <ul>
                                <li v-for="(item1, index1) in item.mileageContent" :key="index1">
                                    <div>{{ item1.month }}月</div>
                                    <p>{{ item1.content }}</p>
                                </li>
                                <!-- <li>
                                    <div>4月</div>
                                    <p>唐诚石家庄公司成立，并购克瑞斯，小顶会计</p>
                                </li>
                                <li>
                                    <div>4月</div>
                                    <p>唐诚石家庄公司成立，并购克瑞斯，小顶会计</p>
                                </li>
                                <li>
                                    <div>4月</div>
                                    <p>唐诚石家庄公司成立，并购克瑞斯，小顶会计</p>
                                </li>
                                <li>
                                    <div>4月</div>
                                    <p>唐诚石家庄公司成立，并购克瑞斯，小顶会计</p>
                                </li>
                                <li>
                                    <div>10月</div>
                                    <p>唐诚石家庄公司成立，并购克瑞斯，小顶会计</p>
                                </li> -->
                            </ul>
                            <div class="show">.....</div>
                        </div>
                    </div>
                    <div v-if="index % 2 != 0">
                        <div>
                            <div class="qiu"></div>
                            <div class="year">{{ item.mileageYear }}</div>
                            <p>【{{ item.mileageTitle }}】</p>
                            <div style="margin-top: 10px;width: 518px;height: 2px;background-color: #F0F0F0;margin-left: 38px;">
                                <div style="margin-left: 70%;width: 30%;border: 1px solid #DADADA;"></div>
                            </div>
                            <ul>
                                <li v-for="(item2, index2) in item.mileageContent" :key="index2">
                                    <p>{{ item2.content }}</p>
                                    <div>{{ item2.month }}月</div>
                                </li>
                                <!-- <li>
                                    <div>4月</div>
                                    <p>唐诚石家庄公司成立，并购克瑞斯，小顶会计</p>
                                </li>
                                <li>
                                    <div>4月</div>
                                    <p>唐诚石家庄公司成立，并购克瑞斯，小顶会计</p>
                                </li>
                                <li>
                                    <div>4月</div>
                                    <p>唐诚石家庄公司成立，并购克瑞斯，小顶会计</p>
                                </li>
                                <li>
                                    <div>4月</div>
                                    <p>唐诚石家庄公司成立，并购克瑞斯，小顶会计</p>
                                </li>
                                <li>
                                    <div>10月</div>
                                    <p>唐诚石家庄公司成立，并购克瑞斯，小顶会计</p>
                                </li> -->
                            </ul>
                            <div class="show">.....</div>
                        </div>
                        <img :src="imgurl + item.mileageImg" alt="">
                    </div>
                </li>
                <!-- <li>
                    <img src="../assets/image/93176a07e5ce9bf625a93fafc2312ac1e118b767fa477d-8Fq7of_fw1200.png" alt="">
                    <div>
                        <p>【星火启亮 冀往开来】</p>
                        <div style="margin-top: 10px;width: 95%;height: 2px;background-color: #F0F0F0;">
                            <div style="width: 30%;border: 1px solid #DADADA;"></div>
                        </div>
                        <ul>
                            <li>
                                <div>4月</div>
                                <p>唐诚石家庄公司成立，并购克瑞斯，小顶会计</p>
                            </li>
                            <li>
                                <div>4月</div>
                                <p>唐诚石家庄公司成立，并购克瑞斯，小顶会计</p>
                            </li>
                            <li>
                                <div>4月</div>
                                <p>唐诚石家庄公司成立，并购克瑞斯，小顶会计</p>
                            </li>
                            <li>
                                <div>4月</div>
                                <p>唐诚石家庄公司成立，并购克瑞斯，小顶会计</p>
                            </li>
                            <li>
                                <div>10月</div>
                                <p>唐诚石家庄公司成立，并购克瑞斯，小顶会计</p>
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <div>
                        <p>【星火启亮 冀往开来】</p>
                        <div style="margin-top: 10px;width: 95%;height: 2px;background-color: #F0F0F0;">
                            <div style="margin-left: 70%;width: 30%;border: 1px solid #DADADA;"></div>
                        </div>
                        <ul>
                            <li>
                                <p>唐诚石家庄公司成立，并购克瑞斯</p>
                                <div>4月</div>
                            </li>
                            <li>
                                <p>唐诚石家庄公司成立，并购克瑞斯</p>
                                <div>10月</div>
                            </li>
                            <li>
                                <p>唐诚石家庄公司成立，并购克瑞斯</p>
                                <div>10月</div>
                            </li>
                            <li>
                                <p>唐诚石家庄公司成立，并购克瑞斯</p>
                                <div>10月</div>
                            </li>
                            <li>
                                <p>唐诚石家庄公司成立，并购克瑞斯</p>
                                <div>10月</div>
                            </li>
                        </ul>
                    </div>
                    <img src="../assets/image/93176a07e5ce9bf625a93fafc2312ac1e118b767fa477d-8Fq7of_fw1200.png" alt="">
                </li>
                <li>
                    <img src="../assets/image/93176a07e5ce9bf625a93fafc2312ac1e118b767fa477d-8Fq7of_fw1200.png" alt="">
                    <div>
                        <p>【星火启亮 冀往开来】</p>
                        <div style="margin-top: 10px;width: 95%;height: 2px;background-color: #F0F0F0;">
                            <div style="width: 30%;border: 1px solid #DADADA;"></div>
                        </div>
                        <ul>
                            <li>
                                <div>4月</div>
                                <p>唐诚石家庄公司成立，并购克瑞斯，小顶会计</p>
                            </li>
                            <li>
                                <div>4月</div>
                                <p>唐诚石家庄公司成立，并购克瑞斯，小顶会计</p>
                            </li>
                            <li>
                                <div>4月</div>
                                <p>唐诚石家庄公司成立，并购克瑞斯，小顶会计</p>
                            </li>
                            <li>
                                <div>4月</div>
                                <p>唐诚石家庄公司成立，并购克瑞斯，小顶会计</p>
                            </li>
                            <li>
                                <div>10月</div>
                                <p>唐诚石家庄公司成立，并购克瑞斯，小顶会计</p>
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <div>
                        <p>【星火启亮 冀往开来】</p>
                        <div style="margin-top: 10px;width: 95%;height: 2px;background-color: #F0F0F0;">
                            <div style="margin-left: 70%;width: 30%;border: 1px solid #DADADA;"></div>
                        </div>
                        <ul>
                            <li>
                                <p>唐诚石家庄公司成立，并购克瑞斯，小顶会计</p>
                                <div>10月</div>
                            </li>
                            <li>
                                <p>唐诚石家庄公司成立，并购克瑞斯，小顶会计</p>
                                <div>10月</div>
                            </li>
                            <li>
                                <p>唐诚石家庄公司成立，并购克瑞斯，小顶会计</p>
                                <div>10月</div>
                            </li>
                            <li>
                                <p>唐诚石家庄公司成立，并购克瑞斯，小顶会计</p>
                                <div>10月</div>
                            </li>
                            <li>
                                <p>唐诚石家庄公司成立，并购克瑞斯，小顶会计</p>
                                <div>10月</div>
                            </li>
                        </ul>
                    </div>
                    <img src="../assets/image/93176a07e5ce9bf625a93fafc2312ac1e118b767fa477d-8Fq7of_fw1200.png" alt="">
                </li> -->
            </ul>
        </div>

        <footer-view></footer-view>
        <side-bar class="fixed"></side-bar>

    </div>
</template>

<script>
import topView from '@/components/zh/topView.vue'
import footerView from '@/components/zh/footerView.vue'
import SideBar from '@/components/zh/sideBar.vue'
import axios from 'axios';

export default {
    components: { footerView, SideBar, topView },
    data() {
        return {
            infoShow: false,
            lichengList: [],
            contentList: [],
            // imgurl: 'http://192.168.0.199:8087/api',
            imgurl: ''
        }
    },
    created() {
        this.getlichengList()
        // this.$store.state.componentKey += 1
        this.website()
        this.imgurl = this.$store.state.imgurl
    },
    methods: {
        gotoAboutus() {
            this.$router.push('/aboutus')
        },
        getlichengList() {
            axios.get('/api/pcsystem/TckgMileage/list',{params:{language:'0'}}).then((res) => {
                if (res.status == 200) {

                    // let content = res.data.rows[2].mileageContent
                    // content = JSON.parse(content)
                    console.log(res.data.rows);
                    for (let i = 0; i < res.data.rows.length; i++) {
                        res.data.rows[i].mileageContent = JSON.parse(res.data.rows[i].mileageContent)
                        // content = JSON.parse(content)
                        // console.log(content[0]);
                        // this.contentList.push(content[0])
                    }
                    this.lichengList = res.data.rows
                    console.log(this.lichengList);
                }

            }).catch()
        },
        //网站访问信息
        website() {
            axios.post('/api/pcsystem/TckgSoftLogin', { userType: '发展历程' }).then((res) => {
                console.log(res);
            }).catch()
        },
    }

}
</script>

<style lang="scss" scoped>
.fixed {
    position: fixed;
    top: 150px;
    right: 5px;
}

.backTop {
    position: relative;
    width: 100%;
    // height: 450px;
    height: 26vw;
    min-width: 1200px;
    min-height: 310px;
    // height: 500px;
    background-image: url("../../assets/image/lichengBack.png");
    background-size: 100% 100%;

    // div {
    //     margin: auto;
    //     padding-top: 80px;
    //     width: 1160px;
    //     color: #EA5514;
    //     text-align: center;
    // }

    // p {
    //     margin: 20px 0;
    //     color: #fff;
    //     font-size: 50px;
    //     font-weight: 700;
    // }

    // .english {
    //     display: block;
    //     font-size: 16px;
    //     color: #fff;
    // }

    // button {
    //     margin-top: 30px;
    //     width: 100px;
    //     height: 30px;
    //     line-height: 30px;
    //     color: #fff;
    //     background-color: #FFB258;
    //     border: 0;
    //     border-radius: 15px;
    // }
}

.wenhua {
    // position: relative;
    // margin: auto;
    padding: 60px 0;
    min-width: 1200px;
    // background-color: #F4F4F4;
    background-image: url('../../assets/image/licheng/back.png');
    background-size: 100% 100%;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    ul {
        margin: 30px auto;
        display: flex;
        justify-content: space-around;
        width: 1200px;

        li {
            width: 23%;
            height: 300px;
            background-image: url('../../assets/image/licheng/qiyeyuanjing.png');
            background-size: 100% 100%;

            p {
                margin-top: 40px;
                text-align: center;
                // font-size: 20px;
                font-size: 24px;
            }

            span {
                display: block;
                text-align: center;
                color: #fff;
                font-size: 18px;
                line-height: 26px;

                &:nth-child(2) {
                    margin-top: 145px;
                }
            }

            &:nth-child(2) {
                background-image: url('../../assets/image/licheng/back3.png');
                background-size: 100% 100%;
            }

            &:nth-child(3) {
                background-image: url('../../assets/image/licheng/back2.png');
                background-size: 100% 100%;
            }

            &:nth-child(4) {
                background-image: url('../../assets/image/licheng/back4.png');
                background-size: 100% 100%;
            }

            &:hover {
                position: relative;
                top: -10px;
            }
        }
    }

}

.licheng {
    // position: relative;
    // margin: auto;
    padding: 60px 0;
    min-width: 1200px;
    background-color: #F4F4F4;
    // background-image: url('../assets/image/qiyewenhua/e744b11c2624cf0f57638b5ae2c482faab8ce1db5364a-YyRkJR_fw1200.png');
    // background-size: 100% 100%;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    >ul {
        margin: 30px auto;
        width: 1200px;
        // height: 500px;
        background-image: url('../../assets/image/licheng/centerLine.png');
        background-position: 50% 0;
        background-repeat: no-repeat;

        // text-align: center;
        >li {
            // // margin: auto;
            // margin-top: 50px;
            // margin-left: 230px;
            // display: flex;

            // &:first-child {
            //     padding-top: 160px;
            //     margin-top: 0;
            // }
            &:first-child {
                padding-top: 160px;
                margin-top: 0;
            }

            >div {
                // margin: auto;
                margin-top: 50px;
                // margin-left: 250px;
                display: flex;


                >div {
                    position: relative;
                    margin-left: 50px;
                    // width: 350px;
                    width: 568px;
                    // min-height: 260px;
                    // height: 300px;
                    padding: 20px 0px 20px 20px;
                    // border-color: #fff;
                    background-color: #FFF;
                    border-radius: 6px;

                    .year{
                        padding: 5px 10px;
                        // width: 60px;
                        width: 137px;
                        color: #fff;
                        background-color: #EA5513;
                        text-align: center;
                        font-size: 32px;
                        border-radius: 30px;
                    }

                    >.qiu {
                        position: absolute;
                        top: 20px;
                        left: -25px;
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        background-color: #EA5514;
                    }

                    >p {
                        margin-top: 20px;
                        // font-size: 20px;
                        font-size: 26px;
                        font-weight: 500;
                    }

                    >ul {
                        margin-top: 10px;
                        margin-left: 12px;
                        font-size: 14px;
                        color: #8C8C8C;
                        // width: 518px;

                        >li {
                            display: flex;
                            margin-top: 20px;

                            >div {
                                // font-size: 14px;
                                color: #4C4C4C;
                                width: 60px;
                                font-size: 20px;
                            }

                            >p {
                                // fon
                                line-height: 25px;
                                font-size: 18px;
                                // width: 280px;
                                width: 468px;
                            }
                        }
                    }

                    &:hover {
                        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
                    }
                }

                img {
                    // width: 300px;
                    // height: 200px;
                    // margin-left: 100px;
                    width: 558px;
                    height: 312px;
                }
            }

            &:nth-child(even) {
                text-align: end;

                >div {
                    margin-left: -70px;
                    // padding: 0 10px;
                    >div {
                        // position: relative;
                        // margin-right: 60px;
                        // margin-left: 20px;
                        padding: 20px;

                        .year{
                            margin-left: 400px;
                        }

                        >.qiu {
                            // position: absolute;
                            left: 603px;
                            // top: 20px;
                            // right: -200px;
                        }

                        ul {
                            li {
                                justify-content: end;
                                margin-right: 10px;

                                div {
                                    // width: 40px;
                                    width: 60px;
                                }
                            }
                        }
                        .show{
                            margin-right: 10px;
                        }
                    }
                }
                img {
                    margin-left: 50px;
                }

            }
            .show{
                display: none;
            }
            &:last-child{
                .show{
                    display: block;
                }
            }
        }
    }

}
</style>