<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "App",
  components: {
    // HelloWorld,
  },
  
};
</script>
<style lang="scss">
*{
  margin: 0;
  padding: 0;
  font-family: Source HanSansCN !important;
}
a{
  text-decoration: none;
}
ul{
  list-style: none;
  li{
    cursor: pointer;
  }
}
button{
  cursor: pointer;
}

</style>
