var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('top-view'),_c('div',{staticClass:"backTop"}),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"service"},[_vm._m(4),_c('ul',{key:_vm.refresh},_vm._l((_vm.list),function(item,index){return _c('li',{key:index,on:{"mouseenter":function($event){return _vm.mouseenter(index)},"mouseleave":_vm.mouseleave}},[_c('img',{attrs:{"src":_vm.imgurl + item.img}}),_c('div',[_c('p',[_vm._v(_vm._s(item.num)),_c('span',[_vm._v("members")])]),_c('span',[_vm._v(_vm._s(item.name))])]),_c('span',{domProps:{"innerHTML":_vm._s(item.content)}})])}),0)]),_c('div',{staticClass:"advantages"},[_vm._m(5),_c('ul',_vm._l((_vm.dataList),function(item){return _c('li',{key:item.contactId},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(item.deptName))]),(item.contactUser)?_c('div',{staticClass:"content1"},[_c('div'),_c('p',[_vm._v(_vm._s(item.contactUser)+"："+_vm._s(item.contactPhone))])]):_vm._e(),_c('div',{staticClass:"content2"},[_c('div'),_c('p',[_vm._v(_vm._s(item.contactAddress))])])])}),0)]),_c('div',{staticClass:"orange"},[_vm._v(" Looking forward to co-operation and suggestions from you ")]),_c('footer-view'),_c('side-bar',{staticClass:"fixed"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background-color":"#F4F4F4"}},[_c('ul',{staticClass:"fuwu"},[_c('li',[_c('img',{attrs:{"src":require("../../assets/image/haiwai/jingchengfuwu.png")}}),_c('div',[_c('p',[_vm._v("Committed service")]),_c('span',[_vm._v("Professional team to serve the whole life cycle of enterprises")])])]),_c('li',[_c('img',{attrs:{"src":require("../../assets/image/haiwai/huligongying.png")}}),_c('div',[_c('p',[_vm._v("Mutually beneficial and win-win")]),_c('span',[_vm._v("Long-term co-operation and shared dividends")])])]),_c('li',[_c('img',{attrs:{"src":require("../../assets/image/haiwai/yetaigongxiang.png")}}),_c('div',[_c('p',[_vm._v("Business sharing")]),_c('span',[_vm._v("Cross-border synergy for building ambitions")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"personal"},[_c('p',[_c('img',{attrs:{"src":require("../../assets/image/left.png")}}),_vm._v(" Cooperation objectives "),_c('img',{attrs:{"src":require("../../assets/image/right.png")}})]),_c('ul',[_c('li',[_c('img',{staticStyle:{"width":"100%","height":"250px"},attrs:{"src":require("../../assets/image/haiwai/mubiao1.png")}}),_c('p',[_vm._v("01.")]),_c('span',[_vm._v("The whole industry chain business matching, focusing on the sharing and integration of resources through in-depth strategic co-operation, hand in hand, win-win business opportunities")])]),_c('li',[_c('img',{staticStyle:{"width":"100%","height":"250px"},attrs:{"src":require("../../assets/image/haiwai/mubiao2.png")}}),_c('p',[_vm._v("02.")]),_c('span',[_vm._v("Expanding multi-industry market areas, bringing new growth points, enhancing brand influence, setting industry benchmarks with craftsmanship quality, and creating service models")])]),_c('li',[_c('img',{staticStyle:{"width":"100%","height":"250px"},attrs:{"src":require("../../assets/image/haiwai/mubiao3.png")}}),_c('p',[_vm._v("03.")]),_c('span',[_vm._v("Solve problems such as operation and management bottlenecks and team turnover, and help companies reach their business goals (revenue, cost, profit, customer volume, efficiency)")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"working"},[_c('p',[_c('img',{attrs:{"src":require("../../assets/image/left.png")}}),_vm._v(" Why work with us "),_c('img',{attrs:{"src":require("../../assets/image/right.png")}})]),_c('div',[_c('ul',[_c('li',[_c('p',[_vm._v("Strong Enterprise")]),_c('span',[_vm._v("Strength")])]),_c('li',[_c('p',[_vm._v("Abundant market")]),_c('span',[_vm._v("resources")])]),_c('li',[_c('p',[_vm._v("Global Services")]),_c('span',[_vm._v("Local Experts")])])]),_c('img',{attrs:{"src":require("../../assets/image/haiwai/heCenter.png")}}),_c('ul',[_c('li',[_c('p',[_vm._v("Mature team")]),_c('span',[_vm._v("support")])]),_c('li',[_c('p',[_vm._v("Innovation drive")]),_c('span',[_vm._v("and R&D strength")])]),_c('li',[_c('p',[_vm._v("Excellent service")]),_c('span',[_vm._v("system")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flow"},[_c('p',[_c('img',{attrs:{"src":require("../../assets/image/left.png")}}),_vm._v(" Cooperation Process "),_c('img',{attrs:{"src":require("../../assets/image/right.png")}})]),_c('ul',[_c('li',[_c('p',[_vm._v("Preliminary acquaintance")])]),_c('li',{staticStyle:{"width":"30px","margin-top":"100px"}},[_c('img',{attrs:{"src":require("../../assets/image/haiwai/jiantou.png")}})]),_c('li',[_c('p',[_vm._v("Reach a collaboration")])]),_c('li',{staticStyle:{"width":"30px","margin-top":"100px"}},[_c('img',{attrs:{"src":require("../../assets/image/haiwai/jiantou.png")}})]),_c('li',[_c('p',[_vm._v("Agreed programmes")])]),_c('li',{staticStyle:{"width":"30px","margin-top":"100px"}},[_c('img',{attrs:{"src":require("../../assets/image/haiwai/jiantou.png")}})]),_c('li',[_c('p',[_vm._v("Guiding operational implementation")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('img',{attrs:{"src":require("../../assets/image/haiwai/whiteD.png")}}),_vm._v(" Support Team "),_c('img',{attrs:{"src":require("../../assets/image/haiwai/whiteD.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('img',{attrs:{"src":require("../../assets/image/left.png")}}),_vm._v(" Contact us "),_c('img',{attrs:{"src":require("../../assets/image/right.png")}})])
}]

export { render, staticRenderFns }