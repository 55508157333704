<template>
    <!-- <div style="position: relative;display: flex;align-items: center;min-width: 1200px;"> -->
    <div style="position: relative;" class="bigBox">
        <div class="back">
            <img src="../../assets/image/logo.png" class="logo">
            <!-- <ul id="top">
            <li class="top">首页</li>
            <li class="top">集团服务</li>
            <li class="top">唐诚国际</li>
            <li class="top">商业合伙</li>
            <li class="top">集团咨询</li>
            <li class="top">走进唐诚</li>
            <li class="top">社会责任</li>
        </ul> -->
            <!-- <el-tabs value="one" @tab-click="handleClick">
            <el-tab-pane label="首页" name="one"></el-tab-pane>
            <el-tab-pane label="集团服务" name="two"></el-tab-pane>
            <el-tab-pane label="唐诚国际" name="three"></el-tab-pane>
            <el-tab-pane label="商业合伙" name="four"></el-tab-pane>
            <el-tab-pane label="集团咨询" name="five"></el-tab-pane>
            <el-tab-pane label="走进唐诚" name="six"></el-tab-pane>
            <el-tab-pane label="社会责任" name="seven"></el-tab-pane>
        </el-tabs> -->
            <!-- <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
            active-text-color="#EA5514" :router="true">
            <el-menu-item index="1" @click="goHome">{{ list[0].label }}</el-menu-item>
            <el-submenu index="2">
                <template slot="title">{{ list[1].label }}</template>
<div style="padding: 10px 20px;">
    <el-menu-item class="jtfw" @click="gotoKuaiji" index="2-1">
        <p>{{list[1].children[0].label}}</p>
        <ul>
            <li v-for="item in list[1].children[0].children" :key="item.id">{{ item.label }}</li>
        </ul>
    </el-menu-item>
    <el-menu-item class="jtfw" @click="gotoKuaiji" index="2-2">
        <p>{{list[1].children[1].label}}</p>
        <ul>
            <li v-for="item in list[1].children[1].children" :key="item.id">{{ item.label }}</li>

        </ul>
    </el-menu-item>
    <el-menu-item class="jtfw" @click="gotoHaiwai" index="3-1">
        <p>{{list[1].children[2].label}}</p>
        <ul>
            <li v-for="item in list[1].children[2].children" :key="item.id">{{ item.label }}</li>

        </ul>
    </el-menu-item>
    <el-menu-item class="jtfw" @click="gotoQidai" index="2-3">
        <p>{{list[1].children[3].label}}</p>
        <ul>
            <li v-for="item in list[1].children[3].children" :key="item.id">{{ item.label }}</li>
        </ul>
    </el-menu-item>
    <el-menu-item class="jtfw" @click="gotoZonghe" index="2-4">
        <p>{{list[1].children[4].label}}</p>
        <ul>
            <li v-for="item in list[1].children[4].children" :key="item.id">{{ item.label }}</li>

        </ul>
    </el-menu-item>
    <el-menu-item class="jtfw" @click="gotoZizhi" index="2-5">
        <p>{{list[1].children[5].label}}</p>
        <ul>
            <li v-for="item in list[1].children[5].children" :key="item.id">{{ item.label }}</li>

        </ul>
    </el-menu-item>
    <el-menu-item class="jtfw" @click="gotoZonghe" index="2-6">
        <p>{{list[1].children[6].label}}</p>
        <ul>
            <li v-for="item in list[1].children[6].children" :key="item.id">{{ item.label }}</li>

        </ul>
    </el-menu-item>
    <el-menu-item class="jtfw" @click="gotoZonghe" index="2-7">
        <p>{{list[1].children[7].label}}</p>
        <ul>
            <li v-for="item in list[1].children[7].children" :key="item.id">{{ item.label }}</li>
        </ul>
    </el-menu-item>
</div>
</el-submenu>
<el-menu-item index="3" @click="gotoHaiwai">{{ list[2].label }}</el-menu-item>
<el-menu-item index="4" @click="gotoShangye">{{ list[3].label }}</el-menu-item>
<el-menu-item index="5" @click="gotoZixun">{{ list[4].label }}</el-menu-item>
<el-submenu index="6">
    <template slot="title">{{ list[5].label }}</template>
    <div class="jtzx">
        <el-menu-item index="6-1" @click="gotoJianjie">
            <p>集团简介</p>
        </el-menu-item>
        <el-menu-item index="6-2" @click="gotoDongshizhang">
            <p>董事长寄语</p>
        </el-menu-item>
        <el-menu-item index="6-3" @click="gotoQiyewenhua">
            <p>集团文化</p>
        </el-menu-item>
        <el-menu-item index="6-4" @click="gotoJituangoucheng">
            <p>集团构成</p>
        </el-menu-item>
        <el-menu-item index="6-5" @click="gotoFazhanlicheng">
            <p>发展历程</p>
        </el-menu-item>
        <el-menu-item index="6-6" @click="gotoQuanqiubujv">
            <p>全球布局</p>
        </el-menu-item>
    </div>
</el-submenu>
<el-submenu index="7">
    <template slot="title">{{ list[6].label }}</template>
    <div class="jtzx">
        <el-menu-item index="7-1" @click="gotoXiaoqi">
            <p>校企合作</p>
        </el-menu-item>
        <el-menu-item index="7-2" @click="gotoShangxiehui">
            <p>商协会联动</p>
        </el-menu-item>
        <el-menu-item index="7-3" @click="gotoGongyi">
            <p>唐诚公益</p>
        </el-menu-item>
    </div>
</el-submenu>
</el-menu> -->
            <!-- <el-menu :default-active="$route.path" class="el-menu-demo" mode="horizontal" @select="handleSelect"
            active-text-color="#EA5514" :router="true">
            <template v-for="item in list">
                <el-submenu :key="item.id" :index="item.classifyPath"
                    :class="{ 'active': $route.path === item.classifyPath }" v-if="item.children">
                    <div class="childitem" v-if="item.children">
                        <el-menu-item v-for="child in item.children" :key="child.id" :index="child.classifyPath">
                            <span slot="title" class="childitemT">{{ child.label }}</span>
                            <div v-if="child.children" class="childitem2">
                                <ul v-for="grandson in child.children" :key="grandson.id">
                                    <li slot="title" @click="navTab(grandson.classifyPath)">{{ grandson.label }}</li>
                                </ul>
                            </div>
                        </el-menu-item>
                    </div>
                    <span slot="title">{{ item.label }}</span>
                </el-submenu>
                <el-menu-item :key="item.id" :index="item.classifyPath" v-else>
                    <span slot="title">{{ item.label }}</span>
                </el-menu-item>
            </template>
        </el-menu> -->
            <el-menu :default-active="path" :unique-opened="true" class="el-menu-demo" mode="horizontal"
                @select="handleSelect" active-text-color="#EA5514" :router="true" menu-trigger="click">
                <template v-for="item in list">
                    <el-menu-item :key="item.id" :index="item.classifyPath" class="showMenu">
                        <span slot="title">{{ item.label }}</span>
                        <div class="childitem" v-if="item.children" :class="item.label == '集团服务' ? 'left' : ''">
                            <div v-for="child in item.children" :key="child.id" :index="child.classifyPath"
                                class="isShow itemFlex">
                                <span slot="title" @click.stop="gotoPage(child.classifyPath)">
                                    <img :src="imgurl + child.classifyImg" alt="" style="width: 0;height: 0;"
                                        :class="item.label == '走进唐诚' || item.label == '社会责任' ? 'huise' : ''">
                                    <img :src="imgurl + child.classifyImg2" alt="" style="width: 0;height: 0;"
                                        :class="item.label == '走进唐诚' || item.label == '社会责任' ? 'jvse' : ''">
                                    <!-- <div
                                    style="background-image: url(`${img}`);width: 20px;height: 20px;background-size: 100% 100%;">
                                </div> -->
                                    {{ child.label }}
                                    <!-- <p></p> -->
                                    <!-- <img src="../assets/icon/jianH.png" alt="" :class="item.label == '走进唐诚' || item.label == '社会责任' ? 'jianH showJ' : ''"> -->
                                    <!-- <img src="../assets/icon/jianB.png" alt="" :class="item.label == '集团服务'? 'jianH jianH' : ''"> -->
                                    <img src="../../assets/icon/jianH.png" alt=""
                                        :class="item.label == '走进唐诚' || item.label == '社会责任' ? 'jianH showJ' : 'jianH'">
                                    <img src="../../assets/icon/jianO.png" alt=""
                                        :class="item.label == '走进唐诚' || item.label == '社会责任' ? 'jianO showJ' : 'jianO'">
                                </span>
                                <div v-if="child.children" class="childitem2">
                                    <ul>
                                        <li slot="title" v-for="grandson in child.children" :key="grandson.id"
                                            @click.stop="gotoPage(grandson.classifyPath)">
                                            {{ grandson.label }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </el-menu-item>
                </template>
            </el-menu>
            <div class="tel">
                <img src="../../assets/icon/telGary.png">
                <span>400-168-5699</span>
            </div>
        </div>
        <div class="change" @mouseleave="isChange = false">
            <button @mouseenter="isChange = true">中文<img src="../../assets/icon/jianO.png" alt=""></button>
            <div v-if="isChange">
                <p @click="changeChinese">中文</p>
                <p @click="changeEnglish">English</p>
            </div>
        </div>
    </div>
</template>

<script>
import router from '@/router';
import axios from 'axios';
import { mapMutations } from 'vuex';
export default {
    data() {
        return {
            activeIndex: '1',
            list: [],
            imgurl: '',
            path: '/',
            isChange: false,
            // lang:this.$store.state.lang
        };
    },
    created() {
        // if (this.$route.query.key) {
        //     this.activeIndex = this.$route.query.key
        // }
        // console.log(this.$route.query.key),
        // this.img = require('../assets/icon/cheng.png')
        // console.log(typeof(this.img));
        this.imgurl = this.$store.state.imgurl
        this.getcdList()
    },
    // watch:{
    //     lang(newValue,oldValue){
    //         console.log('111111111111');
    //         if(newValue=='english'){
    //             console.log('english');
    //         } else {
    //             console.log('chinese');
    //         }
    //     }
    // },
    mounted() {
        if (this.type != null) {
            if (this.type == 1) {
                this.path = '/'
                // console.log("ceshi");
            } else if (this.type == 2) {
                this.path = '/zixun'
            } else if (this.type == 3) {
                this.path = '/shangxiehui'
            }
        } else {
            // this.path = this.$route.path
            if (this.$route.path == '/zizhi' || this.$route.path == '/qidai' || this.$route.path == '/zonghe') {
                this.path = '/kuaiji'
            } else if (this.$route.path == '/dongshizhang' || this.$route.path == '/qiyewenhua' || this.$route.path == '/jituangoucheng' || this.$route.path == '/fazhanlicheng' || this.$route.path == '/quanqiubujv' || this.$route.path == '/BeiJing') {
                this.path = '/aboutus'
            } else if (this.$route.path == '/xiaoqi' || this.$route.path == '/gongyi') {
                this.path = '/shangxiehui'
            } else {
                this.path = this.$route.path
            }
        }
    },
    methods: {
        navTab(path) {
            this.$router.push({ path: path })
        },
        getcdList() {
            axios.get('/api/pcsystem/TckgClassify/treeselect', { params: { language: "0" } }).then(
                (res) => {
                    this.list = res.data.data
                    console.log(this.list);
                    // return res
                }
            ).catch(
                // console.log(error)
                console.log("失败")
            )
        },
        handleSelect(key, keyPath) {
            // this.activeIndex = key
            console.log(key, keyPath);
            // this.$forceUpdate()
            // if(key=='6-1'){
            //     this.gotojianjie()
            // }
        },
        gotoPage(path) {
            this.$router.push({ path: path })
        },
        gotoKuaiji() {
            this.$router.push({ path: '/kuaiji', query: { key: '2' } })
        },
        gotoZonghe() {
            this.$router.push({ path: '/zonghe', query: { key: '2' } })
        },
        gotoZizhi() {
            this.$router.push({ path: '/zizhi', query: { key: '2' } })
        },
        gotoHaiwai() {
            this.$router.push({ path: '/haiwai', query: { key: '3' } })
        },
        gotoQidai() {
            this.$router.push({ path: '/qidai', query: { key: '2' } })
        },
        goHome() {
            this.$router.push({ path: '/', query: { key: '1' } })
        },
        gotoShangye() {
            this.$router.push({ path: '/shangye', query: { key: '4' } })
        },
        gotoZixun() {
            this.$router.push({ path: '/zixun', query: { key: '5' } })
        },
        gotoJianjie() {
            this.$router.push({ path: '/aboutus', query: { key: '6' } })
        },
        gotoDongshizhang() {
            this.$router.push({ path: '/dongshizhang', query: { key: '6' } })
        },
        gotoQiyewenhua() {
            this.$router.push({ path: '/qiyewenhua', query: { key: '6' } })
        },
        gotoJituangoucheng() {
            this.$router.push({ path: '/jituangoucheng', query: { key: '6' } })
        },
        gotoFazhanlicheng() {
            this.$router.push({ path: '/fazhanlicheng', query: { key: '6' } })
        },
        gotoQuanqiubujv() {
            this.$router.push({ path: '/quanqiubujv', query: { key: '6' } })
        },
        gotoXiaoqi() {
            this.$router.push({ path: '/xiaoqi', query: { key: '7' } })
        },
        gotoShangxiehui() {
            this.$router.push({ path: '/shangxiehui', query: { key: '7' } })
        },
        gotoGongyi() {
            this.$router.push({ path: '/gongyi', query: { key: '7' } })
        },
        // ...mapMutations({
        //     changeLang:'Lang'
        // }),
        changeChinese() {
            this.isChange = false
            // this.goHome()
            // this.changeLang({lang:'chinese'})
            // console.log(this.$store.state.lang);
        },
        changeEnglish() {
            this.isChange = false
            this.$router.push({ path: '/indexE', query: {} })
            // this.changeLang({lang:'english'})
            // console.log(this.$store.state.lang);
        }
    },
    props: {
        type: {
            type: String,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.back {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2vh auto;
    width: 1200px;

    // height: 100px;
    .logo {
        width: 186px;
        height: 36px;
    }

    ul {
        display: flex;
        justify-content: space-around;

        li {
            margin-left: 2vw;
            padding-bottom: 5px;
            cursor: pointer;
            // &:hover{
            //     border-bottom: 3px solid #DF591F;
            // }

        }
    }

    ::v-deep .el-tabs__header {
        margin: 0;
        // margin-top: -10px;
    }

    ::v-deep .el-menu.el-menu--horizontal {
        border: 0;

        li {
            font-size: 16px;
            margin-left: 10px;
            padding-bottom: 2px;

            .el-submenu__title {
                font-size: 16px;
            }
        }
    }

    .tel {
        // margin-left: 2vw;
        display: flex;
        align-items: center;

        img {
            // width: 18px;
            // height: 14px;
            width: 22px;
            height: 20px;
        }

        span {
            margin-left: 0.3vw;
            font-size: 24px;
            font-weight: 500;
        }
    }
}

.jtzx {
    padding: 10px 20px;

    .el-menu-item {
        padding: 10px 0;
        border-bottom: 1px dashed #E3E1DF;
        cursor: pointer;

        &:hover {
            p {
                color: #E6540E;

            }
        }
    }
}

.el-menu-demo {
    // margin-top: -10px;
    z-index: 9999;
}

.childitem {
    cursor: pointer;
    color: #545452;
    position: absolute;
    top: 105%;
    margin-left: -40px;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    padding: 0 20px;
    // &:first-child{
    //     margin-left: -100px;
    // }
}

.childitem2 {
    top: 0;
    left: 100%;
    background: #fff;
}

.childitem2 ul {
    // display: inline-block;
    display: flex;
    align-items: center;
    margin-top: 6px;
    margin-left: -10px;
    font-family: PingFangSC;

    // font-size: 12px;
    li {
        font-size: 12px !important;
        font-weight: 400;
        line-height: 17px;

        &:hover {
            color: #E6540E;
        }
    }
}

.itemFlex {
    position: relative;
    display: flex;
    // height: 20px;
    align-items: center;
    border-bottom: 1px dashed #979797;
    min-width: 150px;

    // opacity: 0.5;
    &:last-child {
        border-bottom: 0;
    }

    >span {
        display: block;
        min-width: 102px;
        font-weight: 500;
        font-size: 16px;
        // font-weight: 600;
    }

    .jianH,
    .jianO {
        // position: absolute;
        // line-height: 180px;
    }

    .jvse,
    .huise {
        position: relative;
        top: -2px;
        margin-right: 1px;
        width: 18px !important;
        height: 18px !important;
    }

    .jvse,
    .jianO {
        display: none;
    }

    .showJ {
        position: absolute;
        right: 10px;
        top: 21px;
    }

    &:hover {
        span {
            color: #E6540E;
        }

        .huise,
        .jianH {
            display: none;
        }

        .jvse,
        .jianO {
            display: inline-block;
        }

    }
}

.el-menu--horizontal>.el-menu-item {
    line-height: 65px;
}

.isShow {
    display: none;
}

.showMenu {
    // margin-top: 20px;
    // padding: 0 !important;
    line-height: 55px !important;
    font-size: 18px !important;
    color: #333333 !important;
    font-weight: 400 !important;
}

.is-active {
    color: #EA5514 !important;
}

.showMenu:hover .isShow {
    display: flex;
}

.left {
    margin-left: -300px;

    .itemFlex {
        >span {
            font-weight: 600;
        }
    }

    // margin: auto;
}

.change {
    position: absolute;
    top: 16px;
    right: 40px;
    z-index: 9999;

    button {
        display: flex;
        align-items: center;
        padding: 2px 5px;
        padding-right: 15px;
        border: 0;
        color: #EB5414;
        font-size: 18px;
        font-weight: 600;
        line-height: 20px;
        background-color: #fff;

        img {
            margin-left: 4px;
            width: 10px;
            height: 15px;
            transform: rotate(90deg);
        }
    }

    >div {
        border: 1px solid #e2e2e2;
        cursor: pointer;
        color: #545452;
        position: absolute;
        top: 105%;
        margin-left: -20px;
        background: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        padding: 10px 0px;
        z-index: 9999;

        p {
            padding: 5px 20px;
            border-left: 3px solid transparent;

            &:hover {
                border-left: 3px solid #EB5414;
                background-color: #FDEEE8;
                color: #EB5414;
            }
        }
    }

    &:hover {
        img {
            transform: rotate(-90deg);
        }
    }
}
</style>

<style lang="scss" scoped>
@media screen and (max-width:1450px) {
    .bigBox {
        // width: 1350px;
        width: 100%;
    }

    .back {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 2vh auto;
        width: 1100px;

        .logo {
            width: 186px;
            height: 36px;
        }

        ul {
            display: flex;
            justify-content: space-around;

            li {
                margin-left: 0;
                padding-bottom: 5px;
                cursor: pointer;
            }
        }

        ::v-deep .el-tabs__header {
            margin: 0;
            // margin-top: -10px;
        }

        ::v-deep .el-menu.el-menu--horizontal {
            border: 0;

            li {
                font-size: 16px;
                margin-left: 0px;
                padding-bottom: 2px;

                .el-submenu__title {
                    font-size: 16px;
                }

            }
        }

        ::v-deep .el-menu-item {
            padding: 0 15px !important;
        }

        .tel {
            // margin-left: 2vw;
            display: flex;
            align-items: center;

            img {
                // width: 18px;
                // height: 14px;
                width: 22px;
                height: 20px;
            }

            span {
                margin-left: 0.3vw;
                font-size: 24px;
                font-weight: 500;
            }
        }
    }

    .change {
        position: absolute;
        top: 16px;
        right: 20px;
        z-index: 9999;

        button {
            display: flex;
            align-items: center;
            padding: 2px 5px;
            padding-right: 15px;
            border: 0;
            color: #EB5414;
            font-size: 18px;
            font-weight: 600;
            line-height: 20px;
            background-color: #fff;

            img {
                margin-left: 4px;
                width: 10px;
                height: 15px;
                transform: rotate(90deg);
            }
        }

        >div {
            border: 1px solid #e2e2e2;
            cursor: pointer;
            color: #545452;
            position: absolute;
            top: 105%;
            margin-left: -20px;
            background: #fff;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
            padding: 10px 0px;
            z-index: 9999;

            p {
                padding: 5px 20px;
                border-left: 3px solid transparent;

                &:hover {
                    border-left: 3px solid #EB5414;
                    background-color: #FDEEE8;
                    color: #EB5414;
                }
            }
        }

        &:hover {
            img {
                transform: rotate(-90deg);
            }
        }
    }
}

@media screen and (max-width:1300px) {
    .bigBox {
        // width: 1200px;
        width: 100%;
    }

    .back {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 2vh ;
        width: 90%;

        .logo {
            width: 186px;
            height: 36px;
        }

        ul {
            display: flex;
            justify-content: space-around;

            li {
                margin-left: 0;
                padding-bottom: 5px;
                cursor: pointer;
            }
        }

        ::v-deep .el-tabs__header {
            margin: 0;
            // margin-top: -10px;
        }

        ::v-deep .el-menu.el-menu--horizontal {
            border: 0;

            li {
                font-size: 16px;
                margin-left: 0px;
                padding-bottom: 2px;

                .el-submenu__title {
                    font-size: 16px;
                }

            }
        }

        ::v-deep .el-menu-item {
            padding: 0 15px !important;
        }

        .tel {
            // margin-left: 2vw;
            display: flex;
            align-items: center;

            img {
                // width: 18px;
                // height: 14px;
                width: 22px;
                height: 20px;
            }

            span {
                margin-left: 0.3vw;
                font-size: 24px;
                font-weight: 500;
            }
        }
    }
}

@media screen and (max-width:1200px) {
    .bigBox {
        width: 1200px;
    }

    .back {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 2vh 0;
        width: 1100px;

        .logo {
            width: 186px;
            height: 36px;
        }

        ul {
            display: flex;
            justify-content: space-around;

            li {
                margin-left: 0;
                padding-bottom: 5px;
                cursor: pointer;
            }
        }

        ::v-deep .el-tabs__header {
            margin: 0;
            // margin-top: -10px;
        }

        ::v-deep .el-menu.el-menu--horizontal {
            border: 0;

            li {
                font-size: 16px;
                margin-left: 0px;
                padding-bottom: 2px;

                .el-submenu__title {
                    font-size: 16px;
                }

            }
        }

        ::v-deep .el-menu-item {
            padding: 0 15px !important;
        }

        .tel {
            // margin-left: 2vw;
            display: flex;
            align-items: center;

            img {
                // width: 18px;
                // height: 14px;
                width: 22px;
                height: 20px;
            }

            span {
                margin-left: 0.3vw;
                font-size: 24px;
                font-weight: 500;
            }
        }
    }
}
</style>