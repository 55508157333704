<template>
    <div>
        <top-view></top-view>
        <div class="backTop">
        </div>
        <div class="kuaijiQ">
            <div>
                <p>Enterprise loan business group</p>
                <div
                    style="margin-bottom: 40px;width: 81px;height: 4px;background: linear-gradient( 270deg, #F4F4F4 0%, #EA5514 100%);border-radius: 1px;">
                </div>
                <p class="kuaijiQC">
                    Based on providing financing services for small and micro enterprises and their legal persons. The
                    use of Internet technology digital means to achieve customer access, data collection, model
                    evaluation, etc., quickly give solutions.
                    <br><br>
                    Enable enterprises to enjoy low interest, flexible repayment of diversified financial products, to
                    help early enterprises out of the lack of collateral assets, difficult to obtain bank financing
                    dilemma.
                </p>
                <ul>
                    <li>
                        <img src="../../assets/image/qidai/shujvcaiji.png">
                        <span>
                            Customer reach
                        </span>
                    </li>
                    <li></li>
                    <li>
                        <img src="../../assets/image/qidai/kehuchuda.png">
                        <span>Data collection</span>
                    </li>
                    <li></li>
                    <li>
                        <img src="../../assets/image/qidai/moxingpingjia.png">
                        <span>Model evaluation</span>
                    </li>
                </ul>
            </div>
            <img src="../../assets/image/qidai/qidaishiyequn.png">
        </div>
        <div style="padding: 30px 0;background-color: #F4F4F4;min-width: 1200px;">
            <ul class="nbnd">
                <li>
                    <p>01-</p>
                    <div></div>
                    <p>Can I borrow</p>
                </li>
                <li>
                    <p>02-</p>
                    <div></div>
                    <p>Debt consolidation</p>
                </li>
                <li>
                    <p>03-</p>
                    <div></div>
                    <p style="font-size: 18px;">Credit investigation and
                        safeguarding rights</p>
                </li>
                <li>
                    <p>04-</p>
                    <div></div>
                    <p>What is the quota</p>
                </li>
                <li>
                    <p>05-</p>
                    <div></div>
                    <p style="font-size: 18px;">How about the <br>
                        interest on the loan</p>
                </li>
            </ul>
        </div>
        <div class="canIdo">
            <p><img src="../../assets/image/left.png">&nbsp;What can we do for your business&nbsp;<img
                    src="../../assets/image/right.png">
            </p>
            <ul>
                <li>
                    <img src="../../assets/image/qidai/jiangben.png">
                    <p>Cost decreasing and
                        benefit increasing</p>
                </li>
                <li>
                    <img src="../../assets/image/qidai/youhuaxindai.png">
                    <p>Improve credit
                        structure</p>
                </li>
                <li>
                    <img src="../../assets/image/qidai/pojie.png">
                    <p>Crack the financing
                        problem</p>
                </li>
            </ul>>
        </div>
        <div class="service">
            <p><img src="../../assets/image/left.png">&nbsp;Service content&nbsp;<img
                    src="../../assets/image/right.png"></p>
            <ul>
                <li>
                    <p class="qizhi">01</p>
                    <span>
                        <p>Enterprise credit loan</p>
                        <span>specialized guidance</span>
                    </span>
                    <div></div>
                </li>
                <li>
                    <p class="qizhi">02</p>
                    <span>
                        <p>Collateralized consumer loan</p>
                        <span>Low interest, fast arrival</span>
                    </span>
                    <div></div>
                </li>
                <li>
                    <p class="qizhi">03</p>
                    <span>
                        <p>Corporate tax credit</p>
                        <span>Ease the company's rush
                            to burn coal</span>
                    </span>
                    <div></div>
                </li>
                <li>
                    <p class="qizhi">04</p>
                    <span>
                        <p>Secured operating loan</p>
                        <span>Regular channels, system
                            quick batch</span>
                    </span>
                    <div></div>
                </li>
                <li>
                    <p class="qizhi">05</p>
                    <span>
                        <p>Proof of funds</p>
                        <span>Meet various needs of
                            enterprises</span>
                    </span>
                    <div></div>
                </li>
                <li>
                    <p class="qizhi">06</p>
                    <span>
                        <p>Personal credit loan</p>
                        <span>The process is simple and
                            the quota is high</span>
                    </span>
                    <div></div>
                </li>
            </ul>
            <div style="display: flex;justify-content: center;"><button @click="infoShow = true">Click to
                    consult</button></div>
        </div>
        <div class="orange">
            Know the interest rate policy, match the best plan, everything
            <span>All you need to do is provide the relevant information</span>
        </div>
        <div class="personal">
            <p><img src="../../assets/image/left.png">&nbsp;Senior Specialist&nbsp;<img
                    src="../../assets/image/right.png"></p>
            <ul>
                <li v-for="item in personalList" :key="item.personnelId">
                    <img :src="imgurl + item.personnelImg" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">{{ item.personnelName }}</span>
                        <span class="personalC">{{ item.personnelPosition }}</span>
                        <span class="personalRemark">
                                <div style="display: flex;" v-for="(children, index) in item.remark" :key="index">
                                    <div
                                        style="margin-top: 4px;width: 4px;height: 4px;border-radius: 50%;border: 2px solid #fff;background-color: #EA5514;">
                                    </div>
                                    <div style="margin-left: 8px;width: 240px;">{{ children }}</div>
                                </div>
                            </span>
                    </p>
                </li>
            </ul>
        </div>
        <div class="flow">
            <p><img src="../../assets/image/left.png">&nbsp;Service process&nbsp;<img
                    src="../../assets/image/right.png"></p>
            <ul>
                <li>
                    <!-- <img src="../assets/image/kuaiji/jiantou.png"> -->
                    <p>01.</p>
                    <span>Personal credit loan</span>

                </li>
                <li>
                    <p>02.</p>
                    <span>Personnel audit </span>
                </li>
                <li>
                    <p>03.</p>
                    <span>Matching financial institution</span>
                </li>
                <li>
                    <p>04.</p>
                    <span>Offline coordination in person</span>
                </li>
                <li>
                    <p>05.</p>
                    <span>Lending institution</span>

                </li>
                <li>
                    <p>06.</p>
                    <span>The Changes of Business Strategies</span>
                </li>
            </ul>
        </div>
        <div class="advantages">
            <p><img src="../../assets/image/left.png">&nbsp;Advantage of service&nbsp;<img
                    src="../../assets/image/right.png"></p>
            <div>
                <img src="../../assets/image/qidai/fuwuyoushi.png">
                <ul>
                    <li>
                        <div>01</div>
                        <p>Professional analysis, accurate matching
                            of loan projects</p>
                    </li>
                    <li>
                        <div>02</div>
                        <p>Personalized customization for optimal
                            financial planning</p>
                    </li>
                    <li>
                        <div>03</div>
                        <p>50+strategic alliances with financial
                            institutions to meet diverse needs</p>
                    </li>
                    <li>
                        <div>04</div>
                        <p>Stable service personnel, to ensure the
                            continuity of service</p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="success">
            <p><img src="../../assets/image/left.png">&nbsp;Successful case&nbsp;<img
                    src="../../assets/image/right.png"></p>
            <ul>
                <li v-for="item in caseList" :key="item.caseId" @mouseenter="changeContent(item)"
                    @mouseleave="recoveryContent(item)">
                    <img :src="imgurl + item.caseImg">
                    <div>
                        <div class="gengduo"></div>
                        <p>Project:{{ item.caseTitle }}</p>
                        <button>Time:{{ item.caseHs }}</button>
                        <span v-html="item.caseTitle2"></span>
                    </div>
                </li>
            </ul>
            <div style="display: flex;justify-content: center;" class="btn"><button @click="infoShow = true">Click to
                    consult</button></div>
        </div>
        <div class="offer">
            <p><img src="../../assets/image/left.png">&nbsp;We also provide these services&nbsp;<img
                    src="../../assets/image/right.png"></p>
            <ul>
                <li>
                    <p>
                        <img src="../../assets/image/kuaiji/jiangpai.png">
                        <span>Building qualification</span>
                    </p>
                    <button @click="gotoZizhi">for more information</button>
                </li>
                <li>
                    <p>
                        <img src="../../assets/image/kuaiji/caishui.png">
                        <span>Finance and taxation
                            service</span>
                    </p>
                    <button @click="gotoKuaiji">for more information</button>
                </li>
                <li>
                    <p>
                        <img src="../../assets/image/kuaiji/haiwai.png">
                        <span>Overseas business</span>
                    </p>
                    <button @click="gotoHaiwai">for more information</button>
                </li>
                <li>
                    <p>
                        <img src="../../assets/image/kuaiji/zhishi.png">
                        <span>Intellectual property</span>
                    </p>
                    <button @click="gotoZonghe">for more information</button>
                </li>
            </ul>
        </div>
        <footer-view></footer-view>
        <side-bar class="fixed"></side-bar>
        <el-dialog title="Please fill in your personal information" :visible.sync="infoShow" width="40%" center>
            <span style="font-size: 12px;color: #A5A5A5;text-align: center;display: block;margin-top: -30px;">We will
                contact you within 30 minutes</span>

            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
                <el-form-item label="Name:" prop="name">
                    <el-input v-model="ruleForm.name" placeholder="Please enter your name"></el-input>
                </el-form-item>
                <el-form-item label="Phone Number:" prop="tel">
                    <el-input v-model="ruleForm.tel" placeholder="Please enter your phone number"></el-input>
                </el-form-item>
                <el-form-item label="Leave a message:" prop="content">
                    <el-input v-model="ruleForm.content" type="textarea" placeholder="Please enter the question you want to consult
 (no more than 300 words)" class="inputC"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm('ruleForm')">Table shortly</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import topView from '@/components/en/topView.vue'
import footerView from '@/components/en/footerView.vue'
import SideBar from '@/components/en/sideBar.vue'
import axios from 'axios';

export default {
    components: { footerView, SideBar, topView },
    data() {
        return {
            infoShow: false,
            personalList: [],
            params: { pageSize: 8, pageNum: 1, personnelType: "2", language: '1' },
            // imgurl: 'http://192.168.0.199:8087/api',
            imgurl: '',
            caseList: [],
            ruleForm: {
                name: '',
                tel: '',
                content: ''
            },
            rules: {
                name: [
                    { required: true, message: '请输入您的姓名', trigger: 'blur' },
                    // { min: 7, max: 11, message: '请输入正确的手机号码', trigger: 'blur' }
                ],
                tel: [
                    { required: true, message: '请输入您的电话', trigger: 'blur' },
                    { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号码', trigger: 'blur' }
                ],
                content: [
                    // { required: true, message: '请输入您的电话', trigger: 'blur' },
                    { min: 0, max: 300, message: '最多输入300字', trigger: 'blur' }
                ]

            },
            text: '',
        }
    },
    created() {
        this.getPersonalList()
        this.getcaseList()
        this.website()
        this.imgurl = this.$store.state.imgurl
        // this.$store.state.componentKey += 1
    },
    methods: {
        getPersonalList() {
            axios.get('/api/pcsystem/TckgPersonnel/list', { params: this.params }).then((res) => {
                if (res.status == 200) {
                    this.personalList = res.data.rows
                    for (let i = 0; i < this.personalList.length; i++) {
                        this.personalList[i].remark = res.data.rows[i].remark.split('。')
                    }
                    // console.log(this.personalList);
                }

            }).catch()
        },
        getcaseList() {
            axios.get('/api/pcsystem/TckgCase/list', { params: { pageNum: 1, pageSize: 3, caseType: '2', language: '1' } }).then((res) => {
                if (res.status == 200) {
                    this.caseList = res.data.rows
                    console.log(this.caseList);
                }
            }).catch()
        },
        gotoZonghe() {
            this.$router.push({ path: '/zongheE', query: { key: '2' } })
        },
        gotoHaiwai() {
            this.$router.push({ path: '/haiwaiE', query: { key: '3' } })
        },
        gotoZizhi() {
            this.$router.push({ path: '/zizhiE', query: { key: '2' } })
        },
        gotoKuaiji() {
            this.$router.push({ path: '/kuaijiE', query: { key: '2' } })
        },
        //网站访问信息
        website() {
            axios.post('/api/pcsystem/TckgSoftLogin', { userType: '企贷事业群' }).then((res) => {
                console.log(res);
            }).catch()
        },
        //提交信息
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // alert('submit!');
                    this.infoShow = false
                    console.log(this.ruleForm);
                    axios.post('/api/pcsystem/TckgConsult', { params: this.ruleForm }).then((res) => {
                        // console.log(res);
                        if (res.status == 200) {
                            // console.log(111111);
                            this.$message({
                                message: '提交成功，请耐心等待',
                                type: 'success'
                            });
                        }
                    }).catch()
                } else {
                    // this.$message({
                    //     message: '提交失败，请稍后重试',
                    //     type: 'warning'
                    // });
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        changeContent(item) {
            this.text = item.caseTitle2
            item.caseTitle2 = item.caseContent
            // console.log(item);
        },
        recoveryContent(item) {
            console.log(this.text);
            item.caseTitle2 = this.text
            // console.log(item);
        }
    }

}
</script>

<style lang="scss" scoped>
.fixed {
    position: fixed;
    top: 150px;
    right: 5px;
}

.backTop {
    position: relative;
    width: 100%;
    height: 26vw;
    min-width: 1200px;
    min-height: 310px;
    background-image: url("../../assets/image/qidaiEBack.png");
    background-size: 100% 100%;
}

.kuaijiQ {
    margin: 60px auto;
    display: flex;
    justify-content: space-around;
    width: 1160px;

    img {
        border-radius: 0 !important;
    }

    div {
        margin-right: 20px;

        >p {
            margin-bottom: 10px;
            font-size: 22px;
            font-weight: 600;
        }

        .kuaijiQC {
            margin: 20px 0;
            font-size: 16px;
            line-height: 25px;
            font-weight: 400;
        }

        ul {
            margin-top: 50px;
            display: flex;
            justify-content: space-evenly;
            box-shadow: 0 0 10px 0 #F4F4F4;

            li {
                padding: 20px 30px;
                text-align: center;

                // border-right: 1px solid black;
                // &:last-child{
                //     border: 0;
                // }
                &:nth-child(2),
                &:nth-child(4) {
                    margin-top: 35px;
                    padding: 0;
                    width: 1px;
                    height: 26px;
                    // border: 1px solid #C8C4C4;
                    background-color: #C8C4C4;
                }

                span {
                    display: block;
                    font-size: 14px;
                    color: #949494;
                }
            }
        }
    }

}

.nbnd {
    display: flex;
    justify-content: space-around;
    margin: 30px auto;
    width: 1200px;

    li {
        width: 19%;
        background-color: #fff;
        border-radius: 6px;
        text-align: center;

        p {
            margin: 20px 0;
            font-size: 22px;
            font-weight: 500;
        }

        div {
            margin: auto;
            width: 50px;
            height: 50px;
            background-image: url('../../assets/image/qidai/nbndO.png');
            background-size: 100% 100%;
        }

        &:first-child {
            border-top-left-radius: 100px;
        }

        &:nth-child(2) {
            div {
                background-image: url('../../assets/image/qidai/fuzhaizhengheO.png');
            }

            &:hover {
                div {
                    background-image: url('../../assets/image/qidai/fuzhaizhengheH.png');
                }
            }
        }

        &:nth-child(3) {
            div {
                width: 45px;
                height: 50px;
                background-image: url('../../assets/image/qidai/zhengxinweiquanO.png');
            }

            &:hover {
                div {
                    background-image: url('../../assets/image/qidai/zhengxinweiquanH.png');
                }
            }
        }

        &:nth-child(4) {
            div {
                width: 40px;
                height: 50px;
                background-image: url('../../assets/image/qidai/edushiduoshaoO.png');
            }

            &:hover {
                div {
                    background-image: url('../../assets/image/qidai/edushiduoshaoH.png');
                }
            }
        }

        &:nth-child(5) {
            border-top-right-radius: 100px;

            div {
                width: 45px;
                height: 50px;
                background-image: url('../../assets/image/qidai/daikuanlixizenmesuanO.png');
            }

            &:hover {
                div {
                    background-image: url('../../assets/image/qidai/daikuanlixizenmesuanH.png');
                }
            }
        }

        &:hover {
            background-color: #EA5514;
            color: #fff;

            div {
                background-image: url('../../assets/image/qidai/nbndH.png');
            }
        }
    }
}

.canIdo {
    // position: relative;
    padding: 30px 0;
    min-width: 1200px;
    // background-color: #F4F4F4;
    background-image: url('../../assets/image/qidai/60f36944c6bf951d8579d623d7e627ac62513e922dccf6-6Npbt3_fw1200.png');

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    ul {
        display: flex;
        justify-content: space-around;
        margin: 80px auto 30px;
        width: 1160px;

        li {
            width: 30%;
            text-align: center;

            img {
                width: 100px;
                height: 100px;
            }

            p {
                margin: 20px auto 0;
                width: 244px;
                font-size: 24px;
                font-weight: 500;
            }
        }
    }

}

.service {
    // position: relative;
    padding: 75px 0;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    ul {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin: 50px auto 20px;
        width: 1160px;

        li {
            position: relative;
            margin-bottom: 1%;
            padding: 50px 20px 30px;
            align-items: center;
            width: 29%;
            background-color: #fff;
            border-radius: 6px;
            cursor: pointer;

            >div {
                position: absolute;
                top: 60px;
                right: 20px;
                width: 50px;
                height: 50px;
                background-image: url('../../assets/image/qidai/qiyexinyongd.png');
                background-size: 100% 100%;
            }

            &:nth-child(2) {
                >div {
                    background-image: url('../../assets/image/qidai/xiaofeid.png');
                }

                span {
                    p {
                        font-size: 19px;
                    }
                }
            }

            &:nth-child(3) {
                >div {
                    background-image: url('../../assets/image/qidai/shuijind.png');
                }
            }

            &:nth-child(4) {
                >div {
                    background-image: url('../../assets/image/qidai/jingyingD.png');
                }
            }

            &:nth-child(5) {
                >div {
                    background-image: url('../../assets/image/qidai/zijinzhengming.png');
                }
            }

            &:nth-child(6) {
                >div {
                    background-image: url('../../assets/image/qidai/gerenxinyongd.png');
                }
            }

            >span {
                display: block;

                p {
                    margin: 10px 0;
                    font-size: 22px;
                    font-weight: 500;
                }

                span {
                    display: inline-block;
                    width: 200px;
                    font-size: 16px;
                    color: #999999;
                }
            }

            .bottom {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                // z-index: 0;
            }

            &:hover {
                background-image: url('../../assets/image/qidai/bottomLine.png');
                background-size: 100% 10%;
                background-repeat: no-repeat;
                background-position: 0 100%;
            }

            .qizhi {
                position: absolute;
                top: -10px;
                left: 20px;
                margin: 10px 0;
                width: 35px;
                height: 50px;
                color: #F2956C;
                text-align: center;
                line-height: 40px;
                background-image: url('../../assets/image/zizhi/qizhi.png');
                background-size: 100% 100%;
            }
        }
    }

    button {
        margin: 60px 0 10px;
        width: 252px;
        height: 56px;
        padding: 5px 15px;
        font-size: 26px;
        font-weight: 500;
        color: #fff;
        background: linear-gradient(180deg, #FFB552 0%, #FF4D00 100%);
        border: 0;
        border-radius: 30px;
        text-align: center;
    }

}

.personal {
    padding: 60px 0;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    ul {
        margin: 40px auto 0;
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
        width: 1160px;

        li {
            position: relative;
            margin-top: 1vh;
            margin-left: 10px;
            width: 24%;
            height: 340px;
            background-size: 100% 100%;
            border-radius: 6px;
            overflow: hidden;

            >p {
                margin: 0;
                position: absolute;
                bottom: 0;
                padding: 10px 13px 5px;
                width: 91%;
                min-height: 75px;
                background-color: rgba(0, 0, 0, 0.5);
                text-align: start;
                color: #fff;
            }

            .personalT {
                display: block;
                font-size: 18px;
                font-weight: 500;
            }

            .personalC {
                font-size: 13px;
                font-weight: 500;
            }

            .personalRemark {
                margin-top: 5px;
                display: none;
                padding: 5px 10px;
                background-color: #EA5514;
                border-radius: 3px;
                font-size: 9px;
                line-height: 15px;
            }

            &:hover {
                .personalRemark {
                    display: block;
                }
            }
        }
    }

    button {
        margin-top: 20px;
        margin-left: 48%;
        padding: 2px 8px;
        color: #9B9B9B;
        border: 1px solid#9B9B9B;
    }
}

.flow {
    // position: relative;
    padding: 50px 0;
    min-width: 1200px;
    background-image: url("../../assets/image/kuaiji/6458486361c124a42770f394d12bef7d526a503611913d-0S20U5_fw1200.png");

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    >ul {
        margin: 170px auto 20px;
        display: flex;
        width: 1200px;
        height: 200px;
        background-image: url("../../assets/image/qidai/line.png");
        background-size: 100% 50%;
        background-repeat: no-repeat;

        li {
            width: 170px;
            height: 100px;
            color: #fff;

            p {
                margin-top: 10px;
                margin-left: 10px;
                font-size: 20px;
                text-align: start;
            }

            span {
                display: block;
                margin: 5px 10px 10px;
                width: 150px;
                font-size: 16px;
            }

            &:nth-child(odd) {
                margin-top: -100px;
                margin-left: 50px;
                background-image: url('../../assets/image/zizhi/duihuaKT.png');
                background-size: 100% 100%;
            }

            &:nth-child(even) {
                margin-top: 100px;
                background-image: url('../../assets/image/qidai/duihuaKB.png');
                background-size: 100% 100%;

                p {
                    margin-top: 25px;
                }
            }

            &:nth-child(2) {
                margin-top: 50px;
                margin-left: 0px;
            }

            &:nth-child(3) {
                margin-top: -50px;
                margin-left: 40px;
            }

            &:nth-child(4) {
                margin-left: 5px;

                span {
                    width: 170px;
                }
            }

            &:nth-child(5) {
                margin-top: -50px;
                margin-left: 40px;
            }

            &:nth-child(6) {
                margin-top: 60px;
                margin-left: 5px;
            }
        }
    }
}

.advantages {
    padding: 50px 0;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    >div {
        display: flex;
        margin: 60px auto 10px;
        width: 1200px;

        img {
            width: 50%;
        }

        ul {
            margin-top: 35px;
            margin-left: 50px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 50%;
            height: 340px;

            li {
                display: flex;
                align-items: center;
                padding: 8px 15px;
                width: 90%;
                height: 56px;
                background-color: #fff;
                border-radius: 6px;

                div {
                    width: 30px;
                    height: 30px;
                    background-image: url('../../assets/image/kuaiji/quan.png');
                    background-size: 100% 100%;
                    text-align: center;
                    line-height: 30px;
                    color: #fff;
                    font-weight: 600;
                }

                p {
                    margin-left: 20px;
                    width: 400px;
                    line-height: 20px;
                    font-size: 20px;
                }

                &:hover {
                    margin-left: -5%;
                    width: 95%;
                    background-color: #EA5514;
                    color: #fff;

                    div {
                        background-image: url('../../assets/image/kuaiji/quanH.png');
                        color: #EA5514;
                    }
                }
            }
        }
    }

    button {
        margin-left: 48%;
        padding: 5px 15px;
        font-size: 20px;
        font-weight: 500;
        color: #fff;
        background-color: #FE8F3F;
        border: 0;
        border-radius: 20px;
    }
}

.success {
    // position: relative;
    padding: 30px 0 50px;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    >ul {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin: 60px auto 30px;
        width: 1160px;
        height: 400px;
        overflow: hidden;
        // width: 50%;

        >li {
            padding: 20px 15px;
            width: 30%;
            height: 360px;
            background-color: #fff;
            border-radius: 6px;

            >img {
                width: 100%;
                height: 200px;
            }

            >div {
                position: relative;
                margin-left: 0px;
                text-align: start;

                >p {
                    margin-top: 10px;
                    width: 350px;
                    text-align: start;
                    font-size: 15px;
                    font-weight: 500;
                }

                >span {
                    display: block;
                    margin-top: 10px;
                    font-size: 14px;
                    line-height: 25px;
                    color: #AFAFAF;
                    // display: -webkit-box;
                    // -webkit-box-orient: vertical;
                    // -webkit-line-clamp: 3;
                    // overflow: hidden;
                    // text-overflow: ellipsis;

                }

                button {
                    margin-top: 5px;
                    padding: 2px 5px;
                    border: 0;
                    border-radius: 2px;
                    background-color: #E6F2CC;
                    color: #A4C361;
                }

                .gengduo {
                    position: absolute;
                    right: 0px;
                    top: 10px;
                    width: 20px;
                    height: 4px;
                    background-image: url('../../assets/image/gengduo.png');
                    background-size: 100% 100%;
                }
            }

            &:hover {
                width: 100%;
                height: 360px;
                color: #fff;
                background-color: #E65413;

                img {
                    height: 0;
                }

                div {
                    padding: 0 20px 0 0;

                    >p {
                        margin: 10px 0 0px;
                        width: 1000px;
                        font-size: 18px;
                    }

                    >span {
                        color: #fff;
                        display: block;
                        line-height: 24px;
                    }

                    button {
                        margin: 20px 0;
                        color: #fff;
                        background-color: #EE824E;
                    }
                }

                .gengduo {
                    display: none;
                }
            }

            &:nth-child(2) {
                &:hover {
                    position: relative;
                    top: -400px;
                }
            }

            &:last-child {
                &:hover {
                    position: relative;
                    top: -400px;
                }
            }
        }
    }

    .btn {
        >button {
            margin: 30px 0 35px;
            width: 252px;
            height: 56px;
            padding: 5px 15px;
            font-size: 26px;
            font-weight: 500;
            color: #fff;
            background: linear-gradient(180deg, #FFB552 0%, #FF4D00 100%);
            border: 0;
            border-radius: 30px;
            text-align: center;
        }
    }


}

.offer {
    // position: relative;
    padding: 0px 0 50px;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    ul {
        display: flex;
        justify-content: space-around;
        margin: 60px auto 40px;
        width: 1200px;

        li {
            // padding: 20px;
            width: 24%;
            background-color: #fff;
            border-radius: 6px;
            text-align: center;

            p {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: auto;
                padding: 40px 40px 20px;
                width: 40%;
                text-align: center;
                border-bottom: 1px dashed #E1E1E1;

                img {
                    // margin: auto;
                    width: 50px;
                    height: 50px;
                }

                span {
                    display: block;
                    width: 200px;
                    margin-top: 10px;
                    font-size: 18px;
                    font-weight: 500;
                }
            }

            button {
                margin: 20px 0;
                padding: 5px 15px;
                color: #fff;
                background-color: #EA5514;
                border-radius: 15px;
                border: 0;
            }

            &:first-child {
                img {
                    width: 60px;
                    height: 60px;
                }

                p {
                    padding-top: 38px;

                    span {
                        margin-top: 5px;
                    }
                }
            }

            &:nth-child(2) {
                img {
                    width: 70px;
                    height: 65px;
                }

                p {
                    padding-top: 33px;
                    padding-bottom: 0;

                    span {
                        margin-top: 5px;
                    }
                }
            }

            &:nth-child(3) {
                img {
                    width: 60px;
                    height: 60px;
                }

                p {
                    padding-top: 38px;

                    span {
                        margin-top: 5px;
                    }
                }
            }
        }
    }
}

.orange {
    padding: 50px 0;
    min-width: 1200px;
    width: 100%;
    // height: 140px;
    background-image: url("../../assets/image/橙色背景.png");
    background-size: 100% 100%;
    text-align: center;
    color: #fff;
    font-size: 40px;

    span {
        display: block;
        margin-top: 20px;
        font-size: 26px;
    }
}

.info {
    margin: auto;
    position: relative;
    width: 350px;
    border-radius: 6px;
    text-align: center;
    overflow: hidden;
    background-color: #fff;

    p {
        padding: 20px 40px 40px;
        font-size: 20px;
        font-weight: 600;
        color: #fff;
        background-color: #EA5513;
    }

    .name {
        position: absolute;
        top: 60px;
        left: 140px;
        width: 50px;
        height: 50px;
    }

    .cheng {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 25px;
        height: 25px;

    }

    >img {
        &:last-child {
            margin: 40px 0 20px;
            width: 70%;
        }

    }
}

::v-deep .el-dialog--center .el-dialog__body {
    padding-bottom: 0;
}

::v-deep .el-form {
    margin-top: 30px;
    margin-left: -20px;
}

::v-deep .el-textarea__inner {
    height: 150px !important;
}
</style>