var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('top-view'),_c('div',{staticClass:"backTop"}),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"service"},[_vm._m(4),_c('span',[_vm._v("SUPPORT THE TEAM")]),_c('ul',{key:_vm.refresh},_vm._l((_vm.list),function(item,index){return _c('li',{key:index,on:{"mouseenter":function($event){return _vm.mouseenter(index)},"mouseleave":_vm.mouseleave}},[_c('img',{attrs:{"src":_vm.imgurl + item.img}}),_c('div',[_c('p',[_vm._v(_vm._s(item.num)),_c('span',[_vm._v("人")])]),_c('span',[_vm._v(_vm._s(item.name))])]),_c('span',[_vm._v(_vm._s(item.content))])])}),0)]),_c('div',{staticClass:"advantages"},[_vm._m(5),_c('span',[_vm._v("CONTANT US")]),_c('ul',_vm._l((_vm.dataList),function(item){return _c('li',{key:item.contactId},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(item.deptName))]),(item.contactUser)?_c('div',{staticClass:"content1"},[_c('div'),_c('p',[_vm._v(_vm._s(item.contactUser)+"："+_vm._s(item.contactPhone))])]):_vm._e(),_c('div',{staticClass:"content2"},[_c('div'),_c('p',[_vm._v(_vm._s(item.contactAddress))])])])}),0)]),_vm._m(6),_c('footer-view'),_c('side-bar',{staticClass:"fixed"}),(_vm.infoShow)?_c('div',{staticStyle:{"padding-top":"200px","width":"100vw","height":"100vh","background-color":"rgba(0, 0, 0, 0.4)","position":"fixed","top":"0"}},[_c('div',{staticClass:"info"},[_c('p',[_vm._v("扫码关注微信公众号")]),_c('img',{staticClass:"cheng",attrs:{"src":require("../../assets/icon/cheng.png")},on:{"click":function($event){_vm.infoShow = false}}}),_c('img',{staticClass:"name",attrs:{"src":require("../../assets/image/name.png")}}),_c('img',{attrs:{"src":require("../../assets/image/erweima.png")}})])]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background-color":"#F4F4F4"}},[_c('ul',{staticClass:"fuwu"},[_c('li',[_c('img',{attrs:{"src":require("../../assets/image/haiwai/jingchengfuwu.png")}}),_c('div',[_c('p',[_vm._v("精诚服务")]),_c('span',[_vm._v("专业团队服务企业全生命周期")])])]),_c('li',[_c('img',{attrs:{"src":require("../../assets/image/haiwai/huligongying.png")}}),_c('div',[_c('p',[_vm._v("互利共赢")]),_c('span',[_vm._v("长期合作，共分红利")])])]),_c('li',[_c('img',{attrs:{"src":require("../../assets/image/haiwai/yetaigongxiang.png")}}),_c('div',[_c('p',[_vm._v("业态共享")]),_c('span',[_vm._v("跨界协同，共筑宏图")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"personal"},[_c('p',[_c('img',{attrs:{"src":require("../../assets/image/left.png")}}),_vm._v(" 合作目标 "),_c('img',{attrs:{"src":require("../../assets/image/right.png")}})]),_c('span',[_vm._v("CO-OPERATION OBJECTIVES")]),_c('ul',[_c('li',[_c('img',{staticStyle:{"width":"100%","height":"250px"},attrs:{"src":require("../../assets/image/haiwai/mubiao1.png")}}),_c('p',[_vm._v("01.")]),_c('span',[_vm._v("全产业链业务对接，通过深度战略合作，聚焦资源的共享和整合，携手共进，共赢商机")])]),_c('li',[_c('img',{staticStyle:{"width":"100%","height":"250px"},attrs:{"src":require("../../assets/image/haiwai/mubiao2.png")}}),_c('p',[_vm._v("02.")]),_c('span',[_vm._v("拓展多行业市场领域，带来全新增长点，提升品牌影响力，以匠心品质树立行业标杆，打造服务典范")])]),_c('li',[_c('img',{staticStyle:{"width":"100%","height":"250px"},attrs:{"src":require("../../assets/image/haiwai/mubiao3.png")}}),_c('p',[_vm._v("03.")]),_c('span',[_vm._v("解决企业运营管理瓶颈和团队流失等问题，帮助企业达成经营目标（收入、成本、利润、客户量、效率）")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"working"},[_c('p',[_c('img',{attrs:{"src":require("../../assets/image/left.png")}}),_vm._v(" 为什么要和我们合作 "),_c('img',{attrs:{"src":require("../../assets/image/right.png")}})]),_c('span',[_vm._v("WHY ARE YOU WORKING WITH US")]),_c('div',[_c('ul',[_c('li',[_c('p',[_vm._v("企业实力")]),_c('span',[_vm._v("雄厚")])]),_c('li',[_c('p',[_vm._v("市场资源")]),_c('span',[_vm._v("丰富")])]),_c('li',[_c('p',[_vm._v("全球服务")]),_c('span',[_vm._v("本地专家")])])]),_c('img',{attrs:{"src":require("../../assets/image/haiwai/heCenter.png")}}),_c('ul',[_c('li',[_c('p',[_vm._v("成熟的团队")]),_c('span',[_vm._v("支撑")])]),_c('li',[_c('p',[_vm._v("创新驱动与")]),_c('span',[_vm._v("研发实力")])]),_c('li',[_c('p',[_vm._v("卓越的服务")]),_c('span',[_vm._v("体系")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flow"},[_c('p',[_c('img',{attrs:{"src":require("../../assets/image/left.png")}}),_vm._v(" 合作流程 "),_c('img',{attrs:{"src":require("../../assets/image/right.png")}})]),_c('span',[_vm._v("COOPERATION PROCESS")]),_c('ul',[_c('li',[_c('p',[_vm._v("初步了解")])]),_c('li',{staticStyle:{"width":"30px","margin-top":"100px"}},[_c('img',{attrs:{"src":require("../../assets/image/haiwai/jiantou.png")}})]),_c('li',[_c('p',[_vm._v("达成合作")])]),_c('li',{staticStyle:{"width":"30px","margin-top":"100px"}},[_c('img',{attrs:{"src":require("../../assets/image/haiwai/jiantou.png")}})]),_c('li',[_c('p',[_vm._v("商定方案")])]),_c('li',{staticStyle:{"width":"30px","margin-top":"100px"}},[_c('img',{attrs:{"src":require("../../assets/image/haiwai/jiantou.png")}})]),_c('li',[_c('p',[_vm._v("指导运营实施")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('img',{attrs:{"src":require("../../assets/image/haiwai/whiteD.png")}}),_vm._v(" 支持团队 "),_c('img',{attrs:{"src":require("../../assets/image/haiwai/whiteD.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('img',{attrs:{"src":require("../../assets/image/left.png")}}),_vm._v(" 联系我们 "),_c('img',{attrs:{"src":require("../../assets/image/right.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"orange"},[_c('img',{attrs:{"src":require("../../assets/image/kuaiji/whiteLine.png")}}),_vm._v("  期待您的合作与建议  "),_c('img',{attrs:{"src":require("../../assets/image/kuaiji/whiteLine.png")}})])
}]

export { render, staticRenderFns }