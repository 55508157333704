<template>
    <div>
        <top-view></top-view>
        <div class="backTop">
            <!-- <div>
                <p>走进唐诚</p>
                <span class="english">ENTERING TANG CHENG</span>
                <button>了解更多</button>
            </div> -->
        </div>
        <div style="min-width: 1200px;">
            <ul class="fuwu">
                <li>
                    <p class="num">{{obj.briefYgNumber}}<span style="font-size: 14px;font-weight: 500;">人</span></p>
                    <span>员工</span>
                </li>
                <li></li>
                <li>
                    <p class="num">{{obj.briefZyrcNumber}}<span style="font-size: 14px;font-weight: 500;">人</span></p>
                    <span>专业人才</span>
                </li>
                <li></li>
                <li>
                    <p class="num">{{obj.briefFgsNumber}}<span style="font-size: 14px;font-weight: 500;">家</span></p>
                    <span>分公司</span>
                </li>
            </ul>
        </div>
        <div class="aboutus">
            <p><img src="../../assets/image/left.png">&nbsp;关于我们&nbsp;<img src="../../assets/image/right.png"></p>
            <span>ABOUT US</span>
            <div>
                <p v-html="obj.briefAbout"></p>
            </div>
            <ul>
                <li><img :src="imgurl + imgArray[0]"></li>
                <li><img :src="imgurl + imgArray[1]"></li>
                <li><img :src="imgurl + imgArray[2]"></li>
            </ul>
        </div>
        <div class="service">
            <p><img src="../../assets/image/left.png">&nbsp;服务优势&nbsp;<img src="../../assets/image/right.png"></p>
            <span>SERVICE ADVANTAGES</span>
            <ul>
                <li>
                    <img src="../../assets/image/aboutus/content1.png">
                    <div>
                        <p>专业度</p>
                        <button style="margin-left: 0;">专业团队</button>
                        <button>资质齐全</button>
                        <br />
                        <span>唐诚全球业务以财税服务为中轴，云集了境内外专职注册会计师39人，注册税务师15人、执业律师10人，此外公司一直构建专家库，目前专家库成员已高达百人以上。为全球小微企业财税法全方位提供高质量服务。</span>
                        <p class="littleT">【引领跨境财税合规，服务十亿级企业的专业典范】</p>
                        <span class="littleC">唐诚维易国际注册会计师 LIAS HAO
                            近两年代表唐诚在亚马逊全球经销商大会上做14场跨境财税合规分享。唐诚注册会计师张丽媛带队常年服务境内十亿级企业4家。</span>
                    </div>
                </li>
                <li>
                    <img src="../../assets/image/aboutus/content2.png">
                    <div>
                        <p>速度</p>
                        <button style="margin-left: 0;">天下武功</button>
                        <button>唯快不破</button>
                        <br />
                        <span>境内客户注册公司最快当天拿营业执照。英国公司注册、意大利VAT当日拿证。企业财务分析报告，月初第一日送达。企业经营货款分析报告，当日出具。</span>
                        <p class="littleT">【顺应区域财税需求，打造全方位服务体系】</p>
                        <span
                            class="littleC">企业选择财税公司服务不仅节约时间还节约成本。唐诚作为专业的财税公司就是为企业解決财务、税务等方面问题，我们有专业团队，由了解国家政策、经验丰富、办事效率高的专业人员组成。目前我国各项制度越来越完善，对于财税合规的要求已经越来越高。唐诚一直深耕财税行业，有丰富的实践经验，可以根据不同的政策变化及时更新相关服务，规避企业可能面对的风险。</span>
                    </div>
                </li>
                <li>
                    <img src="../../assets/image/aboutus/content3.png">
                    <div>
                        <p>深度</p>
                        <button style="margin-left: 0;">专业精研</button>
                        <button>静水流深</button>
                        <br />
                        <span>以财税服务赋能，打通企业股权顶层架构设计＋专业投资建议+专业法律咨询服务的需求全链条，携手客户创造更多价值。团队多元化，提升复杂税收和法律环境下的业务能力。精准发力，高效协同，助力企业更深层次发展需求战略落地。</span>
                        <p class="littleT">【深度赋能企业，同频财税改革】</p>
                        <span
                            class="littleC">随着财税行业服务逻辑已从“客户服务”转变为“客户经营”，服务赋能、增加价值的理念深入人心。根据2023年底的中央经济工作会议，明确指示要加快完善市场准入、产权保护和交易、数据信息、社会信用等方面的基础性制度，积极稳妥推进财税、统计等重点领域改革，建立健全有利于全国统一大市场建设的各项制度规则。唐诚控股已调整战略部署，时刻淮备着为财税行业改革贡献唐诚力量。</span>
                    </div>
                </li>
                <li>
                    <img src="../../assets/image/aboutus/content4.png">
                    <div>
                        <p>广度</p>
                        <button style="margin-left: 0;">遍布全球</button>
                        <button>天涯比邻</button>
                        <br />
                        <span>唐诚控股根植中国，布局全球，在当前百年未有之大变局下，全球产业加速重构，唐诚抓住机遇，进一步完善全球化战略布局，足迹遍布香港、新加坡、美国、英国、欧洲、中东、北美、亚洲的主要城市，更好地满足了全球客户的财税服务需求。</span>
                        <p class="littleT">【唐诚全球发展轨迹】</p>
                        <span class="littleC">2022年05月：成立英国办事处；2022年12月：与迪拜合作方建立战略合作；
                            <br>2023年06月：成为速卖通SPN服务商；2023年07月：成为亚马逊SPN服务商；
                            <br>2023年12月：香港和新加坡公司成立。</span>
                    </div>
                </li>
            </ul>
        </div>

        <footer-view></footer-view>
        <side-bar class="fixed"></side-bar>
    </div>
</template>

<script>
import topView from '@/components/zh/topView.vue'
import footerView from '@/components/zh/footerView.vue'
import SideBar from '@/components/zh/sideBar.vue'
import axios from 'axios';

export default {
    components: { footerView, SideBar, topView },
    data() {
        return {
            infoShow: false,
            obj: {},
            imgArray: [],
            imgurl: '',
        }
    },
    created() {
        this.imgurl = this.$store.state.imgurl
        this.website()
        this.getAbout()
        // this.$store.state.componentKey += 1
    },
    methods: {
        //网站访问信息
        website() {
            axios.post('/api/pcsystem/TckgSoftLogin', { userType: '集团简介' }).then((res) => {
                console.log(res);
            }).catch()
        },
        getAbout() {
            axios.get('/api/pcsystem/TckgBrief/list',{params:{language:'0'}}).then((res) => {
                if (res.status == 200) {
                    this.obj = res.data.rows[0]
                    this.imgArray = this.obj.briefImgs.split(',')
                    console.log(this.imgArray);
                }
            }).catch()
        },
    }
}
</script>

<style lang="scss" scoped>
.fixed {
    position: fixed;
    top: 150px;
    right: 5px;
}

.backTop {
    position: relative;
    width: 100%;
    // height: 450px;
    // height: 500px;
    height: 26vw;
    min-width: 1200px;
    min-height: 310px;
    background-image: url("../../assets/image/jianjieBack.png");
    background-size: 100% 100%;

    // div {
    //     margin: auto;
    //     padding-top: 80px;
    //     width: 1160px;
    //     color: #EA5514;
    //     text-align: center;
    // }

    // p {
    //     margin: 20px 0;
    //     color: #fff;
    //     font-size: 50px;
    //     font-weight: 700;
    // }

    // .english {
    //     display: block;
    //     font-size: 16px;
    //     color: #fff;
    // }

    // button {
    //     margin-top: 30px;
    //     width: 100px;
    //     height: 30px;
    //     line-height: 30px;
    //     color: #fff;
    //     background-color: #FFB258;
    //     border: 0;
    //     border-radius: 15px;
    // }
}

.fuwu {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: -50px auto 0;
    padding: 40px 30px;
    width: 1140px;
    // height: 150px;
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 6px;

    // z-index: 9999;
    li {
        // padding: 20px 30px;
        text-align: center;
        // border-right: 1px solid black;

        p {
            font-size: 28px;
            font-weight: 600;
            color: #EF591A;
        }

        &:nth-child(2),
        &:nth-child(4) {
            // margin-top: 35px;
            padding: 0;
            width: 1px;
            height: 40px;
            // border: 1px solid #C8C4C4;
            background-color: #C8C4C4;
        }
    }
}

.aboutus {
    // position: relative;
    // margin: auto;
    padding: 50px 0;
    // background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    >div {
        margin: 30px auto;
        width: 1200px;
        text-align: center;
        font-size: 20px;
        line-height: 40px;
        white-space: pre-wrap;
    }

    ul {
        margin: 30px auto;
        width: 1200px;
        display: flex;
        justify-content: space-between;

        li {
            // width: 30%;
            // height: 200px;
            width: 380px;
            height: 245px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

}

.service {
    position: relative;
    padding: 60px 0;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    ul {
        margin: 50px auto 30px;
        width: 1200px;

        li {
            margin-top: 20px;
            display: flex;
            justify-content: space-around;
            padding: 30px 20px;
            background-color: #fff;
            border-radius: 6px;

            img {
                // width: 25%;
                width: 335px;
                height: 376px;
            }

            div {
                margin-left: 20px;
                width: 70%;

                p {
                    margin: 10px 0;
                    font-size: 28px;
                    font-weight: 500;
                }

                button {
                    margin: 10px 10px 10px 0;
                    padding: 5px 20px;
                    font-size: 18px;
                    border: 0;
                    border-radius: 2px;
                    background-color: #F3F9E8;
                    color: #AACE5C;
                }

                span {
                    font-size: 18px;
                    line-height: 28px;
                    color: #ADADAD;
                }

                .littleT {
                    margin-top: 17px;
                    padding: 16px 0 7px;
                    border-top: 1px dashed #9C9C9C;
                    color: #EA5514;
                    font-size: 18px;
                    font-weight: 500;
                }

                .littleC {
                    font-size: 16px;
                    line-height: 28px;
                }

            }

            &:hover {
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
            }

        }
    }

}
</style>