<template>
    <div>
        <top-view></top-view>
        <div class="backTop">
            <button><a href="https://vesaas.com">View More</a></button>
        </div>
        <div class="kuaijiQ">
            <div>
                <p>Tenace Vesaas</p>
                <div
                    style="margin-bottom: 40px;width: 81px;height: 4px;background: linear-gradient( 270deg, #F4F4F4 0%, #EA5514 100%);border-radius: 1px;">
                </div>
                <p class="kuaijiQC">
                    Tenace, as the overseas business group of Tangcheng, is the representative of the Group's global
                    development of the tax business, and the pioneer of the Group's globalisation of business.
<br><br>
                    From serving Chinese enterprises to serving global enterprises, Tenace, as a colourful stroke on
                    Tangcheng's strategic map, is the SPN service provider of Amazon and Ebay, and has served more than
                    20,000+ cross-border e-commerce sellers.
<br><br>
                    In China - Hong Kong, Shenzhen, Guangzhou, Beijing, Shanghai, with a professional team to empower,
                    in Singapore, the United States, Germany, Vietnam, Indonesia, the United Arab Emirates, the United
                    Kingdom, Poland, with branches, multi-country distribution of Tangcheng's own professional
                    accounting, auditing, and legal team, empowering enterprises to cross-border compliance. We have 15
                    international CPAs and 5 international lawyers, and in 2023, we will serve more than 100,000
                    cross-border clients!
                </p>
            </div>
            <img src="../../assets/image/haiwai/shiyequnB.png">
        </div>
        <div style="padding: 30px 0;background-color: #F4F4F4;min-width: 1200px;">
            <ul class="kunnan">
                <li>
                    <p>
                        <span>Difficulty in verbal
                            communication</span>
                    </p>
                    <div>
                        <p>Difficulty in verbal
                            communication</p>
                        <div></div>
                        <span>Inability to communicate with local government agency lawyers, accountants</span>
                    </div>
                </li>
                <li>
                    <p>
                        <span>Complexity of
                            overseas rules</span>
                    </p>
                    <div>
                        <p>Complexity of
                            overseas rules</p>
                        <div></div>
                        <span>It is necessary to understand local regulations and policies and to comply with local
                            regulations.</span>
                    </div>
                </li>
                <li>
                    <p>
                        <span>Costly & time-consuming</span>
                    </p>
                    <div>
                        <p>High time cost</p>
                        <div></div>
                        <span>Failure to understand the potential qualification requirements of the approving authority
                            for different industries may result in a failure of approval</span>
                    </div>
                </li>
                <li>
                    <p>
                        <span>Difficulty in opening
                            bank accounts</span>
                    </p>
                    <div>
                        <p>Difficulty in opening
                            bank accounts</p>
                        <div></div>
                        <span>May involve issues such as visas and travel arrangements</span>
                    </div>
                </li>
            </ul>
        </div>
        <div class="canIdo">
            <p><img src="../../assets/image/left.png">&nbsp;What can we do for your business&nbsp;<img
                    src="../../assets/image/right.png">
            </p>
            <div>
                <ul>
                    <li>
                        <p>
                            <span>Cross-Border Compliance</span>
                            <span>Provide advice, implement compliance policies; conduct risk assessments and provide
                                solutions</span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>Operational Programmes</span>
                            <span>Customised detailed plans to help businesses manage resources effectively and increase
                                profitability</span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>Introductory guide</span>
                            <span>Professional team hand in hand to guide customers, to help speed up the start of the
                                enterprise</span>
                        </p>
                    </li>
                </ul>
                <img src="../../assets/image/haiwai/canDo.png">
                <ul>
                    <li>
                        <p>
                            <span>Reducing the tax burden</span>
                            <span>Legally reduce your tax liability through planning, save money and heartache</span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>Enhancing competitiveness</span>
                            <span>Helping enterprises to enhance their competitiveness at different levels so that they
                                can win at the starting line</span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>Dedicated support</span>
                            <span>1V1 comprehensive support, tailor-made for enterprises to build the strongest
                                engine</span>
                        </p>
                    </li>
                </ul>
            </div>

        </div>
        <div class="orange">
            Low Threshold, Quickly Run Overseas Business
        </div>
        <div class="personal">
            <p><img src="../../assets/image/left.png">&nbsp;Senior Specialist&nbsp;<img
                    src="../../assets/image/right.png"></p>
            <!-- <span>SENIOR EXPERTS</span> -->
            <ul>
                <li v-for="item in personalList" :key="item.personnelId">
                    <img :src="imgurl + item.personnelImg" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">{{ item.personnelName }}</span>
                        <span class="personalC">{{ item.personnelPosition }}</span>
                        <span class="personalRemark">
                            <div style="display: flex;" v-for="(children, index) in item.remark" :key="index">
                                <div
                                    style="margin-top: 4px;width: 4px;height: 4px;border-radius: 50%;border: 2px solid #fff;background-color: #EA5514;">
                                </div>
                                <div style="margin-left: 8px;width: 240px;">{{ children }}</div>
                            </div>
                        </span>
                    </p>
                </li>
            </ul>
        </div>
        <div class="service">
            <p><img src="../../assets/image/left.png">&nbsp;Service content&nbsp;<img
                    src="../../assets/image/right.png"></p>
            <div class="top">
                <div class="topLeft">
                    <div></div>
                    <p>Outward Investment Filing ODI/
                        <br>No.37 Return Investment Filing/
                        <br>Customs import and export filing
                    </p>
                </div>
                <ul class="topRight">
                    <li>
                        <div></div>
                        <span>
                            <p>Overseas Bank Account
                                Opening</p>
                            <span>Unsuccessful full refund</span>
                        </span>
                    </li>
                    <li>
                        <div></div>
                        <span>
                            <p>VIE Construction</p>
                            <span>Protecting client company assets</span>
                        </span>

                    </li>
                    <li>
                        <div></div>
                        <span>
                            <p>Foreign exchange
                                funds management</p>
                            <span>Optimal allocation of funds for
                                clients</span>
                        </span>
                    </li>
                    <li>
                        <div></div>
                        <span>
                            <p>Customs Audit Refunds</p>
                            <span>Familiarity with current customs
                                tax audits</span>
                        </span>
                    </li>
                    <li>
                        <div></div>
                        <span>
                            <p>Shareholding & Top-Level Structure Design</p>
                            <span>Professionals draft the design</span>
                        </span>
                    </li>
                    <li>
                        <div></div>
                        <span>
                            <p>VAT/GST/Sales tax</p>
                            <span>VAT/GST/Sales tax</span>
                        </span>
                    </li>
                </ul>
            </div>
            <ul class="bottom">
                <li>
                    <div></div>
                    <span>
                        <p>Export tax rebate
                            or refund</p>
                        <span>Fast processing for easy cost savings</span>
                    </span>
                </li>
                <li>
                    <div></div>
                    <span>
                        <p>Global Compliance
                            Architecture Setup/
                            International Tax Preparation</p>
                    </span>

                </li>
                <li>
                    <div></div>
                    <span>
                        <p>Business registration <br>
                            in various countries/
                            Annual tax return Filing/
                            audit/inventory</p>
                    </span>
                </li>
                <li>
                    <div></div>
                    <span>
                        <p>EPR</p>
                        <span>Packaging method, English,
                            European, American, weeee,
                            battery method, etc.</span>
                    </span>
                </li>
            </ul>

            <div style="display: flex;justify-content: center;"><button @click="infoShow = true">Click to
                    consult</button></div>
        </div>
        <div class="flow">
            <p><img src="../../assets/image/left.png">&nbsp;Service process&nbsp;<img
                    src="../../assets/image/right.png"></p>
            <div style="margin: 40px auto;width: 1196px;border: 2px solid #F2D5C8;"></div>
            <ul>
                <li>
                    <div></div>
                    <p>01</p>
                    <span>Initial Due Diligence (IED)</span>
                </li>
                <li>
                    <div></div>
                    <p>02</p>
                    <span>Combing report</span>
                </li>
                <li>
                    <div></div>
                    <p>03</p>
                    <span>Interpretation of the <br> implementation programme</span>
                </li>
                <li>
                    <div></div>
                    <p>04</p>
                    <span>Landing process</span>
                </li>
                <li>
                    <div></div>
                    <p>05</p>
                    <span>Guidance on operating <br>
                        fund flows</span>
                </li>
                <li>
                    <div></div>
                    <p>06</p>
                    <span>Model setup guide<br>Compliance Accounts Finance</span>
                </li>
                <li>
                    <div></div>
                    <p>07</p>
                    <span>Process implementation <br>
                        consultant</span>
                </li>
                <li>
                    <div></div>
                    <p>08</p>
                    <span>Financial organisation <br>
                        optimisation</span>
                </li>
                <li>
                    <div></div>
                    <p>09</p>
                    <span>Cross-border tax solutions</span>
                </li>
            </ul>
        </div>
        <div class="advantages">
            <p><img src="../../assets/image/left.png">&nbsp;Advantage of service&nbsp;<img
                    src="../../assets/image/right.png"></p>
            <div>
                <img src="../../assets/image/haiwai/advanB.png">
                <ul>
                    <li>
                        <div>01</div>
                        <p>A team of professional accountants and lawyers from Hong Kong, the UK and the US</p>
                    </li>
                    <li>
                        <div>02</div>
                        <p style="font-size: 16px;line-height: 16px;">Experience in providing financial advisory
                            services to a number of billion-dollar cross-border e-commerce big sellers</p>
                    </li>
                    <li>
                        <div>03</div>
                        <p>Strong Customs Brokerage Strength</p>
                    </li>
                    <li>
                        <div>04</div>
                        <p>Autonomous Tax Refund System</p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="success">
            <p><img src="../../assets/image/left.png">&nbsp;Successful case&nbsp;<img
                    src="../../assets/image/right.png"></p>
            <ul>
                <li v-for="item in caseList" :key="item.caseId" @mouseenter="changeContent(item)"
                    @mouseleave="recoveryContent(item)">
                    <img :src="imgurl + item.caseImg">
                    <div>
                        <p>Project:{{ item.caseTitle }}</p>
                        <button>Duration:{{ item.caseHs }}</button>
                        <span v-html="item.caseTitle2"></span>
                        <div class="gengduo"></div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="offer">
            <ul>
                <li>
                    <p>
                        <img src="../../assets/image/kuaiji/jiangpai.png">
                        <span>Building qualification</span>
                    </p>
                    <button @click="gotoZizhi">for more information</button>
                </li>
                <li>
                    <p>
                        <img src="../../assets/image/kuaiji/qianbao.png">
                        <span>Financial service</span>
                    </p>
                    <button @click="gotoQidai">for more information</button>
                </li>
                <li>
                    <p>
                        <img src="../../assets/image/kuaiji/caishui.png">
                        <span>Finance and taxation
                            service</span>
                    </p>
                    <button @click="gotoKuaiji">for more information</button>
                </li>
                <li>
                    <p>
                        <img src="../../assets/image/kuaiji/zhishi.png">
                        <span>Intellectual property</span>
                    </p>
                    <button @click="gotoZonghe">for more information</button>
                </li>
            </ul>
        </div>
        <div class="btn">
            <button><a href="https://vesaas.com">Click to consult</a></button>
        </div>
        <footer-view></footer-view>
        <side-bar class="fixed"></side-bar>
        <el-dialog title="Please fill in your personal information" :visible.sync="infoShow" width="40%" center>
            <span style="font-size: 12px;color: #A5A5A5;text-align: center;display: block;margin-top: -30px;">We will
                contact you within 30 minutes</span>

            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
                <el-form-item label="Name:" prop="name">
                    <el-input v-model="ruleForm.name" placeholder="Please enter your name"></el-input>
                </el-form-item>
                <el-form-item label="Phone Number:" prop="tel">
                    <el-input v-model="ruleForm.tel" placeholder="Please enter your phone number"></el-input>
                </el-form-item>
                <el-form-item label="Leave a message:" prop="content">
                    <el-input v-model="ruleForm.content" type="textarea" placeholder="Please enter the question you want to consult
 (no more than 300 words)" class="inputC"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm('ruleForm')">Table shortly</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import topView from '@/components/en/topView.vue'
import footerView from '@/components/en/footerView.vue'
import SideBar from '@/components/en/sideBar.vue'
import axios from 'axios';


export default {
    components: { footerView, SideBar, topView },
    data() {
        return {
            infoShow: false,
            personalList: [],
            params: { pageSize: 8, pageNum: 1, personnelType: "4", language: '1' },
            // imgurl: 'http://192.168.0.199:8087/api',
            imgurl: '',
            caseList: [],
            ruleForm: {
                name: '',
                tel: '',
                content: ''
            },
            rules: {
                name: [
                    { required: true, message: '请输入您的姓名', trigger: 'blur' },
                    // { min: 7, max: 11, message: '请输入正确的手机号码', trigger: 'blur' }
                ],
                tel: [
                    { required: true, message: '请输入您的电话', trigger: 'blur' },
                    { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号码', trigger: 'blur' }
                ],
                content: [
                    // { required: true, message: '请输入您的电话', trigger: 'blur' },
                    { min: 0, max: 300, message: '最多输入300字', trigger: 'blur' }
                ]

            },
            text: '',
        }
    },
    created() {
        this.getPersonalList()
        this.getcaseList()
        this.website()
        this.imgurl = this.$store.state.imgurl
        // this.$store.state.componentKey += 1
    },
    methods: {
        getPersonalList() {
            axios.get('/api/pcsystem/TckgPersonnel/list', { params: this.params }).then((res) => {
                if (res.status == 200) {
                    this.personalList = res.data.rows
                    for (let i = 0; i < this.personalList.length; i++) {
                        this.personalList[i].remark = res.data.rows[i].remark.split('。')
                    }
                    // console.log(this.personalList);
                }

            }).catch()
        },
        getcaseList() {
            axios.get('/api/pcsystem/TckgCase/list', { params: { pageNum: 1, pageSize: 3, caseType: '4', language: '1' } }).then((res) => {
                if (res.status == 200) {
                    this.caseList = res.data.rows
                    console.log(this.caseList);
                }
            }).catch()
        },
        gotoZonghe() {
            this.$router.push({ path: '/zongheE', query: { key: '2' } })
        },
        gotoQidai() {
            this.$router.push({ path: '/qidaiE', query: { key: '2' } })
        },
        gotoZizhi() {
            this.$router.push({ path: '/zizhiE', query: { key: '2' } })
        },
        gotoKuaiji() {
            this.$router.push({ path: '/kuaijiE', query: { key: '2' } })
        },
        //网站访问信息
        website() {
            axios.post('/api/pcsystem/TckgSoftLogin', { userType: '唐诚国际' }).then((res) => {
                console.log(res);
            }).catch()
        },
        //提交信息
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // alert('submit!');
                    this.infoShow = false
                    console.log(this.ruleForm);
                    axios.post('/api/pcsystem/TckgConsult', { params: this.ruleForm }).then((res) => {
                        // console.log(res);
                        if (res.status == 200) {
                            // console.log(111111);
                            this.$message({
                                message: '提交成功，请耐心等待',
                                type: 'success'
                            });
                        }
                    }).catch()
                } else {
                    // this.$message({
                    //     message: '提交失败，请稍后重试',
                    //     type: 'warning'
                    // });
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        changeContent(item) {
            this.text = item.caseTitle2
            item.caseTitle2 = item.caseContent
            // console.log(item);
        },
        recoveryContent(item) {
            console.log(this.text);
            item.caseTitle2 = this.text
            // console.log(item);
        }
        // gotoWeiyi(){

        // }
    }

}
</script>

<style lang="scss" scoped>
.fixed {
    position: fixed;
    top: 150px;
    right: 5px;
}

.backTop {
    position: relative;
    width: 100%;
    min-width: 1200px;
    min-height: 300px;
    height: 26vw;
    background-image: url("../../assets/image/haiwai/haiwaiEBack.png");
    background-size: 100% 100%;
    display: flex;
    justify-content: center;

    button {
        position: absolute;
        bottom: 4.3vw;
        width: 10.5vw;
        min-width: 145px;
        min-height: 35px;
        padding: 10px;
        line-height: 1.6vw;
        font-weight: 500;
        font-size: 22px;
        background: linear-gradient(180deg, #FFB552 0%, #FF4D00 100%);
        border: 0;
        border-radius: 30px;

        a {
            color: #FFFFFF;

        }
    }
}

.kuaijiQ {
    margin: 60px auto;
    display: flex;
    justify-content: space-around;
    width: 1200px;

    img {
        border-radius: 0 !important;
    }

    div {
        margin-right: 20px;

        >p {
            margin-bottom: 10px;
            font-size: 22px;
            font-weight: 600;
        }

        .kuaijiQC {
            margin: 20px 0;
            font-size: 16px;
            line-height: 22px;
            font-weight: 400;
        }

        ul {
            margin-top: 50px;
            display: flex;
            justify-content: space-around;
            box-shadow: 0 0 10px 0 #F4F4F4;

            li {
                padding: 20px 30px;
                text-align: center;
                // border-right: 1px solid black;

                span {
                    display: block;
                    font-size: 14px;
                    color: #949494;
                }
            }
        }
    }

}

.kunnan {
    display: flex;
    justify-content: space-between;
    margin: 40px auto 0;
    width: 1200px;

    li {
        position: relative;
        width: 24%;
        height: 275px;
        background-image: url('../../assets/image/haiwai/yuyanZ.png');
        background-size: 98% 95%;
        background-repeat: no-repeat;
        text-align: center;

        >p {
            position: absolute;
            left: 0px;
            bottom: 18px;
            padding: 15px;
            width: 87.3%;
            text-align: center;
            color: #fff;

            span {
                position: relative;
                top: 10px;
                display: inline-block;
                width: 70%;
            }
        }

        >div {
            display: none;
            color: #fff;
            text-align: center;

            p {
                margin: 100px auto 0;
                width: 60%;
            }

            >div {
                margin: 10px auto;
                width: 15%;
                height: 2px;
                background-color: #8FC31F;
                border-radius: 2px;
            }

            span {
                display: block;
                margin: auto;
                width: 80%;
                font-size: 14px;
            }
        }

        &:hover {
            background-image: url('../../assets/image/haiwai/yuyanO.png');

            >p {
                display: none;
            }

            >div {
                display: block;
            }
        }

        &:nth-child(2) {
            background-image: url('../../assets/image/haiwai/haiwaiZ.png');

            &:hover {
                background-image: url('../../assets/image/haiwai/haiwaiO.png');

                >p {
                    display: none;
                }

                >div {
                    display: block;
                }
            }
        }

        &:nth-child(3) {
            background-image: url('../../assets/image/haiwai/shijianZ.png');

            &:hover {
                background-image: url('../../assets/image/haiwai/shijianO.png');

                >p {
                    display: none;
                }

                >div {
                    display: block;
                }
            }
        }

        &:nth-child(4) {
            background-image: url('../../assets/image/haiwai/yinhangZ.png');

            &:hover {
                background-image: url('../../assets/image/haiwai/yinhangO.png');

                >p {
                    display: none;
                }

                >div {
                    display: block;
                }
            }
        }
    }
}

.canIdo {
    // position: relative;
    padding: 30px 0;
    min-width: 1200px;
    background-color: #F4F4F4;
    // background-image: url('../assets/image/qidai/60f36944c6bf951d8579d623d7e627ac62513e922dccf6-6Npbt3_fw1200.png');

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    div {
        position: relative;
        display: flex;
        margin: 80px auto 50px;
        width: 1200px;

        img {
            margin: auto;
            // position: absolute;
            width: 400px;
            height: 400px;
            z-index: 9999;
        }

        ul {
            position: absolute;
            // display: flex;
            // justify-content: space-around;
            margin: 50px auto 30px;
            width: 32%;
            z-index: 0;

            li {
                // position: relative;
                margin-bottom: 20px;
                padding: 15px;
                // width: 10%;
                height: 90px;
                text-align: start;
                border: 2px solid #EA5514;
                background-color: #fff;
                border-radius: 10px;

                // overflow: hidden;
                p {
                    color: #727272;
                }

                span {
                    display: block;
                    // width: 90%;

                    &:first-child {
                        margin-bottom: 10px;
                        padding: 3px;
                        width: 231px;
                        text-align: center;
                        background-color: #EA5514;
                        font-size: 18px;
                        border-radius: 30px;
                        color: #fff;
                    }

                    &:last-child {
                        font-size: 14px;
                    }
                }
            }

            &:first-child {
                top: -14%;
                left: 5%;

                li {
                    &:first-child {
                        border-radius: 0;
                        border-bottom-left-radius: 50px;
                        border-top-right-radius: 50px;
                    }

                    &:last-child {
                        border-radius: 0;
                        border-top-left-radius: 50px;
                        border-bottom-right-radius: 50px;
                    }
                }
            }

            &:last-child {
                top: -14%;
                left: 63%;

                li {
                    text-align: end;
                    // p{
                    //     text-align: end;
                    // }

                    span {
                        margin-left: 25px;

                        &:first-child {
                            margin-left: 115px;
                        }

                    }

                    &:first-child {
                        border-radius: 0;
                        border-bottom-right-radius: 50px;
                        border-top-left-radius: 50px;
                    }

                    &:nth-child(2) {
                        span {
                            &:first-child {
                                margin-left: 95px;
                                width: 252px;
                            }
                        }
                    }

                    &:last-child {
                        // text-align: end;
                        border-radius: 0;
                        border-top-right-radius: 50px;
                        border-bottom-left-radius: 50px;

                        span {
                            &:last-child {
                                margin-left: 10px;
                                width: 95%;
                            }
                        }
                    }
                }

            }
        }
    }

}

.service {
    // position: relative;
    padding: 30px 0;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    .top {
        display: flex;
        // justify-content: space-around;
        margin: 50px auto 0px;
        width: 1200px;

        .topLeft {
            margin-bottom: 17px;
            width: 288px;
            height: 281px;
            background-color: #fff;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            >div {
                margin-bottom: 20px;
                width: 44px;
                height: 50px;
                background-image: url('../../assets/image/haiwai/haiwaiDunO.png');
                background-size: 100% 100%;
            }

            p {
                text-align: center;
                // font-weight: 600;
                font-weight: 500;
                font-size: 18px;
                color: #333333;
                line-height: 30px;
            }

            &:hover {
                background-image: url('../../assets/image/qidai/bottomLine.png');
                background-size: 100% 5%;
                background-repeat: no-repeat;
                background-position: 0 100%;
            }
        }

        .topRight {
            display: flex;
            // justify-content: space-around;
            flex-wrap: wrap;
            // width: 75%;
            width: 912px;

            li {
                position: relative;
                margin-bottom: 17px;
                margin-left: 16px;
                padding: 40px 10px 40px 15px;
                display: flex;
                align-items: center;
                width: 258px;
                height: 52px;
                background-color: #fff;
                border-radius: 10px;
                cursor: pointer;

                >div {
                    width: 44px;
                    height: 44px;
                    background-image: url('../../assets/image/haiwai/haiwaiyinhangO.png');
                    background-size: 100% 100%;
                }

                &:nth-child(2) {
                    >div {
                        background-image: url('../../assets/image/haiwai/vieO.png');
                    }
                }

                &:nth-child(3) {
                    >div {
                        background-image: url('../../assets/image/haiwai/waihuiO.png');
                    }
                }

                &:nth-child(4) {
                    >div {
                        background-image: url('../../assets/image/haiwai/haiguanO.png');
                    }
                }

                &:nth-child(5) {
                    >div {
                        background-image: url('../../assets/image/haiwai/guquanO.png');
                    }
                }

                &:nth-child(6) {
                    >div {
                        background-image: url('../../assets/image/haiwai/VAT.png');
                    }
                }

                >span {
                    margin-left: 10px;
                    // margin-top: 50px;
                    display: block;
                    width: 190px;

                    p {
                        width: 114%;
                        margin: 0 0 5px;
                        font-size: 19px;
                        // font-weight: 600;
                        font-weight: 500;
                    }

                    span {
                        display: inline-block;
                        width: 110%;
                        font-size: 14px;
                        color: #B1B1B1;
                        font-weight: 400;
                    }
                }

                &:hover {
                    background-image: url('../../assets/image/qidai/bottomLine.png');
                    background-size: 100% 10%;
                    background-repeat: no-repeat;
                    background-position: 0 100%;
                }

                .qizhi {
                    position: absolute;
                    top: -10px;
                    right: 40px;
                    margin: 10px 0;
                    width: 35px;
                    height: 50px;
                    color: #F2956C;
                    text-align: center;
                    line-height: 40px;
                    background-image: url('../../assets/image/zizhi/qizhi.png');
                    background-size: 100% 100%;
                }
            }
        }
    }

    .bottom {
        display: flex;
        // justify-content: space-around;
        margin: auto;
        width: 1200px;

        li {
            position: relative;
            margin-bottom: 17px;
            margin-left: 16px;
            padding: 40px 10px 40px 17px;
            display: flex;
            align-items: center;
            width: 258px;
            height: 52px;
            background-color: #fff;
            border-radius: 10px;
            cursor: pointer;

            >div {
                width: 44px;
                height: 44px;
                background-size: 100% 100%;
            }

            &:first-child {
                margin-left: 0;

                >div {
                    width: 40px;
                    height: 46px;
                    background-image: url('../../assets/image/haiwai/chukoutuishui.png');
                }
            }

            &:nth-child(2) {
                >div {
                    background-image: url('../../assets/image/haiwai/quanqiu.png');
                }
            }

            &:nth-child(3) {
                >div {
                    background-image: url('../../assets/image/haiwai/geguogongshang.png');
                }

                >span {
                    p {
                        width: 131% !important;
                    }
                }
            }

            &:nth-child(4) {
                >div {
                    background-image: url('../../assets/image/haiwai/ERP.png');
                }
            }

            >span {
                margin-left: 10px;
                // margin-top: 50px;
                display: block;
                width: 160px;

                p {
                    width: 118%;
                    margin: 0 0 5px;
                    font-size: 18px;
                    // font-weight: 600;
                    font-weight: 500;
                }

                span {
                    display: inline-block;
                    width: 120%;
                    font-size: 14px;
                    color: #B1B1B1;
                    font-weight: 400;
                }
            }

            &:hover {
                background-image: url('../../assets/image/qidai/bottomLine.png');
                background-size: 100% 10%;
                background-repeat: no-repeat;
                background-position: 0 100%;
            }

            .qizhi {
                position: absolute;
                top: -10px;
                right: 40px;
                margin: 10px 0;
                width: 35px;
                height: 50px;
                color: #F2956C;
                text-align: center;
                line-height: 40px;
                background-image: url('../../assets/image/zizhi/qizhi.png');
                background-size: 100% 100%;
            }
        }
    }

    button {
        margin: 60px 0 75px;
        width: 252px;
        height: 56px;
        padding: 5px 15px;
        font-size: 26px;
        font-weight: 500;
        color: #fff;
        background: linear-gradient(180deg, #FFB552 0%, #FF4D00 100%);
        border: 0;
        border-radius: 30px;
        text-align: center;
    }

}

.personal {
    padding: 75px 0 50px;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 30px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    ul {
        margin: 30px auto 0;
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
        width: 1200px;

        li {
            position: relative;
            margin-top: 1vh;
            margin-left: 10px;
            width: 289px;
            height: 356px;
            background-size: 100% 100%;
            border-radius: 6px;
            overflow: hidden;

            >p {
                margin: 0;
                position: absolute;
                bottom: 0;
                padding: 10px 13px 5px;
                width: 91%;
                min-height: 75px;
                background-color: rgba(0, 0, 0, 0.5);
                text-align: start;
                color: #fff;
            }

            .personalT {
                display: block;
                font-size: 18px;
                font-weight: 500;
            }

            .personalC {
                font-size: 13px;
                font-weight: 500;
            }

            .personalRemark {
                margin-top: 5px;
                display: none;
                padding: 5px 10px;
                background-color: #EA5514;
                border-radius: 3px;
                font-size: 9px;
                line-height: 15px;
            }

            &:hover {
                .personalRemark {
                    display: block;
                }
            }
        }
    }

    button {
        margin-top: 20px;
        margin-left: 48%;
        padding: 2px 8px;
        color: #9B9B9B;
        border: 1px solid#9B9B9B;
    }
}

.flow {
    padding: 30px 0 50px;
    background-image: url("../../assets/image/kuaiji/6458486361c124a42770f394d12bef7d526a503611913d-0S20U5_fw1200.png");

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    >ul {
        margin: 30px auto 20px;
        display: flex;
        justify-content: space-around;
        width: 1200px;

        li {
            position: relative;
            width: 60px;
            height: 320px;
            color: #EA5514;
            border: 1px solid #FBAD8B;
            border-radius: 4px;

            div {
                position: absolute;
                top: -52px;
                left: 20px;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                background-color: #F2D5C8;
            }

            p {
                margin: 10px auto;
                width: 30px;
                height: 30px;
                font-size: 18px;
                text-align: center;
                line-height: 30px;
                background-color: #FFF4EE;
                border-radius: 50%;
            }

            span {
                line-height: 60px;
                writing-mode: vertical-rl;
            }

            &:hover {
                background-color: #F6EBE5;
                border: 1px solid #F6EBE5;

                div {
                    background-color: #EA5514;
                }

                p {
                    background-color: #EA5514;
                    color: #fff;
                }
            }

            &:nth-child(3),
            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7),
            &:nth-child(8) {
                width: 60px;

                div {
                    left: 20px;
                }

                span {
                    margin-left: 7px;
                    line-height: 23.7px;
                }
            }
        }
    }
}

.advantages {
    padding: 75px 0 30px;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    >div {
        display: flex;
        margin: 30px auto;
        width: 1200px;

        // justify-content: space-around;
        img {
            width: 50%;
        }

        ul {
            margin-top: 60px;
            margin-left: 50px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 50%;
            height: 340px;

            li {
                display: flex;
                align-items: center;
                margin-top: 25px;
                padding: 8px 15px;
                width: 90%;
                height: 56px;
                background-color: #fff;
                border-radius: 6px;

                div {
                    width: 30px;
                    height: 30px;
                    background-image: url('../../assets/image/kuaiji/quan.png');
                    background-size: 100% 100%;
                    text-align: center;
                    line-height: 30px;
                    color: #fff;
                    font-weight: 600;
                }

                p {
                    width: 450px;
                    margin-left: 20px;
                    line-height: 20px;
                    font-size: 20px;
                }

                &:hover {
                    margin-left: -5%;
                    width: 95%;
                    background-color: #EA5514;
                    color: #fff;

                    div {
                        background-image: url('../../assets/image/kuaiji/quanH.png');
                        color: #EA5514;
                    }
                }
            }
        }
    }

    button {
        margin-left: 48%;
        padding: 5px 15px;
        font-size: 20px;
        font-weight: 500;
        color: #fff;
        background-color: #FE8F3F;
        border: 0;
        border-radius: 20px;
    }
}

.success {
    padding: 30px 0 10px;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    ul {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin: 30px auto;
        width: 1200px;
        // height: 417px;
        height: 400px;
        overflow: hidden;
        // width: 50%;

        li {
            // display: flex;
            // margin-bottom: 25px;
            padding: 20px 15px;
            width: 30%;
            height: 360px;
            background-color: #fff;
            border-radius: 6px;

            >img {
                width: 100%;
                height: 200px;
            }

            >div {
                position: relative;
                margin-left: 10px;
                // position: relative;
                text-align: start;

                // width: 500px;
                p {
                    margin-top: 10px;
                    text-align: start;
                    font-size: 16px;
                    font-weight: 600;
                }

                span {
                    display: block;
                    margin-top: 10px;
                    font-size: 14px;
                    line-height: 25px;
                    color: #AFAFAF;
                    // display: -webkit-box;
                    // -webkit-box-orient: vertical;
                    // -webkit-line-clamp: 3;
                    // overflow: hidden;
                    // text-overflow: ellipsis;
                }

                button {
                    margin-top: 5px;
                    padding: 2px 5px;
                    border: 0;
                    border-radius: 2px;
                    background-color: #E6F2CC;
                    color: #A4C361;
                }

                .gengduo {
                    position: absolute;
                    right: 0px;
                    top: 10px;
                    width: 20px;
                    height: 4px;
                    background-image: url('../../assets/image/gengduo.png');
                    background-size: 100% 100%;
                }
            }

            &:hover {
                width: 100%;
                // height: 357px;
                height: 360px;
                color: #fff;
                background-color: #E65413;

                img {
                    height: 0;
                }

                div {
                    padding: 0 20px 0 0;

                    p {
                        margin: 10px 0;
                    }

                    span {
                        color: #fff;
                        display: block;
                        line-height: 20px;
                    }

                    button {
                        margin: 0px 0 10px;
                        color: #fff;
                        background-color: #EE824E;
                    }
                }

                .gengduo {
                    display: none;
                }
            }

            &:nth-child(2) {
                &:hover {
                    position: relative;
                    // top: -380px;
                    top: -400px;
                    // top: -390px;
                    // top: -385px;
                }
            }

            &:last-child {
                &:hover {
                    position: relative;
                    top: -400px;
                }
            }
        }
    }

    >button {
        margin-left: 48%;
        padding: 5px 15px;
        font-size: 20px;
        font-weight: 500;
        color: #fff;
        background-color: #FE8F3F;
        border: 0;
        border-radius: 20px;
    }
}

.offer {
    padding: 30px 0 30px;
    min-width: 1200px;
    background-color: #F4F4F4;

    ul {
        display: flex;
        justify-content: space-around;
        margin: 10px auto 40px;
        width: 1200px;

        li {
            // padding: 20px;
            width: 24%;
            background-color: #fff;
            border-radius: 6px;
            text-align: center;

            p {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: auto;
                padding: 40px 40px 20px;
                width: 40%;
                text-align: center;
                border-bottom: 1px dashed #E1E1E1;

                img {
                    // margin: auto;
                    width: 50px;
                    height: 50px;
                }

                span {
                    display: block;
                    margin-top: 10px;
                    width: 200px;
                    font-size: 18px;
                    font-weight: 500;
                }
            }

            button {
                margin: 20px 0;
                padding: 5px 15px;
                color: #fff;
                background-color: #EA5514;
                border-radius: 15px;
                border: 0;
            }

            &:nth-child(1) {
                img {
                    width: 58px;
                    height: 58px;
                }

                p {
                    padding-top: 38px;

                    span {
                        margin-top: 5px;
                    }
                }
            }

            &:nth-child(3) {
                img {
                    width: 70px;
                    height: 65px;
                }

                p {
                    padding-top: 30px;
                    padding-bottom: 0;

                    span {
                        margin-top: 0px;
                    }
                }
            }
        }
    }
}

.orange {
    padding: 50px 0;
    width: 100%;
    min-width: 1200px;
    // height: 140px;
    background-image: url("../../assets/image/橙色背景.png");
    background-size: 100% 100%;
    text-align: center;
    line-height: 90px;
    color: #fff;
    font-size: 40px;
}

.btn {
    padding-bottom: 50px;
    min-width: 1200px;
    display: flex;
    justify-content: center;
    background-color: #F4F4F4;

    button {
        // margin-top: 350px;
        width: 252px;
        height: 56px;
        line-height: 30px;
        font-weight: 500;
        font-size: 26px;
        background: linear-gradient(180deg, #FFB552 0%, #FF4D00 100%);
        border: 0;
        border-radius: 30px;

        a {
            color: #FFFFFF;

        }
    }
}

.info {
    margin: auto;
    position: relative;
    width: 350px;
    border-radius: 6px;
    text-align: center;
    overflow: hidden;
    background-color: #fff;

    p {
        padding: 20px 40px 40px;
        font-size: 20px;
        font-weight: 600;
        color: #fff;
        background-color: #EA5513;
    }

    .name {
        position: absolute;
        top: 60px;
        left: 140px;
        width: 50px;
        height: 50px;
    }

    .cheng {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 25px;
        height: 25px;

    }

    >img {
        &:last-child {
            margin: 40px 0 20px;
            width: 70%;
        }

    }
}

::v-deep .el-dialog--center .el-dialog__body {
    padding-bottom: 0;
}

::v-deep .el-form {
    margin-top: 30px;
    margin-left: -20px;
}

::v-deep .el-textarea__inner {
    height: 150px !important;
}
</style>