<template>
    <div>
        <top-view :type="type"></top-view>
        <div class="backTop">
            <!-- <div>
                <p>集团资讯</p>
                <span class="english">GROUP INFORMATION</span>
                <button @click="gotoAboutus">了解更多</button>
            </div> -->
        </div>
        <div style="background-color: #F4F4F4;padding: 30px 0;min-width: 1200px;">
            <ul class="show">
                <li v-for="item in dataList" :key="item.dynamicId" @click="gotoContent(item.dynamicId)">
                    <img :src="imgurl + item.dynamicImg" alt="">
                    <div>
                        <p>{{ item.dynamicTitle }}</p>
                        <img src="../../assets/image/zixun/lineO.png">
                        <span v-html="item.dynamicTitle2">
                        </span>
                        <div style="display: flex;align-items: center;justify-content: space-between;position: absolute;bottom: 20px;width: 750px;">
                            <ul>
                                <li><img src="../../assets/image/zixun/diqiu.png"></li>
                                <!-- <li>{{ item.dynamicType == '1' || item.dynamicType == '2' ? "公司动态" : "全球热点" }}</li> -->
                                <li>公司动态</li>
                                <li><img src="../../assets/image/zixun/qizhi.png"></li>
                                <li>{{ item.dynamicName }}</li>
                            </ul>
                            <div class="gengduo">查看更多&gt;</div>
                        </div>
                    </div>
                </li>
                <!-- <li>
                    <img src="../assets/image/93176a07e5ce9bf625a93fafc2312ac1e118b767fa477d-8Fq7of_fw1200.png" alt="">
                    <div>
                        <p>唐诚控股集团特邀参加“2023中国财税服务博览会”暨“第四节818财税节嘉年”</p>
                        <img src="../assets/image/zixun/lineO.png">
                        <span>
                            8月16日-8月18日，“2023中国财税服务博览会”暨“第四节818财税节嘉年”，在上海国际会议中心举办。这是中国最大的财税服务行业盛会，吸引了来自...
                        </span>
                        <ul>
                            <li><img src="../assets/image/zixun/diqiu.png"></li>
                            <li>公司动态</li>
                            <li><img src="../assets/image/zixun/qizhi.png"></li>
                            <li>818财税节</li>
                        </ul>
                    </div>
                </li>
                <li>
                    <img src="../assets/image/93176a07e5ce9bf625a93fafc2312ac1e118b767fa477d-8Fq7of_fw1200.png" alt="">
                    <div>
                        <p>唐诚控股集团特邀参加“2023中国财税服务博览会”暨“第四节818财税节嘉年”</p>
                        <img src="../assets/image/zixun/lineO.png">
                        <span>
                            8月16日-8月18日，“2023中国财税服务博览会”暨“第四节818财税节嘉年”，在上海国际会议中心举办。这是中国最大的财税服务行业盛会，吸引了来自...
                        </span>
                        <ul>
                            <li><img src="../assets/image/zixun/diqiu.png"></li>
                            <li>公司动态</li>
                            <li><img src="../assets/image/zixun/qizhi.png"></li>
                            <li>818财税节</li>
                        </ul>
                    </div>
                </li>
                <li>
                    <img src="../assets/image/93176a07e5ce9bf625a93fafc2312ac1e118b767fa477d-8Fq7of_fw1200.png" alt="">
                    <div>
                        <p>唐诚控股集团特邀参加“2023中国财税服务博览会”暨“第四节818财税节嘉年”</p>
                        <img src="../assets/image/zixun/lineO.png">
                        <span>
                            8月16日-8月18日，“2023中国财税服务博览会”暨“第四节818财税节嘉年”，在上海国际会议中心举办。这是中国最大的财税服务行业盛会，吸引了来自...
                        </span>
                        <ul>
                            <li><img src="../assets/image/zixun/diqiu.png"></li>
                            <li>公司动态</li>
                            <li><img src="../assets/image/zixun/qizhi.png"></li>
                            <li>818财税节</li>
                        </ul>
                    </div>
                </li> -->
            </ul>
        </div>
        <div class="dongtai">
            <div>
                <div>
                    <p>公司动态</p>
                    <span class="english">COMPANY DYNAMICS</span>
                    <br />
                    <img src="../../assets/image/zixun/dongtaiO.png">
                    <img src="../../assets/image/zixun/dongtaiZ.png" style="width: 150px;">
                    <br />
                    <span>唐诚不定期举办研讨会、专题讲座、主题论坛等活动，涉及公司财税、离岸贸易、跨境电商、知识产权、建筑资质等领域。</span>
                </div>
                <ul>
                    <li v-for="item in gongsiList" :key="item.dynamicId" @click="gotoContent(item.dynamicId)">
                        <img :src="imgurl + item.dynamicImg">
                        <div>
                            <p>{{ item.dynamicTitle }}</p>
                            <div class="text" v-html="item.dynamicTitle2"></div>
                            <div class="gengduo">查看更多&gt;</div>
                        </div>
                    </li>
                    <!-- <li>
                        <img src="../assets/image/93176a07e5ce9bf625a93fafc2312ac1e118b767fa477d-8Fq7of_fw1200.png">
                        <div>
                            <p>砺刃披靡，征途无憾|唐诚控股集团618年中大促荣耀收官</p>
                            <span>正是奋斗时，不负韶光宴。6月17日，北京、上海、广州、天津、成都等11家公司为了同一个目标...</span>
                        </div>
                    </li>
                    <li>
                        <img src="../assets/image/93176a07e5ce9bf625a93fafc2312ac1e118b767fa477d-8Fq7of_fw1200.png">
                        <div>
                            <p>砺刃披靡，征途无憾|唐诚控股集团618年中大促荣耀收官</p>
                            <span>正是奋斗时，不负韶光宴。6月17日，北京、上海、广州、天津、成都等11家公司为了同一个目标...</span>
                        </div>
                    </li> -->
                </ul>
            </div>

        </div>
        <div class="redian">
            <div>
                <div>
                    <p>全球热点</p>
                    <span class="english">GLOBAL HOT SPOTS</span>
                    <br />
                    <!-- <div style="display: flex; align-items: center;"> -->
                    <img src="../../assets/image/zixun/redianO.png">
                    <img src="../../assets/image/zixun/redianZ.png"
                        style="width: 200px;height: 40px;position: relative;top: -8px;">
                    <!-- </div> -->

                    <br />
                    <span>凭借唐诚专业的服务团队，汇集并综合全球税务和财务信息以及相关法律法规，为企业的决策提供更加丰富的支持和参考。</span>
                </div>
                <ul>
                    <li v-for="item in quanqiuList" :key="item.dynamicId" @click="gotoContent(item.dynamicId)">
                        <img :src="imgurl + item.dynamicImg">
                        <p>{{ item.dynamicTitle }}</p>
                        <div class="gengduo">查看更多&gt;</div>
                    </li>
                    <!-- <li>
                        <img src="../assets/image/93176a07e5ce9bf625a93fafc2312ac1e118b767fa477d-8Fq7of_fw1200.png">
                        <p>砺刃披靡，征途无憾|唐诚控股集团618年中大促荣耀收官</p>
                    </li>
                    <li>
                        <img src="../assets/image/93176a07e5ce9bf625a93fafc2312ac1e118b767fa477d-8Fq7of_fw1200.png">
                        <p>砺刃披靡，征途无憾|唐诚控股集团618年中大促荣耀收官</p>
                    </li>
                    <li>
                        <img src="../assets/image/93176a07e5ce9bf625a93fafc2312ac1e118b767fa477d-8Fq7of_fw1200.png">
                        <p>砺刃披靡，征途无憾|唐诚控股集团618年中大促荣耀收官</p>
                    </li>
                    <li>
                        <img src="../assets/image/93176a07e5ce9bf625a93fafc2312ac1e118b767fa477d-8Fq7of_fw1200.png">
                        <p>砺刃披靡，征途无憾|唐诚控股集团618年中大促荣耀收官</p>
                    </li>
                    <li>
                        <img src="../assets/image/93176a07e5ce9bf625a93fafc2312ac1e118b767fa477d-8Fq7of_fw1200.png">
                        <p>砺刃披靡，征途无憾|唐诚控股集团618年中大促荣耀收官</p>
                    </li> -->
                </ul>
            </div>
        </div>
        <div class="kanwu">
            <div>
                <div>
                    <p>集团刊物</p>
                    <span class="english">GROUP PUBLICATIONS</span>
                    <!-- <br/> -->
                    <span>唐诚推出各类刊物旨在强企业文化建设，促进内部沟通逾信息共享，提升员工凝聚力和归属感，展示企业社会责任和品牌形象。</span>
                </div>
                <ul>
                    <li>
                        <p>唐诚控股集团介绍</p>
                    </li>
                    <li>
                        <p>联正企服</p>
                    </li>
                    <li>
                        <p>唐诚国际</p>
                    </li>
                </ul>
            </div>
        </div>

        <footer-view></footer-view>
        <side-bar class="fixed"></side-bar>

    </div>
</template>

<script>
import topView from '@/components/zh/topView.vue'
import footerView from '@/components/zh/footerView.vue'
import SideBar from '@/components/zh/sideBar.vue'
import axios from 'axios';

export default {
    components: { footerView, SideBar, topView },
    data() {
        return {
            infoShow: false,
            // activeIndex: '5',
            dataList: [],
            gongsiList: [],
            quanqiuList: [],
            // imgurl: 'http://192.168.0.199:8087/api',
            imgurl: '',
            type: '2'
        }
    },
    created() {
        this.getList()
        this.getgongsiList()
        this.getquanqiuList()
        this.website()
        this.imgurl = this.$store.state.imgurl
        // this.$store.state.componentKey += 1
    },
    methods: {
        gotoAboutus() {
            this.$router.push('/aboutus')
        },
        getList() {
            axios.get('/api/pcsystem/TckgDynamic/list', { params: { pageSize: 3, pageNum: 1, dynamicType: '4',language:'0' } }).then((res) => {
                if (res.status == 200) {
                    let arr = res.data.rows
                    // this.qydtList.length = 2
                    for (let index = 0; index < 3; index++) {
                        console.log(arr[index])
                        arr[index].dynamicContent = arr[index].dynamicContent.replace(
                            /<img [^>]*src=['"]([^'"]+)[^>]*>/gi, (match, text) => {
                                return ``
                            })
                    }
                    this.dataList = arr
                    // console.log(this.dataList);
                }
            }).catch()
        },
        getgongsiList() {
            axios.get('/api/pcsystem/TckgDynamic/list', { params: { pageSize: 2, pageNum: 1, dynamicType: '2',language:'0' } }).then((res) => {
                if (res.status == 200) {
                    let arr = res.data.rows
                    // this.qydtList.length = 2
                    for (let index = 0; index < 2; index++) {
                        console.log(arr[index])
                        arr[index].dynamicContent = arr[index].dynamicContent.replace(
                            /<img [^>]*src=['"]([^'"]+)[^>]*>/gi, (match, text) => {
                                return ``
                            })
                    }
                    this.gongsiList = arr
                    // console.log(this.gongsiList);
                }
            }).catch()
        },
        getquanqiuList() {
            axios.get('/api/pcsystem/TckgDynamic/list', { params: { pageSize: 5, pageNum: 1, dynamicType: '3',language:'0' } }).then((res) => {
                if (res.status == 200) {
                    this.quanqiuList = res.data.rows
                    console.log(this.quanqiuList);
                }
            }).catch()
        },
        gotoContent(id) {
            this.$router.push({ path: '/content', query: { id: id, type: this.type } })
        },
        //网站访问信息
        website() {
            axios.post('/api/pcsystem/TckgSoftLogin', { userType: '集团资讯' }).then((res) => {
                console.log(res);
            }).catch()
        },

    }

}
</script>

<style lang="scss" scoped>
.fixed {
    position: fixed;
    top: 150px;
    right: 5px;
}

.backTop {
    position: relative;
    width: 100%;
    min-width: 1200px;
    min-height: 310px;
    height: 26vw;
    // height: 500px;
    // height: 450px;
    background-image: url("../../assets/image/zixunBack.png");
    background-size: 100% 100%;

    div {
        margin: auto;
        padding-top: 80px;
        width: 1160px;
        color: #EA5514;
        text-align: center;
    }

    p {
        margin: 20px 0;
        color: #fff;
        font-size: 50px;
        font-weight: 700;
    }

    .english {
        display: block;
        font-size: 16px;
        color: #fff;
    }

    button {
        margin-top: 30px;
        width: 100px;
        height: 30px;
        line-height: 30px;
        color: #fff;
        background-color: #FFB258;
        border: 0;
        border-radius: 15px;
    }
}

.show {
    margin: auto;
    width: 1160px;

    // background-color: #F4F4F4;
    >li {
        margin-top: 20px;
        display: flex;
        justify-content: space-around;
        padding: 15px 0;
        // width: 95%;
        border-radius: 6px;
        background-color: #fff;

        >img {
            width: 30%;
            // height: 180px;
            border-radius: 6px;
        }

        >div {
            position: relative;
            width: 65%;
            // margin-left: 20px;

            >p {
                margin: 15px 0 10px;
                // font-size: 20px;
                font-size: 22px;
                line-height: 30px;
                font-weight: 600;
            }

            >span {
                display: block;
                margin: 15px 0 25px;
                width: 90%;
                // min-height: 80px;
                // font-size: 16px;
                font-size: 18px;
                line-height: 28px;
                color: #999999;
                // display: -webkit-box;
                // -webkit-line-clamp: 2;
                // -webkit-box-orient: vertical;
                // overflow: hidden;
                // text-overflow: ellipsis;
            }

            ul {
                // position: absolute;
                // bottom: 20px;
                display: flex;
                align-items: center;

                // align-items: center;
                li {
                    margin-left: 5px;
                    font-size: 12px;
                    // line-height: 20px;
                    color: #B6B6B6;

                    img {
                        margin-top: 3px;
                        width: 16px;
                        height: 16px;
                    }

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }

            .gengduo {
                // position: absolute;
                // right: -500px;
                // bottom: 20px;
                font-weight: 400;
                // margin-left: 500px;
                font-size: 12px;
                color: #999999;
                // line-height: 20px;
                // height: 16px;
                width: 65px;
            }
        }

        &:hover {
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
        }
    }

}

.dongtai {
    padding: 30px 0;
    min-width: 1200px;
    background-color: #F4F4F4;

    >div {
        margin: auto;
        display: flex;
        justify-content: space-around;
        width: 1160px;

        >div {
            padding: 50px 30px 30px;
            width: 35%;
            background-image: url('../../assets/image/zixun/dongtaiB.png');
            background-size: 100% 100%;
            border-radius: 0 !important;

            p {
                font-size: 24px;
                font-weight: 600;
            }

            .english {
                font-size: 10px;
                color: #A7A7A7;
            }

            >img {
                margin: 20px;
                // &:first-child{
                width: 50px;
                height: 40px;

                // }
                &:last-child {
                    width: 150px;
                }
            }

            span {
                // width: ;
                // font-size: ;
                line-height: 28px;

            }
        }

        ul {
            width: 58%;
            margin-left: 20px;

            li {
                display: flex;
                margin-bottom: 5px;
                background-color: #fff;
                border-radius: 6px;
                position: relative;

                img {
                    // width: 40%;
                    width: 278px;
                    height: 184px;
                    border-radius: 0 !important;
                }

                >div {
                    // position: relative;
                    margin-left: 15px;
                    width: 55%;

                    p {
                        margin: 20px 0 10px;
                        // font-size: 18px;
                        font-size: 20px;
                        line-height: 28px;
                        font-weight: 600;
                    }

                    .text {
                        // text-align: left;
                        // font-size: 14px;
                        // line-height: 1.5em;
                        // max-height: 3em;
                        // height: 42px;
                        font-size: 16px;
                        line-height: 22px;
                        color: #717171;
                        // display: -webkit-box;
                        // -webkit-line-clamp: 2;
                        // -webkit-box-orient: vertical;
                        // overflow: hidden;
                        // text-overflow: ellipsis;
                    }

                    .text {
                        -webkit-font-smoothing: antialiased;
                        /* 字体抗锯齿 */
                        -webkit-text-size-adjust: 100%;
                        /* 禁止文字尺寸自动调整 */
                        text-rendering: optimizeLegibility;
                        /* 文字渲染优化 */
                    }

                    .gengduo {
                        position: absolute;
                        right: 10px;
                        bottom: 18px;
                        font-weight: 400;
                        font-size: 12px;
                        color: #999999;
                        line-height: 20px;
                        width: 65px;
                    }
                }

                &:hover {
                    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
                }
            }
        }
    }
}

.redian {
    padding: 30px 0;
    min-width: 1200px;
    background-color: #F4F4F4;

    >div {
        margin: auto;
        display: flex;
        justify-content: space-around;
        width: 1160px;

        >div {
            padding: 50px 30px 0px;
            width: 35%;
            height: 380px;
            background-image: url('../../assets/image/zixun/redianB.png');
            background-size: 100% 100%;

            p {
                font-size: 24px;
                font-weight: 600;
            }

            .english {
                font-size: 10px;
                color: #A7A7A7;
            }

            img {
                margin: 30px 20px;
                width: 50px;
                height: 40px;
            }

            span {
                // width: ;
                // font-size: ;
                line-height: 28px;
            }
        }

        ul {
            margin-left: 20px;
            display: flex;
            // justify-content: space-around;
            flex-wrap: wrap;
            width: 58%;
            // flex-grow: 1;

            li {
                position: relative;
                // display: flex;
                margin-left: 2%;
                width: 32%;
                background-color: #fff;
                border-radius: 6px;

                &:first-child {
                    margin-bottom: 10px;
                    margin-left: 0;
                    width: 49%;

                    img {
                        height: 150px;
                    }

                    p {
                        // width: 80%;
                        // display: -webkit-box;
                        // -webkit-line-clamp: 1;
                        // -webkit-box-orient: vertical;
                        // overflow: hidden;
                        // text-overflow: ellipsis;
                    }
                }

                &:nth-child(2) {
                    margin-bottom: 10px;
                    width: 49%;
                    p{
                        padding-right: 40px;
                    }
                    img {
                        height: 150px;
                    }
                }

                &:nth-child(3) {
                    margin-left: 0;
                }

                img {
                    width: 100%;
                    height: 167px;
                }

                p {
                    // margin: 20px 0 10px;
                    padding: 0px 22px 0px 10px;
                    font-size: 14px;
                    font-weight: 600;
                    white-space: nowrap;
                    /* 保持文本在一行，不换行 */
                    overflow: hidden;
                    /* 超出容器部分隐藏 */
                    text-overflow: ellipsis;
                }

                .gengduo {
                    position: absolute;
                    right: 5px;
                    bottom: 5px;
                    font-weight: 400;
                    font-size: 12px;
                    color: #999999;
                    line-height: 20px;
                    width: 65px;
                }

                &:hover {
                    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
                }

            }
        }
    }
}

.kanwu {
    padding: 30px 0;
    min-width: 1200px;
    background-color: #F4F4F4;

    >div {
        margin: auto;
        display: flex;
        justify-content: space-around;
        width: 1160px;

        >div {
            padding: 50px 30px 30px;
            width: 42%;
            background-image: url('../../assets/image/zixun/kanwuB.png');
            background-size: 100% 100%;

            p {
                font-size: 24px;
                font-weight: 600;
            }

            .english {
                display: inline;
                font-size: 10px;
                color: #A7A7A7;
            }

            span {
                display: block;
                margin-top: 20px;
                // width: ;
                // font-size: ;
                line-height: 28px;

            }
        }

        ul {
            margin-left: 15px;
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            width: 70%;
            // flex-grow: 1;

            li {
                // margin-left: 20px;
                // text-align: center;
                // padding-left: 20px;
                width: 31.5%;
                height: 300px;
                background-image: url('../../assets/image/zixun/kanwu1.png');
                background-size: 100% 100%;
                background-repeat: no-repeat;
                border-radius: 6px;

                p {
                    margin-top: 260px;
                    text-align: center;
                    color: #fff;
                }

                &:nth-child(2) {
                    background-image: url('../../assets/image/zixun/kanwu2.png');

                }

                &:nth-child(3) {
                    background-image: url('../../assets/image/zixun/kanwu3.png');

                }
            }
        }
    }
}
</style>