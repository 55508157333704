<template>
    <div>
        <top-view></top-view>
        <div class="backTop">
        </div>
        <div class="wenhua">
            <p><img src="../../assets/image/left.png">&nbsp;Group culture&nbsp;<img src="../../assets/image/right.png"></p>
            <ul>
                <li>
                    <p>Corporate vision</p>
                    <span>Hand in hand billion enterprise <br> brilliant</span>
                    <span>Create a hundred years of <br> Tang Cheng</span>
                </li>
                <li>
                    <p>Enterprise mission</p>
                    <span>Customers are excellent <br> because of Tang Cheng</span>
                    <span>Employees are happy because <br> of Tang Cheng</span>
                </li>
                <li>
                    <p>Value</p>
                    <span>Honest and trustworthy, <br> passionate and dedicated</span>
                    <span>Embrace change, create and <br> prosper together</span>
                </li>
                <li>
                    <p>Practice standard</p>
                    <span>Use professional accounting <br> and tax services</span>
                    <span>Create value for customers</span>
                </li>
            </ul>
        </div>
        <div class="licheng">
            <p><img src="../../assets/image/left.png">&nbsp;Development course&nbsp;<img src="../../assets/image/right.png"></p>
            <ul>
                <li v-for="(item, index) in lichengList" :key="item.mileageId">
                    <div v-if="index % 2 == 0">
                        <img :src="imgurl + item.mileageImg" alt="">
                        <div>
                            <div class="qiu"></div>
                            <div class="year">{{ item.mileageYear }}</div>
                            <p>【{{ item.mileageTitle }}】</p>
                            <div style="margin-top: 10px;width: 518px;height: 2px;background-color: #F0F0F0;margin-left: 12px;">
                                <div style="width: 30%;border: 1px solid #DADADA;"></div>
                            </div>
                            <ul>
                                <li v-for="(item1, index1) in item.mileageContent" :key="index1">
                                    <div>{{ item1.month }}</div>
                                    <p>{{ item1.content }}</p>
                                </li>
                            </ul>
                            <div class="show">.....</div>
                        </div>
                    </div>
                    <div v-if="index % 2 != 0">
                        <div>
                            <div class="qiu"></div>
                            <div class="year">{{ item.mileageYear }}</div>
                            <p>【{{ item.mileageTitle }}】</p>
                            <div style="margin-top: 10px;width: 518px;height: 2px;background-color: #F0F0F0;margin-left: 38px;">
                                <div style="margin-left: 70%;width: 30%;border: 1px solid #DADADA;"></div>
                            </div>
                            <ul>
                                <li v-for="(item2, index2) in item.mileageContent" :key="index2">
                                    <p>{{ item2.content }}</p>
                                    <div>{{ item2.month }}</div>
                                </li>
                            </ul>
                            <div class="show">.....</div>
                        </div>
                        <img :src="imgurl + item.mileageImg" alt="">
                    </div>
                </li>
            </ul>
        </div>

        <footer-view></footer-view>
        <side-bar class="fixed"></side-bar>

    </div>
</template>

<script>
import topView from '@/components/en/topView.vue'
import footerView from '@/components/en/footerView.vue'
import SideBar from '@/components/en/sideBar.vue'
import axios from 'axios';

export default {
    components: { footerView, SideBar, topView },
    data() {
        return {
            infoShow: false,
            lichengList: [],
            contentList: [],
            // imgurl: 'http://192.168.0.199:8087/api',
            imgurl: ''
        }
    },
    created() {
        this.getlichengList()
        // this.$store.state.componentKey += 1
        this.website()
        this.imgurl = this.$store.state.imgurl
    },
    methods: {
        gotoAboutus() {
            this.$router.push('/aboutus')
        },
        getlichengList() {
            axios.get('/api/pcsystem/TckgMileage/list',{params:{language:'1'}}).then((res) => {
                if (res.status == 200) {
                    console.log(res.data.rows);
                    for (let i = 0; i < res.data.rows.length; i++) {
                        res.data.rows[i].mileageContent = JSON.parse(res.data.rows[i].mileageContent)
                    }
                    this.lichengList = res.data.rows
                    console.log(this.lichengList);
                }

            }).catch()
        },
        //网站访问信息
        website() {
            axios.post('/api/pcsystem/TckgSoftLogin', { userType: '发展历程' }).then((res) => {
                console.log(res);
            }).catch()
        },
    }

}
</script>

<style lang="scss" scoped>
.fixed {
    position: fixed;
    top: 150px;
    right: 5px;
}

.backTop {
    position: relative;
    width: 100%;
    height: 26vw;
    min-width: 1200px;
    min-height: 310px;
    background-image: url("../../assets/image/lichengEBack.png");
    background-size: 100% 100%;

}

.wenhua {
    padding: 60px 0;
    min-width: 1200px;
    background-image: url('../../assets/image/licheng/back.png');
    background-size: 100% 100%;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    ul {
        margin: 30px auto;
        display: flex;
        justify-content: space-around;
        width: 1200px;

        li {
            width: 23%;
            height: 300px;
            background-image: url('../../assets/image/licheng/qiyeyuanjing.png');
            background-size: 100% 100%;

            p {
                margin-top: 40px;
                text-align: center;
                font-size: 24px;
            }

            span {
                display: block;
                text-align: center;
                color: #fff;
                font-size: 16px;
                line-height: 20px;

                &:nth-child(2) {
                    margin-top: 135px;
                }
            }

            &:nth-child(2) {
                background-image: url('../../assets/image/licheng/back3.png');
                background-size: 100% 100%;
            }

            &:nth-child(3) {
                background-image: url('../../assets/image/licheng/back2.png');
                background-size: 100% 100%;
            }

            &:nth-child(4) {
                background-image: url('../../assets/image/licheng/back4.png');
                background-size: 100% 100%;
            }

            &:hover {
                position: relative;
                top: -10px;
            }
        }
    }

}

.licheng {
    padding: 60px 0;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    >ul {
        margin: 30px auto;
        width: 1200px;
        // height: 500px;
        background-image: url('../../assets/image/licheng/centerLine.png');
        background-position: 50% 0;
        background-repeat: no-repeat;

        >li {
            &:first-child {
                padding-top: 160px;
                margin-top: 0;
            }

            >div {
                margin-top: 50px;
                display: flex;


                >div {
                    position: relative;
                    margin-left: 50px;
                    width: 568px;
                    padding: 20px 0px 20px 20px;
                    background-color: #FFF;
                    border-radius: 6px;

                    .year{
                        padding: 5px 10px;
                        width: 137px;
                        color: #fff;
                        background-color: #EA5513;
                        text-align: center;
                        font-size: 32px;
                        border-radius: 30px;
                    }

                    >.qiu {
                        position: absolute;
                        top: 20px;
                        left: -25px;
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        background-color: #EA5514;
                    }

                    >p {
                        margin-top: 20px;
                        font-size: 22px;
                        font-weight: 500;
                    }

                    >ul {
                        margin-top: 10px;
                        margin-left: 12px;
                        color: #8C8C8C;

                        >li {
                            display: flex;
                            margin-top: 20px;

                            >div {
                                margin-right: 4px;
                                color: #4C4C4C;
                                font-size: 14px;
                            }

                            >p {
                                line-height: 16px;
                                font-size: 12px;
                                width: 468px;
                            }
                        }
                    }

                    &:hover {
                        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
                    }
                }

                img {
                    width: 558px;
                    height: 312px;
                }
            }

            &:nth-child(even) {
                text-align: end;

                >div {
                    margin-left: -70px;
                    // padding: 0 10px;
                    >div {
                        padding: 20px;

                        .year{
                            margin-left: 400px;
                        }

                        >.qiu {
                            left: 603px;
                        }

                        ul {
                            li {
                                justify-content: end;
                                margin-right: 10px;

                                div {
                                    margin-left: 4px;
                                }
                            }
                        }
                        .show{
                            margin-right: 10px;
                        }
                    }
                }
                img {
                    margin-left: 50px;
                }

            }
            .show{
                display: none;
            }
            &:last-child{
                .show{
                    display: block;
                }
            }
        }
    }

}
</style>