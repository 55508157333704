<template>
    <div>
        <top-view></top-view>
        <div class="backTop">
            <!-- <div>
                <p>收户&nbsp;并购&nbsp;合股</p>
                <ul>
                    <li>收购各地代账</li>
                    <li>财税公司</li>
                    <li>户数不限</li>
                    <li>按户计价</li>
                </ul>
                <span class="english">北京/深圳/上海/广州/天津/成都/西安/石家庄/廊坊/山西/杭州/武汉</span>
                <button>了解更多</button>
            </div> -->
        </div>
        <div style="background-color: #F4F4F4;">
            <ul class="fuwu">
                <li>
                    <img src="../../assets/image/haiwai/jingchengfuwu.png">
                    <div>
                        <p>精诚服务</p>
                        <span>专业团队服务企业全生命周期</span>
                    </div>
                </li>
                <li>
                    <img src="../../assets/image/haiwai/huligongying.png">
                    <div>
                        <p>互利共赢</p>
                        <span>长期合作，共分红利</span>
                    </div>
                </li>
                <li>
                    <img src="../../assets/image/haiwai/yetaigongxiang.png">
                    <div>
                        <p>业态共享</p>
                        <span>跨界协同，共筑宏图</span>
                    </div>
                </li>
            </ul>
        </div>

        <div class="personal">
            <p><img src="../../assets/image/left.png">&nbsp;合作目标&nbsp;<img src="../../assets/image/right.png"></p>
            <span>CO-OPERATION OBJECTIVES</span>
            <ul>
                <li>
                    <img src="../../assets/image/haiwai/mubiao1.png" style="width: 100%;height: 250px;">
                    <p>01.</p>
                    <span>全产业链业务对接，通过深度战略合作，聚焦资源的共享和整合，携手共进，共赢商机</span>
                </li>
                <li>
                    <img src="../../assets/image/haiwai/mubiao2.png" style="width: 100%;height: 250px;">
                    <p>02.</p>
                    <span>拓展多行业市场领域，带来全新增长点，提升品牌影响力，以匠心品质树立行业标杆，打造服务典范</span>
                </li>
                <li>
                    <img src="../../assets/image/haiwai/mubiao3.png" style="width: 100%;height: 250px;">
                    <p>03.</p>
                    <span>解决企业运营管理瓶颈和团队流失等问题，帮助企业达成经营目标（收入、成本、利润、客户量、效率）</span>
                </li>
            </ul>
        </div>

        <div class="working">
            <p><img src="../../assets/image/left.png">&nbsp;为什么要和我们合作&nbsp;<img src="../../assets/image/right.png"></p>
            <span>WHY ARE YOU WORKING WITH US</span>
            <div>
                <ul>
                    <li>
                        <p>企业实力</p>
                        <span>雄厚</span>
                    </li>
                    <li>
                        <p>市场资源</p>
                        <span>丰富</span>
                    </li>
                    <li>
                        <p>全球服务</p>
                        <span>本地专家</span>
                    </li>
                </ul>
                <img src="../../assets/image/haiwai/heCenter.png">
                <ul>
                    <li>
                        <p>成熟的团队</p>
                        <span>支撑</span>
                    </li>
                    <li>
                        <p>创新驱动与</p>
                        <span>研发实力</span>
                    </li>
                    <li>
                        <p>卓越的服务</p>
                        <span>体系</span>
                    </li>
                </ul>
            </div>

        </div>

        <div class="flow">
            <p><img src="../../assets/image/left.png">&nbsp;合作流程&nbsp;<img src="../../assets/image/right.png"></p>
            <span>COOPERATION PROCESS</span>
            <!-- <div style="margin: 40px auto;width: 1160px;border: 1px solid #EA5514;"></div> -->
            <ul>
                <li>
                    <p>初步了解</p>
                </li>
                <li style="width: 30px;margin-top: 100px;"><img src="../../assets/image/haiwai/jiantou.png"></li>
                <li>
                    <p>达成合作</p>
                </li>
                <li style="width: 30px;;margin-top: 100px;"><img src="../../assets/image/haiwai/jiantou.png"></li>
                <li>
                    <p>商定方案</p>
                </li>
                <li style="width: 30px;;margin-top: 100px;"><img src="../../assets/image/haiwai/jiantou.png"></li>
                <li>
                    <p>指导运营实施</p>
                </li>
            </ul>
        </div>
        <div class="service">
            <p><img src="../../assets/image/haiwai/whiteD.png">&nbsp;支持团队&nbsp;<img
                    src="../../assets/image/haiwai/whiteD.png"></p>
            <span>SUPPORT THE TEAM</span>
            <ul :key="refresh">
                <li v-for="(item, index) in list" :key="index" @mouseenter="mouseenter(index)" @mouseleave="mouseleave">
                    <img  :src="imgurl + item.img">
                    <div >
                        <p>{{ item.num }}<span>人</span></p>
                        <span>{{ item.name }}</span>
                    </div>
                    <span>{{ item.content }}</span>
                </li>
                <!-- <li  class="serviceLi">
                    <div @mouseenter="mouseenter(0)" @mouseleave="mouseLeave()">
                        <img src="../assets/image/haiwai/woshou1.png" style="pointer-events: none;">
                        <p>26<span>人</span></p>
                        <span>顾问团队</span>
                    </div>
                    <span>市场范围内咨询支持，最新信息一手掌握</span>
                </li>
                <li @mouseover="mouseenter(1)" class="serviceLi">
                    <img src="../assets/image/haiwai/woshou2.png">
                    <div>
                        <p>132<span>人</span></p>
                        <span>运营团队</span>
                    </div>
                    <span>答疑辅导，及时给出解决方案</span>
                </li>
                <li @mouseover="mouseenter(2)" class="serviceLi">
                    <img src="../assets/image/haiwai/woshou3.png">
                    <div>
                        <p>568<span>人</span></p>
                        <span>交付团队</span>
                    </div>
                    <span>高标准交付服务，提升客户体验</span>
                </li> -->
            </ul>
            <!-- <button @click="infoShow = true">点击咨询</button> -->
        </div>

        <div class="advantages">
            <p><img src="../../assets/image/left.png">&nbsp;联系我们&nbsp;<img src="../../assets/image/right.png"></p>
            <span>CONTANT US</span>
            <ul>
                <li v-for="item in dataList" :key="item.contactId">
                    <p class="title">{{ item.deptName }}</p>
                    <div class="content1" v-if="item.contactUser">
                        <div></div>
                        <p>{{ item.contactUser }}：{{ item.contactPhone }}</p>
                    </div>
                    <div class="content2">
                        <div></div>
                        <p>{{ item.contactAddress }}</p>
                    </div>
                </li>
                <!-- <li>
                    <p class="title">北京分公司</p>
                    <div class="content1">
                        <div></div>
                        <p>张廷宙：18600685558</p>
                    </div>
                    <div class="content2">
                        <div></div>
                        <p>北京市朝阳区温特莱中心B座10层</p>
                    </div>
                </li>
                <li>
                    <p class="title">北京分公司</p>
                    <div class="content1">
                        <div></div>
                        <p>张廷宙：18600685558</p>
                    </div>
                    <div class="content2">
                        <div></div>
                        <p>北京市朝阳区温特莱中心B座10层</p>
                    </div>
                </li>
                <li>
                    <p class="title">北京分公司</p>
                    <div class="content1">
                        <div></div>
                        <p>张廷宙：18600685558</p>
                    </div>
                    <div class="content2">
                        <div></div>
                        <p>北京市朝阳区温特莱中心B座10层</p>
                    </div>
                </li>
                <li>
                    <p class="title">北京分公司</p>
                    <div class="content1">
                        <div></div>
                        <p>张廷宙：18600685558</p>
                    </div>
                    <div class="content2">
                        <div></div>
                        <p>北京市朝阳区温特莱中心B座10层</p>
                    </div>
                </li>
                <li>
                    <p class="title">北京分公司</p>
                    <div class="content1">
                        <div></div>
                        <p>张廷宙：18600685558</p>
                    </div>
                    <div class="content2">
                        <div></div>
                        <p>北京市朝阳区温特莱中心B座10层</p>
                    </div>
                </li>
                <li>
                    <p class="title">北京分公司</p>
                    <div class="content1">
                        <div></div>
                        <p>张廷宙：18600685558</p>
                    </div>
                    <div class="content2">
                        <div></div>
                        <p>北京市朝阳区温特莱中心B座10层</p>
                    </div>
                </li>
                <li>
                    <p class="title">北京分公司</p>
                    <div class="content1">
                        <div></div>
                        <p>张廷宙：18600685558</p>
                    </div>
                    <div class="content2">
                        <div></div>
                        <p>北京市朝阳区温特莱中心B座10层</p>
                    </div>
                </li>
                <li>
                    <p class="title">北京分公司</p>
                    <div class="content1">
                        <div></div>
                        <p>张廷宙：18600685558</p>
                    </div>
                    <div class="content2">
                        <div></div>
                        <p>北京市朝阳区温特莱中心B座10层</p>
                    </div>
                </li>
                <li>
                    <p class="title">北京分公司</p>
                    <div class="content1">
                        <div></div>
                        <p>张廷宙：18600685558</p>
                    </div>
                    <div class="content2">
                        <div></div>
                        <p>北京市朝阳区温特莱中心B座10层</p>
                    </div>
                </li>
                <li>
                    <p class="title">北京分公司</p>
                    <div class="content1">
                        <div></div>
                        <p>张廷宙：18600685558</p>
                    </div>
                    <div class="content2">
                        <div></div>
                        <p>北京市朝阳区温特莱中心B座10层</p>
                    </div>
                </li>
                <li>
                    <p class="title">北京分公司</p>
                    <div class="content1">
                        <div></div>
                        <p>张廷宙：18600685558</p>
                    </div>
                    <div class="content2">
                        <div></div>
                        <p>北京市朝阳区温特莱中心B座10层</p>
                    </div>
                </li>
                <li>
                    <p class="title">北京分公司</p>
                    <div class="content1">
                        <div></div>
                        <p>张廷宙：18600685558</p>
                    </div>
                    <div class="content2">
                        <div></div>
                        <p>北京市朝阳区温特莱中心B座10层</p>
                    </div>
                </li>
                <li>
                    <p class="title">北京分公司</p>
                    <div class="content1">
                        <div></div>
                        <p>张廷宙：18600685558</p>
                    </div>
                    <div class="content2">
                        <div></div>
                        <p>北京市朝阳区温特莱中心B座10层</p>
                    </div>
                </li>
                <li>
                    <p class="title">北京分公司</p>
                    <div class="content1">
                        <div></div>
                        <p>张廷宙：18600685558</p>
                    </div>
                    <div class="content2">
                        <div></div>
                        <p>北京市朝阳区温特莱中心B座10层</p>
                    </div>
                </li>
                <li>
                    <p class="title">北京分公司</p>
                    <div class="content1">
                        <div></div>
                        <p>张廷宙：18600685558</p>
                    </div>
                    <div class="content2">
                        <div></div>
                        <p>北京市朝阳区温特莱中心B座10层</p>
                    </div>
                </li>
                <li>
                    <p class="title">北京分公司</p>
                    <div class="content1">
                        <div></div>
                        <p>张廷宙：18600685558</p>
                    </div>
                    <div class="content2">
                        <div></div>
                        <p>北京市朝阳区温特莱中心B座10层</p>
                    </div>
                </li>
                <li>
                    <p class="title">北京分公司</p>
                    <div class="content1">
                        <div></div>
                        <p>张廷宙：18600685558</p>
                    </div>
                    <div class="content2">
                        <div></div>
                        <p>北京市朝阳区温特莱中心B座10层</p>
                    </div>
                </li> -->

            </ul>
            <!-- <button @click="infoShow = true">点击咨询</button> -->
        </div>
        <div class="orange">
            <img src="../../assets/image/kuaiji/whiteLine.png">&nbsp;&nbsp;期待您的合作与建议&nbsp;&nbsp;<img
                src="../../assets/image/kuaiji/whiteLine.png">
        </div>

        <footer-view></footer-view>
        <side-bar class="fixed"></side-bar>
        <div v-if="infoShow"
            style="padding-top: 200px;width:100vw;height: 100vh;background-color:rgba(0, 0, 0, 0.4);position: fixed;top: 0">
            <div class="info">
                <p>扫码关注微信公众号</p>
                <img src="../../assets/icon/cheng.png" class="cheng" @click="infoShow = false">
                <img src="../../assets/image/name.png" class="name">
                <img src="../../assets/image/erweima.png">
            </div>
        </div>

    </div>
</template>

<script>
import topView from '@/components/zh/topView.vue'
import footerView from '@/components/zh/footerView.vue'
import SideBar from '@/components/zh/sideBar.vue'
import axios from 'axios';

export default {
    components: { footerView, SideBar, topView },
    data() {
        return {
            infoShow: false,
            dataList: [],
            list: [{
                num: 26,
                name: '顾问团队',
                content: '市场范围内咨询支持，最新信息一手掌握',
                img: require('../../assets/image/haiwai/woshou1.png')
            }, {
                num: 132,
                name: '运营团队',
                content: '答疑辅导，及时给出解决方案',
                img: require('../../assets/image/haiwai/woshou2.png')
            }, {
                num: 568,
                name: '交付团队',
                content: '高标准交付服务，提升客户体验',
                img: require('../../assets/image/haiwai/woshou3.png')
            }],
            imgurl: '',
            num: 0,
            refresh: 0
        }
    },
    created() {
        this.getList()
        // this.$store.state.componentKey += 1
        this.website()
        // this.imgurl = this.$store.state.imgurl
    },
    methods: {
        getList() {
            axios.get('/api/pcsystem/TckgContact/list',{params:{language:'0'}}).then((res) => {
                if (res.status == 200) {
                    this.dataList = res.data.rows
                    // console.log(this.dataList);
                }
            }).catch()
        },
        //网站访问信息a
        website() {
            axios.post('/api/pcsystem/TckgSoftLogin', { userType: '商业合伙' }).then((res) => {
                console.log(res);
            }).catch()
        },
        mouseenter(index) {
            let num = this.num
            if(num === 0) {
                let obj = this.list[1]
                this.list[1] = this.list[index]
                this.list[index] = obj
                this.refresh += 1
                this.num += 1
            }
            // console.log(this.list);
        },
        mouseleave() {
            this.num = 0
        }
    }

}
</script>

<style lang="scss" scoped>
.fixed {
    position: fixed;
    top: 150px;
    right: 5px;
}

.backTop {
    position: relative;
    width: 100%;
    height: 26vw;
    min-width: 1200px;
    min-height: 310px;
    // height: 500px;
    // height: 450px;
    background-image: url("../../assets/image/hehuoBack.png");
    background-size: 100% 100%;
    // z-index: 0;

    // div {
    //     margin: auto;
    //     padding-top: 80px;
    //     width: 1160px;
    //     color: #EA5514;
    //     text-align: center;
    // }

    // p {
    //     margin: 20px 0;
    //     color: #fff;
    //     font-size: 50px;
    //     font-weight: 700;
    // }

    // .english {
    //     display: block;
    //     font-size: 16px;
    //     color: #fff;
    // }

    // button {
    //     margin-top: 30px;
    //     width: 100px;
    //     height: 30px;
    //     line-height: 30px;
    //     color: #fff;
    //     background-color: #FFB258;
    //     border: 0;
    //     border-radius: 15px;
    // }

    // ul {
    //     margin: 20px auto;
    //     display: flex;
    //     justify-content: space-between;
    //     width: 50%;
    //     border: 1px solid #fff;
    //     color: #fff;
    //     text-align: center;

    //     li {
    //         // text-align: center;
    //         padding: 5px 10px;
    //         width: 24%;
    //         border-right: 1px solid #fff;

    //         &:last-child {
    //             border: 0;
    //         }
    //     }
    // }
}

.fuwu {
    position: relative;
    display: flex;
    justify-content: space-around;
    margin: -50px auto 0;
    padding: 40px 30px;
    width: 1140px;
    // height: 150px;
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 6px;

    // z-index: 9999;
    li {
        display: flex;
        align-items: center;

        // height: 100px;
        img {
            width: 50px;
            height: 50px;
        }

        div {
            margin-left: 15px;

            p {
                margin-bottom: 5px;
                font-size: 18px;
                font-weight: 600;
            }

            span {
                font-size: 16px;
                color: #4B4B4B;
            }
        }
    }
}

.kuaijiQ {
    margin: 60px auto;
    display: flex;
    justify-content: space-around;
    width: 1160px;

    img {
        border-radius: 0 !important;
    }

    div {
        margin-right: 20px;

        >p {
            margin-bottom: 50px;
            font-size: 18px;
            font-weight: 600;
        }

        .kuaijiQC {
            margin: 20px 0;
            font-size: 16px;
            font-weight: 400;
        }

        ul {
            margin-top: 50px;
            display: flex;
            justify-content: space-around;
            box-shadow: 0 0 10px 0 #F4F4F4;

            li {
                padding: 20px 30px;
                text-align: center;
                // border-right: 1px solid black;

                span {
                    display: block;
                    font-size: 14px;
                    color: #949494;
                }
            }
        }
    }

}

.kunnan {
    display: flex;
    justify-content: space-around;
    margin: 50px auto 30px;
    width: 1160px;

    li {
        position: relative;
        width: 25%;
        text-align: center;
        // overflow: hidden;

        img {
            width: 100%;
            height: 300px;
        }

        p {
            // margin-top: 20px;
            position: absolute;
            left: 6px;
            bottom: 15px;
            padding: 10px;
            width: 89%;
            text-align: center;
            color: #fff;
            background-color: rgba(0, 0, 0, 0.5);

            span {
                display: block;

                &:first-child {
                    font-size: 18px;
                }

                &:last-child {
                    font-size: 14px;
                }
            }
        }
    }
}

.canIdo {
    // position: relative;
    padding: 30px;
    background-color: #F4F4F4;
    // background-image: url('../assets/image/qidai/60f36944c6bf951d8579d623d7e627ac62513e922dccf6-6Npbt3_fw1200.png');

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    div {
        position: relative;
        display: flex;
        margin: 50px auto 30px;
        width: 1160px;

        img {
            margin: auto;
            // position: absolute;
            width: 400px;
            height: 400px;
            z-index: 9999;
        }

        ul {
            position: absolute;
            // display: flex;
            // justify-content: space-around;
            margin: 50px auto 30px;
            width: 28%;
            z-index: 0;

            li {
                // position: relative;
                margin-bottom: 20px;
                padding: 15px 20px 5px;
                // width: 10%;
                height: 80px;
                text-align: start;
                border: 2px solid #EA5514;
                background-color: #fff;
                border-radius: 10px;

                // overflow: hidden;
                p {
                    color: #727272;

                }

                span {
                    display: block;

                    &:first-child {
                        margin-bottom: 10px;
                        padding: 3px 10px;
                        width: 30%;
                        text-align: center;
                        background-color: #EA5514;
                        font-size: 18px;
                        border-radius: 30px;
                        color: #fff;
                    }

                    &:last-child {
                        font-size: 14px;
                    }
                }
            }

            &:first-child {
                top: -10%;
                left: 10%;

                li {
                    &:first-child {
                        border-radius: 0;
                        border-bottom-left-radius: 50px;
                        border-top-right-radius: 50px;
                    }

                    &:last-child {
                        border-radius: 0;
                        border-top-left-radius: 50px;
                        border-bottom-right-radius: 50px;
                    }
                }
            }

            &:last-child {
                top: -10%;
                left: 63%;

                li {
                    text-align: end;

                    span {

                        // margin-left: 150px;
                        &:first-child {
                            margin-left: 180px;
                        }
                    }

                    &:first-child {
                        border-radius: 0;
                        border-bottom-right-radius: 50px;
                        border-top-left-radius: 50px;
                    }

                    &:last-child {
                        border-radius: 0;
                        border-top-right-radius: 50px;
                        border-bottom-left-radius: 50px;
                    }
                }

            }
        }
    }



}

.service {
    // position: relative;
    padding: 50px 0 90px;
    min-width: 1200px;
    height: 535px;
    // background-color: #F4F4F4;
    background-image: url('../../assets/image/haiwai/woshouO.png');
    background-size: 100% 100%;
    color: #fff;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
                transform: rotate(180deg);
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        // color: #fff;
        font-size: 22px;
        font-weight: 400;
    }

    ul {
        margin: 70px auto 30px;
        display: flex;
        justify-content: space-around;
        width: 1160px;
        height: 375px;

        li {
            padding-bottom: 10px;
            width: 30%;
            height: 375px;
            background-color: #fff;
            text-align: center;
            border-radius: 10px;
            z-index: 999 !important;
            // point-events:none;


            // .image {
            //     width: 97%;
            //     height: 75%;
            //     background-image: url('../assets/image/haiwai/woshou1.png');
            //     background-size: 100% 100%;
            // }

            img {
                width: 100%;
                height: 75%;
                z-index: 0 !important;
            }

            div {
                margin: 0 auto 3px;
                padding: 5px;
                width: 90%;
                border-bottom: 1px solid #cacaca;

                p {
                    font-size: 20px;
                    color: #EB6225;
                    font-weight: 600;
                }

                span {
                    font-size: 14px;
                    font-weight: 500;
                    color: #333333;
                }
            }

            span {
                font-size: 12px;
                color: #999999;
            }

            // &:first-child{
            //     &:hover{

            //     }
            // }

            &:nth-child(2) {
                position: relative;
                top: -30px;
                height: 430px;
                width: 33%;
                // .image {
                //     background-image: url('../assets/image/haiwai/woshou2.png');
                // }
            }

            // &:nth-child(3) {
            //     .image {
            //         background-image: url('../assets/image/haiwai/woshou3.png');
            //     }
            // }
            // &:hover {
            //     // position: relative;
            //     // top: -20px;
            //     // width: 33%;
            //     // height: 420px;
            //     &:first-child {
            //         .image {
            //             background-image: url('../assets/image/haiwai/woshou2.png');
            //         }
            //     }
            //     &:nth-child(2) {
            //         // position: relative;
            //         // top: -20px;
            //         // width: 33%;
            //         // height: 420px;
            //         .image {
            //             background-image: url('../assets/image/haiwai/woshou2.png');
            //         }
            //     }
            //     &:nth-child(3) {
            //         .image {
            //             background-image: url('../assets/image/haiwai/woshou3.png');
            //         }
            //     }
            // }
        }

        // &:hover {
        //     li {
        //         &:nth-child(2) {
        //             position: relative;
        //             top: -20px;
        //             width: 33%;
        //             height: 420px;

        //             .image {
        //                 background-image: url('../assets/image/haiwai/woshou2.png');
        //             }
        //         }
        //     }
        // }
    }

    button {
        margin-top: 30px;
        margin-left: 48%;
        padding: 5px 15px;
        font-size: 20px;
        font-weight: 500;
        color: #fff;
        background-color: #FE8F3F;
        border: 0;
        border-radius: 20px;
    }

}

.personal {
    // position: relative;
    padding: 50px 0;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    ul {
        margin: 50px auto 0;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 1160px;

        li {
            // position: relative;
            // margin-top: 1vh;
            // margin-left: 10px;
            width: 33%;
            // height: 340px;
            // background-image: url("../assets/image/personal.png");
            // background-size: 100% 100%;
            background-color: #fff;
            border-radius: 6px;

            >p {
                margin: 20px;
                padding: 10px 0;
                width: 30px;
                font-size: 20px;
                font-weight: 700;
                color: #EB5E21;
                border-bottom: 3px solid #EB5E21;
            }

            span {
                display: block;
                margin: 0 20px 30px;
                // padding: 0 10px;
                font-size: 16px;
                color: #5F5F5F;
            }

            &:hover {
                // margin-top: -5px;
                position: relative;
                top: -10px;
            }

        }
    }

    button {
        margin-top: 20px;
        margin-left: 48%;
        padding: 2px 8px;
        color: #9B9B9B;
        border: 1px solid#9B9B9B;
    }
}

.working {
    // position: relative;
    padding: 30px 0 50px;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    >div {
        margin: 70px auto 10px;
        display: flex;
        justify-content: space-around;
        width: 1160px;

        ul {
            width: 30%;

            // text-align: center;
            li {
                margin-top: 70px;
                padding: 5px;
                width: 50%;
                height: 45px;
                color: #fff;

                p {
                    margin-left: 50px;
                    margin-top: 2px;
                }

                span {
                    display: block;
                    margin-left: 50px;
                    font-size: 16px;
                }
            }

            &:first-child {
                li {
                    margin-left: 100px;

                    &:first-child {
                        margin-top: 100px;
                        background-image: url('../../assets/image/haiwai/he1.png');
                        background-size: 100% 100%;
                    }

                    &:nth-child(2) {
                        background-image: url('../../assets/image/haiwai/he2.png');
                        background-size: 100% 100%;
                    }

                    &:last-child {
                        background-image: url('../../assets/image/haiwai/he3.png');
                        background-size: 100% 100%;
                    }
                }
            }

            &:last-child {

                // margin-left: 40px;
                li {
                    // padding-right: 40px;
                    text-align: end;
                    margin-left: 60px;

                    p {
                        margin-left: 0;
                        margin-right: 50px;
                        margin-top: 2px;
                    }

                    span {
                        // display: block;
                        margin-right: 50px;
                        // font-size: 14px;
                    }

                    &:first-child {
                        margin-top: 100px;
                        background-image: url('../../assets/image/haiwai/he4.png');
                        background-size: 100% 100%;
                    }

                    &:nth-child(2) {
                        background-image: url('../../assets/image/haiwai/he5.png');
                        background-size: 100% 100%;
                    }

                    &:last-child {
                        background-image: url('../../assets/image/haiwai/he6.png');
                        background-size: 100% 100%;
                    }
                }
            }
        }
    }

}

.flow {
    // position: relative;
    padding: 0px 0 10px;
    min-width: 1200px;
    // background-image: url("../assets/image/kuaiji/6458486361c124a42770f394d12bef7d526a503611913d-0S20U5_fw1200.png");
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    >ul {
        margin: 80px auto 20px;
        display: flex;
        justify-content: space-around;
        width: 1160px;

        li {
            width: 16%;
            height: 210px;

            p {
                margin-top: 150px;
                font-size: 18px;
                color: black;
                text-align: center;
            }

            &:first-child {
                background-image: url('../../assets/image/haiwai/hezuo1.png');
                background-size: 100% 100%;

                &:hover {
                    position: relative;
                    top: -10px;
                }
            }

            &:nth-child(3) {
                background-image: url('../../assets/image/haiwai/hezuo2.png');
                background-size: 100% 100%;

                &:hover {
                    position: relative;
                    top: -10px;
                }
            }

            &:nth-child(5) {
                background-image: url('../../assets/image/haiwai/hezuo3.png');
                background-size: 100% 100%;

                &:hover {
                    position: relative;
                    top: -10px;
                }
            }

            &:last-child {
                background-image: url('../../assets/image/haiwai/hezuo4.png');
                background-size: 100% 100%;

                &:hover {
                    position: relative;
                    top: -10px;
                }
            }

        }
    }
}

.advantages {
    // position: relative;
    padding: 60px 0 50px;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    ul {
        margin: 60px auto;
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
        width: 1160px;

        li {
            margin-top: 10px;
            margin-left: 10px;
            padding: 20px 0 20px 15px;
            width: 47%;
            background-color: #fff;
            border-radius: 6px;

            // color: black;
            .title {
                margin-bottom: 10px;
                color: black;
                font-size: 18px;

            }

            .content1 {
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                div {
                    width: 14px;
                    height: 14px;
                    background-image: url('../../assets/image/haiwai/telH.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }

                p {
                    margin-left: 10px;
                    font-size: 14px;
                    color: #7E7E7E;
                }
            }

            .content2 {
                display: flex;
                align-items: center;

                div {
                    width: 14px;
                    height: 16px;
                    background-image: url('../../assets/image/haiwai/weizhiH.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }

                p {
                    margin-left: 10px;
                    font-size: 14px;
                    color: #7E7E7E;
                }
            }

            &:hover {
                background-color: #EA5514;

                // color: #fff;
                .title {
                    margin-bottom: 10px;
                    color: #fff;
                    font-size: 18px;

                }

                .content1 {

                    // display: flex;
                    // align-items: center;
                    // margin-bottom: 10px;
                    div {
                        // width: 18px;
                        // height: 20px;
                        background-image: url('../../assets/image/haiwai/telW.png');
                        // background-size: 100% 100%;
                        // background-repeat: no-repeat;
                    }

                    p {
                        // margin-left: 10px;
                        // font-size: 14px;
                        color: #fff;
                    }
                }

                .content2 {

                    // display: flex;
                    // align-items: center;
                    div {
                        // width: 18px;
                        // height: 18px;
                        background-image: url('../../assets/image/haiwai/weizhiW.png');
                        // background-size: 100% 100%;
                        // background-repeat: no-repeat;
                    }

                    p {
                        // margin-left: 10px;
                        // font-size: 14px;
                        color: #fff;
                    }
                }
            }
        }
    }

    button {
        margin-left: 48%;
        padding: 5px 15px;
        font-size: 20px;
        font-weight: 500;
        color: #fff;
        background-color: #FE8F3F;
        border: 0;
        border-radius: 20px;
    }
}

.success {
    // position: relative;
    padding: 30px 0 50px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    ul {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin: 30px auto;
        width: 1160px;
        // width: 50%;

        li {
            // display: flex;
            margin-bottom: 25px;
            padding: 20px 15px;
            width: 30%;
            background-color: #fff;
            border-radius: 6px;

            img {
                width: 100%;
                height: 200px;
            }

            div {
                margin-left: 10px;
                // position: relative;
                text-align: start;

                // width: 500px;
                p {
                    margin-top: 10px;
                    text-align: start;
                    font-size: 16px;
                    font-weight: 600;
                }

                span {
                    display: block;
                    margin-top: 10px;
                    font-size: 14px;
                    line-height: 25px;
                    color: #AFAFAF;
                }

                button {
                    padding: 2px 5px;
                    border: 0;
                    border-radius: 2px;
                    background-color: #E6F2CC;
                    color: #A4C361;
                }
            }
        }
    }

    >button {
        margin-left: 48%;
        padding: 5px 15px;
        font-size: 20px;
        font-weight: 500;
        color: #fff;
        background-color: #FE8F3F;
        border: 0;
        border-radius: 20px;
    }
}

.offer {
    // position: relative;
    padding: 30px 0 50px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    ul {
        display: flex;
        justify-content: space-around;
        margin: 40px auto;
        width: 1160px;

        li {
            // padding: 20px;
            width: 24%;
            background-color: #fff;
            border-radius: 6px;

            p {
                margin: auto;
                padding: 40px 40px 30px;
                width: 60%;
                text-align: center;
                border-bottom: 1px dashed #E1E1E1;

                img {
                    // margin: auto;
                    width: 50px;
                    height: 50px;
                }

                span {
                    display: block;
                    margin-top: 10px;
                    font-size: 18px;
                    font-weight: 600;
                }
            }

            button {
                margin: 20px 0 20px 35%;
                padding: 5px 15px;
                color: #fff;
                background-color: #EA5514;
                border-radius: 15px;
                border: 0;
            }
        }
    }
}

.orange {
    padding: 70px 0;
    min-width: 1200px;
    width: 100%;
    // height: 100px;
    background-image: url("../../assets/image/橙色背景.png");
    background-size: 100% 100%;
    text-align: center;
    color: #fff;
    font-size: 40px;

    img {
        position: relative;
        top: -10px;

        &:nth-child(2) {
            transform: rotate(180deg) !important;
        }
    }

    // line-height: 150px;
    span {
        display: block;
        margin-top: 20px;
        font-size: 16px;

        // line-height: 10px;

    }
}

.info {
    margin: auto;
    position: relative;
    width: 350px;
    border-radius: 6px;
    text-align: center;
    overflow: hidden;
    background-color: #fff;

    p {
        padding: 20px 40px 40px;
        font-size: 20px;
        font-weight: 600;
        color: #fff;
        background-color: #EA5513;
    }

    .name {
        position: absolute;
        top: 60px;
        left: 140px;
        width: 50px;
        height: 50px;
    }

    .cheng {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 25px;
        height: 25px;

    }

    >img {
        &:last-child {
            margin: 40px 0 20px;
            width: 70%;
        }

    }
}
</style>