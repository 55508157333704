import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    componentKey:0,
    // imgurl:'http://120.78.131.181:8087/api'
    // imgurl:'http://192.168.0.199:8087/api'
    imgurl:'/api',
    lang:'chinese'
  },
  getters: {
  },
  mutations: {
    Lang(state,payload){
      state.lang = payload.lang;
    }
  },
  actions: {
  },
  modules: {
  }
})
