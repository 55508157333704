<template>
    <div class="app">
        <div class="isshow">
            <div class="contentB">
                <div class="sanj"></div>
                <p class="name">Welcome to call us</p>
                <p class="content"><img src="../../assets/icon/right.png">One-Stop Service<img src="../../assets/icon/left.png"></p>
                <p class="orangeS orangeT">
                    <img src="../../assets/icon/sideTel.png">176-0039-5273
                </p>
            </div>
            <el-button class="item">
                <img src="../../assets/icon/tel.png" class="pic">
                <p>Consult us</p>
            </el-button>
        </div>
        <div class="isshow">
            <div class="contentB">
                <div class="sanj"></div>
                <p class="name">Tang Cheng Holdings</p>
                <p class="content"><img src="../../assets/icon/right.png">One-Stop Service<img src="../../assets/icon/left.png"></p>
                <img src="../../assets/image/gongzhonghaoEWM.png" class="erweima">
                <p class="orangeS">Pay attention to Tang Cheng 
                    Holding public number</p>
            </div>
            <el-button class="item">
                <img src="../../assets/icon/weixin.png" class="pic" style="width: 35px;height: 32px;">
                <p>Wechat public <br> account</p>
            </el-button>
        </div>
        <div class="isshow">
            <div class="contentB">
                <div class="sanj"></div>
                <p class="name">Tang Cheng Holdings</p>
                <p class="content"><img src="../../assets/icon/right.png">One-Stop Service<img src="../../assets/icon/left.png"></p>
                <img src="../../assets/image/douyinEWM.png" class="erweima">
                <p class="orangeS">Follow Tangcheng Holdings
                    Tiktok</p>
            </div>
            <el-button class="item">
                <img src="../../assets/icon/douyin.png" class="pic">
                <p>Tiktok Account <br> number</p>
            </el-button>
        </div>
        <el-backtop style="margin-top: 27px;">
            <el-button class="item">
                <img src="../../assets/icon/xiangshang.png" class="pic">
            </el-button>
        </el-backtop>
    </div>
</template>

<script>
export default {
    data() {
        return {
        }
    }
}
</script>

<style lang="scss" scoped>
.app {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 9999;
}

.isshow {
    margin-top: 10px;
    position: relative;
}

.isshow .contentB {
    position: absolute;
    right: 130px;
    padding: 17px 10px;
    display: none;
    background-color: #fff;
    width: 200px;
    // height: 323px;
    border-radius: 6px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    text-align: center;

    .sanj {
        position: absolute;
        right: -20px;
        border: 10px solid #fff;
        border-top: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid transparent;
    }

    .erweima {
        width: 154px !important;
        height: 154px !important;
    }
}

.isshow:hover .contentB {
    display: block;
    // width: 300px;
}

.item {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    padding: 10px 0 !important;
    // span{
    //     width: 110px !important;
    // }
    p{
        // width: 50px;
        text-align: center;
        // margin-left: -10px;
    }
}

::v-deep .is-light {
    border: 0 !important;
}

.el-button.el-button,
.el-checkbox.is-bordered.el-checkbox.is-bordered,
.el-backtop {
    width: 110px;
    border: none;
}

.el-button+.el-button,
.el-checkbox.is-bordered+.el-checkbox.is-bordered {
    margin: 10px 0 0 0 !important;
}

.pic {
    width: 30px;
    height: 30px;
}

::v-deep .el-backtop {
    top: 398px;
    right: 5px !important;
}

.name {
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    color: #333333;
}

.content {
    position: relative;
    font-weight: 400;
    font-size: 12px;
    color: #999999;

    img {
        position: absolute;
        top: 7px;
        left: 8px;
        width: 38px;

        &:last-child {
            left: 154px;
        }
    }
}

.orangeS {
    // font-size: 14px;
    // color: #EA5615;
    // text-align: center;
    // // background: none;
    font-weight: 500;
    font-size: 14px;
    color: #EA5514;
}

.orangeT {
    width: 100%;
    margin-top: 5px;
    position: relative;
    margin-left: 10px;
    font-size: 20px;
    color: #EA5412;
    line-height: 24px;
    text-align: center;

    // font-weight: 700;
    img {
        position: absolute;
        left: 2px;
        top:4px;
    }
}
</style>