<template>
    <div>
        <top-view></top-view>
        <div class="backTop">
        </div>
        <div class="kuaijiQ">
            <div>
                <p>Qualified business group</p>
                <div
                    style="margin-bottom: 40px;width: 81px;height: 4px;background: linear-gradient( 270deg, #F4F4F4 0%, #EA5514 100%);border-radius: 1px;">
                </div>
                <p class="kuaijiQC">
                    Lianzheng Enterprise Service is a consulting platform dedicated to construction qualification
                    services under Tangcheng Holdings, providing professional and efficient construction qualification
                    consulting services for enterprises in the national construction industry.
                    <br><br>
                    More than 100 social expert resources, accurate implementation of the declaration difficulties, to
                    help the industry enterprise qualification bid efficiently. Pay close attention to service, strictly
                    control customer costs, promote the key nodes and details of information and process, so as to
                    predict in advance, supervise in the matter, and take responsibility afterwards.
                </p>
            </div>
            <img src="../../assets/image/jianzhufuwu.png">
        </div>
        <div class="canIdo">
            <p><img src="../../assets/image/left.png">&nbsp;What can we do for your business&nbsp;<img
                    src="../../assets/image/right.png"></p>
            <div>
                <ul>
                    <li>
                        <p>Precise measures</p>
                        <span>Ask customer needs to solve the problem of qualification declaration</span>
                    </li>
                    <li>
                        <p>After-sales tracking</p>
                        <span>The qualification agency process is simpler and better for you in the follow-up
                            service</span>
                    </li>
                </ul>
                <img src="../../assets/image/kuaiji/canIdo.png">
                <ul>
                    <li>
                        <p>Expedited processing</p>
                        <span>Multi-person team, high efficiency, short processing cycle</span>
                    </li>
                    <li>
                        <p> One-stop agency </p>
                        <span>Complex process we run, you sit and wait for the license </span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="service">
            <p><img src="../../assets/image/left.png">&nbsp;Service content&nbsp;<img
                    src="../../assets/image/right.png"></p>
            <ul>
                <li>
                    <div></div>
                    <p class="qizhi">01</p>
                    <p>Construction primary contractor</p>
                    <span>New transaction/upgrade/transfer,
                        worry-free service</span>
                </li>
                <li>
                    <div></div>
                    <p class="qizhi">02</p>
                    <p>Construction contract</p>
                    <span>Zero errors, cost-effective</span>
                </li>
                <li>
                    <div></div>
                    <p class="qizhi">03</p>
                    <p>Service qualification</p>
                    <span>Service qualification</span>
                </li>
                <li>
                    <div></div>
                    <p class="qizhi">04</p>
                    <p>Safety production license</p>
                    <span>VIP access</span>
                </li>
                <li>
                    <div></div>
                    <p class="qizhi">05</p>
                    <p>Permit for installation (repair, test)
                        of electric power facilities</p>
                    <span>process standardization</span>
                </li>
                <li>
                    <div></div>
                    <p class="qizhi">06</p>
                    <p>Real estate development
                        qualification</p>
                    <span>Hundreds of successful cases, trustworthy</span>
                </li>
            </ul>
        </div>
        <div class="flow">
            <p><img src="../../assets/image/left.png">&nbsp;Service process&nbsp;<img
                    src="../../assets/image/right.png"></p>
            <ul>
                <li>
                    <p>01.</p>
                    <span>Negotiation and cooperation</span>
                </li>
                <li>
                    <p>02.</p>
                    <span>Enter into a contract</span>
                </li>
                <li>
                    <p>03.</p>
                    <span>Approve and initiate a projec</span>
                </li>
                <li>
                    <p>04.</p>
                    <span>Review</span>
                </li>
                <li>
                    <p>05.</p>
                    <span>Filing</span>
                </li>
                <li>
                    <p>06.</p>
                    <span>Approval tracking</span>

                </li>
                <li>
                    <p>07.</p>
                    <span>Approval of evidence collection</span>
                </li>
            </ul>
        </div>
        <div class="personal">
            <div style="display: flex;justify-content: center;"><button @click="infoShow = true">Click to
                    consult</button></div>
            <p><img src="../../assets/image/left.png">&nbsp;Senior Specialist&nbsp;<img
                    src="../../assets/image/right.png"></p>
            <ul>
                <li v-for="item in personalList" :key="item.personnelId">
                    <img :src="imgurl + item.personnelImg" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">{{ item.personnelName }}</span>
                        <span class="personalC">{{ item.personnelPosition }}</span>
                        <span class="personalRemark">
                            <div style="display: flex;" v-for="(children, index) in item.remark" :key="index">
                                <div
                                    style="margin-top: 4px;width: 4px;height: 4px;border-radius: 50%;border: 2px solid #fff;background-color: #EA5514;">
                                </div>
                                <div style="margin-left: 8px;width: 240px;">{{ children }}</div>
                            </div>
                        </span>
                    </p>
                </li>
            </ul>
        </div>
        <div class="advantages">
            <p><img src="../../assets/image/left.png">&nbsp;Advantage of service&nbsp;<img
                    src="../../assets/image/right.png"></p>
            <div>
                <img src="../../assets/image/zizhi/heshou.png">
                <ul>
                    <li>
                        <div>01</div>
                        <p>Answer questions accurately and solve
                            problems</p>
                    </li>
                    <li>
                        <div>02</div>
                        <p>Creative service</p>
                    </li>
                    <li>
                        <div>03</div>
                        <p>Process standard, price transparent</p>
                    </li>
                    <li>
                        <div>04</div>
                        <p>Have independent research and development
                            data platform</p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="orange">
            Strict and standardized qualification new process · High pass rate
            <span>Qualification transfer · No legal risk of credit and debt</span>
        </div>
        <div class="success">
            <p><img src="../../assets/image/left.png">&nbsp;Successful case&nbsp;<img
                    src="../../assets/image/right.png"></p>
            <div>
                <ul>
                    <li v-for="item in caseList1" :key="item.caseId" @mouseenter="changeContent(item)"
                        @mouseleave="recoveryContent(item)">
                        <img :src="imgurl + item.caseImg">
                        <div>
                            <p>Project:{{ item.caseTitle }}</p>
                            <button>Time:{{ item.caseHs }}</button>
                            <span v-html="item.caseTitle2"></span>
                            <div class="gengduo"></div>
                        </div>
                    </li>
                </ul>
                <ul>
                    <li v-for="item in caseList2" :key="item.caseId" @mouseenter="changeContent(item)"
                        @mouseleave="recoveryContent(item)">
                        <img :src="imgurl + item.caseImg">
                        <div>
                            <p>Project:{{ item.caseTitle }}</p>
                            <button>Time:{{ item.caseHs }}</button>
                            <span v-html="item.caseTitle2"></span>
                            <div class="gengduo"></div>
                        </div>
                    </li>
                </ul>
            </div>

        </div>
        <div class="offer">
            <p><img src="../../assets/image/left.png">&nbsp;We also provide these services&nbsp;<img
                    src="../../assets/image/right.png"></p>
            <ul>
                <li>
                    <p>
                        <img src="../../assets/image/kuaiji/caishui.png">
                        <span>Finance and taxation
                            service</span>
                    </p>
                    <button @click="gotoKuaiji">for more information</button>
                </li>
                <li>
                    <p>
                        <img src="../../assets/image/kuaiji/qianbao.png">
                        <span>Financial service</span>
                    </p>
                    <button @click="gotoQidai">for more information</button>
                </li>
                <li>
                    <p>
                        <img src="../../assets/image/kuaiji/haiwai.png">
                        <span>Overseas business</span>
                    </p>
                    <button @click="gotoHaiwai">for more information</button>
                </li>
                <li>
                    <p>
                        <img src="../../assets/image/kuaiji/zhishi.png">
                        <span>Intellectual property</span>
                    </p>
                    <button @click="gotoZonghe">for more information</button>
                </li>
            </ul>
        </div>

        <footer-view></footer-view>
        <side-bar class="fixed"></side-bar>
        <el-dialog title="Please fill in your personal information" :visible.sync="infoShow" width="40%" center>
            <span style="font-size: 12px;color: #A5A5A5;text-align: center;display: block;margin-top: -30px;">We will
                contact you within 30 minutes</span>

            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
                <el-form-item label="Name:" prop="name">
                    <el-input v-model="ruleForm.name" placeholder="Please enter your name"></el-input>
                </el-form-item>
                <el-form-item label="Phone Number:" prop="tel">
                    <el-input v-model="ruleForm.tel" placeholder="Please enter your phone number"></el-input>
                </el-form-item>
                <el-form-item label="Leave a message:" prop="content">
                    <el-input v-model="ruleForm.content" type="textarea" placeholder="Please enter the question you want to consult
 (no more than 300 words)" class="inputC"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm('ruleForm')">Table shortly</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import topView from '@/components/en/topView.vue'
import footerView from '@/components/en/footerView.vue'
import SideBar from '@/components/en/sideBar.vue'
import axios from 'axios';

export default {
    components: { footerView, SideBar, topView },
    data() {
        return {
            infoShow: false,
            personalList: [],
            params: { pageSize: 8, pageNum: 1, personnelType: "1", language: '1' },
            // imgurl:'http://192.168.0.199:8087/api',
            imgurl: '',
            caseList1: [],
            caseList2: [],
            ruleForm: {
                name: '',
                tel: '',
                content: ''
            },
            rules: {
                name: [
                    { required: true, message: '请输入您的姓名', trigger: 'blur' },
                    // { min: 7, max: 11, message: '请输入正确的手机号码', trigger: 'blur' }
                ],
                tel: [
                    { required: true, message: '请输入您的电话', trigger: 'blur' },
                    { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号码', trigger: 'blur' }
                ],
                content: [
                    // { required: true, message: '请输入您的电话', trigger: 'blur' },
                    { min: 0, max: 300, message: '最多输入300字', trigger: 'blur' }
                ]

            },
            text: '',
        }
    },
    created() {
        this.getPersonalList()
        this.getcaseList1()
        this.getcaseList2()
        this.website()
        this.imgurl = this.$store.state.imgurl
        // this.$store.state.componentKey += 1
    },
    methods: {
        //获取人物列表
        getPersonalList() {
            // console.log("eeeeeeeeeee");
            axios.get('/api/pcsystem/TckgPersonnel/list', { params: this.params }).then((res) => {
                if (res.status == 200) {
                    this.personalList = res.data.rows
                    for (let i = 0; i < this.personalList.length; i++) {
                        this.personalList[i].remark = res.data.rows[i].remark.split('。')
                    }
                    // console.log(this.personalList);
                }

            }).catch()
        },
        getcaseList1() {
            axios.get('/api/pcsystem/TckgCase/list', { params: { pageNum: 1, pageSize: 2, caseType: '1', language: '1' } }).then((res) => {
                if (res.status == 200) {
                    this.caseList1 = res.data.rows
                    // console.log(this.caseList1);
                }
            }).catch()
        },
        getcaseList2() {
            axios.get('/api/pcsystem/TckgCase/list', { params: { pageNum: 2, pageSize: 2, caseType: '1', language: '1' } }).then((res) => {
                if (res.status == 200) {
                    this.caseList2 = res.data.rows
                    // console.log(this.caseList2);
                }
            }).catch()
        },
        gotoKuaiji() {
            this.$router.push({ path: '/kuaijiE', query: { key: '2' } })
        },
        gotoQidai() {
            this.$router.push({ path: '/qidaiE', query: { key: '2' } })
        },
        gotoZonghe() {
            this.$router.push({ path: '/zongheE', query: { key: '2' } })
        },
        gotoHaiwai() {
            this.$router.push({ path: '/haiwaiE', query: { key: '3' } })
        },
        //网站访问信息
        website() {
            axios.post('/api/pcsystem/TckgSoftLogin', { userType: '资质事业群' }).then((res) => {
                console.log(res);
            }).catch()
        },
        //提交信息
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // alert('submit!');
                    this.infoShow = false
                    console.log(this.ruleForm);
                    axios.post('/api/pcsystem/TckgConsult', { params: this.ruleForm }).then((res) => {
                        // console.log(res);
                        if (res.status == 200) {
                            // console.log(111111);
                            this.$message({
                                message: '提交成功，请耐心等待',
                                type: 'success'
                            });
                        }
                    }).catch()
                } else {
                    // this.$message({
                    //     message: '提交失败，请稍后重试',
                    //     type: 'warning'
                    // });
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        changeContent(item) {
            this.text = item.caseTitle2
            item.caseTitle2 = item.caseContent
            // console.log(item);
        },
        recoveryContent(item) {
            console.log(this.text);
            item.caseTitle2 = this.text
            // console.log(item);
        }
    }

}
</script>

<style lang="scss" scoped>
.fixed {
    position: fixed;
    top: 150px;
    right: 5px;
}

.backTop {
    position: relative;
    width: 100%;
    height: 26vw;
    min-width: 1200px;
    min-height: 310px;
    background-image: url("../../assets/image/zizhiEBack.png");
    background-size: 100% 100%;

}

.kuaijiQ {
    margin: 60px auto;
    display: flex;
    justify-content: space-around;
    width: 1200px;

    img {
        width: 45%;
        border-radius: 0 !important;
    }

    div {
        width: 50%;
        margin-right: 20px;

        >p {
            margin-bottom: 10px;
            font-size: 22px;
            font-weight: 600;
        }

        .kuaijiQC {
            margin: 20px 0;
            width: 90%;
            font-size: 16px;
            font-weight: 400;
            line-height: 25px;
        }

    }

}

.canIdo {
    padding: 50px 0 20px;
    min-width: 1200px;
    padding-top: 60px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    >div {
        margin: 50px auto;
        display: flex;
        justify-content: space-around;
        width: 1200px;

        img {
            width: 360px;
            height: 360px;
        }

        ul {
            margin-top: 100px;

            li {
                margin-bottom: 60px;
                width: 280px;

                p {
                    margin: 0 0 10px;
                    padding: 5px 10px;
                    width: 252px;
                    color: #fff;
                    text-align: center;
                    border-radius: 0px 20px 0px 20px;
                    background-color: #EA5514;
                    font-size: 22px;
                    font-weight: 500;
                }

                span {
                    font-size: 16px;
                    font-weight: 400;
                    color: #767676;
                }
            }

            &:first-child {
                li {
                    &:last-child {
                        width: 340px;
                    }
                }
            }

            &:last-child {
                text-align: end;

                li {
                    p {
                        margin-left: 10px;
                    }
                }
            }
        }
    }

}

.service {
    padding: 0px 0 50px;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    ul {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin: 50px auto 10px;
        width: 1160px;

        li {
            position: relative;
            margin-bottom: 1%;
            padding: 0 20px 30px;
            display: flex;
            flex-direction: column;
            // width: 29%;
            width: 340px;
            height: 168px;
            background-color: #fff;
            border-radius: 6px;
            cursor: pointer;

            >div {
                margin: 30px 0 10px;
                width: 50px;
                height: 50px;
                background-image: url('../../assets/image/zizhi/chengbaoO.png');
                background-size: 100% 100%;
            }

            &:nth-child(2) {
                >div {
                    background-image: url('../../assets/image/zizhi/shigongO.png');
                }

                &:hover {
                    >div {
                        background-image: url('../../assets/image/zizhi/shigongH.png');
                    }
                }
            }

            &:nth-child(3) {
                >div {
                    background-image: url('../../assets/image/zizhi/maoziO.png');
                }

                &:hover {
                    >div {
                        background-image: url('../../assets/image/zizhi/maoziH.png');
                    }
                }
            }

            &:nth-child(4) {
                >div {
                    background-image: url('../../assets/image/zizhi/anquanO.png');
                }

                &:hover {
                    >div {
                        background-image: url('../../assets/image/zizhi/anquanH.png');
                    }
                }
            }

            &:nth-child(5) {
                >div {
                    margin: 20px 0 10px;
                    background-image: url('../../assets/image/zizhi/chengzhuangO.png');
                }

                &:hover {
                    >div {
                        background-image: url('../../assets/image/zizhi/chengzhuangH.png');
                    }
                }

                p {
                    margin-bottom: 10px;
                }
            }

            &:nth-child(6) {
                >div {
                    margin: 20px 0 10px;
                    background-image: url('../../assets/image/zizhi/fangziO.png');
                }

                &:hover {
                    >div {
                        background-image: url('../../assets/image/zizhi/fangziH.png');
                    }
                }

                p {
                    margin-bottom: 10px;
                }
            }

            p {
                margin: 10px 0;
                font-size: 20px;
                font-weight: 500;
            }

            span {
                font-size: 14px;
                color: #B1B1B1;
            }

            &:hover {
                background-color: #EA5514;
                color: #fff;

                >div {
                    background-image: url('../../assets/image/zizhi/chengbaoH.png');
                }

                span {
                    color: #fff;
                }
            }

            .qizhi {
                position: absolute;
                top: -10px;
                right: 40px;
                margin: 10px 0;
                width: 35px;
                height: 50px;
                color: #F2956C;
                text-align: center;
                line-height: 40px;
                background-image: url('../../assets/image/zizhi/qizhi.png');
                background-size: 100% 100%;
            }
        }
    }

}

.personal {
    padding: 30px 0;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    ul {
        margin: 40px auto 0;
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
        width: 1200px;

        li {
            position: relative;
            margin-top: 1vh;
            margin-left: 10px;
            width: 24%;
            height: 340px;
            background-size: 100% 100%;
            // border-radius: 0;
            border-radius: 6px;
            overflow: hidden;

            >p {
                margin: 0;
                position: absolute;
                bottom: 0;
                padding: 10px 13px 5px;
                width: 91%;
                min-height: 75px;
                background-color: rgba(0, 0, 0, 0.5);
                text-align: start;
                color: #fff;
            }

            .personalT {
                display: block;
                font-size: 18px;
                font-weight: 500;
            }

            .personalC {
                font-size: 13px;
                font-weight: 500;
            }

            .personalRemark {
                margin-top: 5px;
                display: none;
                padding: 5px 10px;
                background-color: #EA5514;
                border-radius: 3px;
                font-size: 9px;
                line-height: 15px;
            }

            &:hover {
                .personalRemark {
                    display: block;
                }
            }
        }
    }

    button {
        margin: 50px 0 50px;
        width: 252px;
        height: 56px;
        padding: 5px 15px;
        font-size: 26px;
        font-weight: 500;
        color: #fff;
        background: linear-gradient(180deg, #FFB552 0%, #FF4D00 100%);
        border: 0;
        border-radius: 30px;
        text-align: center;
    }
}

.flow {
    padding: 50px 0;
    min-width: 1200px;
    background-image: url("../../assets/image/kuaiji/6458486361c124a42770f394d12bef7d526a503611913d-0S20U5_fw1200.png");

    >p {
        margin-bottom: 50px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    >ul {
        margin: 140px auto 20px;
        // padding: 0 0 20px;
        display: flex;
        width: 1200px;
        height: 200px;
        background-image: url("../../assets/image/zizhi/line.png");
        background-size: 100% 50%;
        background-repeat: no-repeat;

        li {
            width: 181px;
            height: 100px;
            color: #fff;

            &:nth-child(odd) {
                margin-top: 100px;
                margin-left: 50px;
                background-image: url('../../assets/image/zizhi/duihuaKB.png');
                background-size: 100% 100%;

                p {
                    margin-top: 25px;
                    margin-left: 15px;
                    font-size: 20px;
                    text-align: start;
                }

                span {
                    display: block;
                    margin: 0px 0px 7px 15px;
                    width: 150px;
                    font-size: 16px;
                }
            }

            &:nth-child(even) {
                margin-top: -100px;
                background-image: url('../../assets/image/zizhi/duihuaKT.png');
                background-size: 100% 100%;

                p {
                    margin-top: 10px;
                    margin-left: 15px;
                    font-size: 20px;
                    text-align: start;
                }

                span {
                    display: block;
                    margin: 0px 15px;
                    width: 150px;
                    font-size: 16px;
                }
            }

            &:nth-child(2) {
                margin-left: 5px;
            }

            &:nth-child(3) {
                margin-left: -5px;
            }

            &:nth-child(4) {
                margin-left: 5px;
            }

            &:nth-child(5) {
                margin-left: 0px;
            }

            &:nth-child(6) {
                margin-left: 25px;
            }

            &:nth-child(7) {
                margin-left: 15px;
            }
        }
    }

}

.advantages {
    padding: 50px 0;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    >div {
        display: flex;
        margin: 40px auto;
        width: 1200px;

        img {
            width: 50%;
        }

        ul {
            margin-top: 20px;
            margin-left: 50px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 50%;
            height: 340px;

            li {
                display: flex;
                align-items: center;
                margin-top: 20px;
                padding: 8px 15px;
                width: 90%;
                height: 56px;
                background-color: #fff;
                border-radius: 6px;

                div {
                    width: 30px;
                    height: 30px;
                    background-image: url('../../assets/image/kuaiji/quan.png');
                    background-size: 100% 100%;
                    text-align: center;
                    line-height: 30px;
                    color: #fff;
                    font-weight: 600;
                }

                p {
                    margin-left: 20px;
                    width: 400px;
                    line-height: 20px;
                    font-size: 20px;
                }

                &:hover {
                    margin-left: -5%;
                    width: 95%;
                    background-color: #EA5514;
                    color: #fff;

                    div {
                        background-image: url('../../assets/image/kuaiji/quanH.png');
                        color: #EA5514;
                    }
                }
            }
        }
    }

    button {
        margin: 30px 0;
        margin-left: 47%;
        // width: 202px;
        // height: 56px;
        padding: 5px 15px;
        font-size: 20px;
        font-weight: 500;
        color: #fff;
        // background-color: #FE8F3F;
        background: linear-gradient(180deg, #FFB552 0%, #FF4D00 100%);
        border: 0;
        border-radius: 30px;
    }
}

.success {
    padding: 60px 0 50px;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    >div {
        display: flex;
        justify-content: space-around;
        margin: 50px auto 0;
        width: 1160px;

        >ul {
            width: 49%;
            height: 500px;
            overflow: hidden;

            >li {
                display: flex;
                margin-bottom: 20px;
                padding: 20px 15px;
                width: 94%;
                // height: 40%;
                background-color: #fff;
                border-radius: 6px;
                overflow: hidden;

                >img {
                    width: 30%;
                    height: 200px;
                    // height: 20px;
                }

                >div {
                    position: relative;
                    width: 70%;
                    height: 200px;
                    margin-left: 10px;
                    // position: relative;
                    text-align: start;

                    // width: 500px;
                    p {
                        margin-top: 10px;
                        text-align: start;
                        font-size: 16px;
                        font-weight: 600;
                    }

                    span {
                        display: block;
                        margin-top: 10px;
                        font-size: 14px;
                        line-height: 25px;
                        color: #AFAFAF;
                        // display: -webkit-box;
                        // -webkit-box-orient: vertical;
                        // -webkit-line-clamp: 4;
                        // overflow: hidden;
                        // text-overflow: ellipsis;
                    }

                    button {
                        margin-top: 5px;
                        padding: 2px 5px;
                        border: 0;
                        border-radius: 2px;
                        background-color: #E6F2CC;
                        color: #A4C361;
                    }

                    .gengduo {
                        position: absolute;
                        right: 0px;
                        top: 10px;
                        width: 20px;
                        height: 4px;
                        background-image: url('../../assets/image/gengduo.png');
                        background-size: 100% 100%;
                    }
                }

                &:hover {
                    // position: absolute;
                    // position: relative;
                    // height: 455px;
                    height: 460px;
                    color: #fff;
                    background-color: #EA5513;

                    img {
                        width: 0;
                    }

                    div {
                        width: 95%;

                        span {
                            color: #fff;
                            display: block;
                        }

                        button {
                            color: #fff;
                            background-color: #EE824E;
                        }
                    }

                    .gengduo {
                        display: none;
                    }
                }

                &:last-child {
                    margin-bottom: 0;

                    &:hover {
                        position: relative;
                        // top: -262px;
                        top: -260px;
                    }
                }
            }
        }
    }


}

.offer {
    padding: 30px 0 50px;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    ul {
        display: flex;
        justify-content: space-around;
        margin: 50px auto;
        width: 1200px;

        li {
            width: 24%;
            background-color: #fff;
            border-radius: 6px;
            text-align: center;

            p {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: auto;
                padding: 40px 40px 20px;
                width: 40%;
                text-align: center;
                border-bottom: 1px dashed #E1E1E1;

                img {
                    // margin: auto;
                    width: 50px;
                    height: 50px;
                }

                span {
                    display: block;
                    margin-top: 10px;
                    width: 200px;
                    font-size: 18px;
                    font-weight: 500;
                }
            }

            button {
                margin: 20px 0;
                padding: 5px 15px;
                color: #fff;
                background-color: #EA5514;
                border-radius: 15px;
                border: 0;
            }

            &:nth-child(1) {
                img {
                    width: 75px;
                    height: 65px;
                }

                p {
                    padding-top: 32px;
                    padding-bottom: 0;

                    span {
                        margin-top: 0px;
                    }
                }
            }

            &:nth-child(3) {
                img {
                    width: 60px;
                    height: 60px;
                }

                p {
                    padding-top: 35px;

                    span {
                        margin-top: 5px;
                    }
                }
            }
        }
    }
}

.orange {
    padding: 50px 0;
    width: 100%;
    min-width: 1200px;
    // height: 140px;
    background-image: url("../../assets/image/橙色背景.png");
    background-size: 100% 100%;
    text-align: center;
    color: #fff;
    font-size: 40px;

    span {
        display: block;
        margin-top: 20px;
        font-size: 22px;
    }
}

.info {
    margin: auto;
    position: relative;
    width: 350px;
    border-radius: 6px;
    text-align: center;
    overflow: hidden;
    background-color: #fff;

    p {
        padding: 20px 40px 40px;
        font-size: 20px;
        font-weight: 600;
        color: #fff;
        background-color: #EA5513;
    }

    .name {
        position: absolute;
        top: 60px;
        left: 140px;
        width: 50px;
        height: 50px;
    }

    .cheng {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 25px;
        height: 25px;

    }

    >img {
        &:last-child {
            margin: 40px 0 20px;
            width: 70%;
        }

    }
}

::v-deep .el-dialog--center .el-dialog__body {
    padding-bottom: 0;
}

::v-deep .el-form {
    margin-top: 30px;
    margin-left: -20px;
}

::v-deep .el-textarea__inner {
    height: 150px !important;
}
</style>