<template>
    <div>
        <top-view></top-view>
        <div class="backTop">
        </div>
        <div class="map">
            <p><img src="../../assets/image/left.png">&nbsp;Global layout&nbsp;<img src="../../assets/image/right.png"></p>
            <div></div>
            <ul>
                <li v-for="item in addressList" :key="item.addressId" @mouseenter="enter(item.addressCode)"
                    @mouseleave="out()">
                    <p>{{ item.addressZname }}</p>
                    <p>{{ item.addressYname }}</p>
                </li>
                <!-- <li>
                    <p>香港</p>
                    <p>Hong Kong</p>
                </li>
                <li>
                    <p>香港</p>
                    <p>Hong Kong</p>
                </li>
                <li>
                    <p>香港</p>
                    <p>Hong Kong</p>
                </li>
                <li>
                    <p>香港</p>
                    <p>Hong Kong</p>
                </li>
                <li>
                    <p>香港</p>
                    <p>Hong Kong</p>
                </li>
                <li>
                    <p>香港</p>
                    <p>Hong Kong</p>
                </li>
                <li>
                    <p>香港</p>
                    <p>Hong Kong</p>
                </li>

                <li>
                    <p>香港</p>
                    <p>Hong Kong</p>
                </li>
                <li>
                    <p>香港</p>
                    <p>Hong Kong</p>
                </li>
                <li>
                    <p>香港</p>
                    <p>Hong Kong</p>
                </li>
                <li>
                    <p>香港</p>
                    <p>Hong Kong</p>
                </li>
                <li>
                    <p>香港</p>
                    <p>Hong Kong</p>
                </li>
                <li>
                    <p>香港</p>
                    <p>Hong Kong</p>
                </li>
                <li>
                    <p>香港</p>
                    <p>Hong Kong</p>
                </li>
                <li>
                    <p>香港</p>
                    <p>Hong Kong</p>
                </li>
                <li>
                    <p>香港</p>
                    <p>Hong Kong</p>
                </li>
                <li>
                    <p>香港</p>
                    <p>Hong Kong</p>
                </li>
                <li>
                    <p>香港</p>
                    <p>Hong Kong</p>
                </li>
                <li>
                    <p>香港</p>
                    <p>Hong Kong</p>
                </li>
                <li>
                    <p>香港</p>
                    <p>Hong Kong</p>
                </li>
                <li>
                    <p>香港</p>
                    <p>Hong Kong</p>
                </li>
                <li>
                    <p>香港</p>
                    <p>Hong Kong</p>
                </li>
                <li>
                    <p>香港</p>
                    <p>Hong Kong</p>
                </li>
                <li>
                    <p>香港</p>
                    <p>Hong Kong</p>
                </li>
                <li>
                    <p>香港</p>
                    <p>Hong Kong</p>
                </li>
                <li>
                    <p>香港</p>
                    <p>Hong Kong</p>
                </li>
                <li>
                    <p>香港</p>
                    <p>Hong Kong</p>
                </li>
                <li>
                    <p>香港</p>
                    <p>Hong Kong</p>
                </li>
                <li>
                    <p>香港</p>
                    <p>Hong Kong</p>
                </li> -->
            </ul>
        </div>
        <div style="background-color: #F4F4F4;padding: 30px 0;min-width: 1200px;">
            <ul class="personal">
                <li v-for="item in personalList" :key="item.staffId" :class="item.color ? 'box' : ''">
                    <div class="img">
                        <img :src="imgurl+item.staffImg" alt="">
                    </div>
                    <div class="content">
                        <p>{{ item.staffName }}<span>&nbsp;/ {{ item.staffCompany }}</span></p>
                        <div class="line"></div>
                        <div>
                            <img src="../../assets/image/bujv/tel.png" alt="">
                            <span>{{ item.staffPhone }}</span>
                        </div>
                        <div>
                            <img src="../../assets/image/bujv/weizhi.png" alt="">
                            <span>{{ item.staffAddress }}</span>
                        </div>
                        <div class="zhiC" v-html="item.staffPost"></div>
                    </div>
                    <div class="sanj"></div>
                </li>
            </ul>
        </div>

        <footer-view></footer-view>
        <side-bar class="fixed"></side-bar>

    </div>
</template>

<script>
import topView from '@/components/en/topView.vue'
import footerView from '@/components/en/footerView.vue'
import SideBar from '@/components/en/sideBar.vue'
import axios from 'axios';

export default {
    components: { footerView, SideBar, topView },
    data() {
        return {
            infoShow: false,
            addressList: [],
            personalList: [],
            // imgurl: 'http://192.168.0.199:8087/api',
            imgurl: ''
        }
    },
    created() {
        this.getaddressList()
        this.getpresonalList()
        this.website()
        this.imgurl = this.$store.state.imgurl
        // this.$store.state.componentKey += 1
    },
    methods: {
        gotoAboutus() {
            this.$router.push('/aboutus')
        },
        getaddressList() {
            axios.get('/api/pcsystem/TckgAddress/list',{params:{language:'1'}}).then((res) => {
                if (res.status == 200) {
                    this.addressList = res.data.rows
                    // console.log(this.addressList);
                }

            }).catch()
        },
        getpresonalList() {
            axios.get('/api/pcsystem/TckgStaff/list',{params:{language:'1'}}).then((res) => {
                if (res.status == 200) {
                    this.personalList = res.data.rows
                    console.log(this.personalList);
                }

            }).catch()
        },
        //网站访问信息
        website() {
            axios.post('/api/pcsystem/TckgSoftLogin', { userType: '全球布局' }).then((res) => {
                console.log(res);
            }).catch()
        },
        enter(id) {
            for (let i = 0; i < this.personalList.length; i++) {
                if (this.personalList[i].staffCode == id) {
                    this.personalList[i]['color'] = 1
                    console.log(this.personalList[i]);
                }
                // else {
                //     this.personalList[i]['color'] = 0
                // }
            }
            this.$forceUpdate()
        },
        out() {
            for (let i = 0; i < this.personalList.length; i++) {
                this.personalList[i]['color'] = 0
                // console.log(this.personalList[i]);
            }
            this.$forceUpdate()
        }
    }

}
</script>

<style lang="scss" scoped>
.fixed {
    position: fixed;
    top: 150px;
    right: 5px;
}

.backTop {
    position: relative;
    width: 100%;
    height: 26vw;
    min-width: 1200px;
    min-height: 310px;
    background-image: url("../../assets/image/quanqiubujvEBack.png");
    background-size: 100% 100%;
}

.map {
    padding: 60px 0 50px;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    div {
        margin: 30px auto;
        width: 1200px;
        height: 549px;
        background-image: url('../../assets/image/地图.png');
        background-size: 100% 100%;
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        margin: 30px auto;
        width: 1200px;

        li {
            // display: flex;
            // justify-content: center;
            // align-items: center;
            // padding: 8px 0;
            // width: 12%;
            width: 148px;
            // width: 140px;
            height: 86px;
            // line-height: 43px;
            border: 1px dashed #EB5615;
            background-color: #FFF5EF;
            color: #EB5615;
            text-align: center;

            p {
                font-family: PingFangSC, PingFang SC;
                margin-top: 17px;
                font-size: 20px;
                font-weight: 500;
                line-height: 27px;

                &:last-child {
                    margin-top: 0px;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 25px;
                }
            }


            &:hover {
                color: #fff;
                background-color: #EB5615;
            }
        }
    }
}

.personal {
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 1200px;

    li {
        margin-top: 53px;
        position: relative;
        padding: 10px 0 5px;
        width: 47%;
        background-color: #fff;
        border-radius: 6px;

        .img {
            position: absolute;
            top: -25px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 153px;
            height: 153px;
            // background-color: red;
            background-image: url('../../assets/image/bujv/backO.png');
            background-size: 100% 100%;
            img{
                border-radius: 50%;
            }
        }

        .content {
            margin-left: 166px;
            width: 70%;
            font-size: 16px;
            p {
                margin: 20px 0 10px;
                font-size: 24px;
                font-weight: 500;
                color: #EA5512;

                span {
                    font-size: 13px;
                    color: #818181;
                }

            }

            .line {
                margin: 0px 0 10px;
                width: 75%;
                height: 2px;
                background-color: #FCE6DC;
            }

            div {
                margin: 10px 0 10px;
                display: flex;
                align-items: center;
                color: #818181;

                span {
                    margin-left: 10px;
                    width: 350px;
                    font-size: 14px;
                }
            }

            .zhiC {
                position: absolute;
                padding: 5px 15px;
                right: 0px;
                top: -10px;
                color: #fff;
                background-color: #EB5615;
                border-top-right-radius: 6px;
                border-bottom-left-radius: 10px;
                font-size: 11px;
                line-height: 12px;
                text-align: center;
            }
        }

        .sanj {
            position: absolute;
            right: 0;
            bottom: 0;
            // width: 50px;
            // height: 50px;
            border: 25px solid #f4f4f4;
            border-top: 25px solid transparent;
            border-left: 25px solid transparent;
            // background-color: #F4F4F4;
        }

        &:nth-child(odd) {
            margin-right: 6%;
        }
    }
}

.box {
    box-shadow: -10px -5px 15px 0 rgba( 0,0,0,0.1);
}
</style>