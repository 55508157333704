import Vue from 'vue'
import VueRouter from 'vue-router'
import indexView from '../views/zh/indexView.vue'
import KuaijiView from '@/views/zh/kuaijiView.vue';
import ZizhiView from '@/views/zh/zizhiView.vue';
import QidaiView from '@/views/zh/qidaiView.vue';
import ZongheView from '@/views/zh/zongheView.vue';
import HaiwaiView from '@/views/zh/haiwaiView.vue';
import ShangyeView from '@/views/zh/shangyeView.vue';
import ZixunView from '@/views/zh/zixunView.vue';
import AboutusView from '@/views/zh/aboutusView.vue';
import DongshizhangView from '@/views/zh/dongshizhangView.vue';
import QiyewenhuaView from '@/views/zh/qiyewenhuaView.vue';
import JituangouchengView from '@/views/zh/jituangouchengView.vue';
import FazhanlichengView from '@/views/zh/fazhanlichengView.vue';
import QuanqiubujvView from '@/views/zh/quanqiubujvView.vue';
import XiaoqiView from '@/views/zh/xiaoqiView.vue';
import ShangxiehuiView from '@/views/zh/shangxiehuiView.vue';
import GongyiView from '@/views/zh/gongyiView.vue';
import ContentView from '@/views/zh/contentView.vue';
import BeiJing from '@/views/zh/BeiJing.vue';
import indexEView from '../views/en/indexEView.vue'
import KuaijiEView from '@/views/en/kuaijiEView.vue';
import ZizhiEView from '@/views/en/zizhiEView.vue';
import QidaiEView from '@/views/en/qidaiEView.vue';
import ZongheEView from '@/views/en/zongheEView.vue';
import HaiwaiEView from '@/views/en/haiwaiEView.vue';
import ShangyeEView from '@/views/en/shangyeEView.vue';
import ZixunEView from '@/views/en/zixunEView.vue';
import AboutusEView from '@/views/en/aboutusEView.vue';
import DongshizhangEView from '@/views/en/dongshizhangEView.vue';
import QiyewenhuaEView from '@/views/en/qiyewenhuaEView.vue';
import JituangouchengEView from '@/views/en/jituangouchengEView.vue';
import FazhanlichengEView from '@/views/en/fazhanlichengEView.vue';
import QuanqiubujvEView from '@/views/en/quanqiubujvEView.vue';
import XiaoqiEView from '@/views/en/xiaoqiEView.vue';
import ShangxiehuiEView from '@/views/en/shangxiehuiEView.vue';
import GongyiEView from '@/views/en/gongyiEView.vue';
import ContentEView from '@/views/en/contentEView.vue';

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: '/',
    component: indexView
  }, 
  {
    path: '/kuaiji',
    name: '/',
    component: KuaijiView
  }, 
  {
    path: '/zizhi',
    name: '/',
    component: ZizhiView
  }, 
  {
    path: '/qidai',
    name: '/',
    component: QidaiView
  }, 
  {
    path: '/zonghe',
    name: '/',
    component: ZongheView
  },
  {
    path: '/haiwai',
    name: '/',
    component: HaiwaiView
  },  
  {
    path: '/shangye',
    name: '/',
    component: ShangyeView
  }, 
  {
    path: '/zixun',
    name: '/',
    component: ZixunView
  }, 
  {
    path: '/aboutus',
    name: '/',
    component: AboutusView
  }, 
  {
    path: '/dongshizhang',
    name: '/',
    component: DongshizhangView
  }, 
  {
    path: '/qiyewenhua',
    name: '/',
    component: QiyewenhuaView
  }, 
  {
    path: '/jituangoucheng',
    name: '/',
    component: JituangouchengView
  }, 
  {
    path: '/fazhanlicheng',
    name: '/',
    component: FazhanlichengView
  }, 
  {
    path: '/quanqiubujv',
    name: '/',
    component: QuanqiubujvView
  }, 
  {
    path: '/xiaoqi',
    name: '/',
    component: XiaoqiView
  },
  {
    path: '/shangxiehui',
    name: '/',
    component: ShangxiehuiView
  },
  {
    path: '/gongyi',
    name: '/',
    component: GongyiView
  },
  {
    path: '/content',
    name: '/',
    component: ContentView
  },
  {
    path: '/BeiJing',
    name: '/',
    component: BeiJing
  },
  {
    path: '/indexE',
    name: '/',
    component: indexEView
  }, 
  {
    path: '/kuaijiE',
    name: '/',
    component: KuaijiEView
  }, 
  {
    path: '/zizhiE',
    name: '/',
    component: ZizhiEView
  }, 
  {
    path: '/qidaiE',
    name: '/',
    component: QidaiEView
  }, 
  {
    path: '/zongheE',
    name: '/',
    component: ZongheEView
  },
  {
    path: '/haiwaiE',
    name: '/',
    component: HaiwaiEView
  },  
  {
    path: '/shangyeE',
    name: '/',
    component: ShangyeEView
  }, 
  {
    path: '/zixunE',
    name: '/',
    component: ZixunEView
  }, 
  {
    path: '/aboutusE',
    name: '/',
    component: AboutusEView
  }, 
  {
    path: '/dongshizhangE',
    name: '/',
    component: DongshizhangEView
  }, 
  {
    path: '/qiyewenhuaE',
    name: '/',
    component: QiyewenhuaEView
  }, 
  {
    path: '/jituangouchengE',
    name: '/',
    component: JituangouchengEView
  }, 
  {
    path: '/fazhanlichengE',
    name: '/',
    component: FazhanlichengEView
  }, 
  {
    path: '/quanqiubujvE',
    name: '/',
    component: QuanqiubujvEView
  }, 
  {
    path: '/xiaoqiE',
    name: '/',
    component: XiaoqiEView
  },
  {
    path: '/shangxiehuiE',
    name: '/',
    component: ShangxiehuiEView
  },
  {
    path: '/gongyiE',
    name: '/',
    component: GongyiEView
  },
  {
    path: '/contentE',
    name: '/',
    component: ContentEView
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
