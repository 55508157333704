<template>
    <div>
        <top-view></top-view>
        <div class="backTop">
        </div>
        <div class="jiyu">
            <p><img src="../../assets/image/left.png">&nbsp;Culture shaping brand&nbsp;<img
                    src="../../assets/image/right.png"></p>
            <div>
                <p>Karma and gather for a dream peer</p>
                <span>An excellent team is destined to be a beautiful scenery <br>
                    We were brought together to dream together <br>
                    So that the good heart condenses the centripetal force <br>
                    Create excellent quality with innovative wisdom <br>
                    Open up the future of the industry with the hope of perfection <br>
                    Always have a high ambition and a sincere heart <br>
                    Go forward without fear of challenge <br>
                    This is a journey that belongs to every Tangcheng member</span>
            </div>
        </div>
        <div class="yuanjing">
            <ul>
                <li>
                    <img src="../../assets/image/qiyewenhua/content1.png">
                    <div>
                        <p>Vision</p>
                        <span style="color: #4C4C4C;font-size: 22px;">Hand in hand with 100 million enterprises brilliant · Create a hundred 
                            years of Tangcheng</span>
                        <p class="littleT">【The Times are great, we bow into the game 】</p>
                        <span
                            style="margin-left: 6px;">The founding team of Tangcheng is young and full of vigor, innovative and brave to meet challenges. With sincere ideals, rich operational experience, vows to serve "100 million" enterprises, with a lifetime to deeply cultivate the soil of the industry. Practical practice of the concept of the heart, with the initial heart, do forever, and strive to build "Tangcheng" this industry century brand. Good vision, the line is coming.</span>
                    </div>
                </li>
                <li>
                    <img src="../../assets/image/qiyewenhua/content2.png">
                    <div>
                        <p>Mission</p>
                        <span style="color: #4C4C4C;font-size: 22px;">Customers are excellent because of Tang Cheng. Employees are happy
                            because of Tang Cheng</span>
                        <p class="littleT">【Choose a thing loyal to life, ten thousand hammer into a weapon 】</p>
                        <span
                            style="margin-left: 6px;">Customer focus is the cornerstone for Tangcheng to build high-quality development of the enterprise service industry, and we have been committed to meeting the diversified needs of customers. From the domestic layout of ten cities, to overseas multinational outlets opened; From the construction of systematic and digital service system to the research and development of Al intelligent terminal, Tangcheng has always taken the mission of providing customers with efficient, fast and comprehensive service.
                            To achieve customer excellence is inseparable from excellent Tangcheng people, Tangcheng wrote "Happy Tangcheng" into the company's constitution at the early stage of construction, took the protection of employees' rights and interests and the improvement of the talent system as a strategic plan for the development of the group, created employees "growth fund", "equity fund" and "study tour fund", and paid attention to the personal growth of employees while improving their income.</span>
                    </div>
                </li>
                <li>
                    <img src="../../assets/image/qiyewenhua/content3.png">
                    <div>
                        <p>Value</p>
                        <span style="color: #4C4C4C;font-size: 22px;">Honest and trustworthy, passion and dedication, embrace change, create
                            and prosper together</span>
                        <p class="littleT">【Do what is true with a sincere heart】</p>
                        <span
                            style="margin-left: 6px;">Tangcheng was born in Shanxi, and the founder came from the hometown of the soul of Jin merchants. The strong culture of honesty of Jin merchants deeply affects every Tangcheng people. In the past three years, Tang Cheng people's footprints all over the country, strategic cooperation with more than 30 national counterparts. In the process of cross-regional and cross-business integration, Tangcheng people can always adjust the pace in time, break through the cognitive boundary, and achieve mutual benefit. So far, Tangcheng has absorbed 27 high-quality talents to become strategic partners, and cultivated 13 professionals to become team leaders.</span>
                    </div>
                </li>
            </ul>
        </div>


        <footer-view></footer-view>
        <side-bar class="fixed"></side-bar>

    </div>
</template>

<script>
import topView from '@/components/en/topView.vue'
import footerView from '@/components/en/footerView.vue'
import SideBar from '@/components/en/sideBar.vue'
import axios from 'axios';

export default {
    components: { footerView, SideBar, topView },
    data() {
        return {
            infoShow: false,
        }
    },
    created() {
        // this.$store.state.componentKey += 1
        this.website()
    },
    methods: {
        gotoAboutus() {
            this.$router.push('/aboutus')
        },
        //网站访问信息
        website() {
            axios.post('/api/pcsystem/TckgSoftLogin', { userType: '企业文化' }).then((res) => {
                console.log(res);
            }).catch()
        },
    }

}
</script>

<style lang="scss" scoped>
.fixed {
    position: fixed;
    top: 150px;
    right: 5px;
}

.backTop {
    position: relative;
    width: 100%;
    height: 26vw;
    min-width: 1200px;
    min-height: 310px;
    background-image: url("../../assets/image/qiyewenhuaEBack.png");
    background-size: 100% 100%;
}

.jiyu {
    padding: 75px 0 30px;
    min-width: 1200px;
    background-image: url('../../assets/image/qiyewenhua/e744b11c2624cf0f57638b5ae2c482faab8ce1db5364a-YyRkJR_fw1200.png');
    background-size: 100% 100%;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    >div {
        margin: 20px;
        text-align: center;

        p {
            margin-top: 40px;
            margin-bottom: 20px;
            color: #EA5514;
            font-size: 30px;
            font-weight: 600;
        }

        span {
            line-height: 36px;
            font-size: 20px;
        }
    }


}

.yuanjing {
    padding: 20px 0;
    min-width: 1200px;
    background-color: #F4F4F4;

    ul {
        margin: 30px auto;
        width: 1160px;

        li {
            margin-top: 20px;
            display: flex;
            justify-content: space-around;
            padding: 30px 20px;
            background-color: #fff;
            border-radius: 6px;

            img {
                width: 335px;
                height: 315px;
            }

            div {
                margin-left: 37px;
                width: 760px;

                p {
                    margin-top: 10px;
                    margin-bottom: 10px;
                    font-size: 28px;
                    font-weight: 500;
                }

                span {
                    display: block;
                    width: 97%;
                    font-size: 14px;
                    line-height: 20px;
                    color: #ADADAD;
                }

                .littleT {
                    margin-top: 10px;
                    padding: 10px 0;
                    color: #EA5514;
                    font-size: 18px;
                }
            }

            &:hover {
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
            }

            &:nth-child(2){
                align-items: center;
            }
        }
    }
}
</style>