<template>
    <div>
        <top-view></top-view>
        <div class="backTop">
        </div>
        <div class="jiyu">
            <p><img src="../../assets/image/left.png">&nbsp;Message from chairma&nbsp;<img src="../../assets/image/right.png"></p>
            <div>
                <div>
                    <div>
                        <p>Founder's Message
                            <img src="../../assets/image/jiyu/jiantou.png" alt="" style="position: relative;top: 17px;">
                        </p>
                        <img src="../../assets/image/jiyu/line.png" alt="">
                        <br>
                        <span class="title">{{ info.chairmanTitle }}</span>
                        <span>{{ info.chairmanStitle }}</span>
                    </div>
                    <img :src="imgurl + info.chairmanImg" alt="">
                </div>
                <p class="content" v-html="info.chairmanContent"></p>
                <img src="../../assets/image/jiyu/jiantou.png" alt="" style="position: relative;top: 17px;left: 550px;">
                <footer>{{ footerT }}<span>·{{ footerC }}</span></footer>
            </div>
        </div>

        <footer-view></footer-view>
        <side-bar class="fixed"></side-bar>

    </div>
</template>

<script>
import topView from '@/components/en/topView.vue'
import footerView from '@/components/en/footerView.vue'
import SideBar from '@/components/en/sideBar.vue'
import axios from 'axios';

export default {
    components: { footerView, SideBar, topView },
    data() {
        return {
            infoShow: false,
            info: {},
            // imgurl: 'http://192.168.0.199:8087/api',
            imgurl: '',
            footerT: '',
            footerC: ''
        }
    },
    created() {
        this.getinfo()
        // this.$store.state.componentKey += 1
        this.website()
        this.imgurl = this.$store.state.imgurl
    },
    methods: {
        getinfo() {
            axios.get('/api/pcsystem/TckgChairman/getInfo',{params:{language:"1"}}).then((res) => {
                if (res.status == 200) {
                    this.info = res.data.data
                    this.footerT = res.data.data.chairmanBrief.split('·')[0]
                    this.footerC = res.data.data.chairmanBrief.split('·')[1]
                }
            }).catch()
        },
        //网站访问信息
        website() {
            axios.post('/api/pcsystem/TckgSoftLogin', { userType: '董事长寄语' }).then((res) => {
                console.log(res);
            }).catch()
        },
    }

}
</script>

<style lang="scss" scoped>
.fixed {
    position: fixed;
    top: 150px;
    right: 5px;
}

.backTop {
    position: relative;
    width: 100%;
    height: 26vw;
    min-width: 1200px;
    min-height: 310px;
    background-image: url("../../assets/image/dongshizhangEBack.png");
    background-size: 100% 100%;
}

.jiyu {
    position: relative;
    padding: 30px 0;
    min-width: 1200px;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    >div {
        margin: 40px auto;
        width: 1200px;
        text-align: center;

        >div {
            display: flex;
            justify-content: space-between;

            div {
                text-align: start;

                p {
                    margin: 10px 0;
                    font-size: 28px;
                    font-weight: 500;
                }

                >img {
                    margin: 10px 0;

                    &:nth-child(4) {
                        margin-top: 40px;
                        width: 250px;
                    }
                }

                span {
                    font-size: 22px;
                    color: #333333;
                    line-height: 30px;
                }

            }

            >img {
                width: 200px;
                height: 200px;
            }

        }

        .content {
            margin-top: 30px;
            padding: 30px;
            background-image: url('../../assets/image/jiyu/back.png');
            background-size: 100% 100%;
            line-height: 34px;
            font-size: 18px;
            text-align: start;
        }

    }

    footer {
        margin-top: 20px;
        text-align: end;
        font-size: 28px;
        line-height: 40px;

        span {
            color: #767676;
        }
    }

    .title {
        display: block;
        margin: 20px 0 10px;
        font-size: 30px;
        font-weight: 500;
        color: #EA5514;
    }
}
</style>