<template>
    <div>
        <top-view></top-view>
        <div class="backTop">
        </div>
        <div class="goucheng">
            <p><img src="../../assets/image/left.png">&nbsp;Group composition&nbsp;<img src="../../assets/image/right.png"></p>
            <ul>
                <li v-for="(item, index) in dataList" :key="item.dynamicId">
                    <div v-if="index % 2 == 0">
                        <img :src="imgurl + item.dynamicImg">
                        <div>
                            <p>{{ item.dynamicTitle }}</p>
                            <img src="../../assets/image/goucheng/line.png" alt=""><br>
                            <span v-html="item.dynamicContent"></span>
                        </div>
                    </div>
                    <div v-else>
                        <div>
                            <p>{{ item.dynamicTitle }}</p>
                            <img src="../../assets/image/goucheng/line.png" alt=""><br>
                            <span v-html="item.dynamicContent"></span>
                        </div>
                        <img :src="imgurl + item.dynamicImg">
                    </div>
                </li>
            </ul>
        </div>

        <footer-view></footer-view>
        <side-bar class="fixed"></side-bar>

    </div>
</template>

<script>
import topView from '@/components/en/topView.vue'
import footerView from '@/components/en/footerView.vue'
import SideBar from '@/components/en/sideBar.vue'
import axios from 'axios';

export default {
    components: { footerView, SideBar, topView },
    data() {
        return {
            infoShow: false,
            // imgurl: 'http://192.168.0.199:8087/api',
            imgurl: '',
            dataList: [],
        }
    },
    created() {
        // this.$store.state.componentKey += 1
        this.getdataList()
        this.website()
        this.imgurl = this.$store.state.imgurl
    },
    methods: {
        getdataList() {
            axios.get('/api/pcsystem/TckgDynamic/list', { params: { dynamicType: '7',language:'1' } }).then((res) => {
                this.dataList = res.data.rows
                console.log(this.dataList);
            }).catch()
        },
        //网站访问信息
        website() {
            axios.post('/api/pcsystem/TckgSoftLogin', { userType: '集团构成' }).then((res) => {
                console.log(res);
            }).catch()
        },
    }

}
</script>

<style lang="scss" scoped>
.fixed {
    position: fixed;
    top: 150px;
    right: 5px;
}

.backTop {
    position: relative;
    width: 100%;
    height: 26vw;
    min-width: 1200px;
    min-height: 310px;
    background-image: url("../../assets/image/qiyegouchengEBack.png");
    background-size: 100% 100%;
}

.goucheng {
    padding: 50px 0;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    ul {
        margin: 50px auto;
        width: 1200px;

        li {
            >div {
                display: flex;
                justify-content: space-between;
                margin-bottom: 20px;
                padding: 20px;
                background-color: #fff;
                border-radius: 6px;

                >img {
                    width: 480px;
                    height: 255px;
                }

                div {
                    width: 57%;

                    p {
                        margin: 20px 0 0;
                        font-size: 24px;
                        font-weight: 500;
                        line-height: 33px;
                    }

                    img {
                        margin: 10px 0 20px;
                    }

                    span {
                        font-size: 16px;
                        color: #999999;
                        line-height: 23px;
                    }
                }
            }
            &:nth-child(even){
                div{
                    div{
                        text-align: end;
                        img{
                            transform: rotate(180deg);
                        }
                        span{
                            display: block;
                            margin-left: 22px;
                            text-align: start;
                        }
                    }
                }
            }

            &:hover {
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
            }

        }
    }

}
</style>