<template>
    <div>
        <top-view :type="type"></top-view>
        <div class="backTop">
        </div>
        <div class="jiangzhuang">
            <p><img src="../../assets/image/left.png">&nbsp;Business association cooperation list&nbsp;<img src="../../assets/image/right.png"></p>
            <ul>
                <li v-for="item in sxhList" :key="item.cooperationId">
                    <img :src="imgurl + item.cooperationImg" alt="">
                    <p>
                        {{ item.cooperationContent }}
                    </p>
                </li>
            </ul>
            <button @click="isShow()" class="btn">
                <p v-if="!show">Unfold<img src="../../assets/image/kuaiji/zhankai.png"></p>
                <p v-else>Put it away<img src="../../assets/image/kuaiji/shouqi.png"></p>
            </button>
        </div>
        <div class="hezuo">
            <p><img src="../../assets/image/left.png">&nbsp;Business association cooperation information&nbsp;<img src="../../assets/image/right.png"></p>
            <ul>
                <li v-for="item in dataList" :key="item.dynamicId" @click="gotoContent(item.dynamicId)">
                    <img :src="imgurl + item.dynamicImg">
                    <div>
                        <div class="gengduo"></div>
                        <div style="background-color: #E3F0C8;width: 72px;height: 8px;border-radius: 4px;margin-top: 10px;">
                            <p style="border: 4px solid #8FC31F;width: 30px;border-radius: 4px;"></p>
                        </div>
                        <p>{{ item.dynamicTitle }}</p>
                        <span v-html="item.dynamicTitle2"></span>
                    </div>
                </li>
            </ul>
        </div>

        <footer-view></footer-view>
        <side-bar class="fixed"></side-bar>

    </div>
</template>

<script>
import topView from '@/components/en/topView.vue'
import footerView from '@/components/en/footerView.vue'
import SideBar from '@/components/en/sideBar.vue'
import axios from 'axios';

export default {
    components: { footerView, SideBar, topView },
    data() {
        return {
            infoShow: false,
            show: false,
            sxhList: [],
            // imgurl: 'http://192.168.0.199:8087/api',
            imgurl: '',
            params: { pageSize: 9, pageNum: 1,language:'1' },
            dataList: [],
            content: '',
            type:'3'
        }
    },
    created() {
        this.getsxhList()
        this.getdataList()
        this.website()
        this.imgurl = this.$store.state.imgurl
        // this.$store.state.componentKey += 1
    },
    methods: {
        isShow() {
            this.show = !this.show
            if (!this.show) {
                this.params = { pageSize: 9, pageNum: 1,language:'1' }
                this.getsxhList()
            } else {
                this.params = {language:'1'}
                this.getsxhList()
            }
        },
        gotoAboutus() {
            this.$router.push('/aboutus')
        },
        getsxhList() {
            axios.get('/api/pcsystem/TckgCooperation/list', { params: this.params }).then((res) => {
                if (res.status == 200) {
                    this.sxhList = res.data.rows
                    // console.log(this.sxhList);
                }

            }).catch()
        },
        getdataList() {
            axios.get('/api/pcsystem/TckgDynamic/list', { params: { pageSize: 3, pageNum: 1, dynamicType: '5',language:'1' } }).then((res) => {
                if (res.status == 200) {
                    let arr = res.data.rows
                    // this.qydtList.length = 2
                    for (let index = 0; index < 3; index++) {
                        console.log(arr[index])
                        arr[index].dynamicContent = arr[index].dynamicContent.replace(
                            /<img [^>]*src=['"]([^'"]+)[^>]*>/gi, (match, text) => {
                                return ``
                            })
                    }
                    this.dataList = arr
                    // console.log(this.dataList);
                }
            }).catch()
        },
        gotoContent(id) {
            this.$router.push({ path: '/contentE', query: { id: id, type: this.type } })
        },
        //网站访问信息
        website() {
            axios.post('/api/pcsystem/TckgSoftLogin', { userType: '商协会联动' }).then((res) => {
                console.log(res);
            }).catch()
        },

    }

}
</script>

<style lang="scss" scoped>
.fixed {
    position: fixed;
    top: 150px;
    right: 5px;
}

.backTop {
    position: relative;
    width: 100%;
    height: 26vw;
    min-width: 1200px;
    min-height: 310px;
    background-image: url("../../assets/image/shangxiehuiEBack.png");
    background-size: 100% 100%;

}

.jiangzhuang {
    padding: 60px 0;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    ul {
        margin: 30px auto 0px;
        display: flex;
        flex-wrap: wrap;
        width: 1200px;

        li {
            margin-top: 30px;
            margin-left: 0.1%;
            width: 33%;
            text-align: center;

            img {
                width: 374px;
                height: 283px;
            }

            p {
                margin: 10px auto;
                width: 345px;
                // font-size: 14px;
                font-size: 20px;
                line-height: 30px;
            }
        }
    }

    button {
        margin-top: 40px;
        margin-left: 47.5%;
        padding: 2px 8px;
        width: 130px;
        color: #979797;
        border: 1px solid#979797;
        font-size: 18px;
        border-radius: 4px;
        background-color: #F4F4F4;
    }
}

.hezuo {
    padding: 30px 0;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    ul {
        margin: 75px auto 50px;
        width: 1200px;

        li {
            position: relative;
            margin-top: 20px;
            display: flex;
            justify-content: space-around;
            // align-items: center;
            padding: 30px 20px;
            background-color: #fff;
            border-radius: 6px;

            img {
                border-top-right-radius: 50px;
                border-bottom-left-radius: 50px;
                width: 387px;
                height: 220px;
            }

            >div {
                margin-left: 10px;
                width: 60%;

                >p {
                    margin: 20px 0;
                    font-size: 20px;
                    line-height: 26px;
                    font-weight: 500;
                }

                >span {
                    width: 90%;
                    font-size: 16px;
                    line-height: 22px;
                    color: #AAAAAA;
                }
            }

            .gengduo {
                position: absolute;
                right: 50px;
                top: 40px;
                width: 20px;
                height: 4px;
                background-image: url('../../assets/image/gengduo.png');
                background-size: 100% 100%;
            }

            &:hover {
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
            }

        }
    }
}
</style>