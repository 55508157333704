<template>
    <div id="app">
        <TopView :type="type"></TopView>
        <div class="backTop">
            <!-- <div>
                <p>全球财税 就找唐诚</p>
                <ul>
                    <li>精诚合作</li>
                    <li>携手共赢</li>
                    <li>共创未来</li>
                </ul>
                <button @click="gotoAboutus">了解更多</button>
            </div> -->
            <!-- <div class="block"> -->
            <!-- <span class="demonstration">默认 Hover 指示器触发</span> -->
            <el-carousel autoplay arrow="never" class="batop">
                <el-carousel-item v-for="(item, index) in imgArray" :key="index">
                    <!-- <img src="../assets/image/banner1.png" alt="" @click="toPage(index)"> -->
                    <img :src="item" alt="" style="width: 100%;height: 100%" @click="toPage(index)" class="topImg">
                    <!-- <div :style=""></div> -->
                    <!-- <h3 class="small">{{ item }}</h3> -->
                </el-carousel-item>
            </el-carousel>
            <!-- </div> -->
        </div>
        <div class="gongsi">
            <div class="rectangular">
                <p class="title">唐诚控股集团</p>
                <span>Tenace.</span>
                <p class="content">唐诚控股集团成立于2021年，境内总部位于中国北京，境外总部位于中国香港。</p>
                <button @click="gotoAboutus">了解更多</button>
            </div>
            <ul>
                <li>
                    <p class="qizhi">
                        <span>境内公司</span>
                        <span class="num">13</span>
                    </p>
                    <div>包括北京、上海、广州、深圳等地</div>
                </li>
                <li>
                    <p class="qizhi">
                        <span>境外公司</span>
                        <span class="num">9</span>
                    </p>
                    <div>包括中国·香港、新加坡、美国、德国、越南、印尼、阿联酋、英国、波兰</div>
                </li>
                <li>
                    <p class="qizhi">
                        <span>员工</span>
                        <span class="num">1432</span>
                    </p>
                    <div>具备资深专业人士和经验丰富的员工队伍</div>
                </li>
                <li>
                    <p class="qizhi">
                        <span>服务项目</span>
                        <span class="num">2000</span>
                    </p>
                    <div>集团旗下拥有唐诚维易、唐诚联正等六大子品牌，形成会计、海外、法律等八大事业群板块</div>
                </li>
                <li>
                    <p class="qizhi">
                        <span>客户</span>
                        <span class="num">6万+</span>
                    </p>
                    <div>涵盖个体户、初创公司、中小企业等不同规模的公司</div>
                </li>
                <li>
                    <p class="qizhi">
                        <span>专业人才</span>
                        <span class="num">178</span>
                    </p>
                    <div>专业领域广而深，能够敏锐把握行业动态，创新解决方案</div>
                </li>
            </ul>
        </div>
        <div class="weixinQ">
            <ul>
                <li class="first">
                    <p style="margin: 40px 0 0 20px;font-size: 30px;font-weight: 600;line-height: 42px;">服务范围</p>
                    <span style="margin-left: 20px;font-size: 14px;color: #C7C7C7;">SCOPE OF SERVICE</span>
                    <p style="margin: 25px 0 0 20px;width: 92%;color: #545454;font-size: 20px;">
                        服务涵盖全球逾22个地区，细分服务超过2000多项内容。</p>
                    <p style="margin: 25px 0 0 20px;width: 92%;color: #545454;font-size: 20px;">
                        秉承客户至上的原则，专注于满足每一位客户的个性化需求和长远利益。</p>
                </li>
                <li @click="gotoKuaiji">
                    <div class="gongshangfuwu">
                        <p class="fuwuText">工商会计服务</p>
                    </div>
                    <ul>
                        <li>公司注册/注销</li>
                        <li>公司变更</li>
                        <li>解除异常</li>
                        <li>代理记账</li>
                        <li>审计报告</li>
                        <li>公司收购/转让</li>
                        <!-- <li v-for="item in cdlist[0].children" :key="item.id">{{ item.label }}</li> -->
                    </ul>
                </li>
                <li @click="gotoZonghe">
                    <div class="zonghefuwu">
                        <p class="fuwuText">综合服务</p>
                    </div>
                    <ul>
                        <li>公司车指标</li>
                        <li>许可证办理</li>
                        <li>知识产权</li>
                        <li>特色园区</li>
                        <li>房租通</li>
                        <li>专精特新企业认定</li>
                    </ul>
                </li>
                <li @click="gotoZizhi">
                    <div class="jianzhufuwu">
                        <p class="fuwuText">建筑资质服务</p>
                    </div>
                    <ul>
                        <li>施工总承包</li>
                        <li>施工专业承包</li>
                        <li>劳务资质</li>
                        <li>安全生产许可证</li>
                        <li><span>承装（修、试）电力设施许可证</span></li>
                        <li>房地产开发资质</li>
                        <!-- <li v-for="item in cdlist[5].children" :key="item.id">{{ item.label }}</li> -->
                    </ul>
                </li>
                <li @click="gotoHaiwai">
                    <div class="haiwaifuwu">
                        <p class="fuwuText">海外服务</p>
                    </div>
                    <ul>
                        <li>海外银行开户</li>
                        <li>VIE搭建</li>
                        <li>外汇资金管理</li>
                        <li>海关稽查退税</li>
                        <li><span>股权及顶层架构设计</span></li>
                        <li>对外投资备案ODI</li>
                        <!-- <li v-for="item in cdlist[2].children" :key="item.id">{{ item.label }}</li> -->
                    </ul>
                </li>
                <li @click="gotoQidai">
                    <div class="qiyefuwu">
                        <p class="fuwuText">企业贷款服务</p>
                    </div>
                    <ul>
                        <li>企业信用贷</li>
                        <li>抵押消费贷</li>
                        <li>企业税金贷</li>
                        <li>抵押经营贷</li>
                        <li>资金证明</li>
                        <li>个人信用贷</li>
                        <!-- <li v-for="item in cdlist[3].children" :key="item.id">{{ item.label }}</li> -->
                    </ul>
                </li>
            </ul>
        </div>
        <div class="shipin">
            <div class="box">
                <ul>
                    <li>
                        <img src="../../assets/image/vedioB.png" alt="">
                    </li>
                    <li>
                        <div class="line"></div>
                        <div class="neirong">
                            <p>代理记账</p>
                            <span class="english">Bookkeeping as an agent</span>
                        </div>
                    </li>
                    <li>
                        <div class="line"></div>
                        <div class="neirong">
                            <p>金融法律</p>
                            <span class="english">Financial Law</span>
                        </div>
                    </li>
                    <li>
                        <div class="line"></div>
                        <div class="neirong">
                            <p>跨境合规</p>
                            <span class="english">Cross-border compliance</span>
                        </div>
                    </li>
                    <li>
                        <div class="line"></div>
                        <div class="neirong">
                            <p>知识产权</p>
                            <span class="english">Intellectual property</span>
                        </div>
                    </li>
                    <li>
                        <div class="line"></div>
                        <div class="neirong">
                            <p>建筑资质</p>
                            <span class="english">Architectural qualifications</span>
                        </div>
                    </li>
                    <li>
                        <div class="line"></div>
                        <div class="neirong">
                            <p>全球架构</p>
                            <span class="english">Global constraints</span>
                        </div>
                    </li>
                </ul>
                <div class="show-imgs-container" @mouseenter="changeTrue()" @mouseleave="changeFalse()">
                    <el-carousel :interval="cutTime" arrow="never" :autoplay="autoplay" height="530px" ref="carouse"
                        @change="getIndex">
                        <el-carousel-item v-for="(item, index) in videos" :key="index">
                            <!-- <video :ref="'videoPlayer' + index" :controls="false" :src="item.src" :muted="item.muted"
                                class="video" @ended="showPoster(index)" :id="'video' + index">
                                您的浏览器不支持Video标签。
                            </video>
                            <i v-show="!isPlay" class="play-btn el-icon-video-play" @click="playVideo"></i>
                            <i v-show="isPlay && isIn" class="play-btn el-icon-video-pause" @click="pauseVideo"></i>
                            <div class="poster" :style="{ backgroundImage: 'url(' + item.poster + ')' }"
                                v-if="fengmian"></div>
                            <div class="player unsound" @click="sound(item, index)"
                                :class="item.muted ? 'unsound' : 'sound'"></div> -->
                            <video :ref="'videoPlayer' + index" :src="item.src" loop controls autoplay muted></video>
                            <div class="poster" :style="{ backgroundImage: 'url(' + item.poster + ')' }"
                                v-if="fengmian"></div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
            <div class="select">
                <div class="selectQ" :class="choice == 0 ? 'choice' : ''" @mouseenter="$refs.carouse.setActiveItem(0);">
                </div>
                <div class="selectQ" :class="choice == 1 ? 'choice' : ''" @mouseenter="$refs.carouse.setActiveItem(1);">
                </div>
                <div class="selectQ" :class="choice == 2 ? 'choice' : ''" @mouseenter="$refs.carouse.setActiveItem(2);">
                </div>
            </div>
        </div>
        <div class="mapBack">
            <div style="display: flex;justify-content: center;"><button @click="infoShow = true">点击咨询</button></div>
            <div class="map">
                <p><img src="../../assets/image/left.png">&nbsp;全球布局&nbsp;<img src="../../assets/image/right.png"></p>
                <span>GLOBAL DISTRIBUTION</span>
                <div>
                    <img src="../../assets/image/地图.png">
                    <div class="mapTitle1">
                        <p>22</p>
                        <span>国家及地区业务范围覆盖全球</span>
                    </div>
                    <div class="mapTitle2">
                        <p>178</p>
                        <span>企业服务专家级人才精通各国法规政策</span>
                    </div>
                </div>
            </div>
            <ul class="city">
                <li>
                    <img src="../../assets/image/2021.png">
                    <p>成立石家庄、廊坊、天津公司；成立北京泰衡会计师事务所；并购克瑞斯，小顶会计、大账房、天津税管家财税公司</p>
                </li>
                <li>
                    <img src="../../assets/image/2022.png">
                    <p>成立北京泰衡资产评估公司、北京、西安公司；并购九州财税公司、ABO Accounts Ltd</p>
                </li>
                <li>
                    <img src="../../assets/image/2023.png">
                    <p>成立成都、深圳、上海、广州、香港、新加坡公司；唐豆智能财税共享中心投入运营</p>
                </li>
            </ul>
        </div>
        <div class="orange">
            <img src="../../assets/image/whiteLine.png" alt="">
            携手亿企辉煌 缔造百年唐诚
            <img src="../../assets/image/whiteLine.png" alt="">
        </div>
        <div class="personal">
            <p><img src="../../assets/image/left.png">&nbsp;唐诚凌云阁&nbsp;<img src="../../assets/image/right.png"></p>
            <span>TANG CHENG LINGYUN PAVILION</span>
            <div>
                <div class="Pic">
                    <img src="../../assets/icon/toLeftH.png" class="toRight" v-if="showImg">
                    <img src="../../assets/icon/toRightG.png" @click="showRight" class="toLeft" v-if="showImg">
                    <img src="../../assets/icon/toLeftG.png" @click="showLeft" class="toRight" v-if="!showImg">
                    <img src="../../assets/icon/toRightH.png" class="toLeft" v-if="!showImg">
                </div>
                <ul>
                    <li v-for="item in personalList" :key="item.personnelId">
                        <img :src="imgurl + item.personnelImg" style="width: 100%;height: 100%;">
                        <p>
                            <span class="personalT">{{ item.personnelName }}</span>
                            <span class="personalC">{{ item.personnelPosition }}</span>
                            <span class="personalRemark">
                                <div style="display: flex;" v-for="(children, index) in item.remark" :key="index">
                                    <div
                                        style="margin-top: 8px;width: 4px;height: 4px;border-radius: 50%;border: 2px solid #fff;background-color: #EA5514;">
                                    </div>
                                    <div style="margin-left: 8px;width: 240px;">{{ children }}</div>
                                </div>
                            </span>
                        </p>
                    </li>
                    <!-- <li>
                    <img src="../assets/image/personal.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">张廷宙</span>
                        <span class="personalC">唐诚北京分公司总经理</span>
                    </p>
                </li>
                <li>
                    <img src="../assets/image/personal2.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">张子墨</span>
                        <span class="personalC">唐诚上海分公司总经理</span>
                    </p>
                </li>
                <li>
                    <img src="../assets/image/personal3.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">崔明晓</span>
                        <span class="personalC">唐诚广州分公司总经理</span>
                    </p>
                </li>
                <li>
                    <img src="../assets/image/personal4.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">赵吉红</span>
                        <span class="personalC">唐诚深圳分公司总经理</span>
                    </p>
                </li>
                <li>
                    <img src="../assets/image/personal5.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">王效杰</span>
                        <span class="personalC">唐诚天津分公司总经理</span>
                    </p>
                </li>
                <li>
                    <img src="../assets/image/personal6.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">罗杰</span>
                        <span class="personalC">唐诚成都分公司总经理</span>
                    </p>
                </li>
                <li>
                    <img src="../assets/image/personal7.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">葛文鑫</span>
                        <span class="personalC">唐诚西安分公司总经理</span>
                    </p>
                </li>
                <li>
                    <img src="../assets/image/personal8.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">刘宏宇</span>
                        <span class="personalC">唐诚石家庄分公司总经理</span>
                    </p>
                </li> -->
                </ul>
            </div>

            <!-- <ul v-else> -->
            <!-- <li v-for="item in personalList" :key="item.personnelId">
                    <img :src="imgurl + item.personnelImg" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">{{ item.personnelName }}</span>
                        <span class="personalC">{{ item.personnelPosition }}</span>
                    </p>
                </li> -->
            <!-- <li>
                    <img src="../assets/image/personal9.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">王效光</span>
                        <span class="personalC">唐诚廊坊分公司总经理</span>
                    </p>
                </li>
                <li>
                    <img src="../assets/image/personal10.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">张丽媛</span>
                        <span class="personalC">唐诚会计专家组长</span>
                    </p>
                </li>
                <li>
                    <img src="../assets/image/personal11.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">陈银</span>
                        <span class="personalC">唐诚武汉分公司总经理</span>
                    </p>
                </li>
                <li>
                    <img src="../assets/image/personal12.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">代振坤</span>
                        <span class="personalC">唐诚资质专家组长</span>
                    </p>
                </li>
                <li>
                    <img src="../assets/image/personal13.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">郁昌</span>
                        <span class="personalC">唐诚顶融事业群COO</span>
                    </p>
                </li>
                <li>
                    <img src="../assets/image/personal14.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">郝丽霞</span>
                        <span class="personalC">唐诚海外事业群COO</span>
                    </p>
                </li>
                <li>
                    <img src="../assets/image/personal15.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">杜少鹏</span>
                        <span class="personalC">唐诚联正事业群COO</span>
                    </p>
                </li>
                <li>
                    <img src="../assets/image/personal16.png" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">肖艳</span>
                        <span class="personalC">唐诚会计事业群COO</span>
                    </p>
                </li> -->
            <!-- </ul> -->
        </div>
        <div class="enterprises">
            <div>
                <div>
                    <p>企业动态</p>
                    <span>BUSINESS DYNAMICS</span>
                    <img src="../../assets/icon/qiyeD.png">
                    <p class="qiyeC">
                        唐诚不定期举办研讨会、专题讲座、主题论坛等活动，涉及公司财税、离岸贸易、跨境电商、知识产权、建筑资质等领域。
                    </p>
                </div>
                <ul>
                    <li v-for="(item, index) in qydtList" :key="item.dynamicId" @click="gotoxiangQ(item.dynamicId)">
                        <img :src="imgurl + item.dynamicImg">
                        <div>
                            <div class="gengduo"></div>
                            <p>{{ titleArray[index][0] }}</p>
                            <span>{{ titleArray[index][1] }}</span>
                            <div>
                                <img src="../../assets/icon/time.png">
                                <span class="time">{{ item.dynamicDate }}</span>
                            </div>
                        </div>
                    </li>
                    <!-- <li>
                        <img src="../assets/image/GXL_4937.png">
                        <div>
                            <p>燃情初夏，全员冲刺</p>
                            <span>唐诚控股集团六月启动会奏响最强音</span>
                            <div>
                                <img src="../assets/icon/time.png">
                                <span class="time">2024-06-03</span>
                            </div>
                        </div>
                    </li>
                    <li>
                        <img src="../assets/image/GXL_4937.png">
                        <div>
                            <p>燃情初夏，全员冲刺</p>
                            <span>唐诚控股集团六月启动会奏响最强音</span>
                            <div>
                                <img src="../assets/icon/time.png">
                                <span class="time">2024-06-03</span>
                            </div>
                        </div>
                    </li> -->
                </ul>
            </div>
        </div>
        <div class="customers">
            <div style="display: flex;justify-content: center;"><button @click="infoShow = true">点击咨询</button></div>
            <p><img src="../../assets/image/left.png">&nbsp;众多客户的共同选择&nbsp;<img src="../../assets/image/right.png"></p>
            <span>CUSTOMER'S CHOICE</span>
            <ul>
                <li v-for="item in choiceList" :key="item.clientId"><img :src="imgurl + item.clientImg" alt=""></li>
                <!-- <li>
                    <img src="../assets/image/zhongjian.png">
                </li>
                <li><img src="../assets/image/reli.png"></li>
                <li><img src="../assets/image/wework.png"></li>
                <li><img src="../assets/image/shinonghui.png"></li>
                <li><img src="../assets/image/getidr.png"></li>
                <li><img src="../assets/image/sogd.png"></li>
                <li><img src="../assets/image/nongao.png"></li>
                <li><img src="../assets/image/daboluo.png"></li> -->
            </ul>
        </div>

        <div class="sprites">
            <ul>
                <li>
                    <img src="../../assets/icon/team.png">
                    <p class="title">专业团队</p>
                    <p>人均从业经验5年以上</p>
                    <p>为客户提供超越价值的服务</p>
                </li>
                <li>
                    <img src="../../assets/icon/qianbao.png">
                    <p class="title">价格透明</p>
                    <p>明码标价</p>
                    <p>拒绝任何隐形消费</p>
                </li>
                <li>
                    <img src="../../assets/icon/suo.png">
                    <p class="title">多重保密</p>
                    <p>拥有信息保密体系</p>
                    <p>保障客户信息安全</p>
                </li>
                <li>
                    <img src="../../assets/icon/fangzi.png">
                    <p class="title">售后保障</p>
                    <p>有专业人员负责售后工作</p>
                    <p>能够及时给出解决办法</p>
                </li>
            </ul>
        </div>

        <footer-view></footer-view>

        <side-bar class="fixed"></side-bar>

        <el-dialog title="请填写您的个人信息" :visible.sync="infoShow" width="30%" center>
            <span
                style="font-size: 12px;color: #A5A5A5;text-align: center;display: block;margin-top: -30px;">我们会在30分钟内联系您</span>

            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="姓名" prop="name">
                    <el-input v-model="ruleForm.name" placeholder="请输入您的姓名"></el-input>
                </el-form-item>
                <el-form-item label="电话" prop="tel">
                    <el-input v-model="ruleForm.tel" placeholder="请输入您的电话号码"></el-input>
                </el-form-item>
                <el-form-item label="留言" prop="content">
                    <el-input v-model="ruleForm.content" type="textarea" placeholder="请输入您的留言（不超过300字）"
                        class="inputC"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm('ruleForm')">立即提交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import TopView from '@/components/zh/topView.vue';
import SideBar from '@/components/zh/sideBar.vue';
import FooterView from '@/components/zh/footerView.vue';
import router from '@/router';
import axios from 'axios';

export default {
    name: "index",
    components: {
        TopView,
        SideBar,
        FooterView
    },
    data() {
        return {
            infoShow: false,
            ruleForm: {
                name: '',
                tel: '',
                content: ''
            },
            rules: {
                name: [
                    { required: true, message: '请输入您的姓名', trigger: 'blur' },
                ],
                tel: [
                    { required: true, message: '请输入您的电话', trigger: 'blur' },
                    { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号码', trigger: 'blur' }
                ],
                content: [
                    // { required: true, message: '请输入您的电话', trigger: 'blur' },
                    { min: 0, max: 300, message: '最多输入300字', trigger: 'blur' }
                ]
            },
            showP: true,
            // activeIndex: '1',
            showImg: true,
            cdlist: [],
            qydtList: [],
            personalList: [],
            params: { pageSize: 8, pageNum: 1, language: '0' },
            choiceList: [],
            // imgurl: '',
            imgurl: '',
            // imgurl1: 'http://localhost:8080',
            // componentKey:0
            imgArray: [
                require('../../assets/image/banner1.png'),
                require('../../assets/image/banner2.png'),
                require('../../assets/image/banner3.png'),
                require('../../assets/image/banner4.png'),
                require('../../assets/image/banner5.png'),
            ],
            titleArray: [],
            autoplay: true,//是否自动切换
            isPlay: false,//播放状态
            isIn: false,//鼠标是否位于播放器内
            cutTime: 4000,//轮播时间，单位毫秒
            nowIndex: 0,
            videos: [
                {
                    // src: "https://www.runoob.com/try/demo_source/mov_bbb.mp4"
                    // src: require('../assets/vedio/WeChat_20240821175529.mp4'),
                    src: 'https://www.tenace.cn/api/profile/video/63151d33f0a336cd38f335f4396bcbb7.mp4',
                    poster: require('../../assets/vedio/fengmian1.png'),
                    muted: true
                    // res: '938*530'
                },
                {
                    // src: require('../assets/vedio/WeChat_20240821175547.mp4'),
                    src: 'https://www.tenace.cn/api/profile/video/a9f1267a9d4a64e984b9678c552a693f.mp4',
                    poster: require('../../assets/vedio/fengmian2.png'),
                    muted: true
                    // res: '938*530'
                },
                {
                    // src: require('../assets/vedio/59ed951163e7edac6630b32d1a521e02.mp4'),
                    src: 'https://www.tenace.cn/api/profile/video/59ed951163e7edac6630b32d1a521e02.mp4',
                    poster: require('../../assets/vedio/fengmian3.png'),
                    muted: true
                    // res: '938*530'
                },
            ],
            choice: 0,
            isplay: false,
            enter: false,
            type: '1',
            fengmian: true,
            muted: true
            // carouse:''
            // mapshow:true
        }
    },
    // computed:{
    //     ...mapState(['componentKey'])
    // },
    created() {
        this.getqydtList()
        this.getPersonalList()
        this.getchoiceList()
        this.website()
        // console.log(this.imgArray);
        this.imgurl = this.$store.state.imgurl
        // this.enter =false
        // this.fengmian=true
        // this.$store.state.componentKey += 1
        // console.log(this.$store.state.componentKey);
        // this.componentKey+=1
    },
    methods: {
        //获取企业动态列表
        getqydtList() {
            axios.get('/api/pcsystem/TckgDynamic/list', { params: { pageSize: 2, pageNum: 1, dynamicType: '1', language: '0' } }).then((res) => {
                // let arr = res.data.rows
                // for (let index = 0; index < 2; index++) {
                //     console.log(arr[index])
                //     arr[index].dynamicContent = arr[index].dynamicContent.replace(
                //         /<img [^>]*src=['"]([^'"]+)[^>]*>/gi, (match, text) => {
                //             return ``
                //         })
                // }
                this.qydtList = res.data.rows
                // let titleArray = []
                for (let index = 0; index < this.qydtList.length; index++) {
                    this.titleArray[index] = res.data.rows[index].dynamicTitle.split('|')
                }
                // console.log(this.titleArray);

            }).catch()
        },
        //获取人物列表
        getPersonalList() {
            axios.get('/api/pcsystem/TckgPersonnel/list', { params: this.params }).then((res) => {
                if (res.status == 200) {
                    this.personalList = res.data.rows
                    for (let i = 0; i < this.personalList.length; i++) {
                        this.personalList[i].remark = res.data.rows[i].remark.split('。')
                    }
                }

            }).catch()
        },
        //获取共同选择列表
        getchoiceList() {
            axios.get('/api/pcsystem/TckgClient/list').then((res) => {
                if (res.status == 200) {
                    this.choiceList = res.data.rows
                    // console.log(this.choiceList);
                }

            }).catch()
        },
        //跳转企业动态详情
        gotoxiangQ(id) {
            this.$router.push({ path: '/content', query: { id: id, type: this.type } })
            // console.log(id);
        },
        //网站访问信息
        website() {
            axios.post('/api/pcsystem/TckgSoftLogin', { userType: '首页' }).then((res) => {
                console.log(res);
            }).catch()
        },
        showLeft() {
            // var left = document.getElementsByClassName('toLeft')[0].src = '../assets/icon/toLeft.png'
            // var right = document.getElementsByClassName('toRight')[0].src = "../assets/icon/toRight.png"
            // // left.src='../assets/icon/toLeft.png'
            // // right.src="../assets/icon/toRight.png"
            // console.log(left, right);
            // this.showP = true
            this.showImg = true
            this.params = { pageSize: 8, pageNum: 1, language: '0' }
            this.getPersonalList()
        },
        showRight() {
            // var left = document.getElementsByClassName('toLeft')[0]
            // var right = document.getElementsByClassName('toRight')[0]
            // left.src = "../assets/icon/toRight.png"
            // right.src = "../assets/icon/toLeft.png"
            // // console.log(111)
            // this.showP = false
            this.showImg = false
            this.params = { pageSize: 8, pageNum: 2, language: '0' }
            this.getPersonalList()
        },
        gotoKuaiji() {
            this.$router.push('/kuaiji')
        },
        gotoZonghe() {
            this.$router.push('/zonghe')
        },
        gotoZizhi() {
            this.$router.push('/zizhi')
        },
        gotoHaiwai() {
            this.$router.push('/haiwai')
        },
        gotoQidai() {
            this.$router.push('/qidai')
        },
        gotoAboutus() {
            this.$router.push('/aboutus')
        },
        toPage(index) {
            if (index == 1) {
                this.gotoHaiwai()
            }
            if (index == 2) {
                this.$router.push('/shangye')
            }
            if (index == 3) {
                this.$router.push('/qiyewenhua')
            }
        },
        // getimg(index,type){
        //     if(type == 'png'){
        //         const a = require(this.imgArray[index]+'.png')
        //         return a
        //     }
        // }
        // showMap(){
        //     this.mapshow = true
        // }
        //提交信息
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // alert('submit!');
                    this.infoShow = false
                    // console.log(this.ruleForm);
                    axios.post('/api/pcsystem/TckgConsult', { params: this.ruleForm }).then((res) => {
                        // console.log(res);
                        if (res.status == 200) {
                            // console.log(111111);
                            this.$message({
                                message: '提交成功，请耐心等待',
                                type: 'success'
                            });
                        }
                    }).catch()
                } else {
                    // this.$message({
                    //     message: '提交失败，请稍后重试',
                    //     type: 'warning'
                    // });
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        //视频部分
        //获取走马灯的索引
        getIndex(nowIndex, oldIndex) {
            //用户可能在上个视频未播放完毕就切换
            //此时需要暂停上个视频并把时间归零，否则影响对当前视频的监听逻辑
            let myVideo = this.$refs['videoPlayer' + oldIndex];
            myVideo[0].pause();
            myVideo[0].currentTime = 0;
            this.nowIndex = nowIndex;
            this.listenVideo();
            //切换选择器
            // this.choice = !this.choice
            if (nowIndex == 0) {
                this.choice = 0
                if (this.enter) {
                    this.playVideo()
                    this.isPlay = true
                }
                // this.selector(0, 1)
            } else if (nowIndex == 1) {
                this.choice = 1
                if (this.enter) {
                    this.playVideo()
                    this.isPlay = true
                }
            } else {
                this.choice = 2
                if (this.enter) {
                    this.playVideo()
                    this.isPlay = true
                }
            }
            // console.log("1111111111111111111111111");
        },
        //监听视频的播放、暂停、播放完毕等事件
        listenVideo() {
            //注意：这样获取的dom是一个数组，必须加上[0]索引才能正确的添加事件
            let myVideo = this.$refs['videoPlayer' + this.nowIndex];
            //监听播放
            myVideo[0].addEventListener("play", () => {
                this.autoplay = false;
                this.isPlay = true;
                this.fengmian = false
            });
            //监听暂停
            myVideo[0].addEventListener("pause", () => {
                this.autoplay = true;
                this.isPlay = false;
            });
            //监听播放完毕
            myVideo[0].addEventListener("ended", () => {
                //时间归零
                myVideo[0].currentTime = 0;
                this.isPlay = false;
                this.autoplay = true;
                // this.fengmian=true;
                // console.log(this.nowIndex);
                // if (this.nowIndex == 0) {
                //     this.nowIndex=1
                //     this.choice=false
                //     this.playVideo()
                // } else {
                //     this.nowIndex=0
                //     this.choice=true
                //     this.playVideo()
                // }

            });

        },
        //播放视频
        playVideo() {
            //注意：这样获取的dom是一个数组，必须加上[0]索引才能正确的添加事件
            let myVideo = this.$refs['videoPlayer' + this.nowIndex];
            // this.autoplay=false
            myVideo[0].play();
            this.enter = true
            this.fengmian = false
            // setTimeout(()=>{
            //     this.muted=false
            // },5000)
            // this.isPlay = true
        },
        //暂停视频
        pauseVideo() {
            //注意：这样获取的dom是一个数组，必须加上[0]索引才能正确的添加事件
            let myVideo = this.$refs['videoPlayer' + this.nowIndex];
            myVideo[0].pause();
            this.enter = false
            // this.fengmian = true
            // this.isPlay = false
        },
        //小球选择
        selector(nowIndex, oldIndex) {
            this.enter = true
            this.getIndex(nowIndex, oldIndex)
            if (nowIndex == 0) {
                this.choice = 0
                this.$refs.carouse.setActiveItem(0);
                this.isplay = true
                this.nowIndex = 0
                this.playVideo()
                this.isPlay = true
                this.autoplay = false
            } else if (nowIndex == 1) {
                this.choice = 1
                this.$refs.carouse.setActiveItem(1);
                this.nowIndex = 1
                this.isplay = true
                this.playVideo()
                this.listenVideo()
                this.isPlay = true
                this.autoplay = false
            } else {
                this.choice = 2
                this.$refs.carouse.setActiveItem(2);
                this.nowIndex = 2
                this.isplay = true
                this.playVideo()
                this.listenVideo()
                this.isPlay = true
                this.autoplay = false
            }
        },
        changeTrue() {
            // setTimeout(() => {
            this.isIn = true
            // }, 200)
            if (this.choice == 0) {
                this.selector(0, 2)
            } else if (this.choice == 1) {
                this.selector(1, 0)
            } else {
                this.selector(2, 1)
            }
        },
        changeFalse() {
            // setTimeout(() => {
            this.isIn = false
            // }, 200)
        },
        sound(item, index) {
            item.muted = !item.muted
        },
    },
    mounted() {
        this.listenVideo();
    }
};
</script>

<style lang="scss" scoped>
.backTop {
    min-width: 1200px;
    // position: relative;
    // width: 100%;
    // height: 450px;
    // background-image: url("../assets/image/banner1.png");
    // background-size: 100% 100%;

    // div {
    //     margin: auto;
    //     padding-top: 80px;
    //     width: 1200px;
    //     color: #EA5514;
    // }

    // p {
    //     font-size: 50px;
    //     font-weight: 700;
    // }

    // ul {
    //     display: flex;
    //     margin: 20px 0;

    //     li {
    //         font-size: 18px;
    //         padding-right: 15px;
    //         margin: 15px 15px 0 0;
    //         border-right: 2px solid #EA5514;
    //     }
    // }

    // button {
    //     margin-top: 20px;
    //     width: 100px;
    //     height: 30px;
    //     line-height: 30px;
    //     color: #fff;
    //     background-color: #FFB258;
    //     border: 0;
    //     border-radius: 15px;
    // }
}

.fixed {
    position: fixed;
    top: 150px;
    right: 5px;
}

.gongsi {
    margin: 40px auto;
    display: flex;
    // justify-content: space-around;
    // align-items: center;
    width: 1200px;

    .rectangular {
        padding: 30px 25px;
        // width: 29.8%;
        width: 355px;
        height: 270px;
        background-image: url("../../assets/image/93176a07e5ce9bf625a93fafc2312ac1e118b767fa477d-8Fq7of_fw1200.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        color: #fff;
        border-radius: 6px;

        .title {
            // padding-top: 10px;
            margin-bottom: 10px;
            font-size: 24px;
            font-weight: 600;
        }

        span {
            font-size: 18px;
        }

        .content {
            margin-top: 25px;
            // font-size: 18px;
            // margin: 20px 0;
            // line-height: 25px;
            font-weight: 400;
            // font-size: 20px;
            font-size: 19px;
            color: #FFFFFF;
            line-height: 30px;
        }

        button {
            margin-top: 20px;
            padding: 7px 20px;
            background-color: #F08759;
            border: 0;
            color: #fff;
            border-radius: 4px;
            // font-size: 22px;
            font-size: 20px;
            font-weight: 500;
        }
    }

    ul {
        margin-top: -10px;
        width: 65%;
        display: flex;
        flex-wrap: wrap;

        li {
            display: flex;
            align-items: center;
            margin-top: 10px;
            margin-left: 2%;
            width: 48%;
            // width: 390px;
            height: 100px;
            // background-color: #FFEFE8;
            background: linear-gradient(to top, #FFEEE6 0%, #FFFBF5 100%);
            border-radius: 6px;


            .qizhi {
                // margin-top: -13px;
                width: 80px;
                height: 60%;
                background-image: url("../../assets/image/qizhi.png");
                background-size: 100% 100%;
                color: #fff;
                font-size: 13px;
                text-align: center;

                span {
                    margin: 7px 0 7px -5px;
                    display: block;
                }

                .num {
                    margin-left: -4px;
                    font-size: 18px;
                }
            }

            >div {
                margin-left: 10px;
                // line-height: 70px;
                width: 70%;
                font-size: 14px;
            }

        }
    }
}

.weixinQ {
    // margin: auto;
    padding: 30px 0;
    min-width: 1200px;
    // min-height: 310px;
    background-color: #F4F4F4;

    >ul {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin: auto;
        width: 1200px;

        >li {
            // margin-left: 10px;
            margin-top: 10px;
            width: 32%;
            height: 300px;
            // border: 1px dashed #9A9A9A;
            border-radius: 10px;
            cursor: pointer;

            ul {
                li {
                    width: 32% !important;

                    span {
                        width: 100px;
                        font-size: 14px;
                    }

                    // padding: 1px 5px;
                }
            }

            &:first-child,
            &:nth-child(4) {
                margin: 0;
                margin-top: 10px;
            }

            .gongshangfuwu,
            .zonghefuwu,
            .jianzhufuwu,
            .haiwaifuwu,
            .qiyefuwu {
                overflow: hidden;
                position: relative;
                height: 200px;
                border-radius: 10px;
                background-image: url("../../assets/image/gongshangfuwu.png");
            }

            .zonghefuwu {
                background-image: url("../../assets/image/zonghefuwu.png");
            }

            .jianzhufuwu {
                background-image: url("../../assets/image/jianzhufuwu.png");
            }

            .haiwaifuwu {
                background-image: url("../../assets/image/haiwaifuwu.png");
            }

            .qiyefuwu {
                background-image: url("../../assets/image/qiyedaikuanfuwu.png");
            }

            .fuwuText {
                // margin-top: 180px;
                position: absolute;
                bottom: 0;
                padding: 10px;
                width: 94.7%;
                color: #fff;
                background-color: rgba(0, 0, 0, 0.4);
                text-align: center;
                // border-top: 1px solid rgba(0,0,0,0.4);
            }

            >ul {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                height: 80px;

                li {
                    margin-top: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 30%;
                    height: 48%;
                    text-align: center;
                    // line-height: 40px;
                    font-size: 14px;
                    background-color: #fff;
                    border-radius: 4px;


                    &:hover {
                        background-color: #ED5413;
                        color: #fff;
                    }
                }
            }
        }

        .first {
            // padding: 0 0px 0 10px;
            background-image: url("../../assets/image/fuwu.png");
            background-size: 100% 100%;
        }
    }

}

.mapBack {
    // width: 100%;
    // padding-top: 50px;
    padding-bottom: 70px;
    background-color: #F4F4F4;
    min-width: 1200px;
    // min-height: 310px;

    button {
        margin: 30px 0 75px;
        // margin-left: 46%;
        // margin-left: 45%;
        // min-width: 8%;
        width: 202px;
        height: 56px;
        padding: 5px 15px;
        // font-size: 26px;
        font-size: 26px;
        font-weight: 500;
        color: #fff;
        // background-color: #FE8F3F;
        background: linear-gradient(180deg, #FFB552 0%, #FF4D00 100%);
        border: 0;
        border-radius: 30px;
        text-align: center;
    }

    .map {
        // position: relative;
        padding: 60px 0 60px;
        width: 100%;
        // height: 600px;
        background-image: url("../../assets/image/地图背景.png");
        background-size: 100% 100%;
        // z-index: 1;

        >p {
            margin-bottom: 10px;
            font-size: 34px;
            font-weight: 700;
            text-align: center;

            >img {
                // position: absolute;
                // top: 36px;
                // left: 816px;
                position: relative;
                top: 3px;
                margin-left: 0;
                width: 33px;
                height: 24px;

                &:last-child {
                    // left: 1055px;
                }
            }
        }

        >span {
            display: block;
            text-align: center;
            color: #999999;
            font-size: 22px;
            font-weight: 400;
        }

        >div {
            position: relative;
            margin: 10px auto;
            padding-bottom: 20px;
            width: 1200px;
        }

        img {
            margin-left: 9%;
            width: 90%;
        }

        .mapTitle1,
        .mapTitle2 {
            position: absolute;
            top: 40%;
            // left: 18.5vw;

            p {
                margin: 0;
                color: #EA5514;
                // font-size: 25px;
                text-align: start;
                font-weight: bold;
                font-size: 42px;
                line-height: 48px;
            }

            span {
                display: block;
                // width: 150px;
                // font-size: 16px;
                width: 180px;
                font-weight: 400;
                font-size: 20px;
                line-height: 26px;
                color: #333333;
            }
        }

        .mapTitle2 {
            top: 70%;
            // left: 450px;
        }
    }

    .city {
        position: relative;
        display: flex;
        padding: 40px 0;
        justify-content: space-around;
        margin: -80px auto 0;
        width: 1200px;
        // height: 200px;
        background-color: #fff;
        // border: 1px solid #9A9A9A;
        box-shadow: 0 0 10px 0 #F4F4F4;
        border-radius: 4px;
        // z-index: 9999;

        li {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 30%;

            img {
                margin-bottom: 30px;
                width: 70px;
                height: 70px;
            }

            p {
                text-align: center;
                font-size: 16px;
                width: 312px;
            }
        }
    }
}

.orange {
    // position: relative;
    padding: 70px 0;
    width: 100%;
    min-width: 1200px;
    // height: 150px;
    background-image: url("../../assets/image/橙色背景.png");
    background-size: 100% 100%;
    text-align: center;
    color: #fff;
    font-size: 40px;
    // line-height: 150px;

    img {
        // position: absolute;
        position: relative;
        top: -10px;

        &:last-child {
            transform: rotate(180deg);
        }
    }
}

.personal {
    position: relative;
    padding: 60px 0;
    min-width: 1200px;
    // margin: 0 auto;
    // width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    >div {
        position: relative;
        margin: 0 auto;
        padding: 20px 0 0;
        width: 1200px;

        .Pic {
            position: absolute;
            // top: 60px;
            right: 0;
            cursor: pointer;

            img {
                width: 25px;

                &:first-child {
                    margin-right: 5px;
                }
            }
        }

        ul {
            margin: 30px auto 0;
            display: flex;
            // justify-content: space-around;
            flex-wrap: wrap;
            width: 1200px;

            li {
                position: relative;
                margin-top: 1vh;
                margin-left: 1%;
                width: 24%;
                height: 340px;
                // background-image: url("../assets/image/personal.png");
                background-size: 100% 100%;
                // border-radius: 0;
                border-radius: 6px;
                overflow: hidden;

                // overflow: hidden;
                >p {
                    margin: 0;
                    position: absolute;
                    bottom: 0;
                    padding: 14px 10px;
                    // width: 89.5%;
                    width: 93%;
                    background-color: rgba(0, 0, 0, 0.5);
                    text-align: start;
                    // border-radius: 0;
                    // border-bottom: 6px;
                    color: #fff;
                }

                .personalT {
                    display: block;
                    font-size: 18px;
                    line-height: 30px;
                    font-weight: 600;
                }

                .personalC {
                    font-size: 16px;
                    font-weight: 500;
                }

                .personalRemark {
                    margin-top: 5px;
                    display: none;
                    padding: 5px 10px;
                    background-color: #EA5514;
                    border-radius: 3px;
                    font-size: 9px;
                    line-height: 23px;
                }

                &:hover {
                    .personalRemark {
                        display: block;
                    }
                }
            }
        }
    }


}

.enterprises {
    padding: 60px 0;
    // height: 300px;
    background-image: url("../../assets/image/868c046010cc87cdbc7982e4060ecda319a96a1283107-PWCdz7_fw1200.png");

    >div {
        display: flex;
        justify-content: space-between;
        margin: auto;
        width: 1200px;

        >div {
            padding: 50px 30px;
            width: 32%;
            // height: 100%;
            background-image: url("../../assets/image/f5dbf895f20f3bd7a31cf7b9f20acdf35a31bb5e8bdf6-Caed5Y_fw1200.png");
            background-size: 100% 100%;

            p {
                // margin: 40px 0 0 10px;
                font-size: 30px;
                font-weight: 700;
            }

            span {
                margin: 5px 0 30px;
                display: block;
                font-size: 12px;
                line-height: 17px;
                color: #C7C7C7;
            }

            img {
                width: 75px;
            }

            .qiyeC {
                margin-top: 20px;
                font-size: 20px;
                font-weight: 500;
                line-height: 32px;
                width: 396px;
                color: #545454;
            }
        }

        ul {
            margin-top: 12px;
            width: 65%;

            // height: 90%;
            li {
                display: flex;
                position: relative;
                height: 47%;
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
                border-radius: 8px;

                &:nth-child(2) {
                    margin-top: 1.5%;
                }

                >img {
                    width: 40%;
                    height: 100%;
                    border-radius: 0;
                }

                >div {
                    // position: relative;
                    padding: 20px;
                    width: 55%;

                    p {
                        margin: 20px 0 20px;
                        font-size: 24px;
                        font-weight: 600;
                        line-height: 33px;
                    }

                    span {
                        font-size: 18px;
                        line-height: 25px;
                        color: #7D7D7D;
                        // display: -webkit-box;
                        // -webkit-line-clamp: 2;
                        // -webkit-box-orient: vertical;
                        // overflow: hidden;
                        // text-overflow: ellipsis;
                    }

                    div {
                        position: absolute;
                        right: 20px;
                        bottom: 20px;
                        display: flex;
                        align-items: center;

                        >img {
                            // position: absolute;
                            // right: 110px;
                            // bottom: 18px;
                            width: 25px;
                        }

                        .time {
                            // position: absolute;
                            // right: 20px;
                            // bottom: 19px;
                            color: #C0C0C1;
                            font-size: 16px;
                        }
                    }

                    .gengduo {
                        position: absolute;
                        right: 30px;
                        top: 50px;
                        width: 20px;
                        height: 4px;
                        background-image: url('../../assets/image/gengduo.png');
                        background-size: 100% 100%;
                    }
                }
            }
        }
    }

}

.customers {
    // position: relative;
    padding: 30px 0 70px;
    min-width: 1200px;
    // height: 300px;
    background-color: #F4F4F4;

    p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    button {
        margin: 50px 0;
        // margin-left: 46%;
        // margin-left: 45%;
        // min-width: 8%;
        width: 202px;
        height: 56px;
        padding: 5px 15px;
        // font-size: 26px;
        font-size: 26px;
        font-weight: 500;
        color: #fff;
        // background-color: #FE8F3F;
        background: linear-gradient(180deg, #FFB552 0%, #FF4D00 100%);
        border: 0;
        border-radius: 30px;
        text-align: center;
    }

    ul {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin: 60px auto 0;
        width: 1200px;

        li {
            margin-top: 10px;
            padding: 10px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24%;
            height: 100px;
            background-color: #fff;
            border-radius: 6px;
            text-align: center;

            &:hover {
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
            }
        }
    }
}

.sprites {
    // width: 100%;
    // height: 400px;
    height: 20.8vw;
    min-width: 1200px;
    min-height: 250px;
    display: flex;
    align-items: center;
    // padding: 6% 0 0;
    background-image: url("../../assets/image/50317a8c3c8d1db5edfb61a4fb177b99958263e013f2298-YeXw1S-2.png");
    background-size: 100% 100%;

    ul {
        margin: auto;
        display: flex;
        justify-content: space-around;
        min-width: 1200px;

        li {
            // margin-top: 100px;
            // margin-top: 5%;
            display: flex;
            flex-direction: column;
            align-items: center;

            .title {
                font-size: 24px;
                font-weight: 700;
                color: #333333;
            }

            p {
                text-align: center;
                color: #888888;
            }
        }
    }
}

::v-deep .el-dialog--center .el-dialog__body {
    padding-bottom: 0;
}

::v-deep .el-form {
    margin-top: 30px;
    margin-left: -20px;

    .el-form-item {
        .el-form-item__label {
            width: 70px !important;
        }

        .el-form-item__content {
            margin-left: 70px !important;
        }
    }
}

::v-deep .el-textarea__inner {
    height: 150px !important;
}

::v-deep .el-carousel__indicator {
    margin-bottom: 25px;
    margin-left: 8px;
    padding: 0;
    width: 10px;
    height: 10px;
    // height: 1px;
    // height: 2px;
    border-radius: 50%;
    background: #F0F0F0;
}

::v-deep .el-carousel__indicator button {
    display: none;
}

/* hover 时的样式 */
// ::v-deep .el-carousel__indicator:hover {
//     background-color: #5cb6ff;
// }

/* 当前激活指示器的样式 */
::v-deep .el-carousel__indicator.is-active {
    width: 40px;
    height: 10px;
    // height: 0px;
    background: #FFFFFF;
    border-radius: 8px;
    // background-color: #ff5700;
    // bottom: -40px;
    // margin-top: 1000px;
    // position: absolute;
    // position: relative;
    // top: 40px;
}

.shipin {
    // margin: 80px auto;
    padding: 50px 0;
    min-width: 1200px;
    // width: 1200px;
    background-color: #F4F4F4;

    .box {
        margin: auto;
        display: flex;
        width: 1200px;
        height: 530px;

        ul {
            padding: 23px 16px;
            width: 230px;
            background-color: #F26523;

            li {
                display: flex;
                margin-top: 20px;
                margin-left: 10px;

                &:first-child {
                    margin-top: 0;
                    margin-left: 0;
                }

                // align-items: center;

                .line {
                    margin-top: 5px;
                    width: 4px;
                    height: 18px;
                    background: #FFFFFF;
                    border-radius: 1px;
                }

                .neirong {
                    margin-left: 6px;

                    p {
                        font-weight: 500;
                        font-size: 20px;
                        color: #FFFFFF;
                        line-height: 28px;
                    }

                    span {
                        font-weight: 400;
                        font-size: 16px;
                        color: #FFFFFF;
                        line-height: 22px;
                    }
                }
            }
        }

        >div {
            flex-grow: 1;
        }
    }
}

::v-deep .show-imgs-container {
    height: 100%;
    cursor: pointer;

    .el-carousel {
        width: 100%;
        height: 100%;
    }

    .el-carousel__container {
        width: 100%;
        height: 100%;
    }

    .el-carousel__item {
        width: 100%;
        height: 100%;
        // border-radius: 10px;

        video {
            width: 100%;
            height: 100%;
            position: fixed;
            display: inline-block;
            vertical-align: baseline;
            object-fit: fill; //消除留白
        }

        .play-btn {
            font-size: 60px;
            color: #fff;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 9999;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .el-carousel__indicators--horizontal {
        display: none;
        // position: relative;
        // bottom: -40px;
        // background-color: #E6E6E6;
    }

    .el-carousel__indicator {
        background: #E6E6E6;
    }

    .el-carousel__indicator.is-active {
        background: #CFCFCF;
    }
}

.select {
    margin: 26px auto 0;
    width: 1200px;
    display: flex;
    // justify-content: center;

    .selectQ {
        margin-left: 573px;
        width: 10px;
        height: 10px;
        background-color: #E6E6E6;
        border-radius: 8px;

        &:nth-child(2) {
            margin-left: 8px;
        }

        &:nth-child(3) {
            margin-left: 8px;
        }
    }

    .choice {
        // margin-left: 8px;
        width: 40px;
        background-color: #CFCFCF;
    }
}

::v-deep .video::-webkit-media-controls-play-button {
    display: none !important;
}

.video {
    width: 100%;
    height: 100%;
    display: block;
}

.poster {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    z-index: 0;
}

.batop {
    min-height: 310px !important;
}

.player {
    position: relative;
    top: -2px;
    left: 900px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 22px;
    height: 22px;
    cursor: pointer;
    margin-top: 17px;
    // z-index: 9999 !important;
}

.unsound {
    background-image: url("../../assets/icon/close.png");
}

.sound {
    // display: none;
    background-image: url("../../assets/icon/open.png");
}
</style>
<style>
@media screen and (max-width:1200px) {
    .batop .el-carousel__container {
        height: 310px !important;
    }

    .topImg {
        width: 1200px !important;
        height: 310px !important;
    }
}

@media screen and (min-width:1201px) {
    .batop .el-carousel__container {
        height: 26vw !important;
    }
}
</style>