import { render, staticRenderFns } from "./kuaijiView.vue?vue&type=template&id=eccff652&scoped=true"
import script from "./kuaijiView.vue?vue&type=script&lang=js"
export * from "./kuaijiView.vue?vue&type=script&lang=js"
import style0 from "./kuaijiView.vue?vue&type=style&index=0&id=eccff652&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eccff652",
  null
  
)

export default component.exports