<template>
    <!-- <div style="position: relative;display: flex;align-items: center;min-width: 1200px;"> -->
    <div style="position: relative;" class="bigBox">
        <div class="back">
            <img src="../../assets/image/logo.png" class="logo">
            <el-menu :default-active="path" :unique-opened="true" class="el-menu-demo" mode="horizontal"
                @select="handleSelect" active-text-color="#EA5514" :router="true" menu-trigger="click">
                <template v-for="item in list">
                    <el-menu-item :key="item.id" :index="item.classifyPath" class="showMenu">
                        <span slot="title" v-html="item.label"></span>
                        <div class="childitem" v-if="item.children"
                            :class="item.label == 'Group <br> Service' ? 'left' : ''">
                            <div v-for="child in item.children" :key="child.id" :index="child.classifyPath"
                                class="isShow itemFlex">
                                <span slot="title" @click.stop="gotoPage(child.classifyPath)">
                                    <img :src="imgurl + child.classifyImg" alt="" style="width: 0;height: 0;"
                                        :class="item.label == 'About <br> Tang Cheng' || item.label == 'Social <br> responsibility' ? 'huise' : ''">
                                    <img :src="imgurl + child.classifyImg2" alt="" style="width: 0;height: 0;"
                                        :class="item.label == 'About <br> Tang Cheng' || item.label == 'Social <br> responsibility' ? 'jvse' : ''">
                                    {{ child.label }}
                                    <img src="../../assets/icon/jianH.png" alt=""
                                        :class="item.label == 'About <br> Tang Cheng' || item.label == 'Social <br> responsibility' ? 'jianH showJ' : 'jianH'">
                                    <img src="../../assets/icon/jianO.png" alt=""
                                        :class="item.label == 'About <br> Tang Cheng' || item.label == 'Social <br> responsibility' ? 'jianO showJ' : 'jianO'">
                                </span>
                                <div v-if="child.children" class="childitem2">
                                    <ul>
                                        <li slot="title" v-for="grandson in child.children" :key="grandson.id"
                                            @click.stop="gotoPage(grandson.classifyPath)">
                                            {{ grandson.label }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </el-menu-item>
                </template>
            </el-menu>
            <div class="tel">
                <img src="../../assets/icon/telGary.png">
                <span>400-168-5699</span>
            </div>
        </div>
        <div class="change" @mouseleave="isChange = false">
            <button @mouseenter="isChange = true">English<img src="../../assets/icon/jianO.png" alt=""></button>
            <div v-if="isChange">
                <p @click="changeChinese">中文</p>
                <p @click="changeEnglish">English</p>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            activeIndex: '1',
            list: [],
            imgurl: '',
            path: '/',
            isChange: false,
        };
    },
    created() {
        this.imgurl = this.$store.state.imgurl
        this.getcdList()
    },
    mounted() {
        if (this.type != null) {
            if (this.type == 1) {
                this.path = '/indexE'
                // console.log("ceshi");
            } else if (this.type == 2) {
                this.path = '/zixunE'
            } else if (this.type == 3) {
                this.path = '/shangxiehuiE'
            }
        } else {
            // this.path = this.$route.path
            if (this.$route.path == '/zizhiE' || this.$route.path == '/qidaiE' || this.$route.path == '/zongheE') {
                this.path = '/kuaijiE'
            } else if (this.$route.path == '/dongshizhangE' || this.$route.path == '/qiyewenhuaE' || this.$route.path == '/jituangouchengE' || this.$route.path == '/fazhanlichengE' || this.$route.path == '/quanqiubujvE') {
                this.path = '/aboutusE'
            } else if (this.$route.path == '/xiaoqiE' || this.$route.path == '/gongyiE') {
                this.path = '/shangxiehuiE'
            } else {
                this.path = this.$route.path
            }
        }
    },
    methods: {
        navTab(path) {
            this.$router.push({ path: path })
        },
        getcdList() {
            axios.get('/api/pcsystem/TckgClassify/treeselect', { params: { language: "1" } }).then(
                (res) => {
                    this.list = res.data.data
                }
            ).catch(
                console.log("失败")
            )
        },
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
        },
        gotoPage(path) {
            this.$router.push({ path: path })
        },
        goHome() {
            this.$router.push({ path: '/', query: { key: '1' } })
        },
        changeChinese() {
            this.isChange = false
            this.goHome()
        },
        changeEnglish() {
            this.isChange = false
        }
    },
    props: {
        type: {
            type: String,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.back {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2vh auto;
    width: 1200px;

    // height: 100px;
    .logo {
        width: 186px;
        height: 36px;
    }

    ul {
        display: flex;
        justify-content: space-around;

        li {
            margin-left: 2vw;
            padding-bottom: 5px;
            cursor: pointer;

        }
    }

    ::v-deep .el-tabs__header {
        margin: 0;
    }

    ::v-deep .el-menu.el-menu--horizontal {
        border: 0;

        li {
            font-size: 16px;
            margin-left: 10px;
            padding-bottom: 2px;

            .el-submenu__title {
                font-size: 16px;
            }
        }
    }

    .tel {
        display: flex;
        align-items: center;

        img {
            width: 22px;
            height: 20px;
        }

        span {
            margin-left: 0.3vw;
            font-size: 20px;
            font-weight: 500;
        }
    }
}

.jtzx {
    padding: 10px 20px;

    .el-menu-item {
        padding: 10px 0;
        border-bottom: 1px dashed #E3E1DF;
        cursor: pointer;

        &:hover {
            p {
                color: #E6540E;

            }
        }
    }
}

.el-menu-demo {
    // margin-top: -10px;
    z-index: 9999;
}

.childitem {
    cursor: pointer;
    color: #545452;
    position: absolute;
    top: 105%;
    margin-left: -100px;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    padding: 0 20px;
}

.childitem2 {
    top: 0;
    left: 100%;
    width: 950px;
    background: #fff;
}

.childitem2 ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: center;
    margin-top: 6px;
    margin-left: 0px;
    font-family: PingFangSC;

    li {
        font-size: 12px !important;
        font-weight: 400;
        line-height: 17px;

        &:hover {
            color: #E6540E;
        }
    }
}

.itemFlex {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px dashed #979797;
    min-width: 150px;

    &:last-child {
        border-bottom: 0;
    }

    >span {
        display: block;
        min-width: 310px;
        font-weight: 500;
        font-size: 16px;
        text-align: start;
    }

    .jvse,
    .huise {
        position: relative;
        top: -2px;
        margin-right: 1px;
        width: 18px !important;
        height: 18px !important;
    }

    .jvse,
    .jianO {
        display: none;
    }

    .showJ {
        position: absolute;
        right: 10px;
        top: 18px;
    }

    &:hover {
        span {
            color: #E6540E;
        }

        .huise,
        .jianH {
            display: none;
        }

        .jvse,
        .jianO {
            display: inline-block;
        }

    }
}

.el-menu--horizontal>.el-menu-item {
    line-height: 65px;
}

.isShow {
    display: none;
}

.showMenu {
    padding: 0 10px 2px !important;
    line-height: 25px !important;
    font-size: 17px !important;
    color: #333333 !important;
    font-weight: 400 !important;
    text-align: center;
}

.is-active {
    color: #EA5514 !important;
}

.showMenu:hover .isShow {
    display: flex;
}

.left {
    margin-left: -400px;

    .itemFlex {
        >span {
            min-width: 110px;
            font-weight: 600;
        }
    }
}

.change {
    position: absolute;
    top: 16px;
    right: 40px;
    z-index: 9999;

    button {
        display: flex;
        align-items: center;
        padding: 2px 5px;
        padding-right: 15px;
        border: 0;
        color: #EB5414;
        font-size: 18px;
        font-weight: 600;
        line-height: 20px;
        background-color: #fff;

        img {
            margin-left: 4px;
            width: 10px;
            height: 15px;
            transform: rotate(90deg);
        }
    }

    >div {
        border: 1px solid #e2e2e2;
        cursor: pointer;
        color: #545452;
        position: absolute;
        top: 105%;
        background: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        padding: 10px 0px;
        z-index: 9999;

        p {
            padding: 5px 20px;
            border-left: 3px solid transparent;

            &:hover {
                border-left: 3px solid #EB5414;
                background-color: #FDEEE8;
                color: #EB5414;
            }
        }
    }

    &:hover {
        img {
            transform: rotate(-90deg);
        }
    }
}
</style>

<style lang="scss" scoped>
@media screen and (max-width:1500px) {
    .bigBox {
        // width: 1350px;
        width: 100%;
    }

    .back {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 2vh auto;
        width: 1100px;

        .logo {
            width: 186px;
            height: 36px;
        }

        ul {
            display: flex;
            justify-content: space-around;

            li {
                margin-left: 0;
                padding-bottom: 5px;
                cursor: pointer;
            }
        }

        ::v-deep .el-tabs__header {
            margin: 0;
            // margin-top: -10px;
        }

        ::v-deep .el-menu.el-menu--horizontal {
            border: 0;

            li {
                font-size: 16px;
                margin-left: 0px;
                padding-bottom: 2px;

                .el-submenu__title {
                    font-size: 16px;
                }

            }
        }

        ::v-deep .el-menu-item {
            padding: 0 9px !important;
        }

        .tel {
            // margin-left: 2vw;
            display: flex;
            align-items: center;

            img {
                // width: 18px;
                // height: 14px;
                width: 22px;
                height: 20px;
            }

            span {
                margin-left: 0.3vw;
                font-size: 20px;
                font-weight: 500;
            }
        }
    }

    .change {
        position: absolute;
        top: 16px;
        right: 20px;
        z-index: 9999;

        button {
            display: flex;
            align-items: center;
            padding: 2px 5px;
            padding-right: 15px;
            border: 0;
            color: #EB5414;
            font-size: 18px;
            font-weight: 600;
            line-height: 20px;
            background-color: #fff;

            img {
                margin-left: 4px;
                width: 10px;
                height: 15px;
                transform: rotate(90deg);
            }
        }

        >div {
            border: 1px solid #e2e2e2;
            cursor: pointer;
            color: #545452;
            position: absolute;
            top: 105%;
            margin-left: -20px;
            background: #fff;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
            padding: 10px 0px;
            z-index: 9999;

            p {
                padding: 5px 20px;
                border-left: 3px solid transparent;

                &:hover {
                    border-left: 3px solid #EB5414;
                    background-color: #FDEEE8;
                    color: #EB5414;
                }
            }
        }

        &:hover {
            img {
                transform: rotate(-90deg);
            }
        }
    }
}

@media screen and (max-width:1350px) {
    .bigBox {
        // width: 1200px;
        width: 100%;
    }

    .back {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 2vh ;
        width: 90%;

        .logo {
            width: 186px;
            height: 36px;
        }

        ul {
            display: flex;
            justify-content: space-around;

            li {
                margin-left: 0;
                padding-bottom: 5px;
                cursor: pointer;
            }
        }

        ::v-deep .el-tabs__header {
            margin: 0;
            // margin-top: -10px;
        }

        ::v-deep .el-menu.el-menu--horizontal {
            border: 0;

            li {
                font-size: 16px;
                margin-left: 0px;
                padding-bottom: 2px;

                .el-submenu__title {
                    font-size: 16px;
                }

            }
        }

        ::v-deep .el-menu-item {
            padding: 0 9px !important;
        }

        .tel {
            // margin-left: 2vw;
            display: flex;
            align-items: center;

            img {
                // width: 18px;
                // height: 14px;
                width: 22px;
                height: 20px;
            }

            span {
                margin-left: 0.3vw;
                font-size: 20px;
                font-weight: 500;
            }
        }
    }
    .change{
        right: 0;
    }
}

@media screen and (max-width:1250px) {
    .bigBox {
        width: 1200px;
    }

    .back {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 2vh 0;
        width: 1080px;

        .logo {
            width: 186px;
            height: 36px;
        }

        ul {
            display: flex;
            justify-content: space-around;

            li {
                margin-left: 0;
                padding-bottom: 5px;
                cursor: pointer;
            }
        }

        ::v-deep .el-tabs__header {
            margin: 0;
            // margin-top: -10px;
        }

        ::v-deep .el-menu.el-menu--horizontal {
            border: 0;

            li {
                font-size: 16px;
                margin-left: 0px;
                padding-bottom: 2px;

                .el-submenu__title {
                    font-size: 16px;
                }

            }
        }

        ::v-deep .el-menu-item {
            padding: 0 7px!important;
        }

        .tel {
            // margin-left: 2vw;
            display: flex;
            align-items: center;

            img {
                // width: 18px;
                // height: 14px;
                width: 22px;
                height: 20px;
            }

            span {
                margin-left: 0.3vw;
                font-size: 20px;
                font-weight: 500;
            }
        }
    }
}
</style>