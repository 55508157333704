<template>
    <div>
        <top-view :type="type"></top-view>
        <div class="backTop">
        </div>
        <div class="gongyi">
            <p><img src="../../assets/image/left.png">&nbsp;Public charity&nbsp;<img src="../../assets/image/right.png"></p>
            <ul>
                <li v-for="item in dataList" :key="item.dynamicId" @click="gotoContent(item.dynamicId)">
                    <img :src="imgurl + item.dynamicImg">
                    <div>
                        <div style="background-color: #E3F0C8;width: 72px;height: 8px;border-radius: 4px;">
                            <p style="border: 4px solid #8FC31F;width: 30px;border-radius: 4px;"></p>
                        </div>
                        <p>{{ item.dynamicTitle }}</p>
                        <span v-html="item.dynamicContent"></span>
                    </div>
                </li>
            </ul>
        </div>

        <footer-view></footer-view>
        <side-bar class="fixed"></side-bar>

    </div>
</template>

<script>
import topView from '@/components/en/topView.vue'
import footerView from '@/components/en/footerView.vue'
import SideBar from '@/components/en/sideBar.vue'
import axios from 'axios';

export default {
    components: { footerView, SideBar, topView },
    data() {
        return {
            infoShow: false,
            imgurl: '',
            dataList: [],
            type:'3'
        }
    },
    created() {
        // this.$store.state.componentKey += 1
        this.getdataList()
        this.website()
        this.imgurl = this.$store.state.imgurl
    },
    methods: {
        gotoAboutus() {
            this.$router.push('/aboutus')
        },
        getdataList() {
            axios.get('/api/pcsystem/TckgDynamic/list', { params: { dynamicType: '6',language:'1' } }).then((res) => {
                if (res.status == 200) {
                    this.dataList = res.data.rows
                    console.log(this.dataList);
                }
            }).catch()
        },
        // gotoContent(id) {
        //     this.$router.push({ path: '/content', query: { id: id, type: this.type } })
        // },
        //网站访问信息
        website() {
            axios.post('/api/pcsystem/TckgSoftLogin', { userType: '唐诚公益' }).then((res) => {
                console.log(res);
            }).catch()
        },
    }

}
</script>

<style lang="scss" scoped>
.fixed {
    position: fixed;
    top: 150px;
    right: 5px;
}

.backTop {
    position: relative;
    width: 100%;
    height: 26vw;
    min-width: 1200px;
    min-height: 310px;
    background-image: url("../../assets/image/gongyiEBack.png");
    background-size: 100% 100%;
}


.gongyi {
    padding: 60px 0;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    ul {
        margin: 60px auto 20px;
        width: 1200px;

        li {
            margin-top: 20px;
            display: flex;
            justify-content: space-around;
            padding: 30px 20px;
            background-color: #fff;
            border-radius: 6px;

            img {
                width: 387px;
                height: 250px;
                border-top-right-radius: 50px;
                border-bottom-left-radius: 50px;
            }

            >div {
                margin-left: 10px;
                width: 60%;

                >div {
                    margin-top: 40px;
                }

                >p {
                    margin: 20px 0 30px;
                    font-size: 22px;
                    line-height: 30px;
                    font-weight: 500;
                }

                >span {
                    width: 90%;
                    font-size: 16px;
                    line-height: 25px;
                    color: #AAAAAA;
                }
            }

            &:hover {
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
            }

        }
    }
}
</style>