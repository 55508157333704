<template>
  <div class="app">
    <ul>
      <li>
        <!-- <img src="../assets/image/logoW.png" alt=""> -->
        <div>
          <p class="title">业务板块</p>
          <p @click="gotoKuaiji">唐诚会计</p>
          <p @click="gotoZizhi">唐诚资质</p>
          <p @click="gotoQidai">唐诚企贷</p>
          <p @click="gotoHaiwai">唐诚国际</p>
          <p @click="gotoZonghe">知识产权</p>
        </div>
        <div>
          <p class="title">关于我们</p>
          <p @click="gotoJianjie">集团简介</p>
          <p @click="gotoDongshizhang">董事长寄语</p>
          <p @click="gotoQiyewenhua">集团文化</p>
          <p @click="gotoJituangoucheng">集团构成</p>
          <p @click="gotoFazhanlicheng">发展历程</p>
          <p @click="gotoQuanqiubujv">全球布局</p>
        </div>
      </li>
      <li>
        <p class="title">联系我们</p>
        <div>
          <ul>
            <li>北京分公司：18600685558</li>
            <li>香港分公司：13651148758</li>
            <li>上海分公司：15001353048</li>
            <li>广州分公司：13710179815</li>
            <li>深圳分公司：19065022582</li>
            <li>天津分公司：18222209456</li>
            <li>南京分公司：15600082051</li>
          </ul>
          <ul>
            <li>西安分公司：15234047779</li>
            <li>成都分公司：19136165950</li>
            <li>杭州分公司：18910752234</li>
            <li>武汉分公司：17702720911</li>
            <li style="font-size: 12px;">石家庄分公司：<span style="font-size: 14px;">15176843555</span></li>
            <li>廊坊分公司：15731642111</li>
          </ul>
        </div>
      </li>
      <li>
        <p class="title">关注我们</p>
        <div>
          <div>
            <img src="../../assets/image/weixinE.png" alt="">
            <p>微信公众号</p>
          </div>
          <div>
            <img src="../../assets/image/shipinE.png" alt="">
            <p>关注视频号</p>
          </div>
          <div>
            <img src="../../assets/image/douyinE.png" alt="">
            <p>关注抖音号</p>
          </div>
        </div>

      </li>
    </ul>
    <p>版权所有，侵权必究&#169;{{ obj.configName }}。保留一切权利。<span>{{ obj.icpBa }}</span></p>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      obj: {}
    }
  },
  methods: {
    getObj() {
      axios.get('/api/pcsystem/TckgConfig/list',{params:{language:'0'}}).then((res) => {
        if (res.status == 200) {
          this.obj = res.data.rows[0]
          // console.log(res);
        }
      }).catch()
    },
    gotoKuaiji() {
      this.$router.push({ path: '/kuaiji', query: { key: '2' } })
    },
    gotoZonghe() {
      this.$router.push({ path: '/zonghe', query: { key: '2' } })
    },
    gotoZizhi() {
      this.$router.push({ path: '/zizhi', query: { key: '2' } })
    },
    gotoHaiwai() {
      this.$router.push({ path: '/haiwai', query: { key: '3' } })
    },
    gotoQidai() {
      this.$router.push({ path: '/qidai', query: { key: '2' } })
    },
    goHome() {
      this.$router.push({ path: '/', query: { key: '1' } })
    },
    gotoShangye() {
      this.$router.push({ path: '/shangye', query: { key: '4' } })
    },
    gotoZixun() {
      this.$router.push({ path: '/zixun', query: { key: '5' } })
    },
    gotoJianjie() {
      this.$router.push({ path: '/aboutus', query: { key: '6' } })
    },
    gotoDongshizhang() {
      this.$router.push({ path: '/dongshizhang', query: { key: '6' } })
    },
    gotoQiyewenhua() {
      this.$router.push({ path: '/qiyewenhua', query: { key: '6' } })
    },
    gotoJituangoucheng() {
      this.$router.push({ path: '/jituangoucheng', query: { key: '6' } })
    },
    gotoFazhanlicheng() {
      this.$router.push({ path: '/fazhanlicheng', query: { key: '6' } })
    },
    gotoQuanqiubujv() {
      this.$router.push({ path: '/quanqiubujv', query: { key: '6' } })
    },
  },
  created() {
    this.getObj()
  }

}
</script>

<style lang="scss" scoped>
.app {
  width: 100%;
  min-width: 1200px;
  // height: 150px;
  background-color: #351304;

  >ul {
    margin: auto;
    // padding: 70px 0 40px;
    padding: 38px 0;
    display: flex;
    // justify-content: space-between;
    // align-items: center;
    width: 1200px;

    >li {
      // width: 33%;
      // text-align: center;
      text-align: start;
      color: #fff;

      &:nth-child(1) {
        display: flex;
        justify-content: space-between;
        color: #fff;
        text-align: start;

        div {
          &:nth-child(2) {
            margin: 0 136px;
          }

          p {
            font-weight: 500;
            font-size: 14px;
            color: #FFFFFF;
            line-height: 22px;
            // opacity: 0.6;
          }
        }
      }

      &:nth-child(2) {
        div {
          display: flex;

          ul {
            font-weight: 500;
            font-size: 14px;
            color: #FFFFFF;
            line-height: 22px;

            &:nth-child(2) {
              margin-left: 25px;
            }
          }
        }
      }

      &:last-child {
        margin-left: 136px;

        // img{
        //   border-radius: 6px;
        // }
        >div {
          display: flex;
          justify-content: space-around;
          border: 0;

          div {
            margin-left: 25px;

            &:first-child {
              margin-left: 0;
            }
          }

          p {
            font-size: 14px;
            // color: #AEA19B;
            color: #fff;
          }
        }


      }
      p{
        opacity: 0.6;
        margin-bottom: 8px;
      }
      li{
        margin-bottom: 8px;
        opacity: 0.6;
      }
    }
  }

  >p {
    background-color: #280C00;
    margin: 0 auto;
    padding: 34px 0;
    text-align: center;
    // color: #5C5C5C;
    color: #fff;
    //border-top: 1px solid #fff;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 20px;

    // span {
    //   color: #fff;
    // }
  }

  .title {
    margin-bottom: 18px;
    font-size: 18px !important;
    font-weight: 500;
    color: #fff;
    opacity: 1;
  }
}
</style>