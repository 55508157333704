import { render, staticRenderFns } from "./zongheEView.vue?vue&type=template&id=7132b813&scoped=true"
import script from "./zongheEView.vue?vue&type=script&lang=js"
export * from "./zongheEView.vue?vue&type=script&lang=js"
import style0 from "./zongheEView.vue?vue&type=style&index=0&id=7132b813&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7132b813",
  null
  
)

export default component.exports