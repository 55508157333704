<template>
    <div>
        <top-view></top-view>
        <div class="backTop">
            <!-- <div>
                <p>社会责任</p>
                <span class="english">SOCIAL RESPONSIBILITY</span>
                <button @click="gotoAboutus">了解更多</button>
            </div> -->
        </div>
        <div class="jiuye">
            <p><img src="../../assets/image/left.png">&nbsp;跑出保障就业加“数”度&nbsp;<img src="../../assets/image/right.png"></p>
            <span>
                RUN OUT OF GUARANTEED EMPLOYMENT PLUS "NUMBER" DEGREE</span>
            <div>
                <div>
                    <img src="../../assets/image/xiaoqi/pic1.png" alt="">
                    <img src="../../assets/image/xiaoqi/pic2.png" alt="">
                    <img src="../../assets/image/xiaoqi/pic3.png" alt="">
                </div>
                <ul>
                    <li>
                        <p>就业是民生之本，稳就业就是稳民生，促就业就是促发展。稳就业就要保障重点群体，拓宽就业渠道，高校就业生是宝贵的人才资源，同时也是保就业稳就业的重点群体。</p>
                    </li>
                    <li>
                        <p>分行业、分区域挖倔开发适合高校毕业生的就业岗位，举办区域、行业、专业等定制式招聘活动，把促进高校生就业工作摆在突出位置。</p>

                    </li>
                    <li>
                        <p>助力首都经济贸易大学、山西大学工商学院、辽宁理工大学、内蒙古包头职业技术学院等几所高校解决学生就业难题，三年来累计为应届大学生提供就业岗位200+。</p>

                    </li>
                </ul>
            </div>
        </div>
        <div class="bangfu">
            <p><img src="../../assets/image/left.png">&nbsp;帮扶小企业 援企“有温度”&nbsp;<img src="../../assets/image/right.png"></p>
            <span>
                HELPING SMALLBUSINESSES WITH WARMTH
            </span>
            <div>
                <div>
                    <ul>
                        <li>
                            <p>新冠疫情的爆发对全球经济造成了严重冲击，对小微企业来说更是致命一击。作为小微企业服务者，帮扶小微企业，唐诚义不容辞。</p>
                        </li>
                        <li>
                            <p>疫情期间，为陷入经营困境的全国超1000家小微企业提供3-12个月免费记账服务。免费为企业跨区域业务办理12000余次，助力企业复产复工。</p>

                        </li>
                    </ul>
                    <div>
                        <img src="../../assets/image/xiaoqi/zhengtiback.png" alt="">
                        <!-- <img src="../assets/image/xiaoqi/pic2.png" alt="">
                    <img src="../assets/image/xiaoqi/pic3.png" alt=""> -->
                    </div>
                </div>
            </div>
        </div>

        <footer-view></footer-view>
        <side-bar class="fixed"></side-bar>

    </div>
</template>

<script>
import topView from '@/components/zh/topView.vue'
import footerView from '@/components/zh/footerView.vue'
import SideBar from '@/components/zh/sideBar.vue'
import axios from 'axios';

export default {
    components: { footerView, SideBar, topView },
    data() {
        return {
            infoShow: false,
        }
    },
    created() {
        // this.$store.state.componentKey += 1
        this.website()
    },
    methods: {
        gotoAboutus() {
            this.$router.push('/aboutus')
        },
        //网站访问信息
        website() {
            axios.post('/api/pcsystem/TckgSoftLogin', { userType: '校企合作' }).then((res) => {
                console.log(res);
            }).catch()
        },
    }

}
</script>

<style lang="scss" scoped>
.fixed {
    position: fixed;
    top: 150px;
    right: 5px;
}

.backTop {
    position: relative;
    width: 100%;
    height: 26vw;
    min-width: 1200px;
    min-height: 310px;
    // height: 500px;
    // height: 450px;
    background-image: url("../../assets/image/xiaoqiBack.png");
    background-size: 100% 100%;

    // div {
    //     margin: auto;
    //     padding-top: 80px;
    //     width: 1160px;
    //     color: #EA5514;
    //     text-align: center;
    // }

    // p {
    //     margin: 20px 0;
    //     color: #fff;
    //     font-size: 50px;
    //     font-weight: 700;
    // }

    // .english {
    //     display: block;
    //     font-size: 16px;
    //     color: #fff;
    // }

    // button {
    //     margin-top: 30px;
    //     width: 100px;
    //     height: 30px;
    //     line-height: 30px;
    //     color: #fff;
    //     background-color: #FFB258;
    //     border: 0;
    //     border-radius: 15px;
    // }
}


.jiuye {
    // position: relative;
    // margin: auto;
    padding: 60px 0;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    >div {
        margin: 50px auto 30px;
        display: flex;
        justify-content: space-between;
        width: 1200px;

        >div {
            width: 48%;

            img {
                margin: 3px 5px;
                width: 48%;
                // height: 200px;

                &:first-child {
                    width: 98%;
                }
            }
        }

        ul {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 52%;
            margin-left: 10px;
            margin-top: -20px;

            li {
                padding: 20px 0 0;
                width: 100%;
                height: 140px;
                background-image: url('../../assets/image/xiaoqi/back.png');
                background-size: 100% 100%;

                &:nth-child(2) {
                    background-image: url('../../assets/image/xiaoqi/backtwo.png');
                }

                p {
                    margin: 20px;
                    // line-height: 24px;
                    font-size: 20px;
                    line-height: 34px;
                }

                // &:first-child{
                //     margin-top: -20px;
                // }
            }
        }
    }


}

.bangfu {
    // position: relative;
    // margin: auto;
    // padding: 60px 0 0;
    min-width: 1200px;
    background-color: #F4F4F4;


    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    >div {
        margin-top: 60px;
        padding: 40px 0 60px;
        background-image: url('../../assets/image/xiaoqi/background.png');
        background-size: 100% 100%;

        >div {
            margin: 50px auto 10px;
            display: flex;
            justify-content: space-between;
            // align-items: center;
            width: 1200px;

            >div {
                width: 55%;

                img {
                    margin: 3px 5px;
                    // width: 48%;
                    // height: 135px;

                    &:first-child {
                        // width: 96%;
                        width: 620px;
                        height: 380px;
                    }
                }
            }

            ul {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 45%;
                margin-top: -28px;
                // margin-right: 20px;

                li {
                    padding: 40px 40px;
                    width: 88%;
                    min-height: 140px;
                    background-image: url('../../assets/image/xiaoqi/backT.png');
                    background-size: 100% 100%;

                    &:last-child {
                        margin-top: 2px;
                        background-image: url('../../assets/image/xiaoqi/backF.png');
                        p{
                            margin-top: 0px;
                        }
                    }

                    p {
                        margin: 20px 0;
                        // line-height: 24px;
                        font-size: 20px;
                        line-height: 34px;
                    }
                }
            }
        }
    }
}
</style>