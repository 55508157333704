<template>
    <div class="app">
        <!-- <el-tooltip class="item" effect="light" content="zixun" placement="left-start">
            <div slot="content" class="contentB">
                <p class="name">欢迎来电</p>
                <p class="content"><img src="../assets/icon/right.png" style="left: -8px;">一站式服务<img src="../assets/icon/left.png" style="left: 100px;"></p>
                <p class="orangeS" style="margin-top: 5px;position: relative;margin-left: 25px;font-weight: 700;"><img src="../assets/icon//sideTel.png" style="position: absolute;left: -20px;top: -2px;">176-0039-5273</p>
            </div> -->
        <div class="isshow">
            <div class="contentB">
                <div class="sanj"></div>
                <p class="name">欢迎来电</p>
                <p class="content"><img src="../../assets/icon/right.png">一站式服务<img src="../../assets/icon/left.png"></p>
                <p class="orangeS orangeT">
                    <img src="../../assets/icon//sideTel.png">176-0039-5273
                </p>
            </div>
            <el-button class="item">
                <img src="../../assets/icon/tel.png" class="pic">
                <p>咨询我们</p>
            </el-button>
        </div>
        <div class="isshow">
            <div class="contentB">
                <div class="sanj"></div>
                <p class="name">唐诚控股</p>
                <p class="content"><img src="../../assets/icon/right.png">一站式服务<img src="../../assets/icon/left.png"></p>
                <img src="../../assets/image/gongzhonghaoEWM.png" class="erweima">
                <p class="orangeS">关注唐诚控股公众号</p>
            </div>
            <el-button class="item">
                <img src="../../assets/icon/weixin.png" class="pic">
                <p>微信公众号</p>
            </el-button>
        </div>
        <div class="isshow">
            <div class="contentB">
                <div class="sanj"></div>
                <p class="name">唐诚控股</p>
                <p class="content"><img src="../../assets/icon/right.png">一站式服务<img src="../../assets/icon/left.png"></p>
                <img src="../../assets/image/douyinEWM.png" class="erweima">
                <p class="orangeS">关注唐诚控股抖音号</p>
            </div>
            <el-button class="item">
                <img src="../../assets/icon/douyin.png" class="pic">
                <p>抖音账号</p>
            </el-button>
        </div>


        <!-- </el-tooltip> -->
        <!-- <el-tooltip class="item" effect="light" content="weixin" placement="left-start">
            <div slot="content">
                <p class="name">唐诚控股</p>
                <p class="content"><img src="../assets/icon/right.png">一站式服务<img src="../assets/icon/left.png"></p>
                <img src="../assets/image/gongzhonghaoEWM.png">
                <p class="orangeS">关注唐诚控股公众号</p>
            </div>
            <el-button>
                <img src="../assets/icon/weixin.png" class="pic">
                <p>微信公众号</p>
            </el-button>
        </el-tooltip>
        <el-tooltip class="item" effect="light" content="douyin" placement="left-start">
            <div slot="content">
                <p class="name">唐诚控股</p>
                <p class="content"><img src="../assets/icon/right.png">一站式服务<img src="../assets/icon/left.png"></p>
                <img src="../assets/image/douyinEWM.png">
                <p class="orangeS">关注唐诚控股抖音号</p>
            </div>
            <el-button>
                <img src="../assets/icon/douyin.png" class="pic">
                <p>抖音账号</p>
            </el-button>
        </el-tooltip> -->
        <!-- <el-tooltip class="item" effect="dark" placement="left-start"> -->
        <!-- <el-backtop target=".page-component__scroll .el-scrollbar__wrap" :bottom="100"> -->

        <el-backtop style="margin-top: 10px;">
            <el-button class="item">
                <img src="../../assets/icon/xiangshang.png" class="pic">
            </el-button>
        </el-backtop>
        <!-- </el-backtop> -->
        <!-- </el-tooltip> -->
    </div>
</template>

<script>
export default {
    data() {
        return {
        }
    }
}
</script>

<style lang="scss" scoped>
.app {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 9999;
}

.isshow {
    margin-top: 10px;
    position: relative;
    // width: 300px;
    // box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    // .item{
    //     position: absolute;
    //     right: 0;
    //     &:nth-child(2){
    //         top: 80px;
    //     }
    //     &:last-child{
    //         top: 160px;
    //     }
    // }
}

.isshow .contentB {
    position: absolute;
    right: 130px;
    padding: 17px 30px;
    display: none;
    background-color: #fff;
    width: 160px;
    // height: 323px;
    border-radius: 6px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    text-align: center;

    .sanj {
        position: absolute;
        right: -20px;
        // width: 10px;
        // height: 10px;
        border: 10px solid #fff;
        border-top: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid transparent;
        // background-color: red;
    }

    // .name {
    //     font-weight: 500;
    //     font-size: 24px;
    //     color: #333333;
    //     line-height: 33px;
    // }

    // .content {
    //     position: relative;
    //     font-weight: 400;
    //     font-size: 12px;
    //     color: #999999;
    //     line-height: 17px;
    //     img{
    //         position: absolute;
    //     }
    // }

    // .orangeS {
    //     // font-size: 20px;
    //     color: #EA5412;
    //     font-weight: 500;
    //     font-size: 14px;
    //     color: #EA5514;
    // }

    .erweima {
        width: 154px !important;
        height: 154px !important;
    }
}

.isshow:hover .contentB {
    display: block;
    // width: 300px;
}

// .isshow:hover{
//     width: 300px;
//     .item{
//         // position: absolute;
//         right: 0;
//     }
// }

.item {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    // border: none !important;
}

// .contentB{
//     border: 1px solid red;
// }

::v-deep .is-light {
    border: 0 !important;
}

.el-button.el-button,
.el-checkbox.is-bordered.el-checkbox.is-bordered,
.el-backtop {
    width: 110px;
    border: none;
}

.el-button+.el-button,
.el-checkbox.is-bordered+.el-checkbox.is-bordered {
    margin: 10px 0 0 0 !important;
}

.pic {
    width: 30px;
    height: 30px;
}

::v-deep .el-backtop {
    top: 398px;
    right: 5px !important;
}

.name {
    // margin-bottom: 5px;
    // text-align: center;
    // font-size: 16px;
    // font-weight: 600;
    font-weight: 500;
    font-size: 24px;
    color: #333333;
}

.content {
    position: relative;
    // text-align: center;
    // font-size: 12px;
    // color: #B4B4B4;
    font-weight: 400;
    font-size: 12px;
    color: #999999;

    img {
        position: absolute;
        top: 7px;
        left: 8px;
        width: 38px;

        &:last-child {
            left: 113px;
        }
    }
}

.orangeS {
    // font-size: 14px;
    // color: #EA5615;
    // text-align: center;
    // // background: none;
    font-weight: 500;
    font-size: 14px;
    color: #EA5514;
}

.orangeT {
    width: 100%;
    margin-top: 5px;
    position: relative;
    margin-left: 10px;
    font-size: 20px;
    color: #EA5412;
    line-height: 24px;
    text-align: center;

    // font-weight: 700;
    img {
        position: absolute;
        left: -14px;
        top:4px;
    }
}
</style>