<template>
    <div>
        <top-view></top-view>
        <div class="backTop">
        </div>
        <div style="min-width: 1200px;">
            <ul class="fuwu">
                <li>
                    <p class="num">{{obj.briefYgNumber}}<span style="font-size: 14px;font-weight: 500;">people</span></p>
                    <span>Employee</span>
                </li>
                <li></li>
                <li>
                    <p class="num">{{obj.briefZyrcNumber}}<span style="font-size: 14px;font-weight: 500;">people</span></p>
                    <span>Professional Talent</span>
                </li>
                <li></li>
                <li>
                    <p class="num">{{obj.briefFgsNumber}}</p>
                    <span>Branch Offices</span>
                </li>
            </ul>
        </div>
        <div class="aboutus">
            <p><img src="../../assets/image/left.png">&nbsp;ABOUT US&nbsp;<img src="../../assets/image/right.png"></p>
            <div>
                <p v-html="obj.briefAbout"></p>
            </div>
            <ul>
                <li><img :src="imgurl + imgArray[0]"></li>
                <li><img :src="imgurl + imgArray[1]"></li>
                <li><img :src="imgurl + imgArray[2]"></li>
            </ul>
        </div>
        <div class="service">
            <p><img src="../../assets/image/left.png">&nbsp;Service Advantages&nbsp;<img
                    src="../../assets/image/right.png"></p>
            <ul>
                <li>
                    <img src="../../assets/image/aboutus/content1.png">
                    <div>
                        <p>Professionalism</p>
                        <button style="margin-left: 0;">Professional team</button>
                        <button>Comprehensively qualified</button>
                        <br />
                        <span>Tangcheng's global business focuses on financial and tax services, and has gathered 39
                            full-time certified public accountants at home and abroad, 15 certified tax agents and 10
                            practicing lawyers. In addition, the company has been building an expert database, and the
                            members of the expert database have reached more than 100 people. To provide high-quality
                            services for the global small and micro enterprises fiscal and tax law.</span>
                        <p class="littleT">【Leading Cross-Border Tax and Compliance，Professional Examples of Serving
                            Billion-Dollar Enterprises】</p>
                        <span class="littleC">Tang Cheng's Vision International Certified Public Accountant LIAS HAO has
                            represented Tang Cheng in 14 cross-border tax compliance sharing sessions at the Amazon
                            Global Vendor Conference over the past two years. Tang Cheng Certified Public Accountant
                            Liyuan Zhang leads a team that provides long-term services to four companies with a
                            valuation of over 1 billion dollars.</span>
                    </div>
                </li>
                <li>
                    <img src="../../assets/image/aboutus/content2.png">
                    <div>
                        <p>Speed</p>
                        <button style="margin-left: 0;">Martial Arts of the World</button>
                        <button>Timeliness is crucial</button>
                        <br />
                        <span>Domestic customers can obtain business licenses for their companies within the same day.
                            UK company registration and Italian VAT licenses can be obtained on the same day. Financial
                            analysis reports for businesses are delivered on the first day of each month. Operational
                            cash flow analysis reports are prepared on the same day.</span>
                        <p class="littleT">【Meet the regional tax and financial needs, build a comprehensive service
                            system】</p>
                        <span class="littleC">Companies choose tax and finance company services not only to save time
                            but also to save costs. Tangcheng, as a professional tax and finance company, is here to
                            help businesses solve their financial and tax-related problems. Our team consists of
                            experienced and efficient professionals who are well-versed in national policies and
                            regulations. With the increasingly sophisticated regulatory framework in China, the demand
                            for tax and compliance has never been higher. Tangcheng has been deeply rooted in the tax
                            and finance industry for years, with extensive practical experience. We can update our
                            services in response to changing policies and regulations to help businesses avoid potential
                            risks.</span>
                    </div>
                </li>
                <li>
                    <img src="../../assets/image/aboutus/content3.png">
                    <div>
                        <p>Depth</p>
                        <button style="margin-left: 0;">Mastery of a Profession</button>
                        <button>Still waters run deep</button>
                        <br />
                        <span>Empowered by financial and tax services, we can provide a full chain of services that
                            covers top-level corporate equity structure design + professional investment advice +
                            professional legal consulting services. Our diverse team enhances our ability to operate in
                            complex tax and legal environments. We focus on key areas and work efficiently to help our
                            clients implement their strategic plans at a deeper level.</span>
                        <p class="littleT">【Deeply empower enterprises，Synchronous Tax and Finance Reform】</p>
                        <span class="littleC">With the service logic of the finance and tax industry shifting from
                            "customer service" to "customer operation", the concept of service empowerment and value
                            addition has become deeply ingrained. According to the Central Economic Work Conference in
                            December 2023, it was specifically instructed to speed up the establishment of fundamental
                            systems in areas such as market access, property rights protection and transaction, data
                            information, and social credit, and to steadily and prudently push forward reform in key
                            areas such as fiscal and statistical systems, establish and improve the institutional and
                            regulatory framework that supports the development of a unified national market.Tangcheng
                            Holdings has adjusted its strategic deployment and is always ready to contribute Tangcheng
                            strength to the reform of the finance and tax industry.</span>
                    </div>
                </li>
                <li>
                    <img src="../../assets/image/aboutus/content4.png">
                    <div>
                        <p>Width</p>
                        <button style="margin-left: 0;">Spread all over the world</button>
                        <button>Distant as the ends of the earth, yet close as neighbors</button>
                        <br />
                        <span>Tangcheng Holdings is rooted in China and has a global presence. In the current
                            century-long unprecedented great changes, global industries are accelerating restructuring.
                            Tangcheng seizes the opportunity and further improves its global strategic layout, with its
                            footprints spreading across major cities in Hong Kong, Singapore, the United States, the
                            United Kingdom, Europe, the Middle East, North America, and Asia, better meeting the tax
                            service needs of global customers.</span>
                        <p class="littleT">【Tang Cheng's Global Development Trajectory】</p>
                        <span class="littleC">May 2022: Establish UK office; December 2022: Establish strategic
                            partnership with Dubai partner;
                            <br> June 2023: Become AliExpress SPN service provider; July 2023: Become Amazon SPN service
                            provider;
                            <br> December 2023: Establish Hong Kong and Singapore companies.</span>
                    </div>
                </li>
            </ul>
        </div>

        <footer-view></footer-view>
        <side-bar class="fixed"></side-bar>
    </div>
</template>

<script>
import topView from '@/components/en/topView.vue'
import footerView from '@/components/en/footerView.vue'
import SideBar from '@/components/en/sideBar.vue'
import axios from 'axios';

export default {
    components: { footerView, SideBar, topView },
    data() {
        return {
            infoShow: false,
            obj: {},
            imgArray: [],
            imgurl: '',
        }
    },
    created() {
        this.imgurl = this.$store.state.imgurl
        this.website()
        this.getAbout()
        // this.$store.state.componentKey += 1
    },
    methods: {
        //网站访问信息
        website() {
            axios.post('/api/pcsystem/TckgSoftLogin', { userType: '集团简介' }).then((res) => {
                console.log(res);
            }).catch()
        },
        getAbout() {
            axios.get('/api/pcsystem/TckgBrief/list',{params:{language:'1'}}).then((res) => {
                if (res.status == 200) {
                    this.obj = res.data.rows[0]
                    this.imgArray = this.obj.briefImgs.split(',')
                    console.log(this.imgArray);
                }
            }).catch()
        },
    }
}
</script>

<style lang="scss" scoped>
.fixed {
    position: fixed;
    top: 150px;
    right: 5px;
}

.backTop {
    position: relative;
    width: 100%;
    height: 26vw;
    min-width: 1200px;
    min-height: 310px;
    background-image: url("../../assets/image/jianjieEBack.png");
    background-size: 100% 100%;
}

.fuwu {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: -50px auto 0;
    padding: 40px 30px;
    width: 1140px;
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 6px;

    li {
        text-align: center;

        p {
            font-size: 28px;
            font-weight: 600;
            color: #EF591A;
        }

        &:nth-child(2),
        &:nth-child(4) {
            padding: 0;
            width: 1px;
            height: 40px;
            background-color: #C8C4C4;
        }
    }
}

.aboutus {
    padding: 50px 0;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    >div {
        margin: 30px auto;
        width: 1200px;
        text-align: center;
        font-size: 20px;
        line-height: 40px;
        white-space: pre-wrap;
    }

    ul {
        margin: 30px auto;
        width: 1200px;
        display: flex;
        justify-content: space-between;

        li {
            // width: 30%;
            // height: 200px;
            width: 380px;
            height: 245px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

}

.service {
    position: relative;
    padding: 60px 0;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    ul {
        margin: 50px auto 30px;
        width: 1200px;

        li {
            margin-top: 20px;
            display: flex;
            justify-content: space-around;
            padding: 30px 20px;
            background-color: #fff;
            border-radius: 6px;

            img {
                width: 335px;
                height: 376px;
            }

            div {
                margin-left: 20px;
                width: 70%;

                p {
                    margin: 10px 0;
                    font-size: 20px;
                    font-weight: 500;
                }

                button {
                    margin: 5px 5px 5px 0;
                    padding: 5px 10px;
                    font-size: 16px;
                    border: 0;
                    border-radius: 2px;
                    background-color: #F3F9E8;
                    color: #AACE5C;
                }

                span {
                    font-size: 14px;
                    line-height: 20px;
                    color: #ADADAD;
                }

                .littleT {
                    margin-top: 17px;
                    padding: 16px 0 7px;
                    border-top: 1px dashed #9C9C9C;
                    color: #EA5514;
                    font-size: 15px;
                    font-weight: 500;
                }

                .littleC {
                    font-size: 14px;
                    line-height: 20px;
                }

            }

            &:hover {
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
            }
            &:nth-child(3){
                p{
                    margin: 5px 0;
                    padding: 5px 0 0 0;
                }
            }

        }
    }

}
</style>