<template>
    <div>
        <top-view></top-view>
        <div style="width: 100%;height: 100%;margin-bottom: 40px;">
            <img src="../../assets/image/BeiJing.png" alt="" style="width: 100%;height: 100%;">
        </div>
        <footer-view></footer-view>

        <side-bar class="fixed"></side-bar>
    </div>
</template>
<script>
import TopView from '@/components/zh/topView.vue';
import SideBar from '@/components/zh/sideBar.vue';
import FooterView from '@/components/zh/footerView.vue';

export default {
    // name: "index",
    components: {
        TopView,
        SideBar,
        FooterView
    },
};
</script>
<style>
.fixed {
    position: fixed;
    top: 150px;
    right: 5px;
}
</style>