<template>
    <div id="app">
        <TopView :type="type"></TopView>
        <div class="backTop">
            <el-carousel autoplay arrow="never" class="batop">
                <el-carousel-item v-for="(item, index) in imgArray" :key="index">
                    <img :src="item" alt="" style="width: 100%;height: 100%" @click="toPage(index)" class="topImg">
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="gongsi">
            <div class="rectangular">
                <p class="title">Tang Cheng Holding Group</p>
                <span>Tenace.</span>
                <p class="content">Tang Cheng Holding Group was established in 2021, with domestic headquarters in
                    Beijing, China and overseas headquarters in Hong Kong, China.</p>
                <button @click="gotoAboutus">know more about</button>
            </div>
            <ul>
                <li>
                    <p class="qizhi">
                        <span style="margin-top: 2px;">Domestic company</span>
                        <span class="num" style="margin-top: -10px;">13</span>
                    </p>
                    <div>Including Beijing, Shanghai, Guangzhou, Shenzhen and other places</div>
                </li>
                <li>
                    <p class="qizhi">
                        <span style="margin-top: 2px;">Overseas company</span>
                        <span class="num" style="margin-top: -10px;">9</span>
                    </p>
                    <div>Including Hong Kong, Singapore, United States, Germany, Viet Nam, Indonesia, U.A.E., United Kingdom, Poland </div>
                </li>
                <li>
                    <p class="qizhi">
                        <span>Employee</span>
                        <span class="num">1432</span>
                    </p>
                    <div>With senior professionals and experienced staff</div>
                </li>
                <li>
                    <p class="qizhi">
                        <span>Service item</span>
                        <span class="num">2000</span>
                    </p>
                    <div style="font-size: 12px;">The group has six sub-brands such as Tang Cheng weiyi and Tang Cheng
                        lianzheng, forming eight business groups such as accounting, overseas and legal sectors</div>
                </li>
                <li>
                    <p class="qizhi">
                        <span> Client</span>
                        <span class="num">60000+</span>
                    </p>
                    <div>It covers companies of different sizes such as self-employed people, startups and smes</div>
                </li>
                <li>
                    <p class="qizhi">
                        <span style="margin-top: 2px;">Professional personnel</span>
                        <span class="num" style="margin-top: -10px;">178</span>
                    </p>
                    <div>Wide and deep areas of expertise, able to grasp the industry dynamics, innovative solutions
                    </div>
                </li>
            </ul>
        </div>
        <div class="weixinQ">
            <ul>
                <li class="first">
                    <p style="margin: 40px 0 0 20px;font-size: 30px;font-weight: 600;line-height: 42px;">Service Area
                    </p>
                    <p style="margin: 15px 0 0 20px;width: 92%;color: #545454;font-size: 18px;">
                        Services cover more than 22 regions around the world, with more than 2,000 content
                        segments.<br><br>Adhering to the principle of customer first, we focus on meeting the individual
                        needs and long-term interests of every customer.</p>
                </li>
                <li @click="gotoKuaiji">
                    <div class="gongshangfuwu">
                        <p class="fuwuText">Business accounting service</p>
                    </div>
                    <ul>
                        <li style="font-size: 10px">Company registration/deregistration</li>
                        <li>conversion of a company</li>
                        <li>Clear exception</li>
                        <li>Agency bookkeeping</li>
                        <li>Audit report</li>
                        <li>Company acquisition/transfer</li>
                        <!-- <li v-for="item in cdlist[0].children" :key="item.id">{{ item.label }}</li> -->
                    </ul>
                </li>
                <li @click="gotoZonghe">
                    <div class="zonghefuwu">
                        <p class="fuwuText">Business accounting service</p>
                    </div>
                    <ul>
                        <li>Company car
                            index</li>
                        <li>Permit
                            processing</li>
                        <li>Intellectual
                            property</li>
                        <li>Characteristic park</li>
                        <li>Renpass</li>
                        <li style="font-size: 10px;">Specialized special new enterprise identification</li>
                    </ul>
                </li>
                <li @click="gotoZizhi">
                    <div class="jianzhufuwu">
                        <p class="fuwuText">Construction qualification service</p>
                    </div>
                    <ul>
                        <li>General construction
                            contract</li>
                        <li>Construction
                            contract</li>
                        <li>Service
                            qualification</li>
                        <li>Safety production
                            permit</li>
                        <li style="font-size: 10px;">Permit for installation
                            (repair, test) of electric
                            power facilities</li>
                        <li>Real estate
                            development qualification</li>
                        <!-- <li v-for="item in cdlist[5].children" :key="item.id">{{ item.label }}</li> -->
                    </ul>
                </li>
                <li @click="gotoHaiwai">
                    <div class="haiwaifuwu">
                        <p class="fuwuText">Overseas service</p>
                    </div>
                    <ul>
                        <li>Open an overseas
                            bank account</li>
                        <li>VIE setup</li>
                        <li>Foreign exchange
                            fund management</li>
                        <li>Customs checks
                            the tax refund</li>
                        <li><span>Equity and top-level
                                structure design</span></li>
                        <li>Foreign investment
                            recordODI</li>
                    </ul>
                </li>
                <li @click="gotoQidai">
                    <div class="qiyefuwu">
                        <p class="fuwuText">Corporate loan service</p>
                    </div>
                    <ul>
                        <li>Enterprise credit loan</li>
                        <li>Collateralized
                            consumer loan</li>
                        <li>Corporate tax
                            credit</li>
                        <li>Secured operating
                            loan </li>
                        <li>Proof of funds</li>
                        <li>Personal credit
                            loan</li>
                    </ul>
                </li>
            </ul>
        </div>
        <div class="shipin">
            <div class="box">
                <ul>
                    <li>
                        <img src="../../assets/image/vedioB.png" alt="">
                    </li>
                    <li>
                        <div class="line"></div>
                        <div class="neirong">
                            <p>Agency bookkeeping</p>
                        </div>
                    </li>
                    <li>
                        <div class="line"></div>
                        <div class="neirong">
                            <p>Financial laws</p>
                        </div>
                    </li>
                    <li>
                        <div class="line"></div>
                        <div class="neirong">
                            <p>Cross-border compliance</p>
                        </div>
                    </li>
                    <li>
                        <div class="line"></div>
                        <div class="neirong">
                            <p>Intellectual property</p>
                        </div>
                    </li>
                    <li>
                        <div class="line"></div>
                        <div class="neirong">
                            <p>Building qualification</p>
                        </div>
                    </li>
                    <li>
                        <div class="line"></div>
                        <div class="neirong">
                            <p>Global architecture</p>
                        </div>
                    </li>
                </ul>
                <div class="show-imgs-container" @mouseenter="changeTrue()" @mouseleave="changeFalse()">
                    <el-carousel :interval="cutTime" arrow="never" :autoplay="autoplay" height="530px" ref="carouse"
                        @change="getIndex">
                        <el-carousel-item v-for="(item, index) in videos" :key="index">
                            <video :ref="'videoPlayer' + index" :src="item.src" loop controls autoplay muted></video>
                            <div class="poster" :style="{ backgroundImage: 'url(' + item.poster + ')' }"
                                v-if="fengmian"></div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
            <div class="select">
                <div class="selectQ" :class="choice == 0 ? 'choice' : ''" @mouseenter="$refs.carouse.setActiveItem(0);">
                </div>
                <div class="selectQ" :class="choice == 1 ? 'choice' : ''" @mouseenter="$refs.carouse.setActiveItem(1);">
                </div>
                <div class="selectQ" :class="choice == 2 ? 'choice' : ''" @mouseenter="$refs.carouse.setActiveItem(2);">
                </div>
            </div>
        </div>
        <div class="mapBack">
            <div style="display: flex;justify-content: center;"><button @click="infoShow = true">Click to
                    consult</button></div>
            <div class="map">
                <p><img src="../../assets/image/left.png">&nbsp;Global Distribution&nbsp;<img
                        src="../../assets/image/right.png"></p>
                <!-- <span>GLOBAL DISTRIBUTION</span> -->
                <div>
                    <img src="../../assets/image/地图.png">
                    <div class="mapTitle1">
                        <p>22</p>
                        <span>National and regional
                            business scope Global
                            coverage</span>
                    </div>
                    <div class="mapTitle2">
                        <p>178</p>
                        <span>Enterprise service expert level talents
                            Proficient in laws and policies of
                            various countries</span>
                    </div>
                </div>

            </div>
            <ul class="city">
                <li>
                    <img src="../../assets/image/2021E.png">
                    <p style="width: 278px;">Established Shijiazhuang, Langfang, Tianjin companies; Beijing Taiheng
                        Accounting Firm was established; Merger and acquisition of Kreis, small top accounting, big
                        accounting house, Tianjin tax management home finance and taxation company</p>
                </li>
                <li>
                    <img src="../../assets/image/2022E.png">
                    <p style="width: 261px;">Set up Beijing Taiheng Asset Appraisal Company, Beijing, Xi 'an company;
                        Merger and acquisition of Kyushu Finance & Tax Corporation and ABO Accounts Ltd</p>
                </li>
                <li>
                    <img src="../../assets/image/2023E.png">
                    <p style="width: 353px;">Established Shijiazhuang, Langfang, Tianjin companies; Beijing Taiheng
                        Accounting Firm was established; Merger and acquisition of Kreis, small top accounting, big
                        accounting house, Tianjin tax established Chengdu, Shenzhen, Shanghai, Guangzhou, Hong Kong,
                        Singapore companies; Tangdou intelligent tax sharing center was put into operation</p>
                </li>
            </ul>
        </div>
        <div class="orange">
            Hand in hand to create a hundred years of <br>
            brilliant enterprise Tangcheng
        </div>
        <div class="personal">
            <p><img src="../../assets/image/left.png">&nbsp;Tang Cheng Lingyun Pavilion&nbsp;<img
                    src="../../assets/image/right.png"></p>
            <div>
                <div class="Pic">
                    <img src="../../assets/icon/toLeftH.png" class="toRight" v-if="showImg">
                    <img src="../../assets/icon/toRightG.png" @click="showRight" class="toLeft" v-if="showImg">
                    <img src="../../assets/icon/toLeftG.png" @click="showLeft" class="toRight" v-if="!showImg">
                    <img src="../../assets/icon/toRightH.png" class="toLeft" v-if="!showImg">
                </div>
                <ul>
                    <li v-for="item in personalList" :key="item.personnelId">
                        <img :src="imgurl + item.personnelImg" style="width: 100%;height: 100%;">
                        <p>
                            <span class="personalT">{{ item.personnelName }}</span>
                            <span class="personalC">{{ item.personnelPosition }}</span>
                            <span class="personalRemark">
                                <div style="display: flex;" v-for="(children, index) in item.remark" :key="index">
                                    <div
                                        style="margin-top: 4px;width: 4px;height: 4px;border-radius: 50%;border: 2px solid #fff;background-color: #EA5514;">
                                    </div>
                                    <div style="margin-left: 8px;width: 240px;">{{ children }}</div>
                                </div>
                            </span>
                        </p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="enterprises">
            <div>
                <div>
                    <p>Enterprise dynamics</p>
                    <img src="../../assets/icon/qiyeD.png">
                    <p class="qiyeC">
                        Tang Cheng holds seminars, special lectures, theme forums and other activities from time to
                        time, covering the fields of corporate finance and taxation, offshore trade, cross-border
                        e-commerce, intellectual property, construction qualification and so on.
                    </p>
                </div>
                <ul>
                    <li v-for="(item, index) in qydtList" :key="item.dynamicId" @click="gotoxiangQ(item.dynamicId)">
                        <img :src="imgurl + item.dynamicImg">
                        <div>
                            <div class="gengduo"></div>
                            <p>{{ titleArray[index][0] }}</p>
                            <span>{{ titleArray[index][1] }}</span>
                            <div>
                                <img src="../../assets/icon/time.png">
                                <span class="time">{{ item.dynamicDate }}</span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="customers">
            <div style="display: flex;justify-content: center;"><button @click="infoShow = true">Click to
                    consult</button></div>
            <p><img src="../../assets/image/left.png">&nbsp;The common choice of many customers&nbsp;<img
                    src="../../assets/image/right.png"></p>
            <ul>
                <li v-for="item in choiceList" :key="item.clientId"><img :src="imgurl + item.clientImg" alt=""></li>
            </ul>
        </div>
        <div class="sprites">
            <ul>
                <li>
                    <img src="../../assets/icon/team.png">
                    <p class="title">Professional team</p>
                    <p>More than 5 years of working experience</p>
                    <p>To provide customers with services beyond value</p>
                </li>
                <li>
                    <img src="../../assets/icon/qianbao.png">
                    <p class="title">Price transparency</p>
                    <p>Clearly mark the price</p>
                    <p>Say no to any hidden purchases</p>
                </li>
                <li>
                    <img src="../../assets/icon/suo.png">
                    <p class="title">Multiple secrecy</p>
                    <p>Have information confidentiality system</p>
                    <p>Ensure customer information security</p>
                </li>
                <li>
                    <img src="../../assets/icon/fangzi.png">
                    <p class="title">After-sale guarantee</p>
                    <p>There are professional staff responsible for after-sales work</p>
                    <p>Able to give timely solutions</p>
                </li>
            </ul>
        </div>
        <footer-view></footer-view>
        <side-bar class="fixed"></side-bar>
        <el-dialog title="Please fill in your personal information" :visible.sync="infoShow" width="40%" center>
            <span style="font-size: 12px;color: #A5A5A5;text-align: center;display: block;margin-top: -30px;">We will
                contact you within 30 minutes</span>

            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
                <el-form-item label="Name:" prop="name">
                    <el-input v-model="ruleForm.name" placeholder="Please enter your name"></el-input>
                </el-form-item>
                <el-form-item label="Phone Number:" prop="tel">
                    <el-input v-model="ruleForm.tel" placeholder="Please enter your phone number"></el-input>
                </el-form-item>
                <el-form-item label="Leave a message:" prop="content">
                    <el-input v-model="ruleForm.content" type="textarea" placeholder="Please enter the question you want to consult
 (no more than 300 words)" class="inputC"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm('ruleForm')">Table shortly</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import TopView from '@/components/en/topView.vue';
import SideBar from '@/components/en/sideBar.vue';
import FooterView from '@/components/en/footerView.vue';
import router from '@/router';
import axios from 'axios';

export default {
    name: "index",
    components: {
        TopView,
        SideBar,
        FooterView
    },
    data() {
        return {
            infoShow: false,
            ruleForm: {
                name: '',
                tel: '',
                content: ''
            },
            rules: {
                name: [
                    { required: true, message: '请输入您的姓名', trigger: 'blur' },
                ],
                tel: [
                    { required: true, message: '请输入您的电话', trigger: 'blur' },
                    { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号码', trigger: 'blur' }
                ],
                content: [
                    // { required: true, message: '请输入您的电话', trigger: 'blur' },
                    { min: 0, max: 300, message: '最多输入300字', trigger: 'blur' }
                ]
            },
            showP: true,
            // activeIndex: '1',
            showImg: true,
            cdlist: [],
            qydtList: [],
            personalList: [],
            params: { pageSize: 8, pageNum: 1, language: '1' },
            choiceList: [],
            // imgurl: '',
            imgurl: '',
            // imgurl1: 'http://localhost:8080',
            // componentKey:0
            imgArray: [
                require('../../assets/image/bannerE1.png'),
                require('../../assets/image/bannerE2.png'),
                require('../../assets/image/bannerE3.png'),
                require('../../assets/image/bannerE4.png'),
                require('../../assets/image/bannerE5.png'),
            ],
            titleArray: [],
            autoplay: true,//是否自动切换
            isPlay: false,//播放状态
            isIn: false,//鼠标是否位于播放器内
            cutTime: 4000,//轮播时间，单位毫秒
            nowIndex: 0,
            videos: [
                {
                    // src: "https://www.runoob.com/try/demo_source/mov_bbb.mp4"
                    // src: require('../assets/vedio/WeChat_20240821175529.mp4'),
                    src: 'https://www.tenace.cn/api/profile/video/63151d33f0a336cd38f335f4396bcbb7.mp4',
                    poster: require('../../assets/vedio/fengmian1.png'),
                    muted: true
                    // res: '938*530'
                },
                {
                    // src: require('../assets/vedio/WeChat_20240821175547.mp4'),
                    src: 'https://www.tenace.cn/api/profile/video/a9f1267a9d4a64e984b9678c552a693f.mp4',
                    poster: require('../../assets/vedio/fengmian2.png'),
                    muted: true
                    // res: '938*530'
                },
                {
                    // src: require('../assets/vedio/59ed951163e7edac6630b32d1a521e02.mp4'),
                    src: 'https://www.tenace.cn/api/profile/video/59ed951163e7edac6630b32d1a521e02.mp4',
                    poster: require('../../assets/vedio/fengmian3.png'),
                    muted: true
                    // res: '938*530'
                },
            ],
            choice: 0,
            isplay: false,
            enter: false,
            type: '1',
            fengmian: true,
            muted: true
        }
    },
    created() {
        this.getqydtList()
        this.getPersonalList()
        this.getchoiceList()
        this.website()
        this.imgurl = this.$store.state.imgurl
    },
    methods: {
        //获取企业动态列表
        getqydtList() {
            axios.get('/api/pcsystem/TckgDynamic/list', { params: { pageSize: 2, pageNum: 1, dynamicType: '1', language: '1' } }).then((res) => {
                this.qydtList = res.data.rows
                for (let index = 0; index < this.qydtList.length; index++) {
                    this.titleArray[index] = res.data.rows[index].dynamicTitle.split('|')
                }

            }).catch()
        },
        //获取人物列表
        getPersonalList() {
            axios.get('/api/pcsystem/TckgPersonnel/list', { params: this.params }).then((res) => {
                if (res.status == 200) {
                    this.personalList = res.data.rows
                    for (let i = 0; i < this.personalList.length; i++) {
                        this.personalList[i].remark = res.data.rows[i].remark.split('。')
                    }
                    // console.log(this.personalList);
                }

            }).catch()
        },
        //获取共同选择列表
        getchoiceList() {
            axios.get('/api/pcsystem/TckgClient/list').then((res) => {
                if (res.status == 200) {
                    this.choiceList = res.data.rows
                    // console.log(this.choiceList);
                }

            }).catch()
        },
        //跳转企业动态详情
        gotoxiangQ(id) {
            this.$router.push({ path: '/contentE', query: { id: id, type: this.type } })
            // console.log(id);
        },
        //网站访问信息
        website() {
            axios.post('/api/pcsystem/TckgSoftLogin', { userType: '首页' }).then((res) => {
                console.log(res);
            }).catch()
        },
        showLeft() {
            this.showImg = true
            this.params = { pageSize: 8, pageNum: 1, language: '1' }
            this.getPersonalList()
        },
        showRight() {
            this.showImg = false
            this.params = { pageSize: 8, pageNum: 2, language: '1' }
            this.getPersonalList()
        },
        gotoKuaiji() {
            this.$router.push('/kuaijiE')
        },
        gotoZonghe() {
            this.$router.push('/zongheE')
        },
        gotoZizhi() {
            this.$router.push('/zizhiE')
        },
        gotoHaiwai() {
            this.$router.push('/haiwaiE')
        },
        gotoQidai() {
            this.$router.push('/qidaiE')
        },
        gotoAboutus() {
            this.$router.push('/aboutusE')
        },
        toPage(index) {
            if (index == 1) {
                this.gotoHaiwai()
            }
            if (index == 2) {
                this.$router.push('/shangyeE')
            }
            if (index == 3) {
                this.$router.push('/qiyewenhuaE')
            }
        },
        //提交信息
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.infoShow = false
                    axios.post('/api/pcsystem/TckgConsult', { params: this.ruleForm }).then((res) => {
                        if (res.status == 200) {
                            this.$message({
                                message: '提交成功，请耐心等待',
                                type: 'success'
                            });
                        }
                    }).catch()
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        //视频部分
        //获取走马灯的索引
        getIndex(nowIndex, oldIndex) {
            //用户可能在上个视频未播放完毕就切换
            //此时需要暂停上个视频并把时间归零，否则影响对当前视频的监听逻辑
            let myVideo = this.$refs['videoPlayer' + oldIndex];
            myVideo[0].pause();
            myVideo[0].currentTime = 0;
            this.nowIndex = nowIndex;
            this.listenVideo();
            //切换选择器
            if (nowIndex == 0) {
                this.choice = 0
                if (this.enter) {
                    this.playVideo()
                    this.isPlay = true
                }
            } else if (nowIndex == 1) {
                this.choice = 1
                if (this.enter) {
                    this.playVideo()
                    this.isPlay = true
                }
                // this.selector(1, 0)
            } else {
                this.choice = 2
                if (this.enter) {
                    this.playVideo()
                    this.isPlay = true
                }
            }
        },
        //监听视频的播放、暂停、播放完毕等事件
        listenVideo() {
            //注意：这样获取的dom是一个数组，必须加上[0]索引才能正确的添加事件
            let myVideo = this.$refs['videoPlayer' + this.nowIndex];
            //监听播放
            myVideo[0].addEventListener("play", () => {
                this.autoplay = false;
                this.isPlay = true;
                this.fengmian = false
            });
            //监听暂停
            myVideo[0].addEventListener("pause", () => {
                this.autoplay = true;
                this.isPlay = false;
            });
            //监听播放完毕
            myVideo[0].addEventListener("ended", () => {
                //时间归零
                myVideo[0].currentTime = 0;
                this.isPlay = false;
                this.autoplay = true;
            });

        },
        //播放视频
        playVideo() {
            //注意：这样获取的dom是一个数组，必须加上[0]索引才能正确的添加事件
            let myVideo = this.$refs['videoPlayer' + this.nowIndex];
            // this.autoplay=false
            myVideo[0].play();
            this.enter = true
            this.fengmian = false
        },
        //暂停视频
        pauseVideo() {
            //注意：这样获取的dom是一个数组，必须加上[0]索引才能正确的添加事件
            let myVideo = this.$refs['videoPlayer' + this.nowIndex];
            myVideo[0].pause();
            this.enter = false
        },
        //小球选择
        selector(nowIndex, oldIndex) {
            this.enter = true
            this.getIndex(nowIndex, oldIndex)
            if (nowIndex == 0) {
                this.choice = 0
                this.$refs.carouse.setActiveItem(0);
                this.isplay = true
                this.nowIndex = 0
                this.playVideo()
                this.isPlay = true
                this.autoplay = false
            } else if (nowIndex == 1) {
                this.choice = 1
                this.$refs.carouse.setActiveItem(1);
                this.nowIndex = 1
                this.isplay = true
                this.playVideo()
                this.listenVideo()
                this.isPlay = true
                this.autoplay = false
            } else {
                this.choice = 2
                this.$refs.carouse.setActiveItem(2);
                this.nowIndex = 2
                this.isplay = true
                this.playVideo()
                this.listenVideo()
                this.isPlay = true
                this.autoplay = false
            }
        },
        changeTrue() {
            this.isIn = true
            if (this.choice == 0) {
                this.selector(0, 2)
            } else if (this.choice == 1) {
                this.selector(1, 0)
            } else {
                this.selector(2, 1)
            }
        },
        changeFalse() {
            this.isIn = false
        },
        sound(item, index) {
            item.muted = !item.muted
        }
    },
    mounted() {
        this.listenVideo();
    }
};
</script>

<style lang="scss" scoped>
.backTop {
    min-width: 1200px;
}

.fixed {
    position: fixed;
    top: 150px;
    right: 5px;
}

.gongsi {
    margin: 40px auto;
    display: flex;
    width: 1200px;

    .rectangular {
        padding: 30px 10px 30px 25px;
        // width: 29.8%;
        width: 375px;
        height: 300px;
        background-image: url("../../assets/image/93176a07e5ce9bf625a93fafc2312ac1e118b767fa477d-8Fq7of_fw1200.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        color: #fff;
        border-radius: 6px;

        .title {
            margin-bottom: 10px;
            font-size: 24px;
            font-weight: 500;
        }

        span {
            font-size: 18px;
        }

        .content {
            margin-top: 10px;
            font-weight: 400;
            font-size: 16px;
            opacity: 0.8;
            color: #FFFFFF;
            line-height: 30px;
        }

        button {
            margin-top: 20px;
            padding: 7px 20px;
            background-color: #F08759;
            border: 0;
            color: #fff;
            border-radius: 4px;
            // font-size: 22px;
            font-size: 20px;
            font-weight: 500;
        }
    }

    ul {
        margin-top: -10px;
        // width: 65%;
        width: 900px;
        display: flex;
        flex-wrap: wrap;

        li {
            display: flex;
            align-items: center;
            margin-top: 10px;
            margin-left: 20px;
            width: 390px;
            height: 112px;
            background: linear-gradient(to top, #FFEEE6 0%, #FFFBF5 100%);
            border-radius: 6px;

            .qizhi {
                // margin-top: -13px;
                // width: 80px;
                // height: 80%;
                width: 104px;
                height: 73px;
                background-image: url("../../assets/image/qizhi.png");
                background-size: 100% 100%;
                color: #fff;
                font-size: 13px;
                text-align: center;

                span {
                    margin: 7px 0 10px -5px;
                    display: block;
                    font-size: 15px;
                }

                .num {
                    // margin-top: 0;
                    margin-left: -4px;
                    font-size: 22px;
                }
            }

            >div {
                margin-left: 10px;
                // line-height: 70px;
                width: 70%;
                font-size: 14px;
            }

        }
    }
}

.weixinQ {
    // margin: auto;
    padding: 30px 0;
    min-width: 1200px;
    // min-height: 310px;
    background-color: #F4F4F4;

    >ul {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin: auto;
        width: 1200px;

        >li {
            // margin-left: 10px;
            margin-top: 10px;
            width: 32%;
            height: 300px;
            // border: 1px dashed #9A9A9A;
            border-radius: 10px;
            cursor: pointer;

            &:first-child,
            &:nth-child(4) {
                margin: 0;
                margin-top: 10px;
            }

            .gongshangfuwu,
            .zonghefuwu,
            .jianzhufuwu,
            .haiwaifuwu,
            .qiyefuwu {
                overflow: hidden;
                position: relative;
                height: 244px;
                border-radius: 10px;
                background-image: url("../../assets/image/gongshangfuwu.png");
            }

            .zonghefuwu {
                background-image: url("../../assets/image/zonghefuwu.png");
            }

            .jianzhufuwu {
                background-image: url("../../assets/image/jianzhufuwu.png");
            }

            .haiwaifuwu {
                background-image: url("../../assets/image/haiwaifuwu.png");
            }

            .qiyefuwu {
                background-image: url("../../assets/image/qiyedaikuanfuwu.png");
            }

            .fuwuText {
                // margin-top: 180px;
                position: absolute;
                bottom: 0;
                padding: 10px;
                width: 94.7%;
                height: 30px;
                color: #fff;
                background-color: rgba(0, 0, 0, 0.4);
                text-align: center;
                font-size: 18px;
                line-height: 30px;
                // border-top: 1px solid rgba(0,0,0,0.4);
            }

            >ul {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                height: 80px;

                li {
                    margin-top: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // width: 30%;
                    height: 48%;
                    width: 124px;
                    text-align: center;
                    // line-height: 40px;
                    font-size: 12px;
                    background-color: #fff;
                    border-radius: 4px;

                    &:hover {
                        background-color: #ED5413;
                        color: #fff;
                    }
                }
            }
        }

        .first {
            // padding: 0 0px 0 10px;
            width: 390px;
            height: 340px;
            background-image: url("../../assets/image/fuwu.png");
            background-size: 100% 100%;
        }
    }

}

.mapBack {
    // width: 100%;
    // padding-top: 50px;
    padding-bottom: 70px;
    background-color: #F4F4F4;
    min-width: 1200px;
    // min-height: 310px;

    button {
        margin: 30px 0 75px;
        // margin-left: 46%;
        // margin-left: 45%;
        // min-width: 8%;
        width: 252px;
        height: 56px;
        padding: 5px 15px;
        // font-size: 26px;
        font-size: 26px;
        font-weight: 500;
        color: #fff;
        // background-color: #FE8F3F;
        background: linear-gradient(180deg, #FFB552 0%, #FF4D00 100%);
        border: 0;
        border-radius: 30px;
        text-align: center;
    }

    .map {
        // position: relative;
        padding: 60px 0 60px;
        width: 100%;
        // height: 600px;
        background-image: url("../../assets/image/地图背景.png");
        background-size: 100% 100%;
        // z-index: 1;

        >p {
            margin-bottom: 10px;
            font-size: 34px;
            font-weight: 500;
            text-align: center;

            >img {
                margin-left: 0;
                width: 33px;
                height: 24px;
            }
        }

        >span {
            display: block;
            text-align: center;
            color: #999999;
            font-size: 22px;
            font-weight: 400;
        }

        >div {
            position: relative;
            margin: 10px auto;
            padding-bottom: 20px;
            width: 1200px;
        }

        img {
            margin-left: 9%;
            width: 90%;
        }

        .mapTitle1,
        .mapTitle2 {
            position: absolute;
            top: 40%;

            p {
                margin: 0;
                color: #EA5514;
                // font-size: 25px;
                text-align: start;
                font-weight: bold;
                font-size: 42px;
                line-height: 48px;
            }

            span {
                display: block;
                width: 194px;
                font-weight: 400;
                font-size: 18px;
                line-height: 26px;
                color: #333333;
            }
        }

        .mapTitle2 {
            top: 70%;

            span {
                width: 315px;
            }
        }
    }

    .city {
        position: relative;
        display: flex;
        padding: 40px 0;
        justify-content: space-around;
        margin: -80px auto 0;
        width: 1200px;
        // height: 200px;
        background-color: #fff;
        // border: 1px solid #9A9A9A;
        box-shadow: 0 0 10px 0 #F4F4F4;
        border-radius: 4px;
        // z-index: 9999;

        li {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 30%;

            img {
                margin-bottom: 30px;
                width: 70px;
                height: 70px;
            }

            p {
                text-align: center;
                font-size: 12px;
            }
        }
    }
}

.orange {
    padding: 50px 0;
    width: 100%;
    min-width: 1200px;
    background-image: url("../../assets/image/橙色背景.png");
    background-size: 100% 100%;
    text-align: center;
    color: #fff;
    font-size: 40px;
}

.personal {
    position: relative;
    padding: 60px 0;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    >div {
        position: relative;
        margin: 0 auto;
        padding: 20px 0 0;
        width: 1200px;

        .Pic {
            position: absolute;
            // top: 60px;
            right: 0;
            cursor: pointer;

            img {
                width: 25px;

                &:first-child {
                    margin-right: 5px;
                }
            }
        }

        ul {
            margin: 30px auto 0;
            display: flex;
            flex-wrap: wrap;
            width: 1200px;

            li {
                position: relative;
                margin-top: 1vh;
                margin-left: 1%;
                width: 24%;
                height: 340px;
                background-size: 100% 100%;
                border-radius: 6px;
                overflow: hidden;

                >p {
                    margin: 0;
                    position: absolute;
                    bottom: 0;
                    padding: 5px 13px;
                    width: 91%;
                    min-height: 75px;
                    background-color: rgba(0, 0, 0, 0.5);
                    text-align: start;
                    color: #fff;
                }

                .personalT {
                    display: block;
                    font-size: 18px;
                    line-height: 30px;
                    font-weight: 500;
                }

                .personalC {
                    font-size: 13px;
                    font-weight: 500;
                }

                .personalRemark {
                    margin-top: 5px;
                    display: none;
                    padding: 5px 10px;
                    background-color: #EA5514;
                    border-radius: 3px;
                    font-size: 9px;
                    line-height: 15px;
                }

                &:hover {
                    .personalRemark {
                        display: block;
                    }
                }
            }
        }
    }


}

.enterprises {
    padding: 60px 0;
    // height: 300px;
    background-image: url("../../assets/image/868c046010cc87cdbc7982e4060ecda319a96a1283107-PWCdz7_fw1200.png");

    >div {
        display: flex;
        justify-content: space-between;
        margin: auto;
        width: 1200px;

        >div {
            padding: 50px 30px;
            width: 400px;
            background-image: url("../../assets/image/f5dbf895f20f3bd7a31cf7b9f20acdf35a31bb5e8bdf6-Caed5Y_fw1200.png");
            background-size: 100% 100%;

            p {
                // margin: 40px 0 0 10px;
                font-size: 30px;
                font-weight: 700;
            }

            img {
                margin-top: 10px;
                width: 75px;
            }

            .qiyeC {
                margin-top: 20px;
                font-size: 18px;
                font-weight: 500;
                line-height: 32px;
                width: 410px;
                color: #545454;
            }
        }

        ul {
            margin-top: 12px;
            width: 65%;

            li {
                display: flex;
                position: relative;
                height: 47%;
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
                border-radius: 8px;

                &:nth-child(2) {
                    margin-top: 1.5%;
                }

                >img {
                    width: 40%;
                    height: 100%;
                    border-radius: 0;
                }

                >div {
                    // position: relative;
                    padding: 20px;
                    width: 55%;

                    p {
                        margin: 20px 0 20px;
                        font-size: 20px;
                        font-weight: 500;
                        line-height: 28px;
                    }

                    span {
                        font-size: 18px;
                        line-height: 25px;
                        color: #7D7D7D;
                    }

                    div {
                        position: absolute;
                        right: 20px;
                        bottom: 20px;
                        display: flex;
                        align-items: center;

                        >img {
                            // position: absolute;
                            // right: 110px;
                            // bottom: 18px;
                            width: 25px;
                        }

                        .time {
                            // position: absolute;
                            // right: 20px;
                            // bottom: 19px;
                            color: #C0C0C1;
                            font-size: 16px;
                        }
                    }

                    .gengduo {
                        position: absolute;
                        right: 30px;
                        top: 50px;
                        width: 20px;
                        height: 4px;
                        background-image: url('../../assets/image/gengduo.png');
                        background-size: 100% 100%;
                    }
                }
            }
        }
    }

}

.customers {
    // position: relative;
    padding: 30px 0 70px;
    min-width: 1200px;
    // height: 300px;
    background-color: #F4F4F4;

    p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    button {
        margin: 50px 0;
        width: 252px;
        height: 56px;
        padding: 5px 15px;
        font-size: 26px;
        font-weight: 500;
        color: #fff;
        background: linear-gradient(180deg, #FFB552 0%, #FF4D00 100%);
        border: 0;
        border-radius: 30px;
        text-align: center;
    }

    ul {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin: 60px auto 0;
        width: 1200px;

        li {
            margin-top: 10px;
            padding: 10px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24%;
            height: 100px;
            background-color: #fff;
            border-radius: 6px;
            text-align: center;

            &:hover {
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
            }
        }
    }
}

.sprites {
    // width: 100%;
    // height: 400px;
    height: 20.8vw;
    min-width: 1200px;
    min-height: 250px;
    display: flex;
    align-items: center;
    // padding: 6% 0 0;
    background-image: url("../../assets/image/50317a8c3c8d1db5edfb61a4fb177b99958263e013f2298-YeXw1S-2.png");
    background-size: 100% 100%;

    ul {
        margin: auto;
        display: flex;
        justify-content: space-around;
        min-width: 1200px;

        li {
            // margin-top: 100px;
            // margin-top: 5%;
            width: 270px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .title {
                margin-bottom: 10px;
                font-size: 24px;
                font-weight: 700;
                color: #333333;
            }

            p {
                text-align: center;
                color: #888888;
            }
        }
    }
}

::v-deep .el-dialog--center .el-dialog__body {
    padding-bottom: 0;
}

::v-deep .el-form {
    margin-top: 30px;
    margin-left: -20px;
}

::v-deep .el-textarea__inner {
    height: 150px !important;
}

::v-deep .el-carousel__indicator {
    margin-bottom: 25px;
    margin-left: 8px;
    padding: 0;
    width: 10px;
    height: 10px;
    // height: 1px;
    // height: 2px;
    border-radius: 50%;
    background: #F0F0F0;
}

::v-deep .el-carousel__indicator button {
    display: none;
}

/* 当前激活指示器的样式 */
::v-deep .el-carousel__indicator.is-active {
    width: 40px;
    height: 10px;
    // height: 0px;
    background: #FFFFFF;
    border-radius: 8px;
    // background-color: #ff5700;
    // bottom: -40px;
    // margin-top: 1000px;
    // position: absolute;
    // position: relative;
    // top: 40px;
}

.shipin {
    // margin: 80px auto;
    padding: 50px 0;
    min-width: 1200px;
    // width: 1200px;
    background-color: #F4F4F4;

    .box {
        margin: auto;
        display: flex;
        width: 1200px;
        height: 530px;

        ul {
            padding: 23px 16px;
            width: 230px;
            background-color: #F26523;

            li {
                display: flex;
                margin-top: 35px;
                margin-left: 10px;

                &:first-child {
                    margin-top: 0;
                    margin-left: 0;
                }

                // align-items: center;

                .line {
                    margin-top: 5px;
                    width: 4px;
                    height: 18px;
                    background: #FFFFFF;
                    border-radius: 1px;
                }

                .neirong {
                    margin-left: 6px;

                    p {
                        font-weight: 500;
                        font-size: 18px;
                        color: #FFFFFF;
                        line-height: 28px;
                    }

                    span {
                        font-weight: 400;
                        font-size: 16px;
                        color: #FFFFFF;
                        line-height: 22px;
                    }
                }
            }
        }

        >div {
            flex-grow: 1;
        }
    }
}

::v-deep .show-imgs-container {
    height: 100%;
    cursor: pointer;

    .el-carousel {
        width: 100%;
        height: 100%;
    }

    .el-carousel__container {
        width: 100%;
        height: 100%;
    }

    .el-carousel__item {
        width: 100%;
        height: 100%;
        // border-radius: 10px;

        video {
            width: 100%;
            height: 100%;
            position: fixed;
            display: inline-block;
            vertical-align: baseline;
            object-fit: fill; //消除留白
        }

        .play-btn {
            font-size: 60px;
            color: #fff;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 9999;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .el-carousel__indicators--horizontal {
        display: none;
        // position: relative;
        // bottom: -40px;
        // background-color: #E6E6E6;
    }

    .el-carousel__indicator {
        background: #E6E6E6;
    }

    .el-carousel__indicator.is-active {
        background: #CFCFCF;
    }
}

.select {
    margin: 26px auto 0;
    width: 1200px;
    display: flex;
    // justify-content: center;

    .selectQ {
        margin-left: 573px;
        width: 10px;
        height: 10px;
        background-color: #E6E6E6;
        border-radius: 8px;

        &:nth-child(2) {
            margin-left: 8px;
        }

        &:nth-child(3) {
            margin-left: 8px;
        }
    }

    .choice {
        // margin-left: 8px;
        width: 40px;
        background-color: #CFCFCF;
    }
}

::v-deep .video::-webkit-media-controls-play-button {
    display: none !important;
}

.video {
    width: 100%;
    height: 100%;
    display: block;
}

.poster {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    z-index: 0;
}

.batop {
    min-height: 310px !important;
}

.player {
    position: relative;
    top: -2px;
    left: 900px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 22px;
    height: 22px;
    cursor: pointer;
    margin-top: 17px;
    // z-index: 9999 !important;
}

.unsound {
    background-image: url("../../assets/icon/close.png");
}

.sound {
    // display: none;
    background-image: url("../../assets/icon/open.png");
}
</style>
<style>
@media screen and (max-width:1200px) {
    .batop .el-carousel__container {
        height: 310px !important;
    }

    .topImg {
        width: 1200px !important;
        height: 310px !important;
    }
}

@media screen and (min-width:1201px) {
    .batop .el-carousel__container {
        height: 26vw !important;
    }
}
</style>