<template>
    <div>
        <top-view></top-view>
        <div class="backTop">
        </div>
        <div class="kuaijiQ">
            <div>
                <p>Integrated business group</p>
                <div
                    style="margin-bottom: 40px;width: 81px;height: 4px;background: linear-gradient( 270deg, #F4F4F4 0%, #EA5514 100%);border-radius: 1px;">
                </div>
                <p class="kuaijiQC">
                    The business group is an expert + consultant model, that is, the single project service is provided
                    by professional business personnel and senior expert teachers, and is committed to building a
                    healthy enterprise operation ecological industry chain around B-end users.
                    <br><br>
                    Under the full digital background, do a good job in the integration of financial, tax and legal
                    development, achieve efficient collaborative work, and provide customers with more accurate and more
                    reasonable solutions.
                </p>
            </div>
            <img src="../../assets/image/zonghe/qunBack.png">
        </div>
        <div class="canIdo">
            <p><img src="../../assets/image/left.png">&nbsp;What can we do for your business&nbsp;<img
                    src="../../assets/image/right.png"></p>
            <ul>
                <li>
                    <p>
                        <span class="title">Free evaluation</span>
                        <span>Detailed evaluation based on the current
                            situation of the company</span>
                    </p>
                </li>
                <li>
                    <p>
                        <span>Provide massive resources</span>
                        <span>Millions of resources of your choice</span>
                    </p>
                </li>
                <li>
                    <p>
                        <span>Enjoy preferential policies</span>
                        <span>Apply for subsidies and enjoy national policies</span>
                    </p>
                </li>
            </ul>
        </div>
        <div class="service">
            <p><img src="../../assets/image/left.png">&nbsp;Service content&nbsp;<img
                    src="../../assets/image/right.png"></p>
            <ul>
                <li>
                    <div></div>
                    <p class="qizhi">01</p>
                    <span>
                        <p>Company car index</p>
                        <span>7*24 hours free consultation</span>
                    </span>
                </li>
                <li>
                    <div></div>
                    <p class="qizhi">02</p>
                    <span>
                        <p>Permit processing</p>
                        <span>Handle through regular channels</span>
                    </span>

                </li>
                <li>
                    <div></div>
                    <p class="qizhi">03</p>
                    <span>
                        <p>Intellectual property</p>
                        <span>Direct hand, safe and confidential</span>
                    </span>

                </li>
                <li>
                    <div></div>
                    <p class="qizhi">04</p>
                    <span>
                        <p>Remarkable Parks</p>
                        <span>Optimize resource allocation</span>
                    </span>

                </li>
                <li>
                    <div></div>
                    <p class="qizhi">05</p>
                    <span>
                        <p>Glendale park</p>
                        <span>Receive high subsidies</span>
                    </span>

                </li>
                <li>
                    <div></div>
                    <p class="qizhi">06</p>
                    <span>
                        <p>Specialized special new
                            enterprise identification</p>
                        <span>Provide guidance services
                            throughout the process</span>
                    </span>
                </li>
            </ul>
        </div>
        <div class="personal">
            <p><img src="../../assets/image/left.png">&nbsp;Senior Specialist&nbsp;<img
                    src="../../assets/image/right.png"></p>
            <ul>
                <li v-for="item in personalList" :key="item.personnelId">
                    <img :src="imgurl + item.personnelImg" style="width: 100%;height: 100%;">
                    <p>
                        <span class="personalT">{{ item.personnelName }}</span>
                        <span class="personalC">{{ item.personnelPosition }}</span>
                        <span class="personalRemark">
                            <div style="display: flex;" v-for="(children, index) in item.remark" :key="index">
                                <div
                                    style="margin-top: 4px;width: 4px;height: 4px;border-radius: 50%;border: 2px solid #fff;background-color: #EA5514;">
                                </div>
                                <div style="margin-left: 8px;width: 240px;">{{ children }}</div>
                            </div>
                        </span>
                    </p>
                </li>
            </ul>
        </div>
        <div class="orange">
            Flash card, 100% card, no money back
        </div>
        <div class="flow">
            <p><img src="../../assets/image/left.png">&nbsp;Service process&nbsp;<img
                    src="../../assets/image/right.png"></p>
            <div style="margin: 40px auto;width: 1160px;border: 2px solid #F2D5C8;"></div>
            <ul>
                <li>
                    <div></div>
                    <p>01</p>
                    <span>Senior business docking</span>
                </li>
                <li>
                    <div></div>
                    <p>02</p>
                    <span>Clear demand</span>
                </li>
                <li>
                    <div></div>
                    <p>03</p>
                    <span>Matching service scheme</span>
                </li>
                <li>
                    <div></div>

                    <p>04</p>
                    <span>Senior consultant <br>
                        docking</span>
                </li>
                <li>
                    <div></div>
                    <p>05</p>
                    <span>Sign an agreement</span>
                </li>
                <li>
                    <div></div>
                    <p>06</p>
                    <span>Project start-up</span>
                </li>
                <li>
                    <div></div>
                    <p>07</p>
                    <span>Service follow-up</span>
                </li>
                <li>
                    <div></div>
                    <p>08</p>
                    <span>Project completion</span>
                </li>
                <li>
                    <div></div>
                    <p>09</p>
                    <span>Management consulting <br>
                        planning</span>
                </li>
            </ul>
        </div>
        <div class="advantages">
            <p><img src="../../assets/image/left.png">&nbsp;Advantage of service&nbsp;<img
                    src="../../assets/image/right.png"></p>
            <div>
                <img src="../../assets/image/zonghe/advanB.png">
                <ul>
                    <li>
                        <div>01</div>
                        <p>Finance and tax law experts, high-quality
                            all-round service</p>
                    </li>
                    <li>
                        <div>02</div>
                        <p>Strong expert base support</p>
                    </li>
                    <li>
                        <div>03</div>
                        <p>Digital encryption, data backup, privacy
                            protection</p>
                    </li>
                    <li>
                        <div>04</div>
                        <p>Enjoy fast service anytime, anywhere</p>
                    </li>
                </ul>
            </div>
            <div style="display: flex;justify-content: center;"><button @click="infoShow = true">Click to
                    consult</button></div>
        </div>
        <div class="success">
            <p><img src="../../assets/image/left.png">&nbsp;Successful case&nbsp;<img
                    src="../../assets/image/right.png"></p>
            <div>
                <ul>
                    <li v-for="item in caseList1" :key="item.caseId" @mouseenter="changeContent(item)"
                        @mouseleave="recoveryContent(item)">
                        <img :src="imgurl + item.caseImg">
                        <div>
                            <p>Project:{{ item.caseTitle }}</p>
                            <button>Time:{{ item.caseHs }}</button>
                            <span v-html="item.caseTitle2"></span>
                            <div class="gengduo"></div>
                        </div>
                    </li>

                </ul>
                <ul>
                    <li v-for="item in caseList2" :key="item.caseId" @mouseenter="changeContent(item)"
                        @mouseleave="recoveryContent(item)">
                        <img :src="imgurl + item.caseImg">
                        <div>
                            <p>Project:{{ item.caseTitle }}</p>
                            <button>Time:{{ item.caseHs }}</button>
                            <span v-html="item.caseTitle2"></span>
                            <div class="gengduo"></div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="offer">
            <p><img src="../../assets/image/left.png">&nbsp;We also provide these services&nbsp;<img
                    src="../../assets/image/right.png"></p>
            <ul>
                <li>
                    <p>
                        <img src="../../assets/image/kuaiji/jiangpai.png">
                        <span>Building qualification</span>
                    </p>
                    <button @click="gotoZizhi">for more information</button>
                </li>
                <li>
                    <p>
                        <img src="../../assets/image/kuaiji/qianbao.png">
                        <span>Financial service</span>
                    </p>
                    <button @click="gotoQidai">for more information</button>
                </li>
                <li>
                    <p>
                        <img src="../../assets/image/kuaiji/haiwai.png">
                        <span>Overseas business</span>
                    </p>
                    <button @click="gotoHaiwai">for more information</button>
                </li>
                <li>
                    <p>
                        <img src="../../assets/image/kuaiji/caishui.png">
                        <span>Finance and taxation
                            service</span>
                    </p>
                    <button @click="gotoKuaiji">for more information</button>
                </li>
            </ul>
        </div>
        <footer-view></footer-view>
        <side-bar class="fixed"></side-bar>
        <el-dialog title="Please fill in your personal information" :visible.sync="infoShow" width="40%" center>
            <span style="font-size: 12px;color: #A5A5A5;text-align: center;display: block;margin-top: -30px;">We will
                contact you within 30 minutes</span>

            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
                <el-form-item label="Name:" prop="name">
                    <el-input v-model="ruleForm.name" placeholder="Please enter your name"></el-input>
                </el-form-item>
                <el-form-item label="Phone Number:" prop="tel">
                    <el-input v-model="ruleForm.tel" placeholder="Please enter your phone number"></el-input>
                </el-form-item>
                <el-form-item label="Leave a message:" prop="content">
                    <el-input v-model="ruleForm.content" type="textarea" placeholder="Please enter the question you want to consult
 (no more than 300 words)" class="inputC"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm('ruleForm')">Table shortly</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import topView from '@/components/en/topView.vue'
import footerView from '@/components/en/footerView.vue'
import SideBar from '@/components/en/sideBar.vue'
import axios from 'axios';

export default {
    components: { footerView, SideBar, topView },
    data() {
        return {
            infoShow: false,
            personalList: [],
            params: { pageSize: 8, pageNum: 1, personnelType: "3", language: '1' },
            // imgurl: 'http://192.168.0.199:8087/api',
            imgurl: '',
            caseList: [],
            ruleForm: {
                name: '',
                tel: '',
                content: ''
            },
            rules: {
                name: [
                    { required: true, message: '请输入您的姓名', trigger: 'blur' },
                    // { min: 7, max: 11, message: '请输入正确的手机号码', trigger: 'blur' }
                ],
                tel: [
                    { required: true, message: '请输入您的电话', trigger: 'blur' },
                    { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号码', trigger: 'blur' }
                ],
                content: [
                    // { required: true, message: '请输入您的电话', trigger: 'blur' },
                    { min: 0, max: 300, message: '最多输入300字', trigger: 'blur' }
                ]

            },
            caseList1: [],
            caseList2: [],
            text: '',
        }
    },
    created() {
        // this.$store.state.componentKey += 1
        this.getPersonalList()
        this.getcaseList1()
        this.getcaseList2()
        this.website()
        this.imgurl = this.$store.state.imgurl
    },
    methods: {
        getPersonalList() {
            axios.get('/api/pcsystem/TckgPersonnel/list', { params: this.params }).then((res) => {
                if (res.status == 200) {
                    this.personalList = res.data.rows
                    for (let i = 0; i < this.personalList.length; i++) {
                        this.personalList[i].remark = res.data.rows[i].remark.split('。')
                    }
                    // console.log(this.personalList);
                }

            }).catch()
        },
        getcaseList1() {
            axios.get('/api/pcsystem/TckgCase/list', { params: { pageNum: 1, pageSize: 2, caseType: '3', language: '1' } }).then((res) => {
                if (res.status == 200) {
                    this.caseList1 = res.data.rows
                    console.log(this.caseList1);
                }
            }).catch()
        },
        getcaseList2() {
            axios.get('/api/pcsystem/TckgCase/list', { params: { pageNum: 2, pageSize: 2, caseType: '3', language: '1' } }).then((res) => {
                if (res.status == 200) {
                    this.caseList2 = res.data.rows
                    // console.log(this.caseList2);
                }
            }).catch()
        },
        gotoQidai() {
            this.$router.push({ path: '/qidaiE', query: { key: '2' } })
        },
        gotoHaiwai() {
            this.$router.push({ path: '/haiwaiE', query: { key: '3' } })
        },
        gotoZizhi() {
            this.$router.push({ path: '/zizhiE', query: { key: '2' } })
        },
        gotoKuaiji() {
            this.$router.push({ path: '/kuaijiE', query: { key: '2' } })
        },
        //网站访问信息
        website() {
            axios.post('/api/pcsystem/TckgSoftLogin', { userType: '综合事业群' }).then((res) => {
                console.log(res);
            }).catch()
        },
        //提交信息
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // alert('submit!');
                    this.infoShow = false
                    console.log(this.ruleForm);
                    axios.post('/api/pcsystem/TckgConsult', { params: this.ruleForm }).then((res) => {
                        // console.log(res);
                        if (res.status == 200) {
                            // console.log(111111);
                            this.$message({
                                message: '提交成功，请耐心等待',
                                type: 'success'
                            });
                        }
                    }).catch()
                } else {
                    // this.$message({
                    //     message: '提交失败，请稍后重试',
                    //     type: 'warning'
                    // });
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        changeContent(item) {
            this.text = item.caseTitle2
            item.caseTitle2 = item.caseContent
            // console.log(item);
        },
        recoveryContent(item) {
            console.log(this.text);
            item.caseTitle2 = this.text
            // console.log(item);
        }
    }

}
</script>

<style lang="scss" scoped>
.fixed {
    position: fixed;
    top: 150px;
    right: 5px;
}

.backTop {
    position: relative;
    width: 100%;
    height: 26vw;
    min-width: 1200px;
    min-height: 310px;
    background-image: url("../../assets/image/zongheEBack.png");
    background-size: 100% 100%;
}

.kuaijiQ {
    margin: 60px auto;
    display: flex;
    justify-content: space-around;
    width: 1200px;

    img {
        width: 50%;
        border-radius: 0 !important;
    }

    div {
        width: 45%;

        >p {
            margin-bottom: 10px;
            font-size: 22px;
            font-weight: 600;
        }

        .kuaijiQC {
            margin: 20px 0;
            font-size: 16px;
            line-height: 22px;
            font-weight: 400;
        }

        ul {
            margin-top: 50px;
            display: flex;
            justify-content: space-around;
            box-shadow: 0 0 10px 0 #F4F4F4;

            li {
                padding: 20px 30px;
                text-align: center;
                // border-right: 1px solid black;

                span {
                    display: block;
                    font-size: 14px;
                    color: #949494;
                }
            }
        }
    }

}

.canIdo {
    padding: 75px 0 30px;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    ul {
        display: flex;
        justify-content: space-around;
        margin: 60px auto 30px;
        width: 1160px;

        li {
            position: relative;
            width: 27%;
            height: 290px;
            text-align: center;
            background-image: url('../../assets/image/zonghe/591a4aa4eefc273ead8250e88db7dbe1.png');
            background-size: 100% 100%;
            overflow: hidden;

            &:nth-child(2) {
                background-image: url('../../assets/image/zonghe/2cff48d3fc98bc9ec85a87ed7f2716d4.png');

                &:hover {
                    background-image: url('../../assets/image/zonghe/hua2.png');
                }
            }

            &:nth-child(3) {
                background-image: url('../../assets/image/zonghe/591a4aa4eefc273ead8250e88db7dbe1(1).png');
                background-size: 95% 93%;
                background-repeat: no-repeat;
                background-position: 7px 10px;

                &:hover {
                    background-image: url('../../assets/image/zonghe/hua3.png');
                }
            }

            >p {
                display: flex;
                flex-direction: column;
                justify-content: center;
                position: absolute;
                bottom: 11px;
                left: 7px;
                padding: 0 10px;
                padding-bottom: 20px;
                width: 89%;
                height: 70px;
                text-align: center;
                color: #fff;
                background-color: rgba(0, 0, 0, 0.5);
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;

                span {
                    display: block;

                    &:first-child {
                        margin-top: 10px;
                        margin-bottom: 5px;
                        font-size: 18px;
                        font-weight: 600;
                    }

                    &:last-child {
                        font-size: 14px;
                    }
                }
            }

            &:hover {
                background-image: url('../../assets/image/zonghe/hua1.png');
                background-size: 100% 95%;
                background-position: 0px 10px;
                background-repeat: no-repeat;

                p {
                    padding-top: 10px;
                    background-color: rgba(0, 0, 0, 0);
                    bottom: 0px;
                }
            }
        }
    }
}

.service {
    padding: 30px 0;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    ul {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin: 60px auto 0;
        width: 1200px;

        li {
            position: relative;
            margin-bottom: 1%;
            padding: 40px 30px 40px;
            display: flex;
            align-items: center;
            // width: 27%;
            width: 330px;
            height: 80px;
            background-color: #fff;
            border-radius: 10px;
            cursor: pointer;

            >div {
                width: 60px;
                height: 60px;
                background-image: url('../../assets/image/zonghe/gsczb.png');
                background-size: 100% 100%;
            }

            >span {
                margin-left: 20px;
                display: block;
                width: 230px;

                p {
                    margin: 3px 0 10px;
                    font-size: 22px;
                    font-weight: 500;
                    line-height: 22px;
                }

                span {
                    font-size: 14px;
                    color: #B1B1B1;
                }
            }

            &:hover {
                background-image: url('../../assets/image/qidai/bottomLine.png');
                background-size: 100% 10%;
                background-repeat: no-repeat;
                background-position: 0 100%;
            }

            .qizhi {
                position: absolute;
                top: -10px;
                right: 40px;
                margin: 10px 0;
                width: 26px;
                height: 40px;
                color: #F2956C;
                text-align: center;
                line-height: 40px;
                background-image: url('../../assets/image/zizhi/qizhi.png');
                background-size: 100% 100%;
            }

            &:nth-child(2) {
                >div {
                    background-image: url('../../assets/image/zonghe/xkzbl.png');
                }
            }

            &:nth-child(3) {
                >div {
                    background-image: url('../../assets/image/zonghe/zscq.png');
                }
            }

            &:nth-child(4) {
                >div {
                    background-image: url('../../assets/image/zonghe/tsyq.png');
                }
            }

            &:nth-child(5) {
                >div {
                    background-image: url('../../assets/image/zonghe/fzt.png');
                }
            }

            &:nth-child(6) {
                >div {
                    background-image: url('../../assets/image/zonghe/zjqy.png');
                }

                >span {
                    p {
                        margin-bottom: 0;
                        font-size: 20px;
                    }
                }
            }
        }
    }

    button {
        margin-top: 30px;
        margin-left: 48%;
        padding: 5px 15px;
        font-size: 20px;
        font-weight: 500;
        color: #fff;
        background-color: #FE8F3F;
        border: 0;
        border-radius: 20px;
    }

}

.personal {
    padding: 60px 0 100px;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    ul {
        margin: 30px auto 0;
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
        width: 1200px;

        li {
            position: relative;
            margin-top: 1vh;
            margin-left: 10px;
            width: 24%;
            height: 340px;
            background-size: 100% 100%;
            border-radius: 6px;
            overflow: hidden;

            >p {
                margin: 0;
                position: absolute;
                bottom: 0;
                padding: 10px 13px 5px;
                width: 91%;
                min-height: 75px;
                background-color: rgba(0, 0, 0, 0.5);
                text-align: start;
                // border-radius: 0;
                color: #fff;
            }

            .personalT {
                display: block;
                font-size: 18px;
                font-weight: 500;
            }

            .personalC {
                font-size: 11px;
                font-weight: 500;
            }

            .personalRemark {
                margin-top: 5px;
                display: none;
                padding: 5px 10px;
                background-color: #EA5514;
                border-radius: 3px;
                font-size: 9px;
                line-height: 15px;
            }

            &:hover {
                .personalRemark {
                    display: block;
                }
            }
        }
    }

    button {
        margin-top: 20px;
        margin-left: 48%;
        padding: 2px 8px;
        color: #9B9B9B;
        border: 1px solid#9B9B9B;
    }
}

.flow {
    padding: 70px 0 50px;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    >ul {
        margin: 30px auto 20px;
        display: flex;
        justify-content: space-around;
        width: 1200px;

        li {
            position: relative;
            width: 60px;
            height: 260px;
            color: #EA5514;
            border: 1px solid #FBAD8B;
            border-radius: 4px;

            div {
                position: absolute;
                top: -52px;
                left: 20px;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                background-color: #F2D5C8;
            }

            p {
                margin: 10px auto;
                width: 40px;
                height: 40px;
                font-size: 18px;
                text-align: center;
                line-height: 40px;
                background-color: #FFF4EE;
                border-radius: 50%;
            }

            span {
                line-height: 60px;
                writing-mode: vertical-rl;
            }

            &:hover {
                background-color: #F6EBE5;
                border: 1px solid #F6EBE5;

                div {
                    background-color: #EA5514;
                }

                p {
                    background-color: #EA5514;
                    color: #fff;
                }
            }

            &:nth-child(4),
            &:nth-child(9) {
                width: 60px;

                div {
                    left: 20px;
                }

                span {
                    margin-left: 7px;
                    line-height: 23.7px;
                }
            }
        }
    }
}

.advantages {
    padding: 70px 0 10px;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    >div {
        display: flex;
        margin: 40px auto;
        width: 1200px;

        img {
            width: 50%;
        }

        ul {
            margin-top: 30px;
            margin-left: 50px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 50%;
            height: 330px;

            li {
                display: flex;
                align-items: center;
                margin-top: 20px;
                padding: 8px 15px;
                width: 90%;
                height: 56px;
                background-color: #fff;
                border-radius: 6px;

                div {
                    width: 30px;
                    height: 30px;
                    background-image: url('../../assets/image/kuaiji/quan.png');
                    background-size: 100% 100%;
                    text-align: center;
                    line-height: 30px;
                    color: #fff;
                    font-weight: 600;
                }

                p {
                    margin-left: 20px;
                    width: 400px;
                    line-height: 20px;
                    font-size: 20px;
                }

                &:hover {
                    margin-left: -5%;
                    width: 95%;
                    background-color: #EA5514;
                    color: #fff;

                    div {
                        background-image: url('../../assets/image/kuaiji/quanH.png');
                        color: #EA5514;
                    }
                }
            }
        }
    }

    button {
        margin: 60px 0 0px;
        width: 252px;
        height: 56px;
        padding: 5px 15px;
        font-size: 26px;
        font-weight: 500;
        color: #fff;
        background: linear-gradient(180deg, #FFB552 0%, #FF4D00 100%);
        border: 0;
        border-radius: 30px;
        text-align: center;
    }
}

.success {
    padding: 30px 0 0px;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    >div {
        display: flex;
        justify-content: space-around;
        margin: 60px auto 0;
        width: 1160px;

        ul {
            // display: flex;
            // justify-content: space-around;
            // flex-wrap: wrap;
            // margin: 30px auto 0;
            // width: 1160px;
            // width: 50%;
            width: 49%;
            height: 500px;
            overflow: hidden;

            li {
                display: flex;
                margin-bottom: 20px;
                padding: 20px 15px;
                width: 94%;
                // height: ;
                // width: 46%;
                background-color: #fff;
                border-radius: 6px;

                img {
                    width: 30%;
                    height: 200px;
                }

                div {
                    position: relative;
                    width: 70%;
                    margin-left: 10px;
                    // position: relative;
                    text-align: start;
                    cursor: pointer;

                    // width: 500px;
                    p {
                        margin-top: 10px;
                        text-align: start;
                        font-size: 16px;
                        font-weight: 600;
                    }

                    span {
                        display: block;
                        margin-top: 10px;
                        font-size: 14px;
                        line-height: 25px;
                        color: #AFAFAF;
                        // display: -webkit-box;
                        // -webkit-box-orient: vertical;
                        // -webkit-line-clamp: 3;
                        // overflow: hidden;
                        // text-overflow: ellipsis;
                    }

                    button {
                        margin-top: 5px;
                        padding: 2px 5px;
                        border: 0;
                        border-radius: 2px;
                        background-color: #E6F2CC;
                        color: #A4C361;
                    }

                    .gengduo {
                        position: absolute;
                        right: 0px;
                        top: 10px;
                        width: 20px;
                        height: 4px;
                        background-image: url('../../assets/image/gengduo.png');
                        background-size: 100% 100%;
                    }
                }

                &:hover {
                    // position: absolute;
                    // position: relative;
                    // height: 455px;
                    height: 460px;
                    color: #fff;
                    background-color: #EA5513;

                    img {
                        width: 0;
                    }

                    div {
                        width: 90%;

                        span {
                            color: #fff;
                            display: block;
                            // width: 90%;
                        }

                        button {
                            color: #fff;
                            background-color: #EE824E;
                        }
                    }

                    .gengduo {
                        display: none;
                    }
                }

                &:last-child {
                    margin-bottom: 0;

                    &:hover {
                        position: relative;
                        // top: -255px;
                        top: -260px;
                    }
                }

                &:first-child {
                    &:hover {
                        position: relative;
                        top: 0px;
                    }
                }
            }
        }
    }

    >button {
        margin-left: 48%;
        padding: 5px 15px;
        font-size: 20px;
        font-weight: 500;
        color: #fff;
        background-color: #FE8F3F;
        border: 0;
        border-radius: 20px;
    }
}

.offer {
    padding: 60px 0 50px;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    ul {
        display: flex;
        justify-content: space-around;
        margin: 50px auto;
        width: 1200px;

        li {
            width: 24%;
            background-color: #fff;
            border-radius: 6px;
            text-align: center;

            p {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: auto;
                padding: 40px 40px 20px;
                width: 40%;
                text-align: center;
                border-bottom: 1px dashed #E1E1E1;

                img {
                    // margin: auto;
                    width: 50px;
                    height: 50px;
                }

                span {
                    display: block;
                    margin-top: 10px;
                    width: 200px;
                    font-size: 18px;
                    font-weight: 500;
                }
            }

            button {
                margin: 20px 0;
                padding: 5px 15px;
                color: #fff;
                background-color: #EA5514;
                border-radius: 15px;
                border: 0;
            }

            &:nth-child(1) {
                img {
                    width: 60px;
                    height: 60px;
                }

                p {
                    padding-top: 35px;

                    span {
                        margin-top: 5px;
                    }
                }
            }

            &:nth-child(3) {
                img {
                    width: 58px;
                    height: 58px;
                }

                p {
                    padding-top: 35px;

                    span {
                        margin-top: 5px;
                    }
                }
            }

            &:nth-child(4) {
                img {
                    width: 70px;
                    height: 65px;
                }

                p {
                    padding-top: 22px;
                    padding-bottom: 0;

                    span {
                        margin-top: 5px;
                    }
                }
            }
        }
    }
}

.orange {
    padding: 50px 0;
    min-width: 1200px;
    width: 100%;
    background-image: url("../../assets/image/橙色背景.png");
    background-size: 100% 100%;
    text-align: center;
    color: #fff;
    font-size: 40px;
    font-weight: 500;
}

.info {
    margin: auto;
    position: relative;
    width: 350px;
    border-radius: 6px;
    text-align: center;
    overflow: hidden;
    background-color: #fff;

    p {
        padding: 20px 40px 40px;
        font-size: 20px;
        font-weight: 600;
        color: #fff;
        background-color: #EA5513;
    }

    .name {
        position: absolute;
        top: 60px;
        left: 140px;
        width: 50px;
        height: 50px;
    }

    .cheng {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 25px;
        height: 25px;

    }

    >img {
        &:last-child {
            margin: 40px 0 20px;
            width: 70%;
        }

    }
}

::v-deep .el-dialog--center .el-dialog__body {
    padding-bottom: 0;
}

::v-deep .el-form {
    margin-top: 30px;
    margin-left: -20px;
}

::v-deep .el-textarea__inner {
    height: 150px !important;
}
</style>