<template>
    <div>
        <top-view></top-view>
        <div class="backTop">
            <!-- <div>
                <p>走进唐诚</p>
                <span class="english">ENTERING TANG CHENG</span>
                <button  @click="gotoAboutus">了解更多</button>
            </div> -->
        </div>
        <div class="jiyu">
            <p><img src="../../assets/image/left.png">&nbsp;文化塑品牌&nbsp;<img src="../../assets/image/right.png"></p>
            <span>CULTURE SHAPING BRAND</span>
            <div>
                <!-- <div> -->
                <p>因缘而聚 为梦同行</p>
                <span>一支优秀的团队 注定是一道靓丽的风景
                    <br>我们因缘而聚 为梦同行
                    <br>以至善之心凝聚向心力量
                    <br>以创新之智缔造卓越品质
                    <br>以完美之愿开拓行业未来
                    <br>心存鸿鹄之志 常怀赤诚之心
                    <br>无惧挑战 勇往直前
                    <br>这是一段属于每一个唐诚人的旅程</span>
                <!-- </div> -->
            </div>
        </div>
        <div class="yuanjing">
            <ul>
                <li>
                    <img src="../../assets/image/qiyewenhua/content1.png">
                    <div>
                        <p>愿景<span style="color: #FDEBE4;font-size: 28px;font-weight: 500;display: inline;">VISION</span></p>
                        <!-- <button style="margin-left: 0;">专业精研</button>
                        <button>静水流深</button> -->
                        <br />
                        <span style="color: #4C4C4C;font-size: 22px;">携手亿企辉煌 · 缔造百年唐诚</span>
                        <p class="littleT">【时代波澜壮阔，我们躬身入局】</p>
                        <span style="margin-left: 6px;">唐诚创始团队年轻而充满朝气，具有创新意识并勇于迎接挑战。怀揣赤诚的理想，丰富的运营经验，誓诺服务“亿”家企业，用一生去深耕行业之土。踏实的践行心中理念，用最初的心，做永远的事，努力打造“唐诚”这个行业世纪品牌。美好愿景，行则将至。</span>
                    </div>
                </li>
                <li>
                    <img src="../../assets/image/qiyewenhua/content2.png">
                    <div>
                        <p>使命<span style="color: #FDEBE4;font-size: 28px;font-weight: 500;display: inline;">MISSION</span></p>
                        <!-- <button style="margin-left: 0;">专业精研</button> -->
                        <!-- <button>静水流深</button> -->
                        <br />
                        <span style="color: #4C4C4C;font-size: 22px;">客户因唐诚而卓越 · 员工因唐诚而幸福</span>
                        <p class="littleT">【择一事忠一生，千万锤成一器】</p>
                        <span style="margin-left: 6px;">以客户为中心是唐诚构筑企服行业高质量发展的奠基石，我们一直致力于满足客户多元化需求。从境内十城布局，到境外多国网点开设；从系统化、数字化服务体系搭建，到Al智能终端研发，唐诚始终以：为客户提供高效、快捷、全面服务为使命。
                            成就客户卓越离不开优秀的唐诚人，为此唐诚建设初期就将“幸福唐诚”写入公司宪法,将保障员工权益和完善人才体系作为集团发展的战略方案，开创员工“成长基金”“股权基金”“游学基金”，在提升员工收入同时，也注重员工个人成长。</span>
                    </div>
                </li>
                <li>
                    <img src="../../assets/image/qiyewenhua/content3.png">
                    <div>
                        <p>价值观<span style="color: #FDEBE4;font-size: 28px;font-weight: 500;display: inline;">VALUES</span></p>
                        <!-- <button style="margin-left: 0;">专业精研</button>
                        <button>静水流深</button> -->
                        <br />
                        <span style="color: #4C4C4C;font-size: 22px;">诚实守信 · 激情敬业 · 拥抱变化 · 同创共荣</span>
                        <p class="littleT">【以真诚之心，行信义之事】</p>
                        <span style="margin-left: 6px;">唐诚萌芽于山西，创始人来自晋商之魂的故乡，浓厚的晋商诚信文化深深影响着每一个唐诚人。三年来唐诚人的足迹遍布全国各地，战略合作全国同行30余家。在跨区域,跨业态整合的过程中，唐诚人总能及时调整步伐，突破认知边界，实现互惠互利。目前为止，唐诚对外累计吸纳27名高素质人才成为战略合伙人，对内累计培养13名专业人员成为团队领头羊。</span>
                    </div>
                </li>
            </ul>
        </div>


        <footer-view></footer-view>
        <side-bar class="fixed"></side-bar>

    </div>
</template>

<script>
import topView from '@/components/zh/topView.vue'
import footerView from '@/components/zh/footerView.vue'
import SideBar from '@/components/zh/sideBar.vue'
import axios from 'axios';

export default {
    components: { footerView, SideBar, topView },
    data() {
        return {
            infoShow: false,
        }
    },
    created() {
        // this.$store.state.componentKey += 1
        this.website()
    },
    methods: {
        gotoAboutus() {
            this.$router.push('/aboutus')
        },
        //网站访问信息
        website() {
            axios.post('/api/pcsystem/TckgSoftLogin', { userType: '企业文化' }).then((res) => {
                console.log(res);
            }).catch()
        },
    }

}
</script>

<style lang="scss" scoped>
.fixed {
    position: fixed;
    top: 150px;
    right: 5px;
}

.backTop {
    position: relative;
    width: 100%;
    height: 26vw;
    min-width: 1200px;
    min-height: 310px;
    // height: 500px;
    // height: 450px;
    background-image: url("../../assets/image/qiyewenhuaBack.png");
    background-size: 100% 100%;

    // div {
    //     margin: auto;
    //     padding-top: 80px;
    //     width: 1160px;
    //     color: #EA5514;
    //     text-align: center;
    // }

    // p {
    //     margin: 20px 0;
    //     color: #fff;
    //     font-size: 50px;
    //     font-weight: 700;
    // }

    // .english {
    //     display: block;
    //     font-size: 16px;
    //     color: #fff;
    // }

    // button {
    //     margin-top: 30px;
    //     width: 100px;
    //     height: 30px;
    //     line-height: 30px;
    //     color: #fff;
    //     background-color: #FFB258;
    //     border: 0;
    //     border-radius: 15px;
    // }
}

.jiyu {
    // position: relative;
    // margin: auto;
    padding: 75px 0 30px;
    min-width: 1200px;
    // background-color: #F4F4F4;
    background-image: url('../../assets/image/qiyewenhua/e744b11c2624cf0f57638b5ae2c482faab8ce1db5364a-YyRkJR_fw1200.png');
    background-size: 100% 100%;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 700;
        text-align: center;

        >img {
            // position: absolute;
            // top: 36px;
            // left: 816px;
            position: relative;
            top: 3px;
            margin-left: 0;
            width: 33px;
            height: 24px;

            &:last-child {
                // left: 1055px;
            }
        }
    }

    >span {
        display: block;
        text-align: center;
        color: #999999;
        font-size: 22px;
        font-weight: 400;
    }

    >div {
        // padding: 20px;
        margin: 20px;


        text-align: center;

        // >div {
        //     margin: 40px auto;
        //     width: 1160px;
        //     text-align: center;
        // }
        p {
            margin-top: 40px;
            margin-bottom: 20px;
            color: #EA5514;
            font-size: 30px;
            font-weight: 600;
        }

        span {
            line-height: 36px;
            font-size: 20px;
        }
    }


}

.yuanjing {
    padding: 20px 0;
    min-width: 1200px;
    background-color: #F4F4F4;

    ul {
        margin: 30px auto;
        width: 1160px;

        li {
            margin-top: 20px;
            display: flex;
            justify-content: space-around;
            padding: 30px 20px;
            background-color: #fff;
            border-radius: 6px;

            img {
                // width: 25%;
                width: 335px;
                height: 315px;
            }

            div {
                margin-left: 37px;
                // width: 70%;
                width: 760px;

                p {
                    margin-top: 10px;
                    font-size: 28px;
                    font-weight: 500;
                }

                // button {
                //     margin: 10px 30px 10px 0;
                //     padding: 3px 10px;
                //     border: 0;
                //     border-radius: 2px;
                //     background-color: #F3F9E8;
                //     color: #AACE5C;
                // }

                span {
                    display: block;
                    width: 97%;
                    font-size: 18px;
                    line-height: 28px;
                    color: #ADADAD;
                }

                .littleT {
                    margin-top: 10px;
                    padding: 10px 0;
                    // border-top: 1px dashed #9C9C9C;
                    color: #EA5514;
                    font-size: 18px;
                }
            }

            &:hover {
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
            }

        }
    }
}
</style>