<template>
    <div>
        <top-view></top-view>
        <div class="backTop">
        </div>
        <div class="jiuye">
            <p><img src="../../assets/image/left.png">&nbsp;Run out of guaranteed employment acceleration&nbsp;<img src="../../assets/image/right.png"></p>
            <div>
                <div>
                    <img src="../../assets/image/xiaoqi/pic1.png" alt="">
                    <img src="../../assets/image/xiaoqi/pic2.png" alt="">
                    <img src="../../assets/image/xiaoqi/pic3.png" alt="">
                </div>
                <ul>
                    <li>
                        <p>Employment is the foundation of people's livelihood,stable employment means stable people's livelihood,and promote employment means promote development.To ensure stable employment,we must protect key groups and broaden employment channels. College employment students are valuable human resources, and they are also key groups to ensure stable employment.</p>
                    </li>
                    <li>
                        <p>The development of jobs suitable for college graduates by industry and region, and the holding of regional, industrial,professional and other customized recruitment activities to promote the employment of college students in a prominent position.</p>

                    </li>
                    <li>
                        <p>We have helped Capotal University of Economics and Business, Business School of Shanxi University, Liaoning University of Technology, lnner Mongolia Baotou Vocational and Technical College and other universities to solve the problem of student employment, and provided a total of 200 jobs for fresh college students in the past three years.</p>

                    </li>
                </ul>
            </div>
        </div>
        <div class="bangfu">
            <p><img src="../../assets/image/left.png">&nbsp;Help small enterprises to help enterprises keep warm&nbsp;<img src="../../assets/image/right.png"></p>
            <div>
                <div>
                    <ul>
                        <li>
                            <p>The outbreak of COVID-19 has dealt a severe blow to the global economy, and it is especially fatal for small and micro businesses. As a service provider for small and micro enterprises, Tang Cheng is duty-bound to help small and micro enterprises.</p>
                        </li>
                        <li>
                            <p>During the epidemic period, we provided 3-12 months free bookkeeping services for more than 1,000 small and micro enterprises in trouble. Free of charge for the enterprise cross-regional business handling more than 12,000 times, to help enterprises resume production and work.</p>

                        </li>
                    </ul>
                    <div>
                        <img src="../../assets/image/xiaoqi/zhengtiback.png" alt="">
                    </div>
                </div>
            </div>
        </div>

        <footer-view></footer-view>
        <side-bar class="fixed"></side-bar>

    </div>
</template>

<script>
import topView from '@/components/en/topView.vue'
import footerView from '@/components/en/footerView.vue'
import SideBar from '@/components/en/sideBar.vue'
import axios from 'axios';

export default {
    components: { footerView, SideBar, topView },
    data() {
        return {
            infoShow: false,
        }
    },
    created() {
        // this.$store.state.componentKey += 1
        this.website()
    },
    methods: {
        gotoAboutus() {
            this.$router.push('/aboutus')
        },
        //网站访问信息
        website() {
            axios.post('/api/pcsystem/TckgSoftLogin', { userType: '校企合作' }).then((res) => {
                console.log(res);
            }).catch()
        },
    }

}
</script>

<style lang="scss" scoped>
.fixed {
    position: fixed;
    top: 150px;
    right: 5px;
}

.backTop {
    position: relative;
    width: 100%;
    height: 26vw;
    min-width: 1200px;
    min-height: 310px;
    background-image: url("../../assets/image/xiaoqiEBack.png");
    background-size: 100% 100%;

}


.jiuye {
    padding: 60px 0;
    min-width: 1200px;
    background-color: #F4F4F4;

    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    >div {
        margin: 50px auto 30px;
        display: flex;
        justify-content: space-between;
        width: 1200px;

        >div {
            width: 48%;

            img {
                margin: 3px 5px;
                width: 48%;

                &:first-child {
                    width: 98%;
                }
            }
        }

        ul {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 52%;
            margin-left: 10px;
            margin-top: -20px;

            li {
                padding: 20px 0 0;
                width: 100%;
                height: 140px;
                background-image: url('../../assets/image/xiaoqi/back.png');
                background-size: 100% 100%;

                &:nth-child(2) {
                    background-image: url('../../assets/image/xiaoqi/backtwo.png');
                    p{
                        margin: 30px 20px;
                    }
                }
                &:nth-child(1) {
                    p{
                        margin: 15px 20px;
                    }
                }

                p {
                    margin: 20px;
                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }
    }


}

.bangfu {
    min-width: 1200px;
    background-color: #F4F4F4;


    >p {
        margin-bottom: 10px;
        font-size: 34px;
        font-weight: 500;
        text-align: center;

        >img {
            margin-left: 0;
            width: 33px;
            height: 24px;
        }
    }

    >div {
        margin-top: 60px;
        padding: 40px 0 60px;
        background-image: url('../../assets/image/xiaoqi/background.png');
        background-size: 100% 100%;

        >div {
            margin: 50px auto 10px;
            display: flex;
            justify-content: space-between;
            width: 1200px;

            >div {
                width: 55%;

                img {
                    margin: 3px 5px;

                    &:first-child {
                        width: 620px;
                        height: 380px;
                    }
                }
            }

            ul {
                display: flex;
                flex-direction: column;
                // justify-content: space-between;
                width: 45%;
                margin-top: -28px;

                li {
                    padding: 40px 40px 30px;
                    width: 88%;
                    min-height: 140px;
                    background-image: url('../../assets/image/xiaoqi/backT.png');
                    background-size: 100% 100%;

                    &:last-child {
                        margin-top: 2px;
                        background-image: url('../../assets/image/xiaoqi/backF.png');
                        p{
                            margin-top: 0px;
                        }
                    }

                    p {
                        margin: 20px 0;
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }
        }
    }
}
</style>